import React, {useState} from 'react'
import {
    Project,
    useProjectTransferMutation,
    User, UsersUnion
} from "../../generated-types";
import {Modal} from 'antd'
import UserSearch, {UserSearchResults, UserValue} from "../User/UserSearch";
import {ArrowRightOutlined, TeamOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import TooltipButton from "../TooltipButton";

interface ProjectTransferProps {
    project: Project
}

const ProjectTransfer = ({project}: ProjectTransferProps) => {
    const {t} = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ownerU, setOwnerU] = useState<User | null>(project.transfer?.user ?? null);
    const [ownerUU, setOwnerUU] = useState<UsersUnion | null>(
        project.transfer?.usersUnion ?? null
    );

    const [ProjectTransfer, opt2] = useProjectTransferMutation({
        onCompleted: _ => {
            setIsModalOpen(false);
        }
    });
    const handleOk = () => {
        if (!ownerU && !ownerUU) return;
        ProjectTransfer({
            variables: {
                projectId: project.id,
                userAsOwnerId: ownerU?.id,
                usersUnionAsOwnerId: ownerUU?.id
            }
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let title = t('project.transfer')

    if (project.transfer) {
        title = t('project.transferWaiting') + (
            project.transfer.usersUnion?.title ??  project.transfer.user?.fullName ?? project.transfer.user?.username)
    }

    // TODO: эту фигню надо перенести внутрь UserSearch
    let owner = ownerU
        ? [{value: "u/"+ownerU.id, label: ownerU?.username} as UserValue]
        : (ownerUU
            ? [{value: "uu/"+ownerUU?.id, label: <div><TeamOutlined rev={undefined}/> <span>{`${ownerUU?.title}(${ownerUU?.name})`}</span></div>} as UserValue]
                : [])
        ;

    return (<>
        <Modal open={isModalOpen} width={"45%"} closable={true} centered={true}
               title={t('project.transfer')}
               okText={t('project.transfer')}
               onOk={handleOk} onCancel={handleCancel}
               okButtonProps={{
                   disabled: !ownerU && !ownerUU
               }}
        >
            Порядок передачи проекта:<br/>
            1. Вы указываете пользователя или команду, кому хотите передать проект<br/>
            <UserSearch addUsersUnions={true} addEmails={false} checkSlots={false}
                        initialState={owner} members={[]} invites={[]}
                        membersAdded={(result: UserSearchResults) => {
                            setOwnerU(null)
                            setOwnerUU(null)

                            if (result.users.length > 0)
                                setOwnerU(result.users[0])

                            if (result.unions.length > 0)
                                setOwnerUU(result.unions[0])

                            return false; // возвращает false, чтобы выбранное значение оставалось в UserSearch
                        }} />
            2. Принимающий пользователь или владелец команды подтверждает передачу проекта<br/>
            <i>Только после этого освободятся слоты, занимаемые участниками этого проекта (если они есть)</i><br/>
            <br/>
        </Modal>
        <TooltipButton icon={<ArrowRightOutlined />} ghost block type={"primary"}
                       loading={opt2.loading}
                onClick={()=> {
                    setIsModalOpen(true)
                }}
               tooltipProps={{
                   title:  t('project.transferTooltip')
               }}
        >{title}</TooltipButton></>)
}

export default ProjectTransfer;