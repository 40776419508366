import {Modal} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TariffsTable} from "../TariffsTable/tariffsTable";
import {paymentWidgetContext} from "./PaymentWidgetModal";
import {
    usePayments_ChangeTariffMutation,
    usePayments_GetPaymentLinkLazyQuery,
} from "../../generated-types";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";


export const ModalTariffsTable: React.FC = () => {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const ctx = useReactiveVar(tariffTableModalContext);
    const [getPaymentLink] = usePayments_GetPaymentLinkLazyQuery();
    const [getChangeTariff] = usePayments_ChangeTariffMutation();
    const paymentAccountId = ctx.paymentAccountId
    const currentTariffId = ctx.currentTariffId


    const changeTariff = (id: string) => {
        getChangeTariff({
            variables: {
                tariffId: id,
                paymentAccountId: paymentAccountId!
            }
        }).then((res) => {
            const paymentReceipt = res.data?.payments_changeTariff.paymentReceipt
            if (paymentReceipt) {
                getPaymentLink({
                    variables: {
                        paymentAccountId: paymentAccountId!,
                        input: {
                            amount: paymentReceipt.amount,
                            receiptId: paymentReceipt.id
                        }
                    },
                    onCompleted: (data) => {
                        paymentWidgetContext({
                            confirmationToken: data.payments_getPaymentLink.confirmationToken,
                            paymentAccountId: paymentAccountId!,
                            receiptId: res.data?.payments_changeTariff.paymentReceipt?.id!
                        })
                        onClose();
                    }
                })
            }

        })
    }
    if (!open) {
        setOpen(true)
    }

    const onClose = () => {
        tariffTableModalContext({
            paymentAccountId: undefined,
            currentTariffId:undefined
        })
        setOpen(false);
    };
    return (
        <Modal
            open={open}
            onCancel={onClose}
            cancelText={t('paymentAccount.return')}
            okText={t('paymentAccount.pay')}
            width={1000}
            cancelButtonProps={{style: {display: 'none'}}}
            okButtonProps={{style: {display: 'none'}}}
            centered
        >
            <TariffsTable onClick={changeTariff} currentTariffId={currentTariffId}/>
        </Modal>
    )
}

const TariffTableModalEditor: React.FC = () => {
    const ctx = useReactiveVar(tariffTableModalContext);
    const id = ctx.paymentAccountId;

    if (!id)
        return null;

    return <ModalTariffsTable/>;
};

interface TariffTableModalContext {
    paymentAccountId: string | undefined;
    currentTariffId:string|undefined;
}

function emptyState(): TariffTableModalContext {
    return {
        paymentAccountId: undefined,
        currentTariffId:undefined
    }
}

export const tariffTableModalContext: ReactiveVar<TariffTableModalContext> = makeVar<TariffTableModalContext>(emptyState());

export default TariffTableModalEditor;