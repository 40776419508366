import React from "react";
import {Alert, Button} from "antd";
import {clearToken} from "./authContext";

const UserIsAuthenticated: React.FC = () => {
    return (<div>
        <Alert type="success" message={"IS AUTHENTICATED"} banner />
        <Button onClick={clearToken}>SignOut</Button>
    </div>)
}

export default UserIsAuthenticated