import {Button, Tooltip} from "antd";
import {useTaskResolvedMutation} from "../../generated-types";
import {BorderOutlined, CheckSquareOutlined} from '@ant-design/icons'
import React, { useState } from "react";
import { useTranslation } from "react-i18next";


export interface TaskResolvedProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string
    value: boolean,
    onChange?: (value: boolean) => void;
    disabled?:boolean;
}

export const ResolvedColor = "rgba(45,183,245,0.7)";
export const ResolvedCard = (resolved: boolean, style: React.CSSProperties) => {
    return {
        ...style,
        // borderRight: resolved ? `${ResolvedColor} solid 2px` : undefined,
        // borderLeft: resolved ? `${ResolvedColor} solid 2px` : undefined,
        // background: resolved ? `linear-gradient(-90deg, ${ResolvedColor}, 10%, transparent, transparent)` : undefined
    }
}

function TaskResolved(props: TaskResolvedProps) {
    const {t} = useTranslation();
    const [value, setValue] = useState<boolean>(props.value)

    const [taskResolved] = useTaskResolvedMutation({
        onCompleted: () => {
            if (props.onChange) {
                props.onChange(value)
            }
        }
    })

    return (
        <Tooltip title={t(value ? "task.resolved" : "task.resolve")}>
            <Button icon={value ? <CheckSquareOutlined /> : <BorderOutlined />} type="text" shape={"circle"}
                    style={{color: value ? ResolvedColor : ResolvedColor}}
                    disabled={props.disabled}
                    onClick={(e)=>{
                        setValue(!value)
                        taskResolved({variables: { id: props.id, resolved: !value }})
                        e.preventDefault();
                        e.stopPropagation();
                    }}
            />
            {/*<Switch checkedChildren={t('task.includeResolved')} unCheckedChildren={t('task.includeResolved')} defaultChecked={value}*/}
            {/*        style={{background: value ? ResolvedColor : undefined}}*/}
            {/*        onChange={(checked, event)=>{*/}
            {/*            setValue(checked)*/}
            {/*            taskResolved({variables: {*/}
            {/*                id: props.id,*/}
            {/*                    resolved: checked*/}
            {/*                }})*/}
            {/*        }}*/}
            {/*/>*/}
        </Tooltip>)
}

export default TaskResolved