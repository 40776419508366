import {makeVar, ReactiveVar} from "@apollo/client";
import {Project, Tag, User, UsersUnion} from "../generated-types";
import {authState} from "../routes/Auth/authContext";
import {allUsersUnions} from "./allUsersUnions";
import {onlyUnique} from "../utils";

export class AllTags {
    tags: Tag[] = [];

}

export const allPersonalTags: ReactiveVar<AllTags> = makeVar<AllTags>(new AllTags());
