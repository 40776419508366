import {Badge, BadgeProps, Button, Calendar, CalendarProps, Col, Row, Segmented, Select, Space,} from 'antd';
import React, {useEffect, useState} from 'react';
import {Task, TasksView, useCalendarItemsQuery} from "../../generated-types";
import dayjs, {Dayjs} from 'dayjs';
import {editTaskContext} from "./TaskEditor";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import TaskTitle from "./TaskTitle";
import {FormatTime} from "../TimeIntervalSelector";
import {useReactiveVar} from "@apollo/client";
import {addTaskContext} from "./TaskNew";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

dayjs.extend(weekOfYear)
dayjs.extend(localeData)

const getMonthData = (value: Dayjs) => {
    if (value.month() === 8) {
        return 1394;
    }
};

const filterTasksForDay = (d: Dayjs, tasks: Task[]) => {
    d = d.startOf("day")
    const filtered = tasks.filter(t => {
        const start = !t.startDate ? undefined : dayjs(t.startDate).startOf("day");
        const end = !t.endDate ? undefined : dayjs(t.endDate).startOf("day");

        return (
            start && d.diff(start, "day") == 0 ||
            end && d.diff(end, "day") == 0 ||
            start && end && d.diff(start, "day") > 0 && d.diff(end, "day") < 0
        )
    })
    return filtered
}

const openTask = (task: Task) => {
    editTaskContext({taskId: task.id});
}

const DateSelector = ({value, onChange, type}: {
    value: Dayjs;
    type: "year" | "month" | "week";
    onChange: (date: Dayjs) => void;
}) => {
    const [v, setV] = useState(value)
    const [displayValue, setDisplayValue] = useState("")
    const [options, setOptions] = useState<any[]>([])

    useEffect(() => {
        let current = v.clone();
        const localeData = v.localeData();

        const opts = [];

        switch (type) {
            case "year":
                const year = v.year();
                for (let i = year - 10; i < year + 10; i += 1) {
                    opts.push(
                        <Select.Option key={i} value={i} className="year-item">
                            {i}
                        </Select.Option>,
                    );
                }
                break;
            case "month":
                const months = [];
                for (let i = 0; i < 12; i++) {
                    current = current.month(i);
                    months.push(localeData.monthsShort(current));
                }

                for (let i = 0; i < 12; i++) {
                    opts.push(
                        <Select.Option key={i} value={i} className="month-item">
                            {months[i]}
                        </Select.Option>,
                    );
                }
                break;
            case "week":
                for (let i = 1; i <= 52; i++) {
                    // TODO: тут в выпадающем списке можно показывать интервал дат для этой недели
                    opts.push(
                        <Select.Option key={i} value={i} className="month-item">
                            W{i}
                        </Select.Option>,
                    );
                }
                break;
        }
        setOptions(opts)
    }, [type]);


    useEffect(() => {
        let dv = "";

        if (value != null)
            switch (type) {
                case "year":
                    dv = value.format("YYYY")
                    break;
                case "month":
                    dv = value.format("MMMM")
                    break;
                case "week":
                    dv = "W" + value.week().toString()
                    break;
            }
        setV(value)
        setDisplayValue(dv)
    }, [value]);

    const changeValue = (cnt: number) => {
        const newV = v.clone().add(cnt, type);
        setV(newV)
        onChange(newV)
    }

    const onChangeSelect = (v: any) => {
        let newV = value.clone()
        switch (type) {
            case "year":
                newV = newV.set("year", v);
                break;
            case "month":
                newV = newV.set("month", v);
                break;
            case "week":
                newV = newV.add(v - newV.week(), "weeks");
                break;
        }

        setV(newV)
        onChange(newV)
    }

    return (
        <Space.Compact block>
            <Button icon={<LeftOutlined/>} onClick={() => changeValue(-1)}/>
            <Select
                size="middle"
                showArrow={false}
                popupMatchSelectWidth={false}
                value={displayValue}
                onChange={onChangeSelect}
                style={{minWidth: type === "month" ? 90 : 55}}
            >
                {options}
            </Select>
            <Button icon={<RightOutlined/>} onClick={() => changeValue(+1)}/>
        </Space.Compact>
    )
}

const weekDays = [0, 1, 2, 3, 4, 5, 6];


const byStart = (t1: Task, t2: Task) => {
    return (t1.startTime ?? -1) < (t2.startTime ?? -1) ? -1 : 1
};

interface EmptyTimeProps {
    date: Dayjs,
    end: number,
    start: number,
    taskView: TasksView,
    projectId?: string
}

const EmptyTime: React.FC<EmptyTimeProps> = ({projectId, date, start, end, taskView}) => {

    const addTaskCtx = useReactiveVar(addTaskContext);

    const AddTask = () => {
        addTaskCtx.taskViewId = taskView.id;
        addTaskCtx.startDate = date.toDate();
        addTaskCtx.endDate = date.toDate();
        addTaskCtx.startTime = start;
        addTaskCtx.endTime = end;
        addTaskCtx.projectId = taskView.project?.id ?? projectId;
        addTaskContext({...addTaskCtx});
    }

    return <div>
        <Button type={"text"} size={"small"} onClick={AddTask} block={true} style={{textAlign: "right"}}>
            {FormatTime(start)} - {FormatTime(end)}
        </Button>
    </div>
    // return <div style={{color: "lightgray"}}>{FormatTime(start)} - {FormatTime(end)} свободно</div>
}

const TasksCalendar: React.FC<{tasks: Task[], taskView: TasksView, showProjectTitle?: boolean}> = ({tasks, taskView,showProjectTitle}) => {
    const {t} = useTranslation()
    const [mode, setMode] = useState<"month" | "week">("week")
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf("week"))

    const filtered = tasks.filter(t => {
        return t.startDate || t.endDate || t.startTime || t.endTime;
    })


    const {data, variables} = useCalendarItemsQuery({
        variables: {
            filter: {
                startDate: startDate.startOf(mode),
                endDate: startDate.endOf(mode)
            }
        }
    });

    const dateCellRender = (value: Dayjs) => {
        const listData = filterTasksForDay(value, filtered)

        return (
            <ul className="events">
                {listData.map((task) => (
                    <li style={{cursor: "pointer"}} key={task.id} onClick={(e) => {
                        openTask(task)
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <Badge status={"warning" as BadgeProps['status']} text={task.title}/>
                    </li>
                ))}
            </ul>
        );
    };

    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        return info.originNode;
    };

    const onTypeChange = (t: any) => {
        setMode(t)
    }

    const header = <div style={{padding: 8}}>
        <Row gutter={8}>
            <Col>
                <Segmented value={mode} options={['month', 'week']} onChange={(e) => onTypeChange(e.toString())}/>
            </Col>

            <Col>
                <DateSelector value={startDate} type={"year"} onChange={setStartDate}/>
            </Col>

            <Col>
                <DateSelector value={startDate} type={"month"} onChange={setStartDate}/>
            </Col>

            {mode === "week" &&
							<Col>
								<DateSelector value={startDate} type={"week"} onChange={setStartDate}/>
							</Col>
            }

            <Button type={"default"} onClick={() => setStartDate(dayjs().startOf("day"))}>
                {t("calendar.today")}
            </Button>
        </Row>
    </div>;

    if (mode === "week")
        return <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            {/*<TasksCalendarHeader type={mode} value={dayjs()} onChange={date => {*/}
            {/*    setStartDate(date)*/}
            {/*}} onTypeChange={onTypeChange}/>*/}

            {header}

            <Row justify="start" align="top" style={{
                flexGrow: 1, display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(4, 1fr)",
                // gridTemplateColumns: "1fr 1fr"
            }}>
                {weekDays.map(d => {
                    const dd = startDate.add(d, "days");
                    const dayTasks = filterTasksForDay(dd, filtered).sort(byStart)
                    let lastEmptyTime = 0;
                    // TODO: надо добавлять в форму новой задачи выбор проекта, иначе тут не будет работать из "моих задач" добавление
                    let projectId = dayTasks.find(v => true)?.project?.id;

                    const isToday = dd.startOf("day").diff(dayjs().startOf("day")) == 0

                    return <Col xs={24} style={{height: "100%"}} key={d}>
                        <div style={{
                            border: "1px solid #e1e1e1",
                            borderRadius: 4,
                            padding: 8,
                            margin: "2px",
                            borderLeft: isToday ? "10px solid #ff864f" : "10px solid #ffc8af",
                            boxShadow: isToday ? "rgb(201 201 201) 4px 2px 7px" : "3px 1px 7px #ebebeb"
                        }}>
                            <div>

                                <Row>
                                    <Col xs={12}
                                         style={{fontWeight: "bold"}}>{dd.format("dd DD.MM").toUpperCase()}</Col>
                                </Row>
                            </div>
                            {dayTasks.map(t => {
                                const r = []
                                if (t.startTime && lastEmptyTime < (t.startTime ?? 0)) {
                                    r.push(<EmptyTime date={dd} start={lastEmptyTime} end={t.startTime}
                                                      taskView={taskView} projectId={projectId}/>)
                                    lastEmptyTime = t.endTime ?? t.startTime ?? 0
                                }
                                if (t.endTime && lastEmptyTime < t.endTime)
                                    lastEmptyTime = t.endTime

                                r.push(<div style={{cursor: "pointer"}}><a><TaskTitle task={t}/></a></div>)

                                return r
                            })}
                            <EmptyTime date={dd} start={lastEmptyTime} end={24 * 60} taskView={taskView}
                                       projectId={projectId}/>

                            {data?.calendarItems.map(i => <div>
                                TODO: {i.title}
                            </div>)}
                        </div>
                    </Col>
                })
                }
            </Row>
        </div>;

    return <div>
        <Calendar
            cellRender={cellRender}
            value={startDate}
            mode={"month"}
            headerRender={(props) => header}
        />
    </div>;
};

export default TasksCalendar;
