import {Select} from "antd";
import React, {useEffect, useState} from "react";
import styled from "styled-components";

interface TimeSelectorProps {
    h: number | null,
    m: number | null,
    minH?: number | null,
    minM?: number | null,
    onChange: (hh: number | null, mm: number | null) => boolean
}


const CustomSelect = styled(Select)`
  & > .ant-select-selector {
    padding: 0;
  }
`


const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
const minutes = [0, 15, 30, 45]

export const lbl = (v: number | null) => v == null ? "--" : ((v < 10 ? "0" : "") + v)

const TimeSelector: React.FC<TimeSelectorProps> = ({onChange, h, m, minH, minM}) => {
    const [hh, setHh] = useState<number | null>(h)
    const [mm, setMm] = useState<number | null>(m)

    useEffect(() => {
        if (mm === null && hh != null)
            setMm(0)
    }, [hh]);

    return <div style={{display: "flex", alignItems: "center", margin: "0 3px"}}>
        <CustomSelect
            // style={{margin: "0 -10px"}}
            popupMatchSelectWidth={false}
            value={hh}
            bordered={false}
            showArrow={false}
            placeholder="--"
            onChange={(v: any) => {
                if (onChange(v as number, mm))
                    setHh(v as unknown as number)
            }
            }
            optionLabelProp="label"
            options={hours.filter(i => !minH || minH && i >= minH).map(i => ({value: i, label: lbl(i)}))}
        />
        :
        <CustomSelect
            // style={{margin: "0 -10px"}} // TODO: не получилось с помощью styled убрать padding
            popupMatchSelectWidth={false}
            disabled={hh === null}
            showArrow={false}
            bordered={false}
            placeholder="--"
            value={mm}
            onChange={(v: any) => {
                if (onChange(hh, v))
                    setMm(v as unknown as number)
            }}
            optionLabelProp="label"
            options={minutes.filter(i => !minM || minM && i >= minM).map(i => ({value: i, label: lbl(i)}))}
        />
    </div>
}

export default TimeSelector