import {ApolloCache, makeVar, ReactiveVar} from "@apollo/client";
import {
    BaseUsersUnionFragmentDoc,
    UsersUnion,
    UsersUnionMemberInvite, UsersUnionsListDocument
} from "../generated-types";

export interface AllUsersUnions {
    unions: UsersUnion[]
    invites: UsersUnionMemberInvite[]
}

function emptyState(): AllUsersUnions {
    return {
        unions: [],
        invites: []
    }
}

export const updateUsersUnionInCache = (cache: ApolloCache<any>, userUnion: UsersUnion) => {
    let usersUnions = allUsersUnions().unions
    let usersUnionsInvites = allUsersUnions().invites
    // readQuery иногда возвращает null, почему - непонятно. в инете пишут, что надо чтобы variables
    // были такие же... но в нашем запросе нет variables совсем. Сделал пока конструкцию с allProjects().projects
    // const q = cache.readQuery({ query: ProjectsListDocument }) as any;
    // let projects = q?.projects as Project[];

    if (!userUnion) {
        console.log('updateProjectInCache.projects', userUnion)
        return;
    }

    if (usersUnions.some(p => p.id == userUnion.id)) {
        cache.updateFragment({
            id: userUnion.id,
            fragment: BaseUsersUnionFragmentDoc,
            fragmentName: "baseUsersUnion"
        }, () => {return userUnion})
    }
    else {
        usersUnions = [...usersUnions, userUnion]
    }

    cache.writeQuery({
        query: UsersUnionsListDocument,
        data: {usersUnions, usersUnionsInvites}
    })
}

export const allUsersUnions: ReactiveVar<AllUsersUnions> = makeVar<AllUsersUnions>(emptyState());