import React from "react";
import {Button, Modal} from "antd";
import Text from "antd/es/typography/Text";

interface PaymentErrorModalProps {
    open: boolean;
    onCancel: () => void;
    errorText: string;
    onClick?: () => void;
}

export const PaymentErrorModal: React.FC<PaymentErrorModalProps> = ({open, onCancel, errorText, onClick}) => {
    return <Modal
        open={open}
        onOk={onCancel}
        onCancel={onCancel}
        cancelButtonProps={{style: {display: 'none'}}}>
        <Text strong>
            {errorText}
        </Text>
        {onClick && <Button onClick={onClick}>{'Сменить тариф'}</Button>}
    </Modal>
}