import React from "react";
import {Container} from "./FooterLogo.styled";
import {Link} from "react-router-dom";
import {CopyrightCircleOutlined} from "@ant-design/icons";

export const FooterLogo: React.FC<{collapsed: boolean}> = ({collapsed}) => {
    const b = <span style={{
        borderBottom: "2px solid gray",
        fontWeight: "bolder",
        fontSize: collapsed ? "150%" : "100%"
    }}>B</span>;

    const bl = <div style={{display: "flex"}}><CopyrightCircleOutlined/><span
        style={{paddingRight: 5}}>2023</span>{b}<span>ackline</span></div>;

    return <Container><Link to="/">
        <div className={!collapsed ? "logo" : "logo"}>{collapsed ? b : bl}</div>
    </Link></Container>
}