import React, {useMemo, useState} from 'react'
import {
    CheckOutlined,
    DownOutlined,
    StopOutlined,
    TeamOutlined,
    ThunderboltFilled,
    ThunderboltOutlined,
    UpOutlined
} from '@ant-design/icons';
import {Button, List, Tooltip,} from 'antd';
import {Project, ProjectMember, Task, TaskMember, TaskMemberType, User, UsersUnionMember} from "../../generated-types";
import {useTranslation} from "react-i18next";
import UserListItem from "../User/UserListItem";
import {useReactiveVar} from "@apollo/client";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";

interface Member {
    user: User,
    tMember?: TaskMember,
    projectMember: ProjectMember | undefined,
    uuMember: UsersUnionMember,
    selected: boolean,
    selectedType: TaskMemberType
}


interface TaskMembersEditorProps {
    task: Task,
    project: Project,
    onUpdateMembers: (members: TaskMember[]) => void
}

const ButtonMemberSelected = ({onClick, style, showRemove}: { onClick: any, style: any, showRemove: boolean }) => {
    const [hovered, setHovered] = useState(false);
    const {t} = useTranslation()
    return <Tooltip title={t('task.removeMember')}>
        <Button shape="circle" type="text" style={style}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            // style={{color: isResp ? undefined : "gray"}}
                icon={hovered && !showRemove ? <StopOutlined rev={undefined}/> : <CheckOutlined rev={undefined}/>}
                onClick={onClick}/>
    </Tooltip>;
}

const TaskMembersEditor: React.FC<TaskMembersEditorProps> = ({task, project, onUpdateMembers}) => {
    const {t} = useTranslation()
    const [hoveredMember, setHoveredMember] = useState<Member | null>(null)
    const allUnions = useReactiveVar(allUsersUnions);

    let mmbs = useMemo(() => {
        let res = project.members.map(m => {
            const tm = task.members?.find(tm => tm.user.id == m.user.id);
            return {
                user: m.user,
                projectMember: m,
                tMember: tm,
                selected: tm != undefined,
                selectedType: tm?.memberType
            }
        }) as Member[]

        if (project.usersUnionAsOwner) {
            const uu = allUnions.unions.find(v => v.id === project?.usersUnionAsOwner?.id);
            const uuMembersNotInProject = uu?.members.filter(m => !res.some(mm => mm.user.id === m.user.id));
            if (uuMembersNotInProject)
                res = res
                    .concat(uuMembersNotInProject.map(uum => {
                        const tm = task.members?.find(tm => tm.user.id == uum.user.id);
                        return {
                            uuMember: uum,
                            user: uum.user,
                            projectMember: undefined,
                            tMember: tm,
                            selected: tm != undefined,
                            selectedType: tm?.memberType
                        } as Member
                    }))
        }
        return res;
    }, [project.members, project.usersUnionAsOwner])

    // Список участников, которые отображаются в списке
    const [members, setMembers] = useState<Member[]>(mmbs);
    const [onlyMembers, setOnlyMembers] = useState(true);

    const updateMembers = () => {
        setMembers([...members])
        const taskMembers = members.filter(m=>m.selected)
            .map(m=> {
                let tm = m.tMember ? {...m.tMember} : {user: m.user} as TaskMember;
                tm.memberType = m.selectedType ?? m.tMember?.memberType;
                return tm;
            })

        onUpdateMembers(taskMembers)
    }

    const switchMember = (m: Member) => {
        m.selected = !m.selected;
        m.selectedType = TaskMemberType.Member
        updateMembers()
    }

    const switchResponsible = (m: Member) => {
        m.selected = true;
        const currentValue = m.selectedType;
        members.forEach(m=>{m.selectedType = TaskMemberType.Member})
        m.selectedType = currentValue == TaskMemberType.Responsible ? TaskMemberType.Member : TaskMemberType.Responsible;
        updateMembers()
    }

    const showMembers = members.filter(m => onlyMembers && m.selected || !onlyMembers)
    const selectedMembers = useMemo(() => {
        return members.filter(m => m.selected).length
    }, [members]);

    return (<>
            <div className={"header"}>
                <Button size="small" type={"default"} onClick={() => {
                    setOnlyMembers(!onlyMembers)
                }}
                        style={{flex: 1, border: 0, padding: 0, textAlign: "left"}}
                        icon={<TeamOutlined rev={undefined} />}>
                    {selectedMembers == 0 && t('task.noMembers')}
                    {selectedMembers > 0 && `${t('task.members')} (${selectedMembers})`}
                </Button>

                <Button size="small" style={{border: 0, color: 'lightgray'}} type="default" onClick={() => {
                    setOnlyMembers(!onlyMembers)
                }}
                        icon={onlyMembers ? <DownOutlined/> : <UpOutlined/>}>
                    {/*{!onlyMembers ? "Свернуть список" : "Добавить участников"}*/}
                </Button>
            </div>
            {showMembers.length > 0 && <List
							size="small"
							itemLayout="horizontal"
							dataSource={showMembers}
                            style={onlyMembers ? {} : {maxHeight: 350, overflowY: "auto"}}

							renderItem={(item) => {
                                const isResp = item.selectedType == TaskMemberType.Responsible;

                                return (
                                    <List.Item
                                        onClick={(e) => {
                                            if (!onlyMembers) switchMember(item)
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }}
                                        style={{
                                            cursor: onlyMembers ? undefined : "pointer",
                                            backgroundColor: item.selected && !onlyMembers ? "#dfdfdf" : undefined
                                        }}
                                        actions={[

                                            <Tooltip title={t('task.responsible')}>
                                                <Button shape="circle" type="text"
                                                        style={
                                                            {color: item === hoveredMember || isResp ? undefined : "white"}
                                                        }
                                                        icon={isResp ? <ThunderboltFilled rev={undefined}/> :
                                                            <ThunderboltOutlined rev={undefined}/>} onClick={(e) => {
                                                    switchResponsible(item)
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                }} />
                                            </Tooltip>,
                                            <ButtonMemberSelected
                                                showRemove={!onlyMembers}
                                                style={
                                                    {color: item.selected && !isResp ? undefined : "white"}
                                                }
                                                onClick={(e: React.MouseEvent) => {
                                                    switchMember(item)
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                }}/>,
                                        ]}
                                        onMouseEnter={() => setHoveredMember(item)}
                                        onMouseLeave={() => setHoveredMember(null)}
                                    >
                                        <UserListItem user={item.user} asLink={false}
                                                      active={item.selected || item === hoveredMember}/>
                                        {item.projectMember?.asUnion &&
			                                    <div style={{margin: "0px 20px", color: "gray"}}><span
				                                    style={{color: "#d7d7d7"}}>{t('usersUnion.team')}</span> {item.projectMember.asUnion.title}
			                                    </div>}
                                        {!item.projectMember &&
			                                    <Tooltip title={t('project.userNotInProject')}>
				                                    <div>{t('project.userNotInProjectTitle')}</div>
			                                    </Tooltip>}
                                    </List.Item>
                                )
                            }}/>}
        </>
    );
};

export default TaskMembersEditor