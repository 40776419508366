import React from 'react';
import {Affix, Layout, theme} from "antd";

const { Content} = Layout;

export const PersonalDataAcceptance = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content style={{ margin: '24px' }}>
            <div style={{ fontSize: '120%', padding: 24, minHeight: 360, background: colorBgContainer }}>

                <Affix><h3>Согласие на обработку и политика в отношении персональных данных</h3></Affix>
                <div>Настоящим, свободно, своей волей и в своем интересе выражаю свое согласие ООО «Бэклайн», (ИНН
                    5261135499) (далее – Оператор) на автоматизированную и неавтоматизированную обработку моих
                    персональных данных в соответствии со следующим перечнем:
                </div>
        <br/>
            <p>— мои запросы, как посетителя сайта;</p>
            <p>— IP адрес;</p>
            <p>— файлы cookies;</p>
            <p>— идентификатор пользователя, присваиваемый сайтом;</p>
            <p>— посещенные страницы;</p>
            <p>— количество посещений страниц;</p>
            <p>— информация о перемещении по страницам сайта (в т.ч. запись движения мыши, нажатий на ссылки и элементы сайта);</p>
            <p>— длительность пользовательской сессии;</p>
            <p>— точки входа (сторонние сайты, с которых пользователь по ссылке переходит на сайт);</p>
            <p>— точки выхода (ссылки на сайте, по которым пользователь переходит на сторонние сайты);</p>
            <p>— страна пользователя;</p>
            <p>— регион пользователя;</p>
            <p>— часовой пояс, установленный на устройстве пользователя;</p>
            <p>— провайдер пользователя;</p>
            <p>— браузер пользователя;</p>
            <p>— цифровой отпечаток браузера (canvas fingerprint);</p>
            <p>— доступные шрифты браузера;</p>
            <p>— установленные плагины браузера;</p>
            <p>— параметры WebGL браузера;</p>
            <p>— тип доступных медиа-устройств в браузере;</p>
            <p>— наличие ActiveX;</p>
            <p>— перечень поддерживаемых языков на устройстве пользователя;</p>
            <p>— архитектура процессора устройства пользователя;</p>
            <p>— ОС пользователя;</p>
            <p>— параметры экрана (разрешение, глубина цветности, параметры размещения страницы на экране);</p>
            <p>— информация об использовании средств автоматизации при доступе на сайт;</p>
            <p>— дата и время посещения сайта;</p>
            <p>— источник перехода (UTM метка);</p>
            <p>— значение UTM меток от source до content;</p>
            <p>— уникальный идентификатор, присваиваемый интернет-сторонним сервисом, обеспечивающим обработку статистических данных.</p>
            <div>Для целей оптимизации Оператором своих web-ресурсов, с учетом моих индивидуальных особенностей и предпочтений.</div>
            <br/>
                <div>Оператор вправе осуществлять обработку моих персональных данных следующими способами: сбор, систематизация, накопление, хранение, обновление, изменение, использование, передача третьим лицам (предоставление, доступ), включая Яндекс.Метрика, top.mail.ru.</div>
                <br/>
                    <div>Настоящее согласие вступает в силу с момента моего перехода на сайт Оператора и действует до момента окончания пользования сайтом Оператора либо до дня отзыва согласия с правом Оператора продолжить обработку Персональных данных в установленных законодательством случаях.</div>
                    <br/>
                        <div>Согласие на обработку персональных данных может быть отозвано мною путем направления Оператору письменного уведомления об этом.</div>

        </div>
        </Content>
    )
}