import styled from "styled-components";
import {PageHeader} from "@ant-design/pro-layout";


export const CustomPageHeader = styled(PageHeader)`
  background: #EBEBEB;
  padding-block: 0;
  padding: 0 5px;

  & .ant-page-header-footer {
    margin-block-start: 0;
  }

  & .ant-page-header-heading {
    flex-wrap: nowrap;
  }

  & .ant-tabs.ant-tabs-top {
    margin-bottom: -11px;
  }

  & .ant-tabs-nav {
    margin: 0 0 11px 0;
  }
`