import React from 'react';
import {NotificationsSubscriptionDocument, useNotificationsQuery} from "../generated-types";
import {Empty} from "antd";

const NotificationsList = () => {

    const {data, subscribeToMore} = useNotificationsQuery({
        onCompleted: () => {
            // allProjects(new AllProjects(data.projects as Project[]));
        },
    })

    subscribeToMore({
        document: NotificationsSubscriptionDocument,
        variables: {},
        updateQuery: (prev, {subscriptionData}) => {
            const currentArr = prev.notifications;
            if (!subscriptionData.data) return prev;
            
            const newItem = (subscriptionData.data as any).notificationSubscription;

            if (currentArr.some(v=>v.id == newItem.id))
                return prev;
            
            return {...prev, notifications: [...currentArr, newItem]};

            // return Object.assign({}, prev, {
            //     notifications: [newItem, ...prev.notifications]
            // });
        }
    })

    return (
        <div style={{padding: "50px"}}>
            <Empty description={<>
                Раздел находится в разработке
            </>}/>


            {/*{data?.notifications && data?.notifications.map(d=><div key={d.id}*/}
            {/*                                                        style={{border: "1px solid green", margin: "3px"}}*/}
            {/*>{d.id} {d.createDate}*/}
            {/*    <br/>*/}
            {/*    {d.action}</div>)}*/}
            {/*/!*<InvitesList />*!/*/}
            {/*/!*<NotImplemented />*!/*/}
        </div>
    );
}


export default NotificationsList;