import React, {useState} from 'react';
import {Button, Drawer, Form, Space} from 'antd';
import AddButton from "../AddButton";
import {useTranslation} from "react-i18next";
import {
    Project,
    useProjectCreateMutation,
    ProjectCreateInput,
    UsersUnion
} from "../../generated-types";
import {useNavigate} from "react-router-dom";
import ProjectSettings from "./ProjectSettings";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import {UserOrUnion} from "../User/UserSearch";
import {updateProjectInCache} from "../../subscriptions/allProjects";

interface ProjectNewProps {
    context: UserOrUnion,
    isBlocked?: boolean | undefined
}

const ProjectNew: React.FC<ProjectNewProps> = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [project] = useState<ProjectCreateInput>({title: ""});
    useReactiveVar(authState);
    const isUsersUnion = props.context.__typename == "UsersUnion";

    const [projectCreate,{  loading }] = useProjectCreateMutation({
        onCompleted: (data) => {
            navigate(`/project/${data.projectCreate.id}`)
        },
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.projectCreate as Project)
        }
    });

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const save = (input: any) => {
        if (isUsersUnion)
            input.usersUnionAsOwnerId = "" + props.context.id; // TODO: int or string????

        projectCreate({variables: {input}})
    };

    let title = t("personalProjects");

    if (isUsersUnion)
        title = (props.context as UsersUnion).title;

    return (
        <span>
            <AddButton
                disabled={props.isBlocked}
                title={t('project.create')}
                onClick={showDrawer}
                key="1"/>
            <Drawer key="2"
                    title={title + ": " + t('project.createWindowTitle')}
                    width={720}
                    onClose={onClose}
                    open={open}
                    bodyStyle={{paddingBottom: 80}}
                    footer={
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Button type="primary" block loading={loading} onClick={form.submit} htmlType="submit">
                                {t('project.create')}
                            </Button>
                        </Space>
                    }
            >
                <ProjectSettings project={project as Project} save={save} form={form}/>
            </Drawer>
        </span>
    );
};

export default ProjectNew;