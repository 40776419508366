import React from "react";
import {
    useAllTariffsQuery
} from "../../generated-types";
import {Button, Card, Col, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {currencyType} from "../../utils/currencyType";

interface TariffsTableProps {
    onClick?: (id: string) => void;
    className?: string;
    currentTariffId?:string;
}


export const TariffsTable: React.FC<TariffsTableProps> = ({currentTariffId,onClick, className}) => {
    const {t} = useTranslation();
    const tariffs = useAllTariffsQuery();
    const currency = currencyType(tariffs?.data?.allTariffs[0].currency!)
    const navigate = useNavigate();
    const totalColumns = tariffs.data?.allTariffs.length;
    const span = 24 / totalColumns!

    const {Title} = Typography;

    const cardStyle = {
        margin: "0px 20px 20px",
        backgroundColor: 'rgba(198 210 255 0.2)'
    }

    const monthlyCostStyle = {
        padding: "0 24px",
        marginTop: 0
    }

    const navigateToAuth = () => {
        navigate('/signin')
    }

    return <Row justify="center" style={{minHeight: `${100}vh`}}>{tariffs.data?.allTariffs.map((item, i) =>
        <Col key={item.id} role={'COLUMN'} span={span} order={i}>
            <div className={'tariffsTableColumn'}>
                <Title level={1} italic style={monthlyCostStyle}>{item.title}</Title>
                {item.monthlyCost ?
                    <Title level={2} style={monthlyCostStyle}>{`${item.monthlyCost} `}
                        <span style={{fontSize: 24}}>{currency}</span>
                    </Title>
                    : <Title level={2} style={monthlyCostStyle}>{`${t("tariffsTable.freeCost")} `}</Title>
                }

                <Card title={t("tariffsTable.personalProjectTitle")} bordered={false} style={cardStyle}>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div style={{maxWidth: `${70}$`}}> {t("tariffsTable.numberOfPersonalProjects.title")}</div>
                        <div style={{fontSize: 24}}> {t("tariffsTable.numberOfPersonalProjects.number")} </div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div style={{maxWidth: `${70}$`}}>{t("tariffsTable.personalProjectMembersFreeCount")} </div>
                        <div style={{fontSize: 24}}>{item.personalProjectMembersFreeCount}</div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div
                            style={{maxWidth: `${70}$`}}>{t("tariffsTable.personalProjectAdditionalMemberAdditionalCost")} </div>
                        <div style={{fontSize: 24}}>{`${item.personalProjectAdditionalMemberAdditionalCost}`}
                            <span>{currency}</span></div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div>{t("tariffsTable.personalProjectNumberBeOnTeam.title")} </div>
                        <div style={{fontSize: 24}}>{t("tariffsTable.personalProjectNumberBeOnTeam.number")}</div>
                    </div>
                </Card>
                <Card title={t("tariffsTable.usersUnionTitle")} bordered={false} style={cardStyle}>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div>{t("tariffsTable.usersUnionFreeCount")} </div>
                        <div style={{fontSize: 24}}>{item.usersUnionFreeCount}</div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div>{t("tariffsTable.usersUnionAdditionalCost")} </div>
                        <div
                            style={{fontSize: 24}}>{item.usersUnionAdditionalCost > 0 ? item.usersUnionAdditionalCost : '-'}
                            {item.usersUnionAdditionalCost > 1 && <span>{currency}</span>}
                        </div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div>{t("tariffsTable.usersUnionMembersFreeCount")} </div>
                        <div style={{fontSize: 24}}>{item.usersUnionMembersFreeCount}</div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div>{t("tariffsTable.usersUnionMembersAdditionalCost")} </div>
                        <div style={{fontSize: 24}}>{item.usersUnionMembersAdditionalCost}
                            <span>{currency}</span>
                        </div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div>{t("tariffsTable.usersUnionProjectMemberFreeCount")} </div>
                        <div style={{fontSize: 24}}>{item.usersUnionProjectMemberFreeCount}</div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div> {t("tariffsTable.usersUnionProjectMemberAdditionalCost")} </div>
                        <div style={{fontSize: 24}}>{item.usersUnionProjectMemberAdditionalCost}
                            <span>{currency}</span>
                        </div>
                    </div>
                    <div className={className ? className : 'tariffsTableCardRow'}>
                        <div> {t("tariffsTable.usersUnionCanInvolvedInProjects.title")} </div>
                        <div style={{fontSize: 24}}>{t("tariffsTable.usersUnionCanInvolvedInProjects.number")}</div>
                    </div>
                </Card>
                <Space direction="vertical" style={{width: '100%', display: "flex", alignItems: "center"}}>
                    <Button disabled={item.id === currentTariffId}
                            onClick={onClick ? () => onClick(item.id) : navigateToAuth}
                            size={"middle"}
                            className={'tariffsTableCardButton'}>
                        {t("tariffsTable.button")}
                    </Button>
                </Space>
            </div>
        </Col>
    )}
    </Row>
}
