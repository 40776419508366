import React, { useEffect, useState } from 'react';
import { Button, Divider, List, Modal, Select, Tag as AntTag } from 'antd';
import TagEditor, { tagDefaultColor, TagEditorContext } from "./TagEditor";
import { useTranslation } from 'react-i18next';
import { AccessLevel, ProjectMember, Tag, UsersUnionMember } from "../generated-types";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { CloseOutlined, InboxOutlined, TagsOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { useReactiveVar } from "@apollo/client";
import { allProjects } from "../subscriptions/allProjects";
import { allUsersUnions } from "../subscriptions/allUsersUnions";
import { authState } from "../routes/Auth/authContext";
import { allPersonalTags } from "../subscriptions/allPersonalTags";

interface TaggerProps {
    onChanged?: (tags: string[]) => void
    tags?: Tag[]
    defaultValue?: string[]
    usersUnionId?: string
    projectId?: string
    placeholder?: string
    block?: boolean
    editable?: boolean
    allowEditProjectTags?: boolean
    allowEditUsersUnionTags?: boolean
    onCloseTag?: (tags: string[]) => void
}

const Tagger: React.FC<TaggerProps> = ({ projectId, allowEditUsersUnionTags = false, usersUnionId,
    placeholder, defaultValue, onChanged, tags, editable, block, allowEditProjectTags, onCloseTag }) => {
    const authInfo = useReactiveVar(authState);
    const [selected, setSelected] = useState(defaultValue ?? []);
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContext, setModalContext] = useState<TagEditorContext | null>(null);
    const [selectOpened, setSelectOpened] = useState(false);
    const personalTags = useReactiveVar(allPersonalTags);

    const allP = useReactiveVar(allProjects);
    const allU = useReactiveVar(allUsersUnions);
    const project = allP.projects.find(p => p.id == projectId)
    if (!usersUnionId)
        usersUnionId = project?.usersUnionAsOwner?.id;
    const union = allU.unions.find(p => p.id == usersUnionId)

    const isUnionAdmin = union?.members.some((m: UsersUnionMember) => m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin) ?? false;
    const isProjectAdmin = project?.members.some((m: ProjectMember) => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin) ?? false
    const allowEditPersonalTags = project?.userAsOwner?.id == authInfo.user.id ?? false;

    // Если проекты персональные, тогда только владелец может исправлять метки этого проекта.
    allowEditUsersUnionTags = allowEditUsersUnionTags && isUnionAdmin && !allowEditPersonalTags;
    allowEditProjectTags = allowEditProjectTags && isProjectAdmin;

    let availableTags: Tag[] = [];

    useEffect(() => {
        if (defaultValue)
            setSelected(defaultValue)
    }, [defaultValue]);

    if (project)
        availableTags = availableTags.concat(project.tags.map(t => ({ ...t, project: project } as unknown as Tag)));

    if (union)
        availableTags = availableTags.concat(union.tags.map(t => ({ ...t, UsersUnion: union } as unknown as Tag)));

    if (allowEditPersonalTags)
        availableTags = availableTags.concat(personalTags.tags.map(t => ({ ...t, user: authInfo.user } as unknown as Tag)));

    if (availableTags.length == 0 && tags)
        availableTags = tags;

    // Список тегов, которые отображаются в далоговом окне редактирование тегов
    let dialogEditorTagsList: Tag[] = [];
    if (modalContext?.projectId && project?.tags)
        dialogEditorTagsList = project?.tags;
    else if (modalContext?.usersUnionId && union?.tags)
        dialogEditorTagsList = union?.tags;
    else if (modalContext?.userId && personalTags.tags)
        dialogEditorTagsList = personalTags.tags;

    //Обработчик изменения выбора тегов в выпадающем меню
    const onChangeHandler = (values: string[]) => {
        setSelected(values)
        if (onChanged)
            onChanged(values)
    }

    const tagRender = (allTags: Tag[], props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        const tag = allTags.find(v => v.id === value);

        const color = tag?.color ?? tagDefaultColor

        const style = {
            borderColor: color,
            color: color,
            backgroundColor: "transparent",
            marginRight: 3
        };
        //Обработчик удаления тега
        const onCloseHandler = (e: React.MouseEvent<HTMLElement>) => {
            
            const newTags = selected.filter(t => t !== tag?.id) ?? []
            //Если мы передали свой обработчик удаления тега (в случае с карточкой проекта/задачи в списке) то отработает он,
            // в ином случае сработает обработчик, будто мы убрали тег через select
            if (onCloseTag) {
                onCloseTag(newTags)
            }
            else {
                onChangeHandler(newTags);
            }
        }

        return (
            <AntTag
                key={tag?.id}
                color={color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onCloseHandler}
                bordered={true}
                style={style}
                closeIcon={<CloseOutlined style={{ color: color }} />}
            >
                {label}
            </AntTag>
        );
    };

    const renderOneTag = (tag: Tag, showIcons: boolean = true) => {
        return {
            label: <>
                {showIcons && tag.usersUnion && <TeamOutlined />}
                {showIcons && tag.project && <InboxOutlined />}
                {showIcons && tag.user && <UserOutlined />}
                {tag.title}
            </>,
            value: tag.id
        }
    };

    if (!editable) {
        return <span style={{ paddingLeft: 0, paddingRight: 0 }}>{

            selected.map(s => {
                const tag = availableTags.find(t => t.id == s || t.title == s);
                return tag ? tagRender(availableTags, renderOneTag(tag, false) as CustomTagProps) : null;
            })
        }</span>
    }

    const openEditor = (title: string, context: TagEditorContext) => {
        setIsModalOpen(true)
        setModalTitle(title)
        setModalContext(context)
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    if (!projectId) allowEditProjectTags = false;

    return (
        <>
            {modalContext && <Modal open={isModalOpen} width={"50%"} closable={true} centered={true}
                title={modalTitle}
                footer={null}
                onOk={handleOk} onCancel={handleCancel}
            >
                <List
                    size="small"
                    dataSource={[{ color: tagDefaultColor, id: "" } as Tag]}
                    renderItem={(t) => <TagEditor tag={t} removable={false} context={modalContext} newItem={true} />}
                ></List>
                <Divider style={{ margin: 0 }} />
                {availableTags.length > 0 &&
                    <List
                        size="small"
                        dataSource={dialogEditorTagsList}
                        renderItem={(t) => <TagEditor key={t.id} tag={t} removable={true} context={modalContext} newItem={false} />}
                    ></List>}
            </Modal>
            }
            <div style={{ display: "flex" }}>
                <TagsOutlined style={{ margin: 2, color: "#8d8d8d" }} onClick={() => { if (editable) setSelectOpened(true) }} />
                <Select
                    onDropdownVisibleChange={(v) => setSelectOpened(v)}
                    open={selectOpened}
                    showArrow
                    popupMatchSelectWidth={false}
                    value={selected}
                    // allowClear={true}
                    bordered={false}
                    filterOption={(f, opt) => {
                        const tag = availableTags.find(tt => tt.id == opt?.value)
                        if (!tag) return false;
                        return tag.title.toUpperCase().indexOf(f.toUpperCase()) > -1;
                    }}
                    defaultValue={defaultValue ?? []}
                    disabled={!editable}
                    style={block ? { width: '100%' } : {}}
                    placeholder={placeholder ?? t("tags.taggerPlaceholder")}
                    onChange={onChangeHandler}
                    mode="multiple"
                    tagRender={(r) => tagRender(availableTags, r)}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            {(allowEditProjectTags || allowEditUsersUnionTags || allowEditPersonalTags) && <>
                                <Divider style={{ margin: '8px 0' }} />
                                {allowEditProjectTags && <Button type="text" onClick={
                                    () => openEditor(t("tags.projectEditorTitle") + " " + project?.title, { projectId })
                                } block>
                                    <InboxOutlined />{t("tags.projectEditorTitle")}
                                </Button>}
                                {allowEditUsersUnionTags && <Button type="text" onClick={
                                    () => openEditor(t("tags.usersUnionEditorTitle") + " " + union?.title, { usersUnionId })
                                } block>
                                    <TeamOutlined />{t("tags.usersUnionEditorTitle")}
                                </Button>}
                                {allowEditPersonalTags && <Button type="text" onClick={
                                    () => openEditor(t("tags.personalEditorTitle"), { userId: authInfo.user.id })
                                } block>
                                    <UserOutlined />{t("tags.personalEditorTitle")}
                                </Button>}
                            </>
                            }
                        </>
                    )
                    }
                    options={availableTags.map(t => renderOneTag(t, true))}
                />
            </div>
        </>
    );
};

export default Tagger