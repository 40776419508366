import {Task} from "../../generated-types";
import React from "react";


export interface TaskResolvedBlurProps {
    task: Task
}

const styleResolved = {backgroundColor: "rgba(255,255,255)", zIndex: 1, opacity: "0.65"}
const styleDefault = {position: "absolute", left: 0, right: 0, top: 0, bottom: 0}

function TaskResolvedBlur(props: TaskResolvedBlurProps) {
    const {task} = props;
    const style: any = task.resolved ? {...styleDefault, ...styleResolved} : {...styleDefault}

    return (<div style={style} ></div>)
}

export default TaskResolvedBlur