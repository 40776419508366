import React, { useEffect, useRef, useState } from 'react'
import { DragPreviewImage, DropTargetOptions, useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components'
import { GeneralBoardColumnType } from './GeneralBoardColumn'
import { message } from 'antd'
import { theme } from 'antd'

const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'

const GenearalBoardCardContainer = styled.div<{boxShadow: string, borderRadius: number}>`
width: 100%;
transition: box-shadow .3s ease-in-out;
border-radius: ${({borderRadius})=>borderRadius? `${borderRadius}px` : undefined};
`

export type GeneralBoardItemType<T> = {
    id: number | string,
    columnId: number | string,
    item: T
}

interface GeneralBoardCardProps<I, C> {
    signature: string,
    item: GeneralBoardItemType<I>,
    onItemRender: (item: I) => React.JSX.Element,
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C) => void,
    isBoardDragging: boolean,
    touchEventsDelay: number
}

function GeneralBoardCard<I, C>({ signature, item, onItemRender, onItemDropped, isBoardDragging, touchEventsDelay }: GeneralBoardCardProps<I, C>) {

    const [canDrag, setCanDrag]=useState(false)
    const holdTimer=useRef<NodeJS.Timeout | null>(null)
    const { 
        token: { colorBorder, borderRadius,boxShadow }
    }=theme.useToken()




    const [, dragRef, preview] = useDrag(() => ({
        type: signature,
        item: item.item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            
        }), 
    }), [])

    const [{ isOver }, dropRef] = useDrop(() => (
        {
            accept: signature,
            drop(itemDropped: I, monitor) {
                onItemDropped(itemDropped, item.item)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }),
        }
    ), [])

    //нужно как-то сообщать пользователю, что элемент можно перетаскивать после зажатия, пока сделал сообщение
    const touchStartHandler=()=>{
        holdTimer.current=setTimeout(() => {
            setCanDrag(true)
          }, touchEventsDelay);
    }

    const touchEndHandler=()=>{
        if(holdTimer.current){
            clearTimeout(holdTimer.current)
            holdTimer.current=null
        }
        if(canDrag){
            setCanDrag(false)
        }
    }

    useEffect(()=>{
        if(canDrag){
            message.success('Элемент можно перетаскивать')
        }
    }, [canDrag])
    return (<>
        <DragPreviewImage connect={preview} src={boxImage}/>
        <GenearalBoardCardContainer 
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        ref={isBoardDragging? undefined : dragRef} 
        onTouchStart={touchStartHandler}
        onTouchEnd={touchEndHandler}
        >
            <div ref={dropRef} style={{ borderTop: isOver ? `solid 5px ${colorBorder}` : undefined}}>
                {
                    onItemRender(item.item)
                }
            </div>
        </GenearalBoardCardContainer>
    </>
    )
}

export default GeneralBoardCard