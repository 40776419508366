import React, {useEffect, useState} from "react";
import {Divider} from "antd";
import {
    AuditOutlined,
    CrownOutlined,
    LoginOutlined,
    PhoneOutlined,
    TeamOutlined,
    UserAddOutlined,
    UserOutlined,
    WalletOutlined,
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../../routes/Auth/authContext";
import {Project, User, UsersUnion} from "../../../generated-types";
import {allUsersUnions} from "../../../subscriptions/allUsersUnions";
import {allProjects} from "../../../subscriptions/allProjects";
import {getActiveLeftMenuKey, getLeftMenuOpenKeys, saveLeftMenuOpenKeys} from "../../LeftMenu";
import {CustomUserAvatar} from "./CustomUserAvatar/CustomUserAvatar";
import {CustomMenu, ItemContainer, LeftSider, TitleContainer, TitleText} from "./CustomLeftMenu.styled";
import {LeftMenuButtons} from "./LeftMenuButtons/LeftMenuButtons";
import {CustomIcons} from "../CustomIcons/CustomIcons";
import {Circular} from "../CustomIcons/Icons";
import {FooterLogo} from "./FoterLogo/FooterLogo";
import {LeftMenuCollapseButton} from "../LeftMenuCollapseButton";
import {isMobile} from "react-device-detect";


interface LeftMenuProps {
    contentMargin: number,
    setContentMargin: (number: number) => void
}

export const menuItems: any = {
    personalProject: '/my',
    uuProjects: '/uu',
    otherProjects: '/otherp',
}


export const CustomLeftMenu: React.FC<LeftMenuProps> = () => {
    const {t} = useTranslation();
    const authInfo = useReactiveVar(authState)
    const navigate = useNavigate();
    //Логика своачивания боковой панели
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [openedKeys, setOpenedKeys] = useState<string[] | undefined>(getLeftMenuOpenKeys());
    const [selectedKey, setSelectedKey] = useState<string>(""); // TODO: get from location

    const [isMd, setMd] = useState<boolean>(false) //изменяется на true - false при изменении ширины экрана.

    const allUnions = useReactiveVar(allUsersUnions);
    const allP = useReactiveVar(allProjects);

    // Показываем вообще все проекты
    // const leftMenuProjectsFilter = (): boolean =>true;

    // Показываем только избранные проекты
    const leftMenuProjectsFilter = (p: Project): boolean => p.UserMarkers.isFavorite;

    const projectSorter = (p1: Project, p2: Project): number => p1.usersUnionAsOwner?.title + "-" + p1.title > p1.usersUnionAsOwner?.title + "-" + p2.title ? 1 : -1;

    let leftItems: any[] = [];

    const noProjectsItem = () => ({
        style: {cursor: "default", paddingLeft: 10},
        label: t('noProjects'),
        disabled: true,
        key: Math.random()
    });

    const addTitle = (title: string, children: any[], icon: any, to: string): any => {
        if (children === undefined || !children)
            children = []

        if (children.length === 0)
            children.push(noProjectsItem());

        const res = {
            style: {padding: "0"},
            disabled: false,
            key: title,
            children: children,
            className: 'hideCollapseButton',
            label:
                <TitleContainer>
                    <div style={{width: '100%'}} onClick={(e) => {
                        setSelectedKey(to)
                        setCollapsed((prevState) => isMd ? true : prevState)
                        navigate(to)
                        e.preventDefault()
                        e.stopPropagation()
                    }}>{icon}<TitleText collapsed={collapsed ? "true" : undefined}>{title}</TitleText></div>
                </TitleContainer>
            // label: <TitleContainer>{icon}<TitleText collapsed={collapsed ? "true" : undefined}>{title}</TitleText></TitleContainer>,
        };
        leftItems.push(res)
        return res;
    }

    const addProject = (p: Project, icon?: any): any => {
        const to = `/project/${p.id}`;
        return {
            key: to,
            style: {paddingLeft: 27},
            onClick: () => {
                setCollapsed((prevState)=>isMd?true:prevState)
                setSelectedKey(to)
                navigate(to)
            },
            icon,
            label:
                <Link to={to}>{p.title}</Link>
        };
    }


    const addGroups = (unions: UsersUnion[] | User[], projects: Project[], icon?: any): any[] => {
        const res: any[] = []
        unions.map(ou => {
            const filtered = projects
                .filter(p => p.usersUnionAsOwner?.id === ou.id || p.userAsOwner?.id === ou.id);
            let to = `/${ou.__typename}/${ou.id}`;
            const tt = (ou as UsersUnion)?.title ?? (ou as User)?.username;
            const children = filtered.length > 0 ? filtered.map((p) => addProject(p, <CustomIcons
                svg={Circular}/>)) : [noProjectsItem()];
            res.push({
                style: {cursor: "default", padding: "0"},
                key: to,
                icon: icon,
                children: children,
                label:
                    <ItemContainer collapsed={collapsed ? "true" : undefined} className={"group-item-container"}>
                        <div style={{width: '100%'}} onClick={(e) => {
                            setSelectedKey(to)
                            setCollapsed((prevState)=>isMd?true:prevState)
                            navigate(to)
                            e.preventDefault()
                            e.stopPropagation()
                        }}>{tt}</div>
                    </ItemContainer>
                ,
            })
            return true;
        })
        return res;
    }
    const authorized = authInfo.user != undefined;

    const autoCollapseOnMenuClick = () => {
        if (isMd || isMobile)
            setCollapsed(true)
    }

    if (authorized) {
        const myProjects = allP.myProjects().filter(leftMenuProjectsFilter).sort(projectSorter);
        // if (myProjects.length > 0) {
            const u = myProjects.find(_ => true)?.userAsOwner;
            const projects = addGroups(u ? [u] : [], myProjects, null)
        const children = projects && projects.find(_ => true) ? projects.find(_ => true).children : [];
        addTitle(t("personalProjects"), children, <CrownOutlined
            style={{marginTop: "-2px"}}/>, menuItems.personalProject)
        // }

        addTitle(t("usersUnion.teams"),
            allP.myUnionsProjects().length > 0
                ? addGroups(allUnions.unions, allP.myUnionsProjects().concat(allP.otherUnionsProjects())
                    .filter(leftMenuProjectsFilter).sort(projectSorter), null)
                : [], <TeamOutlined/>, menuItems.uuProjects
        )

        addTitle(t("otherUsersProjects"),
            allP.otherUsers().length > 0 ?
                addGroups(allP.otherUsers(), allP.otherUsersProjects().filter(leftMenuProjectsFilter).sort(projectSorter), null)
                : [], <UserOutlined/>, menuItems.otherProjects
        )
    } else {
        leftItems.push({
            style: {paddingLeft: 12},
            key: '/signin',
            icon: <LoginOutlined rev={undefined}/>,
            label: <Link to="/signin" onClick={autoCollapseOnMenuClick}>{t('auth.signIn')}</Link>,
        })
        leftItems.push({
            style: {paddingLeft: 12},
            key: '/signup',
            icon: <UserAddOutlined rev={undefined}/>,
            label: <Link to="/signup" onClick={autoCollapseOnMenuClick}>{t('auth.signUp')}</Link>,
        })
        leftItems.push({
            style: {paddingLeft: 12},
            key: '/tariffs',
            icon: <WalletOutlined rev={undefined}/>,
            label: <Link to="/tariffs" onClick={autoCollapseOnMenuClick}>{t('tariffsTable.title')}</Link>,
        })
        leftItems.push({
            style: {paddingLeft: 12},
            key: '/termsofuse',
            icon: <AuditOutlined rev={undefined}/>,
            label: <Link to="/termsofuse" onClick={autoCollapseOnMenuClick}>{t('termsOfUse')}</Link>,
        })
        leftItems.push({
            style: {paddingLeft: 12},
            key: '/contacts',
            icon: <PhoneOutlined rev={undefined}/>,
            label: <Link to="/contacts" onClick={autoCollapseOnMenuClick}>{t('contacts')}</Link>,
        })
    }

    // Указание выбранного пункта меню в соответствии с адресом страницы
    useEffect(() => {
        const selectedKeyFromPath = getActiveLeftMenuKey(leftItems, window.location.pathname)
        if (selectedKeyFromPath !== selectedKey)
            setSelectedKey(selectedKeyFromPath)
    }, [selectedKey]);

    const allKeys = leftItems.map(v => v.key);
    if (!openedKeys)
        setOpenedKeys(allKeys);

    return <LeftSider
        breakpoint="md"
        width={isMd ? "100%" : 238}
        collapsedWidth={isMd ? "0" : "72"}
        trigger={null}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        onBreakpoint={(broken) => setMd(broken)}
    >
        {authorized ?
            <CustomUserAvatar collapsed={collapsed} callback={() => setCollapsed((prevState) => !prevState)}
                              user={authInfo.user as User}/> : null}
        {authorized ? <LeftMenuButtons collapsed={collapsed}
                                       onClick={() => setCollapsed((prevState) => isMd ? true : prevState)}/> : null}
        {authorized || isMobile || isMd ?
            <LeftMenuCollapseButton collapsed={collapsed} isMd={isMd} setCollapsed={setCollapsed}/> : null}
        {authorized ?
            <Divider style={{borderColor: "rgb(124, 124, 124, 0.3)", margin: '8px 0 0 0'}}></Divider> : null}

        {allKeys.length > 0 && <CustomMenu
            theme="light"
            mode="inline"
            selectedKeys={[selectedKey]}
            items={leftItems}
            onOpenChange={(v) => {
                // if (!collapsed) {
                //     if (v.indexOf(t("otherUsersProjects")) < 0) v.push(t("otherUsersProjects"))
                //     if (v.indexOf(t("usersUnion.teams")) < 0) v.push(t("usersUnion.teams"))
                //     if (v.indexOf(t("personalProjects")) < 0) v.push(t("personalProjects"))
                // }
                setOpenedKeys(v)
                saveLeftMenuOpenKeys(v)
            }}
            openKeys={openedKeys}
            defaultOpenKeys={allKeys}
        />}

        <FooterLogo collapsed={collapsed}/>

    </LeftSider>
}