import React, { useEffect, useState } from 'react'
import {Form, Input, Button, Alert} from 'antd'
import { User, UserProfileInput } from '../../generated-types'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client/core';

interface ProfileProps{
  userData:User
  error:ApolloError | undefined
  onSendHandler: (input:UserProfileInput) => void
}

const ProfileInfo:React.FC<ProfileProps> = ({userData, error, onSendHandler}) => {

  const [isEditOn, setIsEditOn] = useState<boolean>(false);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const values = Form.useWatch(["email"], form);
  
  const onClickHandler = () =>  {
    if(isEditOn){
      let input = form.getFieldsValue();
      //Меняем значение поля Null на пустую строку.
      for(let v in input){
        if(input[v] === null){
          input[v] = ""
        } 
      }
      onSendHandler(input);
      setIsEditOn(false);
    }else{
      setIsEditOn(true); 
    }
  }

  useEffect(()=>{
    //Если есть ошибка, оставляем поля редактируемыми.
    if(error) {
      setIsEditOn(true);
    }
  }, [error]);

  useEffect(() => {
    //Если email невалидный, делаем кнопку сохранить неактивной
    if(isEditOn){
      form.validateFields({validateOnly: true}).then(
        () => {
          setDisabledBtn(false);
        },
        () => {
          setDisabledBtn(true);
        }
      )
    }
  },[values]);

  return (
    <>
      <Form form={form} name="validateOnly" className="profile-form" layout="vertical" initialValues={userData} disabled={!isEditOn}>
        <Form.Item label={t("profile.userName")} name="username">
            <Input/>
        </Form.Item>
        <Form.Item label={t("profile.fullName")} name="fullName">
            <Input/>
        </Form.Item>
        <Form.Item label={t("profile.email")} name="email" rules={[{
          type: "email",
          message: t("auth.inputEmail") as string
        }]}>
            <Input/>
        </Form.Item>
        <Form.Item label={t("profile.shortInfo")} name="shortInfo">
            <Input/>
        </Form.Item>
        <Form.Item label={t("profile.aboutMe")} name="aboutMe">
            <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button disabled={disabledBtn} onClick={onClickHandler} type={isEditOn ? "primary" : "default"}>
            {isEditOn ? t("profile.save") : t("profile.edit")}
          </Button>
        </Form.Item>
        {error && <p>{error.graphQLErrors.map(({ message }, i) => (
          <Alert key={i} type="error" message={message} />
        ))}</p>}
      </Form>
    </>
  )
}

export default ProfileInfo