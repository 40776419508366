import styled from "styled-components";
import {Row} from "antd";


export const CardRowStyle = styled(Row)`
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid rgba(222, 222, 222, 0.73);
  border-left: 10px solid #ffc8af;
  box-shadow: 3px 1px 7px #ebebeb;

  #TaskCardForm_title {
    border: none;
  }

  #TaskCardForm_description {
    border: none;
  }

  .header {
    padding: 3px;
    border-bottom: 1px solid #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body {
  }

  & > * {
    width: 100%;
  }

  .anticon-delete {
    color: rgba(255, 0, 0, 0.41);
  }

  .ant-list-item {
    padding: 3px 10px;
  }
`

