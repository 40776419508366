import React from 'react';
import { Alert } from 'antd';
import { MutationResult } from '@apollo/client';
import Spinner from "./Spinner";

export const showErrors = (results: Array<any>): any => {
    const r = results as Array<MutationResult>;
    let messages = r.filter(r=>r.error).map(r=>r.error?.message ?? "");
    return messages.map(m => <Alert key={Math.random()} type="error" message={m} banner closable />)
}

export const showLoading = (results: Array<any>): any => {
    let loading = results.find(r=>r.loading);
    if (!loading) return null;
    return <Spinner key={Math.random()} loading></Spinner>;
}

// TODO: про ошибки почитать тут и сделать
// https://www.apollographql.com/docs/react/data/error-handling#advanced-error-handling-with-apollo-link
