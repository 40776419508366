import React from 'react'
import {
    StarFilled, StarOutlined
} from '@ant-design/icons';
import {Button, Tooltip, Spin} from 'antd'
import {
    Project,
    useProjectAddToFavoriteMutation
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {updateProjectInCache} from "../../subscriptions/allProjects";

interface ProjectFavoriteButtonProps {
    project: Project
}

const ProjectFavoriteButton = ({project}: ProjectFavoriteButtonProps) => {
    const {t} = useTranslation()
    const [addToFavorite, {loading: loadingAdd }] = useProjectAddToFavoriteMutation();

    const isFav = project.UserMarkers && project.UserMarkers.isFavorite;

    return (
        <div style={{left: "-10px", top: "3px", position: "relative", minWidth: 32, minHeight: 32}}>
        {loadingAdd
            ? <Spin style={{top: 15}}><div className="content" /></Spin>
            : <Tooltip title={t(isFav ? 'project.RemoveFromFavorite' : 'project.AddToFavorite')}>
                <Button type="text" icon={ isFav ? <StarFilled rev={undefined} /> : <StarOutlined rev={undefined} />}
                          onClick={(e) => {
                            e.stopPropagation();
                              addToFavorite({variables: {input: {
                                          projectId: project.id,
                                          isFavorite: !isFav
                                      }
                                  },
                                  update: (cache, r) => {
                                    updateProjectInCache(cache, r.data?.projectAddToFavorite as Project)
                              }
                              })
                          }
                          } />
            </Tooltip>
            }
        </div>
    )
}

export default ProjectFavoriteButton