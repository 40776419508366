import {Button, Popconfirm, Tooltip} from "antd";
import { useTaskRemoveMutation } from "../../generated-types";
import {DeleteOutlined} from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RefetchesList from "../../queries/RefetchesList";


export interface TaskRemoveBtnProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string
    value?: boolean,
    onChange?: (value: boolean) => void;
    disabled?:boolean;
}

function TaskRemoveBtn(props: TaskRemoveBtnProps) {
    const {t} = useTranslation();
    const [value, setValue] = useState<boolean>(props.value ?? false)

    const [taskRemove] = useTaskRemoveMutation({
        onCompleted: () => {
            if (props.onChange) {
                props.onChange(value)
            }
        },
        refetchQueries: [RefetchesList.TasksByViewId]
    })

    return (
        <Tooltip title={t("task.removeTask")}>
            <Popconfirm
                icon={null}
                title={t('task.confirmRemoveTask')}
                description={t('task.confirmRemoveTaskDescription')}
                onConfirm={()=> {
                    setValue(true)
                    taskRemove({variables: { id: props.id }})
                }}
                disabled={props.disabled}
                onCancel={()=>{}}
                okText={t('remove')}
                cancelText={t('no')}
                okType={"danger"}
            >
            <Button type="text" danger={true} icon={<DeleteOutlined />} ></Button>
            </Popconfirm>
        </Tooltip>)
}

export default TaskRemoveBtn