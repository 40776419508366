import {Maybe, User} from "../../generated-types";
import {UserOutlined} from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";

interface UserAvatarProps {
    avatarUrl?: string | Maybe<string>,
    user?: User | null,
    style?: any,
    className?: string,
}

const UserAvatar = (props: UserAvatarProps) => {
    const {user, avatarUrl, ...otherProps} = props
    if (user)
        return (<Tooltip key={user.id} title={user.username} placement="top" className={props.className}>
            <Avatar src={user.avatarUrl} icon={<UserOutlined rev={undefined}  />} className={props.className} style={props.style}/>
        </Tooltip>)


    return (<Avatar {...otherProps} src={avatarUrl} icon={<UserOutlined rev={undefined} />} className={props.className}/>)
}

export default UserAvatar