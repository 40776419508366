import React from 'react';
import { Spin } from 'antd';

export interface SpinnerProps {
    loading?: boolean | boolean[]
}

const Spinner: React.FC<SpinnerProps> = ({loading}) => {
    if (loading == false || loading == undefined || loading instanceof Array && !loading.some(v=>v === true))
        return null;

    return (
        <div className="spinner" >
            <Spin size='large' />
        </div>
    )
};

export default Spinner;