import React, { useState } from 'react';
import {
    CheckOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    LinkOutlined
} from '@ant-design/icons';
import {Breadcrumb, Button,
    ButtonProps, List, Modal, Select, Switch, Typography} from 'antd';
import {
    AttachedFolder,
    ExternalFile, ExternalFileType,
    useYandexDiskGetFolderItemsQuery
} from "../../generated-types";
import { useTranslation } from 'react-i18next';
import Spinner from "../Spinner";

interface SelectFilesProps {
    title?: string
    /***
     * Базовых путей может быть несколько (например, из проекта и из тимы.
     */
    basePath?: string
    folders?: AttachedFolder[]
    buttonTitle?: string
    loading?: boolean
    onChange?: (items: ExternalFile[]) => void
    onFinish?: (items: ExternalFile[]) => void
    selectedItems?: ExternalFile[]
    onlyFolders?: boolean
    /***
     Показывать или чекбоксы выбора файлов
     ***/
    selectable?: boolean
    multipleSelect?: boolean
    btnProps?: ButtonProps
}

export const FilesList: React.FC<SelectFilesProps> = (props) => {
    const { onChange, onlyFolders, multipleSelect} = props;

    const [selectedFolder, setSelectedFolder] = useState(props.folders != null && props.folders.length > 0 ? props.folders[0] : null); // может быть лучше бы сделать массивом?
    const [basePath, setBasePath] = useState(props.basePath || (selectedFolder != null ? selectedFolder.path : "/"));

    const [currentPath, setCurrentPath] = useState(basePath); // может быть лучше бы сделать массивом?
    const [selectedFiles, setSelectedFiles] = useState<ExternalFile[]>(props.selectedItems ?? []);

    const {data, loading} = useYandexDiskGetFolderItemsQuery({
        variables: {
            path: currentPath,
            attachedFolderId: selectedFolder ? selectedFolder.id : null,
            onlyFolders: onlyFolders ?? false
        }
    })

    const items = data?.yandexDiskGetFolderItems as ExternalFile[];

    const breadcrumbItems = [{title: <Button type={"link"} onClick={()=>setCurrentPath(basePath)}><HomeOutlined rev={undefined} /></Button>}]
        .concat(currentPath.replace(basePath, "").split("/").filter(v=>v != "").map(i=>(
            {
                title: <Button type={"link"} onClick={()=>{
                    const p = currentPath.substring(0, currentPath.indexOf(i)+i.length);
                    setCurrentPath(p)
                }}>{i}</Button>
            })));

    return (<div>
        <Spinner loading={loading || props.loading} />
        {props.folders && props.folders.length > 0 && <Select
            defaultValue={basePath}
            style={{ width: "100%" }}
            onChange={(id)=> {
                if (!props.folders) return;
                const folder = props.folders.find(v=>v.id == id)
                if (!folder) return;
                setSelectedFolder(folder);
                setBasePath(folder.path);
                setCurrentPath(folder.path);
            }}
            options={props.folders.map(p => ({ value: p.id, label: p.path }))}
        />}
        <List
            header={<Breadcrumb items={breadcrumbItems} />}
            dataSource={items}
            renderItem={(item) => {
                return (
                    <List.Item style={{cursor: "pointer"}}
                               onClick={()=> {
                                   if (item.type == ExternalFileType.Dir)
                                       setCurrentPath(item.path)
                               }}
                               actions={[props.selectable && <Switch
											           checkedChildren={<CheckOutlined  rev={undefined} />}
											           unCheckedChildren={null}
											           checked={selectedFiles.some(f=>f.path == item.path)}
											           onChange={(selected, e)=> {
                                     let res =[...selectedFiles];
                                     if (selected && !selectedFiles.some(f => f.path == item.path)) {
                                         if (multipleSelect)
                                             res.push(item)
                                         else
                                             res = [item]
                                     }
                                     if (!selected)
                                         res = selectedFiles.filter(f=>f.path != item.path)

                                     setSelectedFiles(res)
                                     if (onChange)
                                         onChange(res)

                                     e.stopPropagation();
                                     e.preventDefault();
                                 }
                                 }
                               />]
                               }
                    >
                    <span>
                        {item.type == ExternalFileType.Dir && <FolderOpenOutlined rev={undefined} />}
                        <Typography.Text style={{paddingLeft: item.type != ExternalFileType.Dir ? 20 : 5}}>{item.name}</Typography.Text>
                    </span>

                    </List.Item>
                )
            }}
        />
    </div>)
}

const AttachFilesButton: React.FC<SelectFilesProps> = (props) => {
    const {t} = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState(props.selectedItems ?? []);
    let {title = t("file.attachFolderHeader") } = props
    let {buttonTitle  } = props
    if (props.selectable && (selected?.length ?? 0) > 0)
        title += ` (${selected?.length})`



    const handleOk = () => {

        if (props.onFinish) props.onFinish(selected)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (<>
        <Modal open={isModalOpen} width={"50%"} closable={true} centered={true} //footer={null}
               title={title}
               onOk={handleOk} onCancel={handleCancel}
        >
            <FilesList {...props} onChange={(items) => {
                setSelected(items)
                if (props.onChange) props.onChange(items)
            }} />
        </Modal>
        <Button {...props.btnProps} icon={<LinkOutlined  rev={undefined} />}
                onClick={()=> {
                    setIsModalOpen(true)
                }}
        >{buttonTitle}</Button></>)
}

export default AttachFilesButton