import { Card, Col, theme } from 'antd'
import React from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'
import GeneralBoardCard, { GeneralBoardItemType } from './GeneralBoardCard'
import { isMobile } from 'react-device-detect'


const GeneralBoardColumnContainer = styled.div`
width: 95%;
height: 100%;
align-self: center;
`
const GeneralBoardColumnTitle = styled.div`
display: flex;
flex-direction: column;
`
const GeneralBoardColumnBody = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
`

export type GeneralBoardColumnType<T> = {
    id: number | string,
    title: string
    item: T
}

interface GeneralBoardColumnProps<I, C> {
    signature: string,
    items: GeneralBoardItemType<I>[],
    onItemRender: (item: I) => React.JSX.Element,
    column: GeneralBoardColumnType<C>,
    columnTitle: string,
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C) => void
    stickyTopElement?: (column: C) => React.JSX.Element
    isBoardDragging: boolean
    cursorType?: string,
    touchEventsDelay: number
}

function GeneralBoardColumn<I, C>({ signature, items, onItemRender, column, columnTitle, onItemDropped, stickyTopElement, isBoardDragging, cursorType, touchEventsDelay }: GeneralBoardColumnProps<I, C>) {


    const [{ isOver }, drop] = useDrop(() => (
        {
            accept: signature,
            drop(itemDropped: I, monitor) {
                if (monitor.isOver({ shallow: true })) {
                    onItemDropped(itemDropped, undefined, column.item)
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }
    ), [])

    const { 
        token: { colorBorder, colorBgLayout, }
    }=theme.useToken()

    const cardBodyStyle: React.CSSProperties = {
        backgroundColor: colorBgLayout,
        padding: 0,
        marginTop: 1,
        flexGrow: 1,
        overflowY: 'auto'
    }

    const cardHeaderStyle: React.CSSProperties = {
        textAlign: isMobile ? 'center' : undefined,
        padding: 0,
        flexShrink: 0,
        cursor: cursorType
    }

    const cardStyle: React.CSSProperties = {
        height: '100%',
        borderRight: isOver ? `solid 1px ${colorBorder}` : 'none',
        borderLeft: isOver ? `solid 1px ${colorBorder}` : 'none',
        display: 'flex',
        flexDirection: 'column',
    }

    const cardHeaderTextBlockStyle: React.CSSProperties={
        minHeight: 40, 
        display: 'flex', 
        alignItems: 'center', 
        flexGrow: 0,
        justifyContent: isMobile ? 'center' : undefined, 
        paddingLeft: 24
    }

    const header = <GeneralBoardColumnTitle>
        <div style={cardHeaderTextBlockStyle}>{columnTitle}</div>
        {stickyTopElement && stickyTopElement(column.item)}
    </GeneralBoardColumnTitle>

    return (
        <Col role='COLUMN'
            style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
            <GeneralBoardColumnContainer ref={drop}>
                <Card className={'card'} style={cardStyle}
                    title={header}
                    styles={{ body: cardBodyStyle, header: cardHeaderStyle }}
                >
                    <GeneralBoardColumnBody style={{cursor: cursorType}}>
                        {
                            items.map((item) => <GeneralBoardCard
                                key={`GB-item-${column.id}-${item.id}`}
                                signature={signature}
                                item={item}
                                onItemRender={onItemRender}
                                onItemDropped={onItemDropped}
                                isBoardDragging={isBoardDragging} 
                                touchEventsDelay={touchEventsDelay}
                                />)
                        }
                    </GeneralBoardColumnBody>
                </Card>
            </GeneralBoardColumnContainer>
        </Col>
    )
}

export default GeneralBoardColumn