import styled, {css} from "styled-components";
import UserAvatar from "../../../User/UserAvatar";

export const Container = styled.div <{ collapsed?: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 18px 16px 18px 12px;
  cursor: pointer;

  ${({collapsed}) =>
          collapsed &&
          css`
            padding: 18px 16px 18px 12px;
          `};
`

export const CustomAvatar = styled(UserAvatar)`
  width: 40px;
  height: 40px;
  transition-duration: 1s;
  transition-property: height;
  max-width: 40px;
`

