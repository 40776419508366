import React, {useEffect, useMemo, useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import {QuestionOutlined} from '@ant-design/icons';
import {FloatButton, Layout, theme} from 'antd';
import {useReactiveVar} from "@apollo/client";
import {authState} from "./routes/Auth/authContext";
import {
    Project,
    Tag,
    useAllPersonalTagsQuery,
    useProjectsListQuery,
    UsersUnion,
    UsersUnionMemberInvite,
    useUsersUnionsListQuery
} from "./generated-types";
import {showErrors, showLoading} from "./Components/helpers";
import {allUsersUnions} from "./subscriptions/allUsersUnions";
import {AllProjects, allProjects} from "./subscriptions/allProjects";
import TaskEditor from "./Components/Tasks/TaskEditor";
import {DndProvider} from 'react-dnd';
import {pollInterval} from "./ApolloClient";
import ProjectTasksFastList from "./Components/Project/ProjectTasksFastList";
import {allPersonalTags} from "./subscriptions/allPersonalTags";
import CookieConsent from 'react-cookie-consent';
import {useTranslation} from "react-i18next";
import {TouchBackend} from "react-dnd-touch-backend";
import PaymentWidgetEditor from "./Components/Payment/PaymentWidgetModal";
import AddSlotsModalEditor from "./Components/Payment/AddSlotsModal";
import {BlockedInfo} from "./Components/Payment/BlockedInfo";
import TariffTableModalEditor from "./Components/Payment/ModalTariffsTable";
import {CommonDragLayer} from "./Components/CommonDragLayer";
import ProjectTransfersList from "./Components/Project/ProjectTransfersList";
import {CustomLeftMenu} from "./Components/AntCastomComponents/CustomLeftMenu/CustomLeftMenu";
import {TaskNewModal} from "./Components/Tasks/TaskNew";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isMobile } from 'react-device-detect';

const {Content} = Layout;
const App: React.FC = () => {
    const {t} = useTranslation();
    const [contentMargin, setContentMargin] = useState<number>(0); // marginLeft меняется динамически, так как позиция боковой панели фиксирована и выведена и потока.
    const [value, setValue] = useState({width: 0, height: 0});
    const resizeHandler = () => setValue((prev) => ({...prev, width: window.innerWidth, height: window.innerHeight}));

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const authInfo = useReactiveVar(authState);

    const skip = useMemo(() => !authInfo.user, [authInfo]);
    const pollIntervalTime = useMemo(
        () => (skip ? undefined : pollInterval),
        [skip]
    );

    // TODO: переделать на подписки в будущем?
    const projectsQ = useProjectsListQuery({
        skip: skip,
        // fetchPolicy: 'network-only',
        onCompleted: (data) => {
            allProjects(new AllProjects(data.projects as Project[]));
        },
        pollInterval: (pollIntervalTime ?? 60000) + 5000 // TODO: переделать на подписку
    })

    const unionsQ = useUsersUnionsListQuery({
        skip: skip,
        // fetchPolicy: 'network-only',
        onCompleted: (data) => {
            allUsersUnions({
                unions: data.usersUnions as UsersUnion[] ?? [],
                invites: data.usersUnionsInvites as UsersUnionMemberInvite[] ?? []
            });
        },
        pollInterval: (pollIntervalTime ?? 60000) + 1000 // TODO: переделать на подписку
    })

    const allTagsQ = useAllPersonalTagsQuery({
        skip: skip,
        // fetchPolicy: 'network-only',
        onCompleted: (data) => {
            allPersonalTags({
                tags: data.allPersonalTags as Tag[] ?? []
            });
        },
        pollInterval: pollIntervalTime ? pollIntervalTime * 10 : undefined // TODO: переделать на подписку
    })

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        const height = `${window.innerHeight * 0.01}px`;
        document.documentElement.style.setProperty('--vh', height);
    }, [value.height]);

    useEffect(() => {
        if (!authInfo.user) {
            unionsQ.stopPolling();
            projectsQ.stopPolling();
            allTagsQ.stopPolling();
        } else {
            unionsQ.startPolling(pollInterval);
            projectsQ.startPolling(pollInterval);
            allTagsQ.startPolling(pollInterval);
        }
    }, [authInfo]);


    // TODO: заготовка для смены темы светлая/темная
    // const changeTheme = (value: boolean) => {
    // 	if (value)
    // 		UiTheme(UiAlgorithm.dark);
    // 	else
    // 		UiTheme(UiAlgorithm.light);
    // };
    // {/*<Switch onChange={changeTheme} />*/}
    const backend=isMobile? TouchBackend : HTML5Backend
    const options=isMobile? {delayTouchStart: 500} : undefined
    return (
        <DndProvider backend={backend} options={options}>
            <CommonDragLayer />
            <FloatButton icon={<QuestionOutlined/>} tooltip={<div>Тех.поддержка</div>}
                         type={"primary"}
                         onClick={() => {
                             window.open("https://t.me/+ZHao_pYdqQRmYTdi")
                         }}
            />
            <Layout hasSider style={{maxHeight:'100vh', overflow:'hidden'}}>
                <CustomLeftMenu contentMargin={contentMargin} setContentMargin={setContentMargin}/>
                <TaskEditor/>
                <ProjectTasksFastList/>
                <Layout style={{marginLeft: contentMargin, transition: "all 0.2s ease"}}>
                    {authInfo.user ? <BlockedInfo/> : null}
                    {authInfo.user && showErrors([projectsQ, unionsQ])}
                    {authInfo.user && showLoading([projectsQ, unionsQ])}
                    {authInfo.user && <ProjectTransfersList />}
                    <Content
                        style={{
                            overflow:'auto',
                            minHeight: '100vh',
                            display: "flex", flexDirection: "column",
                            background: colorBgContainer,
                        }}
                    >
                        <Outlet/>
                    </Content>
                    <CookieConsent
                        overlay={true}
                        contentStyle={{textAlign: "right"}}
                        buttonText={t('cookieConsent.acceptButton')}
                    >{t('cookieConsent.mainText')}<br/>
                        <Link to={"/PersonalDataAcceptance"}>{t('cookieConsent.linkText')}</Link>
                    </CookieConsent>
                    <AddSlotsModalEditor/>
                    <PaymentWidgetEditor/>
                    <TariffTableModalEditor/>
                    <TaskNewModal/>
                </Layout>
            </Layout>
        </DndProvider>
    );
};

export default App;