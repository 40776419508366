import React, {useEffect, useState} from 'react';
import {Select, Spin, Avatar, Space} from 'antd';
import type {SelectProps} from 'antd/es/select';
import {Maybe, Project, useFindUsersQuery, User, UsersUnion} from "../../generated-types";
import {TeamOutlined, MailOutlined, ClockCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import * as EmailValidator from 'email-validator';
import {ProcessProjectInviteModal} from "../Project/ProcessProjectInviteModal";

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
}

// Usage of DebounceSelect
export interface UserValue {
    label: any;
    value: string;
}

export interface IUser {
    id: any;
    username: string;
}

export interface IInvite {
    user?: Maybe<IUser>;
    usersUnion?: { id: string, title: string };
    email: string;
}

export type UserOrUnion = User | UsersUnion


interface userSearchProps {
    maxMembers?: number,
    project?: Project,
    addUsersUnions: boolean,
    /**
     * Нужно ли добавлять пользователь по имейлу
     */
    addEmails?: boolean,
    membersAdded: (result: UserSearchResults, uMembers:number) => boolean
    members: Maybe<IUser>[]
    initialState?: UserValue[]
    invites: Maybe<IInvite>[]
    disabled?: boolean
    checkSlots?: boolean
}

export interface UserSearchResults {
    users: User[],
    unions: UsersUnion[]
    emails: string[]
}

const INVITE_SENT = "INVITE_SENT";

const UserSearch: React.FC<userSearchProps> = ({
                                                   project,
                                                   maxMembers,
                                                   membersAdded,
                                                   disabled,
                                                   invites,
                                                   members,
                                                   addUsersUnions,
                                                   addEmails = true,
                                                   checkSlots = true,
                                                   initialState
                                               }) => {
    const [value, setValue] = useState<UserValue[]>(initialState ?? []);
    const [search, setSearch] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const {t} = useTranslation()

    const {data, loading} = useFindUsersQuery({
        variables: {search}
    });

    let users: UserValue[] = [];

    if (data && data.findUsers)
        users = data.findUsers
            .filter(v =>
                members.find(i => i != null && i.id == v.id) == null
                &&
                invites.find(i => i != null && i.user?.id == v.id) == null
            )
            .map((u) => {
                    return {
                        asUser: true,
                        label: <div><Avatar size={"small"} src={u.avatarUrl}/><span>{u.username}</span></div>,
                        value: 'u/' + u.id
                    }
                }
            )

    if (addUsersUnions) {
        if (data && data.findUsersUnions.length) {
            users = users.concat(data.findUsersUnions
                .filter(v =>
                    invites.find(i => i != null && i.usersUnion?.id == v.id) == null
                )
                .map((u) => {
                        return {
                            asUsersUnion: true,
                            label: <div><TeamOutlined rev={undefined}/> <span>{`${u.title}(${u.name})`}</span></div>,
                            value: 'uu/' + u.id
                        }
                    }
                ))
        }
    }

    if (users.length == 0 && EmailValidator.validate(search) && addEmails) {
        if (invites.find(i => i != null && i.email == search)) {
            users.push({
                label: <div>Приглашение уже отправлено: {search}</div>,
                value: INVITE_SENT
            })
        } else {
            users.push({
                label: <div>Отправить приглашение: {search}</div>,
                value: search
            })
        }

    }

    const sendInvite =async (unionMaxMembers: number) => {
        let vl = value.filter(v => v.value != INVITE_SENT)
        const res: UserSearchResults = {
            users: vl.filter(v => v.value.startsWith("u/")).map(v => ({
                id: v.value.replace("u/", ""),
                username: v.value
            }) as User),
            unions: vl.filter(v => v.value.startsWith("uu/")).map(v => ({
                id: v.value.replace("uu/", ""),
                title: v.value
            }) as UsersUnion),
            // users: data?.findUsers.filter(u=> vl.some(v=>v.value == "u/"+u.id)) as User[],
            // unions: data?.findUsersUnions.filter(uu=> vl.some(v=>v.value == "uu/"+uu.id)) as UsersUnion[],
            emails: vl.filter(v => EmailValidator.validate(v.value)).map(v => v.value),
        }

        if (membersAdded(res, unionMaxMembers))
            setValue([])
    }

    const onCancelModal = () => {
        setValue([]);
        setOpenModal(false);
    }

    const onOkModal = (unionMembers:number)=>{
        setOpenModal(false);
        sendInvite(unionMembers);
    }

    useEffect(() => {
        if (!value.length)
            return;
        if (checkSlots && value.length>0 && value[value.length - 1].value.includes('uu')) {
            setOpenModal((prevState) => !prevState)
        }
        else {
            sendInvite(1)
        }
    }, [value]);

    return <Space direction="vertical" style={{width: '100%'}}>
        <Space.Compact style={{width: '100%', whiteSpace: 'nowrap'}}>
            <Select
                optionLabelProp="label"
                labelInValue
                filterOption={false}
                disabled={disabled}
                showSearch
                value={value[0]}
                placeholder={addUsersUnions ? t('userSearch.choiceUsersAndUnions') : t('userSearch.choiceUsers')}
                onSearch={(v) => {
                    setSearch(v);
                }
                }
                notFoundContent={loading ? <Spin size="small"/> : null}
                onChange={(newValue:UserValue) => {
                    setValue(()=>
                        [newValue].map(v => {
                            if (!EmailValidator.validate(v.value))
                                return v;

                            if (invites.find(i => i?.email == search))
                                return {
                                    label: <div><ClockCircleOutlined rev={undefined}/> <span>{v.value}</span></div>,
                                    value: INVITE_SENT
                                }
                            return {
                                label: <div><MailOutlined rev={undefined}/> <span>{v.value}</span></div>,
                                value: v.value
                            }
                        }) as UserValue[]);
                }}
                listHeight={228}
                style={{width: '100%'}}
                options={users}
            />
        </Space.Compact>
        {maxMembers && project && <ProcessProjectInviteModal
            open={openModal}
            project={project}
            maxMembers={maxMembers}
            onCancel={onCancelModal}
            onOk={onOkModal}
        />}
    </Space>

};

export default UserSearch;