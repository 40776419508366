import React, {useMemo} from "react";
import {User} from "../../../../generated-types";
import Text from "antd/es/typography/Text";
import {Container, CustomAvatar} from "./CustomUserAvatar.styled";
import {useNavigate} from "react-router-dom";

interface CustomUserAvatarProps {
    user: User;
    collapsed: boolean;
    callback: () => void;
}

export const CustomUserAvatar: React.FC<CustomUserAvatarProps> = ({user, collapsed, callback}) => {
    const navigate = useNavigate();
    const style = useMemo(() => {
        return {
            width:  '40px',
            height:  '40px'
        }
    }, [collapsed])
    return <Container collapsed={collapsed ? "true" : undefined} onClick={() => navigate('/profile')}>
        <CustomAvatar user={user as User} style={style}/>
        {!collapsed && <Text>{user.username}</Text>}
    </Container>
}