import React, {} from 'react';
import { Button, Tooltip, Popconfirm } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { TooltipProps } from 'antd/lib/tooltip';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import {useTranslation} from "react-i18next";

interface TooltipButtonProps extends ButtonProps {
  tooltipProps?: TooltipProps,
  popconfirmProps?: PopconfirmProps
}

const TooltipButton = (props: TooltipButtonProps) => {
  const {t} = useTranslation();
  const {popconfirmProps, tooltipProps, ...buttonProps} = props;
  let btn = <Button {...buttonProps}>{props.children}</Button>;
  if (tooltipProps) btn = <Tooltip {...tooltipProps}>{btn}</Tooltip>;
  if (popconfirmProps)
      btn = <Popconfirm  {...popconfirmProps}
                   title={popconfirmProps?.title ? popconfirmProps.title : t('really')}
      >
          {btn}
      </Popconfirm>
  return btn
}

export default TooltipButton