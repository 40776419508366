import React, { useState } from 'react';
import {
    CheckOutlined, CloudDownloadOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    LinkOutlined
} from '@ant-design/icons';
import {
    Breadcrumb, Button,
    ButtonProps, List, Modal, Select, Switch, Tooltip, Typography
} from 'antd';
import {
    AttachedFolder,
    ExternalFile, ExternalFileType, useGetDownloadLinkLazyQuery,
    useYandexDiskGetFolderItemsQuery
} from "../../generated-types";
import { useTranslation } from 'react-i18next';
import Spinner from "../Spinner";
import {ExternalFileWithId} from "../Tasks/TaskCard";

interface DownloadFileButtonProps {
    file: ExternalFileWithId
}


const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({file}) => {
    const {t} = useTranslation()

    const [getDownloadLink, { data: linkData }] = useGetDownloadLinkLazyQuery({})
    if (linkData?.link) {
        const w = window.open(linkData?.link)
        console.log(w)
    }

    return (<Tooltip title={t("task.downloadFile")}>
        <Button type={"link"} onClick={() => {getDownloadLink({variables: {
                taskFileId: file.id
            }}); }}>
            <CloudDownloadOutlined rev={undefined} style={{paddingRight: 5}} />
        </Button>
    </Tooltip>)
}

export default DownloadFileButton