import React from 'react';
import {Layout} from "antd";

const { Content} = Layout;

type Props = {
    children?: React.ReactNode
};

const Text: React.FC<Props> = ({children}) => {
    return <p>{children}</p>
}

export const Contacts = () => {

    return (
        <Content style={{ margin: '24px' }}>
            <Text><b>Юридическое лицо</b></Text>
            <Text>ООО "Бэклайн"</Text>
            <Text>ИНН: 5261135499</Text>
            <Text>ОГРН: 1235200030515</Text>
            <br/>
            <Text><b>Как можно с нами связаться</b></Text>
            <Text>Адрес для корреспонденции: 603009, Нижегородская область, г Нижний Новгород, Невская ул, д. 21, помещ.
                п2</Text>
            <Text>email: hi@backline.moscow</Text>
            <Text>tlgrm: <a href="https://t.me/+ZHao_pYdqQRmYTdi">Группа поддержки Backline</a></Text>
        </Content>
    )
}