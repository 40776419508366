import { Input, Button } from "antd"
import { t } from "i18next"
import { useState, useMemo, PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import GeneralBoard from "./GeneralBoard"
import { GeneralBoardItemType } from "./GeneralBoardCard"
import { GeneralBoardColumnType } from "./GeneralBoardColumn"

type MockColumn = {
    id: string,
    title: string,
}

type MockItem = {
    id: string,
    title: string,
    columnId: string,
    position: number
}

function MyStickyElement({onClick}: PropsWithChildren<{onClick: (name: string)=>void}>){

    const [name, setName]=useState('')


    return <div>
            <Input value={name} onChange={(e)=>setName(e.target.value)} suffix={<Button onClick={()=>onClick(name)}>+</Button>}/>
        </div>
}
function GeneralBoardTesting() {

    const mockColumns = [{ id: 'cl1', title: 'new' },
    { id: 'cl2', title: 'active' },
    { id: 'cl3', title: 'finished' },
    { id: 'cl4', title: 'frozen' },
    ]

    const [items, setItems] = useState<MockItem[]>([
        {
            id: 'cd1', title: 'Карточка 1', columnId: 'cl1', position: 0.1
        },
        {
            id: 'cd2', title: 'Карточка 2', columnId: 'cl3', position: 0.1
        },
        {
            id: 'cd3', title: 'Карточка 3', columnId: 'cl2', position: 0.1
        },
        {
            id: 'cd4', title: 'Карточка 4', columnId: 'cl1', position: 0.5
        },
        {
            id: 'cd5', title: 'Карточка 5', columnId: 'cl2', position: 0.5
        },
        {
            id: 'cd6', title: 'Карточка 6', columnId: 'cl4', position: 0.1
        }
    ])

    const generalBoardItems: GeneralBoardItemType<MockItem>[] = useMemo(() => {
        return items.map((item) => ({ id: item.id, columnId: item.columnId, item: item } as GeneralBoardItemType<MockItem>))
    }, [items])

    const generalBoardcolumns: GeneralBoardColumnType<MockColumn>[] = mockColumns.map((item) => ({ id: item.id, title: t(`project.status.${item.title}`), item: item }))

    const myColumnsSplitter = (column: GeneralBoardColumnType<MockColumn>, items: GeneralBoardItemType<MockItem>[]) => {
        return items.filter((item) => item.columnId === column.item.id).sort((a, b) => a.item.position - b.item.position)
    }

    const myItemRender = (item: MockItem) => {

        return <div style={{ width: '100%', height: '150px', borderRadius: 10, borderBottom: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
            <Link to='/'>{item.title}</Link>
        </div>

    }

    
    const myItemDroped=(droppedItem: MockItem, droppedOnItem?: MockItem, column?: MockColumn)=>{

        setItems((prevItems)=>{
            const index = prevItems.findIndex((item) => item.id === droppedItem.id)
        
            const newArr = [...prevItems]
            if (column) {
                const columnItems = newArr.filter((item) => item.columnId === column.id).sort((a, b) => a.position - b.position)
                newArr[index].columnId = column.id as string;
                const prevItemPosition = columnItems[columnItems.length - 1]?.position ?? 0
                newArr[index].position = prevItemPosition + (1 - prevItemPosition) / 2
            }
    
            if (droppedOnItem) {
    
                const columnItems = newArr.filter((item) => item.columnId === droppedOnItem.columnId).sort((a, b) => a.position - b.position)
                const droppedOnItemIndex = columnItems.findIndex((el) => el.id === droppedOnItem.id)
                newArr[index].columnId = droppedOnItem.columnId as string;
                const prevItemPosition = columnItems[droppedOnItemIndex - 1]?.position ?? 0
                newArr[index].position = prevItemPosition + (columnItems[droppedOnItemIndex].position - prevItemPosition) / 2;
            }
            return newArr
        })

    }

    const myStickyTopElement = (column: MockColumn) => {

        const onClickHandle=(name: string)=>{
            const columnItems=items.filter((item)=>item.columnId===column.id).sort((a, b) => a.position - b.position)
            const firstColumnItemPosition=columnItems[0]?.position ?? 1
            const newItemPosition=0+firstColumnItemPosition/2

            const newItem: MockItem={
                id: `i${Math.random()}`,
                title: name,
                position: newItemPosition,
                columnId: column.id
            }

            
            setItems(prev=>[...prev,newItem])

        }
        
        return <MyStickyElement onClick={onClickHandle}/>
    }
    return <GeneralBoard items={generalBoardItems}
        signature='CARD'
        columns={generalBoardcolumns}
        columnsSplitter={myColumnsSplitter}
        onItemRender={myItemRender}
        onItemDropped={myItemDroped}
        stickyTopElement={myStickyTopElement}
        columnsToShow={3}
    />
}

export default GeneralBoardTesting