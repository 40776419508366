import React from 'react';
import {Button, Space, Tooltip} from "antd";
import {Task, TasksView, TasksViewColumn, useTaskChangeColumnInViewMutation} from "../../generated-types";
import {useTranslation} from "react-i18next";

const getStyleByStatus = (s: TasksViewColumn) => {
    switch (s.color) {
        case "blue":
            return {backgroundColor: '#469cff'}
        case "orange":
            return {backgroundColor: '#ffc146'}
        case "green":
            return {backgroundColor: '#43cb0096'}
        case "red":
            return {}
        default:
            return {}
    }
}



interface TaskChangeColumnProps {
    taskView: TasksView;
    task: Task
    allowEdit?: boolean
}


const TaskChangeColumn = ({taskView, task, allowEdit=true}: TaskChangeColumnProps) => {

    const {t} = useTranslation();

    const [changeColumn] = useTaskChangeColumnInViewMutation({
        // refetchQueries: [RefetchesList.TasksByViewId]
    })

    return (<Space.Compact>
        {taskView.columns?.filter((c: TasksViewColumn)=>c != undefined).map((clmn: TasksViewColumn) => {
            const taskIsInColumn = clmn?.title == task.status
            const title = t('task.columns.'+clmn?.title);
            return <Tooltip key={clmn?.id} title={title}>
                <Button type={taskIsInColumn ? "primary" : "dashed"} size={"small"} disabled={!allowEdit}
                    // icon={getIconByStatus(clmn as TasksViewColumn)}
                        style={taskIsInColumn ? getStyleByStatus(clmn as TasksViewColumn) : {}}
                        onClick={()=>{
                            if (clmn)
                                changeColumn({
                                    variables: {
                                        taskId: task.id,
                                        taskViewId: taskView.id,
                                        columnId: clmn.id
                                    }
                                })
                        }}
                >{title}</Button>
            </Tooltip>
        })}
    </Space.Compact>);
}

export default TaskChangeColumn;
