import React from 'react';
import { Avatar, Button, List, Popconfirm } from 'antd';
import {
    UsersUnion, UsersUnionMemberInvite, useUsersUnionMemberInviteRemoveMutation
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import { TeamOutlined } from '../../c';
import {DeleteOutlined} from "@ant-design/icons";
import UserListItem from "../User/UserListItem";

interface UsersUnionMembersInvitesProps {
    allowRemove: boolean,
    usersUnion: UsersUnion,
    invites: UsersUnionMemberInvite[]
}

const UsersUnionMembersInvites: React.FC<UsersUnionMembersInvitesProps> = ({invites, allowRemove}) => {
    const {t} = useTranslation()

    const [Remove] = useUsersUnionMemberInviteRemoveMutation({});

    return (
        <div>
            {/*{showErrors([opt1, opt2, opt3])}*/}
            {/*{showLoading([opt1, opt2, opt3])}*/}
            <List
                split={false}
                loading={false}
                itemLayout="horizontal"
                loadMore={false}
                dataSource={invites}
                renderItem={(item) => ( item && <List.Item
						        actions={[
                                    allowRemove && <Popconfirm
                                    icon={null}
                                    title={t('really')}
                                    description={t('inviteSureRemove')}
                                    onConfirm={()=> {
                                        Remove({variables: {id: item.id}})
                                    }}
                                    onCancel={()=>{}}
                                    okText={t('remove')}
                                    cancelText={t('no')}
                                >
                            <Button type="text" danger><DeleteOutlined rev={undefined} /></Button>
                        </Popconfirm>
                    ]}
					        >
                        {
                            item.invitedUser && <UserListItem user={item.invitedUser} />
                        }
                        {
                            item.email && <List.Item.Meta
                                avatar={<Avatar style={{marginLeft: 5}} ><TeamOutlined rev={undefined} /></Avatar>}
                                title={item.email}
                                description={t('invitedByEmail')}
                            />
                        }
					        </List.Item>
                )}
            />
        </div>
    );
};

export default UsersUnionMembersInvites;