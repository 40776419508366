import Icon  from '@ant-design/icons';
import React from "react";
import {CustomIconComponentProps} from "@ant-design/icons/es/components/Icon";

interface CustomSvgProps{
    svg:()=> JSX.Element
}

export const CustomIcons:React.FC<CustomSvgProps>=({svg})=>{
     const CustomIcon = (props:Partial<CustomIconComponentProps>) => (
        <Icon component={svg} {...props}/>
    );
    return <CustomIcon/>
}