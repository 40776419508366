import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, Space} from 'antd';
import {useTranslation} from "react-i18next";
import {Project} from "../../generated-types";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";

import ProjectBreadcrumb from "../ProjectBreadcrumb";
import TaskViewList, {DIRECTION, DisplayView, SORTFIELD} from "../Tasks/TaskViewList";
import { addTaskContext } from '../Tasks/TaskNew';

const ProjectTasksFastList: React.FC = () => {
    const fastTasks = useReactiveVar(ProjectTasksFastListProps);
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const addTaskCtx=useReactiveVar(addTaskContext)

    const isBlocked = fastTasks?.project.paymentAccount?.isBlocked

    useEffect(()=>{
        setOpen(fastTasks != null);
    }, [open, fastTasks]);

    if (!fastTasks)
        return null;

    const {project} = fastTasks ;



    const onClose = () => {
        ProjectTasksFastListProps(null)
        setOpen(false);
    };

    const addNewTask=()=>{
        addTaskContext({...addTaskCtx, projectId: project.id, taskViewId: project.tasksView.id})
    }

    return (
            <Drawer key="2"
                    title={<ProjectBreadcrumb projectId={project.id} />}
                    width={600}
                    onClose={onClose}
                    open={open}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Button disabled={isBlocked} type="primary" block onClick={addNewTask} htmlType="submit">
                                {t('task.create')}
                            </Button>
                        </Space>
                    }
            >
                <TaskViewList showHeader={false} tasksView={project.tasksView}
                              defaultDisplayParams={{
                                  displayView: DisplayView.fastList,
                                  sort: SORTFIELD.position,
                                  tags: [],
                                  iAmResponsible: false,
                                  sortDirection: DIRECTION.DESC,
                                  includeResolved: true
                              }}
                              showProjectTitle={false}
                />
            </Drawer>
    );
};

interface ProjectTasksFastListCtx {
    project: Project
}

export const ProjectTasksFastListProps: ReactiveVar<ProjectTasksFastListCtx | null> = makeVar<ProjectTasksFastListCtx | null>(null);


export default ProjectTasksFastList;