import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider, makeVar, ReactiveVar, useReactiveVar} from '@apollo/client';
import {RouterProvider} from "react-router-dom";
import AppRouter from "./AppRouter";
import client from "./ApolloClient";
import {App, ConfigProvider, theme} from "antd";
import {selectedLanguage} from "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export class UiAlgorithm extends String {
    public static dark: string = 'dark'
    public static light: string = 'light'
    public static compact: string = 'compact'
}

export const UiTheme: ReactiveVar<UiAlgorithm> = makeVar<UiAlgorithm>(UiAlgorithm.light);

const Container: React.FC = () => {
    const uiTheme = useReactiveVar(UiTheme);
    const lang = useReactiveVar(selectedLanguage);
    let algorithm = theme.darkAlgorithm;
    switch (uiTheme) {
        case UiAlgorithm.compact:
            algorithm = theme.compactAlgorithm;
            break
        case UiAlgorithm.light:
            algorithm = theme.defaultAlgorithm;
            break
    }
    return <ConfigProvider csp={{ nonce: 'YourNonceCode' }} theme={{algorithm}} locale={lang.locale}>
        <App>
            <RouterProvider router={AppRouter} />
        </App>
    </ConfigProvider>;
}

//TODO: return back React.StrictMode
root.render(
  // <React.StrictMode>
      <ApolloProvider client={client}>
          <Container />
      </ApolloProvider>
  // </React.StrictMode>
);

reportWebVitals();
