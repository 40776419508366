import React from 'react';
import {Form, Input, Row, Col, Avatar, Spin, message, Divider, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {authState} from '../../routes/Auth/authContext';
import {ApolloError} from '@apollo/client/core';
import {Navigate, useParams} from 'react-router-dom';
import {User, useOtherUserProfileQuery} from '../../generated-types';
import {useReactiveVar} from '@apollo/client';
import {allProjects} from "../../subscriptions/allProjects";
import ProjectsGroup from "../Project/ProjectsGroup";

const {Title, Text} = Typography;

const OtherUserProfile = () => {
    const {id} = useParams();
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const {user} = useReactiveVar(authState);

    const {data, loading} = useOtherUserProfileQuery({
        variables: {
            id
        },
        onError: (error) => {
            message.error(error.message, 4)
        }
    });

    const allP = useReactiveVar(allProjects);
    const userProjects = allP.otherUsersProjects().filter(v => v.userAsOwner?.id == id)

    if (user.id === id) {
        //Если совпадают id текущего пользоватедя и пользователя на которого перешили,
        //то открыть страницу профиля
        return <Navigate to="/profile"/>
    }

    if (loading || !data) {
        return <div style={{
            display: "flex", width: "100%", height: "100%", justifyContent: "center",
            alignItems: "center"
        }}>
            <Spin tip="Loading" size="large"/>
        </div>
    }
    const {userProfile} = data;

    return (
        <section className="other-profile" style={{padding: "30px"}}>
            <header className="other-profile__name">
                <Title level={2}>{userProfile?.fullName ? userProfile?.fullName : t("profile.noFullName")}</Title>
                <Text italic>{userProfile?.shortInfo}</Text>
            </header>
            <Divider/>
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={6}>
                            <div className="other-profile__avatar-section">
                                <div style={{margin: "20px 0"}}>
                                    <Avatar src={userProfile?.avatarUrl} size={150} icon={<UserOutlined/>}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="other-profile__info">
                                <div className="other-profile__info-item">
                                    <p>{t("profile.userName")}</p>
                                    <p>{userProfile?.username}</p>
                                </div>
                                <div className="other-profile__info-item about">
                                    <p>{t("profile.aboutMe")}</p>
                                    <p>{userProfile?.aboutMe}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <ProjectsGroup projects={userProjects}/>
                </Col>
            </Row>
        </section>
    )
}

export default OtherUserProfile