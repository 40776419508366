import React from "react";
import Text from "antd/es/typography/Text";
import {Space} from "antd";
import {PaymentSlot} from "../generated-types";
import {slotsTypesWithAmounts} from "./Payment/SlotsTypesWithAmounts";
import {useTranslation} from "react-i18next";

interface CountInvitesMembersProps {
    slots: PaymentSlot[];
    type: string;
}

export const CountInvitesMembers: React.FC<CountInvitesMembersProps> = ({slots, type}) => {
    const {t} = useTranslation();

    const slotsUsersUnionMembers = slotsTypesWithAmounts(slots as any, type);
    const emptySlots = () => {
        const empty = slotsUsersUnionMembers?.freeEmptySlots.length! + slotsUsersUnionMembers?.payEmptySlots.length!
        return empty
    }

    return <Space.Compact style={{
        width: '100%',
        whiteSpace: 'nowrap',
        display: "flex",
        justifyContent: "flex-end", paddingTop: 8, paddingBottom: 8
    }}>
        <Text>{t('usersUnion.countInvitesMembers', {count: emptySlots()})}</Text>
    </Space.Compact>
}