import React, { useState } from 'react';
import {Card, Empty, List, Tabs} from 'antd';
import ConnectYandexDisk from "./ConnectYandexDisk";
import {
    ExternalFile,
    useAttachFolderMutation,
    useFilesInUsersUnionQuery,
    UsersUnion
} from "../../generated-types";
import { useTranslation } from 'react-i18next';
import RefetchList from "../../queries/RefetchesList";
import AttachFilesButton, {FilesList} from "./AttachFilesButton";
import {editTaskContext} from "../Tasks/TaskEditor";

interface UsersUnionFilesListProps {
    usersUnion: UsersUnion,
    isAdmin: boolean
}

const UsersUnionFilesList: React.FC<UsersUnionFilesListProps> = ({usersUnion, isAdmin}) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState(false);

    const [attachFolder] = useAttachFolderMutation({
        onCompleted: () => {
            setInProgress(false)
            // m.destroy();
        },
        refetchQueries: [RefetchList.UsersUnion]
    })

    const filesInTasks = useFilesInUsersUnionQuery({
        variables: {usersUnionId: usersUnion.id as string}
    })
    const filesInUsersUnion = filesInTasks.data?.filesInUsersUnion;

    const onFolderChanged = (items: ExternalFile[])=>{
        setInProgress(true)
        // TODO: не очень-то получилось
        attachFolder({
            variables: {
                path: items[0].path,
                usersUnionId: usersUnion.id,
            }
        })
    };

    const attachBtn = <AttachFilesButton basePath={"/"} loading={inProgress} onFinish={onFolderChanged} selectable={true} onlyFolders={true}
         buttonTitle={t('file.usersUnionAttachFolderBtn') as string} title={t('file.usersUnionAttachFolder') as string}
         selectedItems={usersUnion.attachedFolders.map((name, path)=>({name, path} as unknown as ExternalFile))}
    />;

    if (usersUnion.attachedFolders.length == 0) {
        return <ConnectYandexDisk allowAttachToken={isAdmin}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('file.adminHasToAttachFolder')}>
                {isAdmin && attachBtn}
            </Empty>
        </ConnectYandexDisk>
    }

    const tabs = usersUnion.attachedFolders.map((f, i) => {
        const id = String(i + 1);
        return {
            label: f.path,
            key: id,
            children: <FilesList folders={[f]} selectable={false}/>,
            closable: true,
        };
    });
    tabs.unshift({
        label: t("file.filesInTasks"),
        key: "",
        closable: false,
        children:
            <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={filesInUsersUnion}
                renderItem={(item) => (
                    <List.Item>
                        <Card title={item.path}>{
                            item.tasks.map(t=> {
                                return <List.Item.Meta
                                    description={<>
                                        <span style={{padding: 5}}>{t.project.title}</span>
                                        <a onClick={(e)=>{
                                            editTaskContext({taskId: t.id});
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }} >{t.title}</a>
                                </>}
                                    // description={<Breadcrumb items={description} />}
                                />
                            })
                        }</Card>
                    </List.Item>
                )}
            />
    })

    return (<ConnectYandexDisk allowAttachToken={isAdmin}>
                <Tabs tabBarExtraContent={isAdmin && attachBtn} items={tabs} style={{marginBottom: 0}}/>
            </ConnectYandexDisk>
    )
};

export default UsersUnionFilesList;