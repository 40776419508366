import React from 'react'
import {Project} from "../../generated-types";
import ProjectsListCard from "./ProjectsListCard";
import {Col, Row} from 'antd'
import ProjectNew from "./ProjectNew";
import {UserOrUnion} from "../User/UserSearch";

interface ProjectsGroupProps {
    projects: Array<Project>,
    context?: UserOrUnion | undefined,
    onProjectClick?: (project: Project) => void
    archived?:boolean
}

const ProjectsGroup = (props: ProjectsGroupProps) => {
    const elements: any[] = [];
    let isBlocked: boolean | undefined
    if (props.projects.length) {
        isBlocked = props.projects[0].paymentAccount?.isBlocked
    }


    if (props.context)
        if(props.archived === false)
            elements.push(<Col key={"new"} flex="300px" style={{minHeight: "170px"}}>
                <ProjectNew context={props.context} isBlocked={isBlocked}/>
            </Col>);
    elements.push(props.projects.map((project: any) => <Col key={project.id} flex="300px">
            <ProjectsListCard key={project.id} project={project} onProjectClick={props.onProjectClick}/>
        </Col>
    ));

    return <Row gutter={[16, 16]}>{elements}</Row>
}

export default ProjectsGroup;