import React, {useMemo, useState} from 'react'
import {
    Breadcrumb,
    Button,
    Col,
    Dropdown,
    Empty,
    Form,
    message,
    notification,
    Popconfirm,
    Row,
    Space,
    Tabs,
    Tooltip,
    Watermark
} from 'antd'
import {ExportOutlined, LeftOutlined, MoreOutlined, SaveOutlined} from '@ant-design/icons'
import {
    AccessLevel,
    Project,
    usePlugToUnionProjectMutation,
    useProjectAttachTagsMutation,
    useProjectCardQuery,
    useProjectLeaveMutation,
    useProjectSwitchArchivedMutation,
    useProjectUpdateMutation
} from "../../generated-types";
import {useNavigate, useParams} from "react-router-dom";
import ProjectMembersEditor from "./ProjectMembersEditor";
import {useTranslation} from "react-i18next";
import ProjectSettings from "./ProjectSettings";
import TaskViewList from "../Tasks/TaskViewList";
import {addTaskContext} from "../Tasks/TaskNew";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import ProjectFilesList from "../File/ProjectFilesList";
import Tagger from "../Tagger";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import Spinner from "../Spinner";
import {isMobile} from "react-device-detect";
import ProjectTransfer from "./ProjectTransfer";
import TooltipButton from "../TooltipButton";
import {CustomPageHeader} from "../AntCastomComponents/PageHeader";
import {ItemType} from "antd/es/menu/interface";

const ProjectCard: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const addTaskCtx = useReactiveVar(addTaskContext);
    const [form] = Form.useForm();
    const {id, tab} = useParams();
    const [selectedTab, setTab] = useState<string>(tab ?? 'tasks');
    const [messageApi, contextHolder] = message.useMessage()

    const {data, loading} = useProjectCardQuery({
        variables: {id: id as string},
        // fetchPolicy: 'network-only',
        onCompleted: (_) => {
        }
    })

    const authInfo = useReactiveVar(authState);

    const [projectUpdate] = useProjectUpdateMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        },
        onCompleted: () => success()
    });

    const [projectAttachTags] = useProjectAttachTagsMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [plugToUnionProject, {}] = usePlugToUnionProjectMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [leaveProject, {}] = useProjectLeaveMutation({
        onCompleted: (_) => {
            notification.success({
                message: t("project.leavedSuccessfully"),
                description: project.title,
            });
            navigate("/")
        }
    });

    const [archivedProject] = useProjectSwitchArchivedMutation({
        update: (cache, r) => {
            const project = r.data?.projectSwitchArchived as Project;
            updateProjectInCache(cache, project)
        }
    });

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('editDataMassage'),
            style: {
                marginTop: '20vh',
            },
        });
    };
    
    const project = data?.project as Project

    let items: any[] = [];
    let content: any=undefined;
    let plugToProjectButton: React.JSX.Element | false=false
    let addTagToProject: React.JSX.Element=<></>
    let leaveProjectButton: React.JSX.Element | false=false;
    let dropdownItems: ItemType[] = [];
    
    if(project){
        items=[
            {
                label: t('project.tasks'),
                key: 'tasks',
                children: <></>,
                // style: { height: 200 }
            },
            {
                label: t('project.files'),
                key: 'files',
                children: <></>,
                // style: { height: 200 }
            },
            {
                label: t('project.members') + " (" + project.members.length + ")",
                key: 'members',
                children: <></>,
                // style: { height: 200 }
            }
    
        ]
        const isAdmin = project.members.some(m => m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin)
        const isNotMember = !(project.members.some(m => m.user.id == authInfo.user.id))

        addTaskCtx.projectId = project.id;
        addTaskContext(addTaskCtx);

        // const showDrawer = () => {
        //     setOpen(true);
        // };
        //
        // const onClose = () => {
        //     setOpen(false);
        // };

        const save = (input: any) => {
            projectUpdate({variables: {projectId: project.id, input}})
        };

        if (isAdmin)
            items.push({
                label: t('project.settings'),
                key: 'settings',
                children: <></>,
                // style: { height: 0 }
            })

        content = {
            tasks: <TaskViewList key={project.tasksView.id} tasksView={project.tasksView} allowCreate={true}
                                showProjectTitle={false}/>,
            files: <ProjectFilesList project={project} isAdmin={isAdmin}/>,
            members: <ProjectMembersEditor project={project}/>,
            settings: <>
                <ProjectSettings project={project} form={form} save={save}/>
                <Space direction="vertical" style={{width: '50%', display:'flex', flexDirection:'column'}}>
                    <Button type="primary" block onClick={form.submit} htmlType="submit">
                        {t('project.save')}
                    </Button>
                    <TooltipButton danger ghost block icon={<SaveOutlined />} onClick={()=>archivedProject({
                        variables: {
                            projectSwitchArchivedId: project.id
                        }
                    })} tooltipProps={{title: t('project.archiveTooltip')}} >
                        {project.archived ? t('project.unArchiving') : t('project.archiving')}
                    </TooltipButton>
                    {contextHolder}
                    {isAdmin && <ProjectTransfer project={project} />}
                </Space>
            </>
        }

        // Присоединиться к проекту
        plugToProjectButton = isNotMember &&
                <Button disabled={project.archived} key={"plugToUnionProject"} type={"primary"} onClick={() => {
            plugToUnionProject({
                variables: {
                    projectId: project.id
                }
            })
        }}>{t('project.joinYourselfToProject')}</Button>;

        addTagToProject = <Tagger projectId={project.id} editable={true} block={true}
                                        defaultValue={project.attachedTags}
                                        allowEditUsersUnionTags={true} allowEditProjectTags={true}
                                        onChanged={(tags) => {
                                            projectAttachTags({
                                                variables: {
                                                    projectId: project.id,
                                                    tags
                                                }
                                            })
                                        }}
        />

        leaveProjectButton = !isNotMember &&
                <>
                    <Tooltip title={t('project.leaveTooltip')}>
                        <Popconfirm
                            icon={null}
                            title={t('project.leaveConfirmTitle')}
                            description={t('project.leaveConfirmDescription')}
                            okButtonProps={{type: "primary", className: "red"}}
                            onConfirm={() => {
                    leaveProject({
                        variables: {
                            projectId: project.id
                        }
                    })
                }}
                            onCancel={() => {
                }}
                            okText={t('yes')}
                            cancelText={t('no')}
                        >
                            <Button type={"text"}
                                    icon={<ExportOutlined/>}>{t("project.leave")}</Button>
                        </Popconfirm>
                    </Tooltip>
                </>;

        if (isMobile) {
            dropdownItems = items.map(itm => {
                return {
                    label: itm.label,
                    key: itm.key,
                    onClick: (e: any) => {
                        setTab(e.key)
                    }
                } as ItemType
            });
            dropdownItems.push({label: plugToProjectButton, key: 'plugToProjectButton'})
            dropdownItems.push({label: leaveProjectButton, key: 'leaveProjectButton'})
            dropdownItems.push({label: addTagToProject, key: 'addTagToProject'})
        }
    }
    
    let topMenuItems = useMemo(() => {

        if (!project) return []

        if (isMobile)
            return [
                {
                    href: '',
                    title: <div>
                        <Dropdown menu={{items: dropdownItems}} trigger={['click']}>
                            <Button icon={<MoreOutlined/>} type={"text"}
                                    onClick={(e) => e.preventDefault()}
                            />
                        </Dropdown>
                    </div>,
                },
                {
                    title: <div style={{flexGrow: 1, paddingRight: 7}}>{project.title}</div>,
                }
            ]

        return [project.usersUnionAsOwner ?
            // TODO: тут надо проверить доступ к этой тиме и не делать ссылку, если этого доступа нет
            {
                href: 'javascript:void(0)',
                onClick: () => {
                    navigate(`/usersUnion/${project.usersUnionAsOwner?.id}`)
                },
                title: <><LeftOutlined/>{project.usersUnionAsOwner.title}</>,
            }
            :
            {
                href: 'javascript:void(0)',
                onClick: () => {
                    navigate(`/user/${project.userAsOwner?.id}`)
                },
                title: <><LeftOutlined/>{project.userAsOwner?.username}</>,
            },
            {
                title: project.title,
            },
        ]
    }, [isMobile, project])

    const watermarkContent = useMemo(() => {
        if (project && project.paymentAccount?.isBlocked) {
            return t('paymentAccount.projectIsBlocked') as string
        } else if (project && project.archived) {
            return t('project.archived')as string
        } else return undefined
    }, [project])

    if (loading)
        return <Spinner loading={loading}/>

    if (!id || !project) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    }

    // У меня никак не получается положить дропдаун ниже так, чтобы он был справа, а название проекта было во всю оставшуюся ширину.
    return <Watermark content={watermarkContent} style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
        <CustomPageHeader
            title={<div style={{
                display: "flex",
                alignItems: "center",
                maxWidth: isMobile ? "100vw" : undefined,
                width: "100%"
            }}>
                <Breadcrumb
                    items={topMenuItems}
                />
                {isMobile ? undefined : <div style={{flexGrow: 1, minWidth: 170}}>{addTagToProject}</div>}
            </div>
            }
            extra={isMobile ? undefined : [plugToProjectButton]}
            footer={
                isMobile ? undefined : <Tabs defaultActiveKey={tab}
                      activeKey={selectedTab}
                                             tabBarExtraContent={leaveProjectButton}
                      items={items}
                      onChange={setTab}
                />
            }
        />

        <Row style={{margin: isMobile ? 0 : "0px 15px 0px 15px", flexGrow: 1}}>
            <Col span={24}>
                {content[selectedTab]}
            </Col>
        </Row>
    </Watermark>
};

export default ProjectCard