import React, {useEffect, useState} from 'react';
import {List, ColorPicker, Typography, Button, Popconfirm, Input, Tooltip} from 'antd';
import {
    AllPersonalTagsDocument,
    Project,
    Tag, TagFragmentDoc,
    useEditTagMutation, useRemoveTagMutation, UsersUnion
} from "../generated-types";
import {CheckOutlined, DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from 'react-i18next';
import {Color} from 'antd/es/color-picker';
import {InputStatus} from "antd/es/_util/statusUtils";
import Spinner from "./Spinner";
import {ApolloCache, useReactiveVar} from "@apollo/client";
import {allProjects} from "../subscriptions/allProjects";
import {allUsersUnions} from "../subscriptions/allUsersUnions";

const {Paragraph} = Typography;


// const options = [{ value: 'gold' }, { value: 'lime' }, { value: 'green' }, { value: 'cyan' }];

const defaultColors = [
    // '#000000',
    // '#000000E0',
    // '#000000A6',
    // '#00000073',
    // '#00000040',
    // '#00000026',
    // '#0000001A',
    // '#00000012',
    // '#0000000A',
    // '#00000005',
    '#F5222D',
    '#FA8C16',
    '#FADB14',
    '#8BBB11',
    '#52C41A',
    '#13A8A8',
    '#1677FF',
    '#2F54EB',
    '#722ED1',
    '#EB2F96',
    // '#F5222D4D',
    // '#FA8C164D',
    // '#FADB144D',
    // '#8BBB114D',
    // '#52C41A4D',
    // '#13A8A84D',
    // '#1677FF4D',
    // '#2F54EB4D',
    // '#722ED14D',
    // '#EB2F964D',
];

export const tagDefaultColor = '#4f97ff'

export interface TagEditorContext {
    projectId?: string | undefined,
    usersUnionId?: string | undefined,
    userId?: string | undefined
}

export interface TagEditorProps {
    context: TagEditorContext,
    tag: Tag,
    removable: boolean
    newItem: boolean
}

const TagEditor = ({tag, removable, context, newItem}: TagEditorProps) => {
    const {t} = useTranslation();
    const [title, setTitle] = useState<string | undefined>(tag.title);
    const [color, setColor] = useState(tag.color ?? tagDefaultColor);
    const [titleStatus, setTitleStatus] = useState<InputStatus>("");
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const allP = useReactiveVar(allProjects);
    const allUnions = useReactiveVar(allUsersUnions);

    const [tagEdit] = useEditTagMutation({});

    const [tagRemove] = useRemoveTagMutation({});

    const deleteTag=(tag:Tag)=>{
        setDeleteInProgress(true)
        tagRemove({
            variables:{
                removeTagId:tag.id
            },
            onCompleted:()=> setDeleteInProgress(false),
            update:(cache,{data})=>{

                if (data?.removeTag && context.projectId) {
                    const prj = allP.projects.find(p=>p.id === context.projectId);
                    if (prj)
                        updateCachedRemoveTag(cache, tag, prj)
                }
                else if (data?.removeTag && context.usersUnionId) {
                    const uu = allUnions.unions.find(p=>p.id === context.usersUnionId);
                    if (uu)
                        updateCachedRemoveTag(cache, tag, uu)
                } else if (data?.removeTag && context.userId) {
                    removeCachedPersonalTags(cache, tag)
                }
            }
        })
    }


    const saveTag = () => {
        setTitleStatus(!title ? "error" : "")
        if (!title)
            return;
        setSaveInProgress(true);
    }


    useEffect(() => {
        if (!title) {
            setSaveInProgress(false);
            return;
        }

        if (saveInProgress) {
            tagEdit({
                variables: {
                    projectId: context.projectId,
                    usersUnionId: context.usersUnionId,
                    userId: context.userId,
                    title: title,
                    color: color ?? tagDefaultColor,
                    id: tag.id
                },
                onCompleted: () => {
                    if (newItem) setTitle(undefined)
                    setSaveInProgress(false);
                },
                update: (cache, {data}) => {
                    const tag = data?.editTag as Tag;
                    if (tag && context.projectId) {
                        const prj = allP.projects.find(p=>p.id === context.projectId);
                        if (prj)
                            updateCachedTags(cache, tag, prj)
                    }
                    else if (data?.editTag && context.usersUnionId) {
                        const uu = allUnions.unions.find(p=>p.id === context.usersUnionId);
                        if (uu)
                            updateCachedTags(cache, tag, uu)
                    } else if (data?.editTag && context.userId) {
                        updateCachedPersonalTags(cache, tag)
                    }

                }
            })
        }
    }, [saveInProgress]);

    const ChangeColor = (clr: Color) => {
        const c = clr.toHexString()
        setColor(c)
        if (!newItem) saveTag()
    }

    const ChangeTitle = (ttl: string) => {
        setTitleStatus(!ttl ? "error" : "")
        setTitle(ttl);
    }

    const picker = <ColorPicker
        key={tag.id}
        value={color}
        onChange={ChangeColor}
        presets={[
            {
                label: null,
                colors: defaultColors,
            }
        ]}
    />;

    if (newItem)
        return (
            <List.Item
                style={{justifyContent: "flex-start"}}
                actions={[
                    <Tooltip title={t("tags.saveNewTag")}>
                        <Button type="text"><CheckOutlined rev={undefined} onClick={saveTag}/></Button>
                    </Tooltip>]}>
                <Spinner loading={saveInProgress}/>
                {picker}
                <Input placeholder={t("tags.add") as string}
                       value={title}
                       status={titleStatus}
                       onKeyPress={(e) => {
                           if (e.charCode === 13) {
                               saveTag()
                           }
                       }}
                       onChange={(e) => {
                           ChangeTitle(e.target.value)
                       }}
                />
            </List.Item>)

    return (
        <List.Item
            style={{justifyContent: "flex-start"}}
            actions={[removable && <Popconfirm
                icon={null}
                title={t('tags.removeConfirmTitle')}
                onConfirm={() => deleteTag(tag)}
                onCancel={() => {}}
            >
                <Spinner loading={deleteInProgress}/>
                <Button type="text" danger><DeleteOutlined rev={undefined}/></Button>
            </Popconfirm>
            ]}>
            <Spinner loading={saveInProgress}/>
            {picker}
            <Paragraph style={{flexGrow: "1", marginBottom: 0, marginLeft: 15}}
                       editable={{
                           onEnd: saveTag,
                           onChange: (txt) => {
                               ChangeTitle(txt)
                           }, triggerType: ["icon", "text"]
                       }}
            >{title}</Paragraph>
        </List.Item>)
}

export default TagEditor;

export const updateCachedTags = (cache: ApolloCache<any>, tag: Tag, obj: Project | UsersUnion) => {
    cache.modify({
        id: cache.identify(obj),
        fields: {
            tags(existsTags) {
	            const tagRef = cache.writeFragment({
                    data: tag,
                    overwrite: true,
                    fragment: TagFragmentDoc
	            });
                if (existsTags.some((v: any)=>v.__ref == tagRef?.__ref)) {
                    return existsTags;
                }
                return [...existsTags, tagRef];
            },
        },
        optimistic: true
    });
}

export const updateCachedPersonalTags = (cache: ApolloCache<any>, tag: Tag) => {
    const __ref = cache.identify(tag)
    cache.writeFragment({
        id: __ref,
        fragment: TagFragmentDoc,
        data: tag
    });

    let {allPersonalTags} = cache.readQuery({query: AllPersonalTagsDocument}) as { allPersonalTags: Tag[] }
    const isNew = !allPersonalTags.some((t)=>t.id==tag.id)

    if (isNew) {
        allPersonalTags = [...allPersonalTags, tag]
        cache.writeQuery({
            query: AllPersonalTagsDocument,
            data: {allPersonalTags}
        });
    }
}

export const updateCachedRemoveTag = (cache: ApolloCache<any>, tag: Tag, obj: Project | UsersUnion)=>{
    const __ref = cache.identify(tag)
    cache.modify({
        id: cache.identify(obj),
        fields: {
            tags(existsTags) {
                return existsTags.filter((v:any)=>v.__ref !== __ref)
            },
        }
    });
}

export const removeCachedPersonalTags = (cache: ApolloCache<any>, tag: Tag) => {
    const tags = cache.readQuery({query: AllPersonalTagsDocument}) as { allPersonalTags: Tag[] }
    const newTags = tags.allPersonalTags.filter((t)=>t.id!==tag.id)
    cache.writeQuery({
        query: AllPersonalTagsDocument,
        data: {allPersonalTags: newTags}
    });
}