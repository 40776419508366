import React from "react";
import {useTranslation} from "react-i18next";
import {useUserPaymentAccountQuery} from "../../generated-types";
import dayjs from "dayjs";
import {Button, Divider, Space, Typography} from "antd";
import {useNavigate} from "react-router-dom";

export const BlockedInfo: React.FC = () => {
    const {t} = useTranslation();
    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery();
    const paymentDate = dayjs(paymentAccount?.paymentDate).startOf("day");
    const {Text} = Typography;
    const navigate = useNavigate();

    const today = dayjs().startOf("day");
    const p = today.isAfter(paymentDate);

    if (p && !paymentAccount?.isBlocked)
        return <Space style={{justifyContent: 'center', padding: 8, background: 'orange'}}
                      split={<Divider type="vertical"/>}>
            <Text style={{color: '#fff'}}>{t('paymentAccount.promisePay', {data: paymentDate.add(7, 'day').format('DD.MM')})}</Text>
            <Button ghost size={'small'} onClick={() => navigate('/profile')}>{t('paymentAccount.pay')}</Button>
        </Space>

    if (paymentAccount?.isBlocked)
        return <Space style={{justifyContent: 'center', padding: 8, background: 'red'}}
                      split={<Divider type="vertical"/>}>
            <Text style={{color: '#fff'}}>{t('paymentAccount.isBlocked')}</Text>
            <Button ghost size={'small'} onClick={() => navigate('/profile')}>{t('paymentAccount.pay')}</Button>
        </Space>

    return null
}