import React, {useState} from "react";
import Text from "antd/es/typography/Text";
import {Button, Col, InputNumber, Modal, Row} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {PaymentSlotType, Project, useUserPaymentAccountQuery} from "../../generated-types";
import {addSlotsContext} from "../Payment/AddSlotsModal";
import {slotsTypesWithAmounts} from "../Payment/SlotsTypesWithAmounts";
import ButtonGroup from "antd/es/button/button-group";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";


interface ProcessProjectInviteFooterProps {
    project: Project;
    maxMembers:number;
    open:boolean;
    onCancel:()=>void;
    onOk:(value:number)=>void;
}

export const ProcessProjectInviteModal: React.FC<ProcessProjectInviteFooterProps> = ({onOk,onCancel,open,project,maxMembers}) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<number>(1);
    const authInfo = useReactiveVar(authState);

    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery({
        variables: {id: project.paymentAccount.id}
    });

    const user = paymentAccount?.managers.find((m) => m.id === authInfo.user.id)

    const onChangeInput = (value: number | null) => {
        if (value) setValue(value);
    }

    const onClickButton = (type: string) => {
        if (type === 'increment') {
            setValue((prev) => prev + 1);
        } else {
            setValue((prev) => prev - 1);
        }
    }

    const openAddSlotsModal = () => {
        const allSlots = paymentAccount?.slots
        const slotsType = Object.keys(PaymentSlotType)
            .map(k => slotsTypesWithAmounts(allSlots!, k))
        addSlotsContext({
            slotsType: slotsType
        });
    }


    return <Modal
        centered={true}
        open={open}
        onCancel={onCancel}
        onOk={()=>onOk(value)}
        okButtonProps={{ disabled:value>maxMembers || value===0 }}
    >
        <Row style={{alignItems: 'baseline', gap: 12}}>
            {maxMembers && <Col>
                <Text style={{marginTop: 12, display: 'block'}} strong>
                {t('usersUnion.processProjectInvite.maxMembers', {count: maxMembers})}
            </Text>
            </Col>}
            <Col>
                <Text style={{marginTop: 12, display: 'block'}} strong>
                    {t('usersUnion.processProjectInvite.editMembersCount')}
                </Text>
            </Col>
            <Col>
                <ButtonGroup>
                    <Button
                        onClick={() => onClickButton('decrement')}
                        disabled={value <= 0}
                        icon={<MinusOutlined/>}
                        type={'primary'}
                    />
                <InputNumber
                    style={{width: 40, borderRadius: 0}}
                    controls={false}
                    value={value}
                    min={1} defaultValue={value}
                    onChange={(value) => onChangeInput(value)}/>
                <Button
                    icon={<PlusOutlined/>}
                    onClick={() => onClickButton('increment')}
                    type={'primary'}
                />
                </ButtonGroup>
            </Col>
        </Row>
        {user && maxMembers < value &&
            <Button style={{marginTop: 12}} type={'primary'}  onClick={() => openAddSlotsModal()}>
                {t('usersUnion.processProjectInvite.addSlots')}
            </Button>
        }
        {!user && maxMembers < value &&
            <Text style={{marginTop: 12, display: 'block'}} strong type={'danger'}>
                {t('usersUnion.processProjectInvite.notOwnerMessage')}
            </Text>
        }
    </Modal>
}