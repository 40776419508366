import {editTaskContext} from "./TaskEditor";
import React from "react";
import {Task} from "../../generated-types";
import {FormatInterval} from "../TimeIntervalSelector";

const defaultStyle = {
    cursor: "pointer",
    fontWeight: "600",
    color: "#343434"
}

const resolvedStyle = {
    textDecoration: "line-through",
}

const TaskTitle = ({task, openOnClick = true}: {task: Task, openOnClick?: boolean}) => {
    let style = defaultStyle;
    if (task.resolved)
        style = {...defaultStyle, ...resolvedStyle}
    return (
        <a onClick={(e) => {
            if (openOnClick)
                editTaskContext({taskId: task.id});
            e.preventDefault();
            e.stopPropagation();
        }}>

            <span style={style}>
                <span
                    style={{paddingRight: 5}}>{task.startTime && task.endTime && FormatInterval(task.startTime, task.endTime)}</span>
                {task.title}
            </span>
        </a>)
}

export default TaskTitle