import * as Apollo from '@apollo/client';
import {gql} from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

/** Уровень доступа к проекту */
export enum AccessLevel {
  Admin = 'admin',
  Member = 'member',
  Owner = 'owner'
}

export type AddSlotsInput = {
  /** Дополнительные приглашения в персональный проект */
  personalProjectAdditionalMember: Scalars['Float'];
  /** Идентификатор квитанции об оплате. Указывается, если идёт пересчет текущей квитанции */
  receiptId?: InputMaybe<Scalars['String']>;
  /** Дополнительные тимы */
  usersUnion: Scalars['Float'];
  /** Дополнительные участники тимы */
  usersUnionMembers: Scalars['Float'];
  /** Дополнительные участники проекта тимы */
  usersUnionProjectMember: Scalars['Float'];
};

export type AddTaskInput = {
  parentId?: InputMaybe<Scalars['ID']>;
  task: EditTaskInput;
  tasksViewColumnId?: InputMaybe<Scalars['ID']>;
  tasksViewId?: InputMaybe<Scalars['ID']>;
};

export type AttachedFileInput = {
  externalFilePath: Scalars['String'];
  name: Scalars['String'];
};

/** Папка, прикреплённая к проекту или объединению */
export type AttachedFolder = {
  __typename?: 'AttachedFolder';
  /** Когда была добавлена папка */
  attachDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  /** Проект, к которому привязана папка */
  project: Project;
  /** Объединение, к которому привязана папка */
  usersUnion: Project;
  /** Привязка к токену, который привязал эту папку */
  yandexToken: YandexToken;
};

export type CalendarFilterInput = {
  /** Дата окончания */
  endDate: Scalars['DateTime'];
  /** Дата начала */
  startDate: Scalars['DateTime'];
};

/** Запись в календаре */
export type CalendarItem = {
  __typename?: 'CalendarItem';
  /** Дата создания */
  CreateDate: Scalars['DateTime'];
  /** Дата окончания */
  endDate: Scalars['DateTime'];
  /** Время окончания в формате количество минут от 0. Максимум 1440 */
  endTime: Scalars['Float'];
  id: Scalars['ID'];
  /** Дата начала */
  startDate: Scalars['DateTime'];
  /** Время начала в формате количество минут от 0. Максимум 1440 */
  startTime: Scalars['Float'];
  /** Описание записи в календаре */
  title?: Maybe<Scalars['String']>;
  /** Создатель записи в календаре. Пока только личные календари */
  user: Project;
};

export type CalendarItemInput = {
  /** Дата окончания */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Время окончания в формате количество минут от 0. Максимум 1440 */
  endTime?: InputMaybe<Scalars['Float']>;
  /** Идентификатор */
  id?: InputMaybe<Scalars['String']>;
  /** Дата начала */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** Время начала в формате количество минут от 0. Максимум 1440 */
  startTime?: InputMaybe<Scalars['Float']>;
  /** Заголовок */
  title?: InputMaybe<Scalars['String']>;
};

/** Результат работы метода смены тарифа */
export type ChangeTariffResult = {
  __typename?: 'ChangeTariffResult';
  /** Платёжный аккаунт */
  paymentAccount: PaymentAccount;
  /** Счёт для оплаты, если происходит переход на более дорогой тариф */
  paymentReceipt?: Maybe<PaymentReceipt>;
};

/** Валюта */
export enum Currency {
  Eur = 'EUR',
  Rub = 'RUB'
}

export type EditTaskInput = {
  description?: InputMaybe<Scalars['String']>;
  /** Дата окончания задачи */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Время окончания в формате количество минут от 0. Максимум 1440 */
  endTime?: InputMaybe<Scalars['Float']>;
  files: Array<AttachedFileInput>;
  /** Важность */
  importance?: InputMaybe<Scalars['Float']>;
  members: Array<TaskMemberInput>;
  projectId: Scalars['ID'];
  /** Дата начала задачи */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** Время начала в формате количество минут от 0. Максимум 1440 */
  startTime?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<TaskStatus>;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
};

/** Файл в стороннем сервисе */
export type ExternalFile = {
  __typename?: 'ExternalFile';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  /** Абсолютный путь до папки */
  path: Scalars['String'];
  /** Публичный адрес папки, если он есть */
  publicUrl?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  /** Папка или файл */
  type: ExternalFileType;
};

/** Тип записи (папка или файл) в стороннем сервисе */
export enum ExternalFileType {
  Dir = 'dir',
  File = 'file'
}

/** Файл в стороннем сервисе и в каких задачах в рамках проекта используется */
export type ExternalFileWithTasks = {
  __typename?: 'ExternalFileWithTasks';
  created: Scalars['DateTime'];
  id: Scalars['String'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  /** Абсолютный путь до папки */
  path: Scalars['String'];
  /** Публичный адрес папки, если он есть */
  publicUrl?: Maybe<Scalars['String']>;
  /** Список задач, в которых используется этот файл */
  tasks: Array<Task>;
  tokenId: Scalars['String'];
  /** Папка или файл */
  type: ExternalFileType;
};

/** Это зеркало(кеш) файла (без данных) из ядиска (или другого сервиса, когда будет).  */
export type File = {
  __typename?: 'File';
  /** Когда был прикреплен файл к задаче */
  attachDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  /** Проект */
  project: Project;
  /** Привязка к задаче */
  task: Task;
  /** Привязка к токену, под которым привязан файл к задаче */
  yandexToken: YandexToken;
};

export type GetPaymentLinkInput = {
  /** На всякий случай передаём сумму, которую отобразили для пользователя. Если отличается от суммы в счете, то вернётся ошибка */
  amount: Scalars['Float'];
  /** Идентификатор квитанции об оплате. */
  receiptId: Scalars['String'];
};

/** Параметры для отображения окна оплаты */
export type GetPaymentLinkResult = {
  __typename?: 'GetPaymentLinkResult';
  /** Токен для передачи в виджет платёжной системы */
  confirmationToken: Scalars['String'];
};

/** Результат приглашения */
export enum InviteResolution {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Received = 'received'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Проверить статус счета у сервиса оплаты и применить позиции в счете  */
  ProcessReceipt: PaymentAccount;
  addTask: Task;
  /** Добавление папки к проекту: возвращать??? */
  attachFolder: Scalars['Boolean'];
  blablabla: Scalars['Boolean'];
  /** Создание или редактирование записи в календаре */
  calendarItemSave: CalendarItem;
  editTag: Tag;
  /** Добавление слотов. В результате возвращается счет, который нужно оплатить */
  payments_addSlots: PaymentReceipt;
  /** TODO: Смена тарифа для платёжного аккаунта */
  payments_changeTariff: ChangeTariffResult;
  /** TODO: Удаление слота из платежного аккаунта */
  payments_removeSlot: PaymentAccount;
  /** Самостоятельно подключиться к проекту команды, в котором участвует пользователь */
  plugToUnionProject: Project;
  processInvite: Project;
  projectAddMembers: Project;
  projectAddToFavorite: Project;
  /** Изменение списка меток у проекта */
  projectAttachTags: Project;
  /** Изменение приоритета и статуса проекта */
  projectChangePriorityAndStatus: Project;
  projectCreate: Project;
  /** Покинуть проект с указанным идентификатором. Из проекта может выйти любой участник. Только если он не единственный админ */
  projectLeave: Scalars['Boolean'];
  /** Изменение доступа участника в проекте */
  projectMemberChangeLevel: Project;
  /** Удаление приглашения в проект */
  projectMemberInviteRemove: Project;
  /** Удаление участника из проекта */
  projectMemberRemove: Project;
  /** Отправка проектов в архив или восстановление из архива */
  projectSwitchArchived: Project;
  projectTransfer: Scalars['Boolean'];
  /** Принять передачу проекта */
  projectTransferAccept: Project;
  /** Отклонить передачу проекта */
  projectTransferReject: Scalars['Boolean'];
  projectUpdate: Project;
  removeTag: Scalars['Boolean'];
  /** Изменить архивность задачи */
  taskChangeArchived: Task;
  /** Перетаскивание задачи из колонки в колонку. Пока в полную не используется. А в зависимости от маппинга колонки на статус задачи выставляется статус задачи */
  taskChangeColumnInView: Task;
  /** Изменить важность задачи */
  taskChangeImportance: Task;
  /** Сменить название задачи */
  taskChangeTitle: Task;
  /** Удаление задачи */
  taskRemove: Scalars['Boolean'];
  /** Задача завершена */
  taskResolved: Task;
  /** Сохранить задачу */
  taskSave: Task;
  /** Создать доску */
  tasksViewCreate: TasksView;
  tasksViewDelete: Scalars['Boolean'];
  /** Изменить доску */
  tasksViewUpdate: TasksView;
  userProfileUpdate?: Maybe<User>;
  userRestorePassword: UserRestorePasswordResult;
  userRestorePasswordSendLink: Scalars['Boolean'];
  userSignIn: Scalars['String'];
  userSignUp: UserSignUpResult;
  usersUnionCreate: UsersUnion;
  /** Удаление объединения без возможности восстановления */
  usersUnionDelete: Scalars['Boolean'];
  usersUnionInviteMembers: UsersUnion;
  /** Покинуть объединение с указанным идентификатором. Может выйти любой участник. Только если он не единственный админ */
  usersUnionLeave: Scalars['Boolean'];
  /** Изменение доступа участника в объединение */
  usersUnionMemberChangeLevel: UsersUnion;
  /** Удаление приглашения в объединение */
  usersUnionMemberInviteRemove: UsersUnion;
  /** Удаление участника из объединения */
  usersUnionMemberRemove: UsersUnion;
  usersUnionProcessInvite: UsersUnion;
  usersUnionUpdate: UsersUnion;
  /** Сохранение токена */
  yandexDiskSaveOAuthToken: Scalars['String'];
};


export type MutationProcessReceiptArgs = {
  paymentAccountId: Scalars['String'];
  receiptId: Scalars['String'];
};


export type MutationAddTaskArgs = {
  input: AddTaskInput;
};


export type MutationAttachFolderArgs = {
  path: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  usersUnionId?: InputMaybe<Scalars['String']>;
};


export type MutationCalendarItemSaveArgs = {
  input: CalendarItemInput;
};


export type MutationEditTagArgs = {
  color: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  usersUnionId?: InputMaybe<Scalars['String']>;
};


export type MutationPayments_AddSlotsArgs = {
  input: AddSlotsInput;
  paymentAccountId: Scalars['String'];
};


export type MutationPayments_ChangeTariffArgs = {
  paymentAccountId: Scalars['String'];
  tariffId: Scalars['String'];
};


export type MutationPayments_RemoveSlotArgs = {
  paymentAccountId: Scalars['String'];
  slotId: Scalars['String'];
};


export type MutationPlugToUnionProjectArgs = {
  projectId: Scalars['String'];
};


export type MutationProcessInviteArgs = {
  inviteId: Scalars['String'];
  resolution: InviteResolution;
  users: Array<Scalars['String']>;
};


export type MutationProjectAddMembersArgs = {
  input: ProjectAddMemberInput;
};


export type MutationProjectAddToFavoriteArgs = {
  input: ProjectAddToFavoritesInput;
};


export type MutationProjectAttachTagsArgs = {
  projectId: Scalars['String'];
  tags: Array<Scalars['String']>;
};


export type MutationProjectChangePriorityAndStatusArgs = {
  priority: Scalars['Float'];
  projectId: Scalars['String'];
  status: Scalars['String'];
  usersUnionId: Scalars['String'];
};


export type MutationProjectCreateArgs = {
  input: ProjectCreateInput;
};


export type MutationProjectLeaveArgs = {
  projectId: Scalars['String'];
};


export type MutationProjectMemberChangeLevelArgs = {
  input: ProjectMemberChangeLevelInput;
};


export type MutationProjectMemberInviteRemoveArgs = {
  id: Scalars['String'];
};


export type MutationProjectMemberRemoveArgs = {
  input: ProjectRemoveMemberInput;
};


export type MutationProjectSwitchArchivedArgs = {
  id: Scalars['String'];
};


export type MutationProjectTransferArgs = {
  projectId: Scalars['String'];
  userAsOwnerId?: InputMaybe<Scalars['String']>;
  usersUnionAsOwnerId?: InputMaybe<Scalars['String']>;
};


export type MutationProjectTransferAcceptArgs = {
  transferId: Scalars['String'];
};


export type MutationProjectTransferRejectArgs = {
  transferId: Scalars['String'];
};


export type MutationProjectUpdateArgs = {
  id: Scalars['String'];
  input: ProjectUpdateInput;
};


export type MutationRemoveTagArgs = {
  id: Scalars['String'];
};


export type MutationTaskChangeArchivedArgs = {
  archived: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationTaskChangeColumnInViewArgs = {
  columnId: Scalars['String'];
  overTaskId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['String'];
  taskViewId: Scalars['String'];
};


export type MutationTaskChangeImportanceArgs = {
  id: Scalars['String'];
  importance: Scalars['Float'];
};


export type MutationTaskChangeTitleArgs = {
  id: Scalars['String'];
  title: Scalars['String'];
};


export type MutationTaskRemoveArgs = {
  id: Scalars['String'];
};


export type MutationTaskResolvedArgs = {
  id: Scalars['String'];
  resolved: Scalars['Boolean'];
};


export type MutationTaskSaveArgs = {
  id: Scalars['String'];
  task: EditTaskInput;
};


export type MutationTasksViewCreateArgs = {
  input: TasksViewCreateInput;
};


export type MutationTasksViewDeleteArgs = {
  id: Scalars['String'];
};


export type MutationTasksViewUpdateArgs = {
  id: Scalars['String'];
  input: TasksViewUpdateInput;
};


export type MutationUserProfileUpdateArgs = {
  input: UserProfileInput;
};


export type MutationUserRestorePasswordArgs = {
  data: UserRestorePasswordInput;
};


export type MutationUserRestorePasswordSendLinkArgs = {
  email: Scalars['String'];
};


export type MutationUserSignInArgs = {
  data: UserSignInInput;
};


export type MutationUserSignUpArgs = {
  data: UserSignUpInput;
};


export type MutationUsersUnionCreateArgs = {
  input: UsersUnionCreateInput;
};


export type MutationUsersUnionDeleteArgs = {
  id: Scalars['String'];
};


export type MutationUsersUnionInviteMembersArgs = {
  input: UsersUnionAddMemberInput;
};


export type MutationUsersUnionLeaveArgs = {
  usersUnionId: Scalars['String'];
};


export type MutationUsersUnionMemberChangeLevelArgs = {
  input: UsersUnionMemberChangeLevelInput;
};


export type MutationUsersUnionMemberInviteRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationUsersUnionMemberRemoveArgs = {
  userId: Scalars['ID'];
  usersUnionId: Scalars['ID'];
};


export type MutationUsersUnionProcessInviteArgs = {
  inviteId: Scalars['String'];
  resolution: InviteResolution;
};


export type MutationUsersUnionUpdateArgs = {
  id: Scalars['String'];
  input: UsersUnionUpdateInput;
};


export type MutationYandexDiskSaveOAuthTokenArgs = {
  expiresIn: Scalars['Float'];
  token: Scalars['String'];
};

/** Оповещения */
export type Notification = {
  __typename?: 'Notification';
  /** Параметры оповещения */
  action?: Maybe<Scalars['String']>;
  /** Дата оповещения */
  createDate: Scalars['String'];
  id: Scalars['ID'];
  /** Получатель (почта или телефон или id пользователя) */
  receiver?: Maybe<Scalars['String']>;
  /** Текстовый статус оповещения */
  status?: Maybe<Scalars['String']>;
  /** Транспорт для передачи оповещения */
  transport: NotificationTransport;
  user: User;
};

/** Виды транспорта для доставки сообщений пользователям */
export enum NotificationTransport {
  Email = 'email',
  Push = 'push',
  Sms = 'sms',
  Telegram = 'telegram'
}

/** Платёжный аккаунт */
export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  /** Текущий пользователь имеет доступ к управлению этип платежным аккаунтом */
  currentUserHasAccess: Scalars['Boolean'];
  /** Количество свободных слотов для определенного типа слотов */
  emptySlotsCount: Scalars['Int'];
  id: Scalars['ID'];
  /** Платёжный аккаунт и все объединения и проекты к нему привязанные заблокированы */
  isBlocked: Scalars['Boolean'];
  /** Список пользователей, кто имеет доступ к платежному аккаунту. Сейчас только создатель имеет доступ */
  managers: Array<User>;
  /** Дата ежемесячного списания */
  paymentDate: Scalars['DateTime'];
  /** Список проектов, которые подключены к этому платежному аккаунту */
  projects: Array<Project>;
  /** История оплат */
  receipts: Array<PaymentReceipt>;
  /** Слоты для этого аккаунта */
  slots: Array<PaymentSlot>;
  /** Выбранный тариф */
  tariff: Tariff;
  title: Scalars['String'];
  /** Ежемесячная плата в валюте тарифа */
  totalMonthly: Scalars['Float'];
  /** Список объединений, которые подключены к этому платежному аккаунту */
  usersUnions: Array<UsersUnion>;
};


/** Платёжный аккаунт */
export type PaymentAccountEmptySlotsCountArgs = {
  slotType: PaymentSlotType;
};

/** Выставленные счета. Создается каждый раз, когда пользователь хочет что-то оплатить или если проводится регулярный платёж */
export type PaymentReceipt = {
  __typename?: 'PaymentReceipt';
  /** Сумма с учетом всех скидок */
  amount: Scalars['Float'];
  /** Сумма без учета скидок по прайсовой стоимости */
  amountByPrice: Scalars['Float'];
  /** Дата-время выставления счета */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Валюта */
  currency: Currency;
  /** Дата устаревания счета. Приходит из сервиса оплаты */
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Список позиций в счете */
  items: Array<PaymentReceiptItem>;
  /** Дата-время оплаты счета */
  paidDate?: Maybe<Scalars['DateTime']>;
  /** Платёжный аккаунт */
  paymentAccount: Tariff;
  /** Дата-время выставления счета в платежной системе */
  paymentDate?: Maybe<Scalars['DateTime']>;
  /** Идентификатор операции у платёжного контрагента */
  paymentId?: Maybe<Scalars['String']>;
  /** Статус оплаты */
  paymentStatus?: Maybe<PaymentStatusEnum>;
};

/** Позиция в счете. Пока можно не показывать в интерфейсе */
export type PaymentReceiptItem = {
  __typename?: 'PaymentReceiptItem';
  /** Итоговая сумма в валюте */
  amount: Scalars['Float'];
  /** Валюта */
  currency: Currency;
  /** Скидка в процентах */
  discount: Scalars['Float'];
  id: Scalars['ID'];
  /** Тип позиции */
  itemType: ReceiptItemType;
  /** Платёжный аккаунт */
  paymentReceipt: PaymentReceipt;
  /** Слот, который оплачен этой строкой */
  paymentSlot?: Maybe<PaymentSlot>;
  /** Цена в валюте  */
  price: Scalars['Float'];
  /** Тариф для оплаты */
  tariff: Tariff;
};

/** Слот в платежном аккаунта  */
export type PaymentSlot = {
  __typename?: 'PaymentSlot';
  /** Дата создания сдлота */
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Бесплатный слот в рамках тарифа */
  isCostFree: Scalars['Boolean'];
  /** Свободный или занятый */
  isEmpty: Scalars['Boolean'];
  /** Тип слота */
  itemType: PaymentSlotType;
  /** Дата последней оплаты слота. Для расчета активности этого слота */
  lastPaidDate?: Maybe<Scalars['DateTime']>;
  /** Дата, до которой оплачен слот */
  paidTill?: Maybe<Scalars['DateTime']>;
  /** Платёжный аккаунт */
  paymentAccount: PaymentAccount;
  /** Участник проекта, привязанный к этому слоту */
  projectMembers?: Maybe<Array<ProjectMember>>;
  /** Дата удаления слота */
  removedDate?: Maybe<Scalars['DateTime']>;
  /** Статус слота. Меняется автоматически */
  status: PaymentSlotStatus;
  /** Участник объединения, привязанный к этому слоту */
  usersUnionMembers?: Maybe<Array<UsersUnionMember>>;
  /** Объединение, привязанное к этому слоту */
  usersUnions?: Maybe<Array<UsersUnion>>;
};

/** Статус слота оплаты */
export enum PaymentSlotStatus {
  Active = 'active',
  Blocked = 'blocked',
  Removed = 'removed',
  WaitForPayment = 'waitForPayment'
}

/** Тип платежного слота */
export enum PaymentSlotType {
  /** Слот для участника личного проекта */
  PersonalProjectAdditionalMember = 'personalProjectAdditionalMember',
  /** Слот для объединения */
  UsersUnion = 'usersUnion',
  /** Слот для участника объединения */
  UsersUnionMembers = 'usersUnionMembers',
  /** Слот для участника проекта объединения */
  UsersUnionProjectMember = 'usersUnionProjectMember'
}

/** Статус проведения оплаты */
export enum PaymentStatusEnum {
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  WaitingForCapture = 'WAITING_FOR_CAPTURE'
}

/** Проект */
export type Project = {
  __typename?: 'Project';
  /** Пользовательский пометки проекта */
  UserMarkers: ProjectUserMarker;
  /** Если текущий пользователь имеет доступ к проекта как участник объединения */
  accessAsUnion?: Maybe<UsersUnion>;
  /** Архивный проект. Такой проект нигде не отображается. Не учитываются слоты оплаты. Не отображаются задачи */
  archived: Scalars['Boolean'];
  /** Список прикрепленных папок */
  attachedFolders: Array<AttachedFolder>;
  /** Прикреплённые теги */
  attachedTags: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** !!!!!!!!!! */
  files: Array<File>;
  id: Scalars['ID'];
  /** Приглашения в проект */
  invites: Array<ProjectMemberInvite>;
  isPublic: Scalars['Boolean'];
  /** Доступ к проекту для отдельных пользователей */
  members: Array<ProjectMember>;
  /** Платёжный аккаунт */
  paymentAccount: PaymentAccount;
  /** Приоритет проекта. Позиция в столбце на доске */
  priority: Scalars['Float'];
  /** Статистика по проекту */
  statistics: ProjectStats;
  /** Статус проекта */
  status: ProjectStatus;
  /** Список тегов в проекте */
  tags: Array<Tag>;
  /** Список задач проекта */
  tasks: Array<Task>;
  /** Связанный с проектом список задач */
  tasksView: TasksView;
  title: Scalars['String'];
  /** Если проект находится в процессе передачи, тогда в этом поле возвращается статус */
  transfer?: Maybe<ProjectTransferRequest>;
  user: User;
  /** Пользователь-владелец */
  userAsOwner?: Maybe<User>;
  /** Объединение-владелец */
  usersUnionAsOwner?: Maybe<UsersUnion>;
};


/** Проект */
export type ProjectPriorityArgs = {
  contextId: Scalars['String'];
};

export type ProjectAddMemberInput = {
  accessLevel?: InputMaybe<AccessLevel>;
  emails: Array<Scalars['String']>;
  maxMembers: Scalars['Float'];
  projectId: Scalars['ID'];
  unions: Array<Scalars['ID']>;
  users: Array<Scalars['ID']>;
};

export type ProjectAddToFavoritesInput = {
  isFavorite: Scalars['Boolean'];
  projectId: Scalars['ID'];
};

export type ProjectCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  /** Объединение - владелец */
  usersUnionAsOwnerId?: InputMaybe<Scalars['String']>;
};

/** Доступ пользователя к проекту */
export type ProjectMember = {
  __typename?: 'ProjectMember';
  /** Уровень доступа к проекту */
  accessLevel: AccessLevel;
  /** Если участник проекта добавлен как участник объединения */
  asUnion?: Maybe<UsersUnion>;
  id: Scalars['ID'];
  /** Слот оплаты */
  paymentSlot?: Maybe<PaymentSlot>;
  /** Проект */
  project: Project;
  /** Участник */
  user: User;
};

export type ProjectMemberChangeLevelInput = {
  level: AccessLevel;
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Приглашение в проект */
export type ProjectMemberInvite = {
  __typename?: 'ProjectMemberInvite';
  /** Дата приглашения */
  CreateDate: Scalars['DateTime'];
  /** Уровень доступа к проекту */
  accessLevel: AccessLevel;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Когда было создано приглашение */
  invitationDate: Scalars['DateTime'];
  /** Пригласивший пользователь */
  inviter?: Maybe<User>;
  /** Количество участников, которые могут участвовать от приглашаемого объединения */
  maxMembers: Scalars['Float'];
  /** Проект */
  project: Project;
  /** Результат приглашения */
  resolution: InviteResolution;
  /** Дата решения по приглашению */
  resolutionDate: Scalars['DateTime'];
  /** Приглашённый пользователь */
  user?: Maybe<User>;
  /** Приглашение для объединения */
  usersUnion?: Maybe<UsersUnion>;
};

export type ProjectRemoveMemberInput = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Статистика проекта */
export type ProjectStats = {
  __typename?: 'ProjectStats';
  doneTasks: Scalars['Float'];
  totalFiles: Scalars['Float'];
  totalTasks: Scalars['Float'];
};

/** Статус проекта */
export enum ProjectStatus {
  Active = 'active',
  Finished = 'finished',
  Frozen = 'frozen',
  New = 'new'
}

/** Передача проекта другому владельцу */
export type ProjectTransferRequest = {
  __typename?: 'ProjectTransferRequest';
  /** Когда был изменен статус запроса */
  changedDate: Scalars['DateTime'];
  /** Когда был создан запрос */
  createDate: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Новый платёжный аккаунт */
  newPaymentAccount: PaymentAccount;
  /** Старый платёжный аккаунт */
  oldPaymentAccount: PaymentAccount;
  /** Проект */
  project: Project;
  /** Кто передаёт проект */
  requester: User;
  /** Статус передачи */
  status: ProjectTransferResolution;
  /** Если проект передаётся пользователю */
  user?: Maybe<User>;
  /** Объединение, в которое передаётся проект */
  usersUnion?: Maybe<UsersUnion>;
};

/** Статус передачи проекта другому владельцу(в другой платёжный аккаунт) */
export enum ProjectTransferResolution {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Received = 'received',
  Rejected = 'rejected'
}

export type ProjectUpdateInput = {
  author?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

/** Всякие метки, добавляемые пользователем к проекту. Избранное, цвет и что угодно, что придумает пылкий мозг пользователя */
export type ProjectUserMarker = {
  __typename?: 'ProjectUserMarker';
  isFavorite: Scalars['Boolean'];
};

export type ProjectsFilterInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Список файлов с ядиска по идентификатору проекта */
  GetFolderItems: Array<ExternalFile>;
  /** Все личные метки пользователя */
  allPersonalTags: Array<Tag>;
  allTags: Array<Tag>;
  /** Список всех доступных тарифов */
  allTariffs: Array<Tariff>;
  calendarItems: Array<CalendarItem>;
  /** Проверка краткого имени объединения на уникальность */
  checkUsersUnionName: Scalars['Boolean'];
  file: File;
  files: Array<File>;
  filesInProject: Array<ExternalFileWithTasks>;
  filesInUsersUnion: Array<ExternalFileWithTasks>;
  findUsers: Array<User>;
  findUsersUnions: Array<UsersUnion>;
  getDownloadLink: Scalars['String'];
  notifications: Array<Notification>;
  /** TODO: Получение ссылки для открытия окна оплаты */
  payments_getPaymentLink: GetPaymentLinkResult;
  project: Project;
  /** Список запросов на передачу проектов текущему пользователю */
  projectTransferRequests: Array<ProjectTransferRequest>;
  projects: Array<Project>;
  projectsInvites: Array<ProjectMemberInvite>;
  task: Task;
  tasks: Array<Task>;
  tasksView: TasksView;
  tasksViews: Array<TasksView>;
  userPaymentAccount: PaymentAccount;
  userProfile?: Maybe<User>;
  usersUnion?: Maybe<UsersUnion>;
  usersUnions: Array<UsersUnion>;
  /** Список приглашений в объединения */
  usersUnionsInvites: Array<UsersUnionMemberInvite>;
  /** Список с ядиска по токену пользователя */
  yandexDiskGetFolderItems: Array<ExternalFile>;
  /** Получение ссылки для генерации токена */
  yandexDiskGetOAuthLink?: Maybe<Scalars['String']>;
};


export type QueryGetFolderItemsArgs = {
  attachedFolderId?: InputMaybe<Scalars['String']>;
  onlyFolders?: Scalars['Boolean'];
  path: Scalars['String'];
};


export type QueryAllTagsArgs = {
  projectId: Scalars['String'];
};


export type QueryCalendarItemsArgs = {
  filter: CalendarFilterInput;
};


export type QueryCheckUsersUnionNameArgs = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type QueryFileArgs = {
  id: Scalars['String'];
};


export type QueryFilesInProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryFilesInUsersUnionArgs = {
  usersUnionId: Scalars['String'];
};


export type QueryFindUsersArgs = {
  search: Scalars['String'];
};


export type QueryFindUsersUnionsArgs = {
  search: Scalars['String'];
};


export type QueryGetDownloadLinkArgs = {
  taskFileId: Scalars['String'];
};


export type QueryPayments_GetPaymentLinkArgs = {
  input: GetPaymentLinkInput;
  paymentAccountId: Scalars['String'];
};


export type QueryProjectArgs = {
  id: Scalars['String'];
};


export type QueryProjectsArgs = {
  filter: ProjectsFilterInput;
};


export type QueryTaskArgs = {
  id: Scalars['String'];
};


export type QueryTasksArgs = {
  iAmResponsible?: InputMaybe<Scalars['Boolean']>;
  includeResolved?: InputMaybe<Scalars['Boolean']>;
  taskViewId: Scalars['String'];
};


export type QueryTasksViewArgs = {
  id: Scalars['String'];
};


export type QueryUserPaymentAccountArgs = {
  paymentAccountId?: InputMaybe<Scalars['String']>;
};


export type QueryUserProfileArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryUsersUnionArgs = {
  id: Scalars['String'];
};


export type QueryYandexDiskGetFolderItemsArgs = {
  attachedFolderId?: InputMaybe<Scalars['String']>;
  onlyFolders?: Scalars['Boolean'];
  path: Scalars['String'];
};

/** Тип продаваемого товара в счете. По сути, надо завести для этого отдельный справочник. Но можно и так оставить пока */
export enum ReceiptItemType {
  /** Оплата дополнительного участника личного проекта сверх тарифа */
  PersonalProjectAdditionalMember = 'personalProjectAdditionalMember',
  /** Оплата тарифа */
  Tariff = 'tariff',
  /** Оплата дополнительного объединения сверх тарифа */
  UsersUnion = 'usersUnion',
  /** Оплата дополнительного участника объединения сверх тарифа */
  UsersUnionMembers = 'usersUnionMembers',
  /** Оплата дополнительного участника проекта объединения сверх тарифа */
  UsersUnionProjectMember = 'usersUnionProjectMember'
}

export type Subscription = {
  __typename?: 'Subscription';
  notificationSubscription: Notification;
};

/** Тег. Редактируются в пределах проекта (а может быть сделать еще и теги в объединении?) */
export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String'];
  id: Scalars['ID'];
  /** Проект */
  project: Project;
  title: Scalars['String'];
  /** Пользователь (только для персональных проектов) */
  user: Project;
  /** Объединение */
  usersUnion: Project;
};

/** Тариф */
export type Tariff = {
  __typename?: 'Tariff';
  /** В архиве, нельзя выбрать */
  archived: Scalars['Boolean'];
  /** Валюта, в которой измеряются все стоимости в тарифе */
  currency: Currency;
  id: Scalars['ID'];
  /** Тариф по-умолчанию */
  isDefault: Scalars['Boolean'];
  /** Стоимость в месяц */
  monthlyCost: Scalars['Float'];
  /** Дополнительное приглашение в персональный проект */
  personalProjectAdditionalMemberAdditionalCost: Scalars['Float'];
  /** Бесплатных приглашений и участников */
  personalProjectMembersFreeCount: Scalars['Float'];
  /** Название тарифа */
  title: Scalars['String'];
  /** Дополнительная тима */
  usersUnionAdditionalCost: Scalars['Float'];
  /** Количество бесплатных тим */
  usersUnionFreeCount: Scalars['Float'];
  /** Дополнительный участник тимы */
  usersUnionMembersAdditionalCost: Scalars['Float'];
  /** Бесплатные участники и приглашений в тимах */
  usersUnionMembersFreeCount: Scalars['Float'];
  /** Дополнительный участник проекта тимы */
  usersUnionProjectMemberAdditionalCost: Scalars['Float'];
  /** Бесплатные участники в проектах тим */
  usersUnionProjectMemberFreeCount: Scalars['Float'];
};

/** Задача */
export type Task = {
  __typename?: 'Task';
  /** Задача в архиве */
  archived: Scalars['Boolean'];
  /** Список вложенных задач */
  childTasks?: Maybe<Array<Maybe<Task>>>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** Время окончания в формате количество минут от 0. Максимум 1440 */
  endTime?: Maybe<Scalars['Float']>;
  /** !!!!!!!!!! */
  files: Array<TaskFile>;
  id: Scalars['ID'];
  /** Важность. от 1 до 5 */
  importance: Scalars['Float'];
  /** История по задаче */
  logs: Array<TaskLog>;
  /** Участники задачи */
  members: Array<TaskMember>;
  number: Scalars['Float'];
  /** Родительская задача */
  parentTask: Task;
  /** Проект */
  project: Project;
  /** Задача завершена */
  resolved: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  /** Время начала в формате количество минут от 0. Максимум 1440 */
  startTime?: Maybe<Scalars['Float']>;
  /** Статус задачи (временное решение) */
  status: TaskStatus;
  /** Прикреплённые теги */
  tags: Array<Scalars['String']>;
  /** Положение задачи в колонках в представлениях */
  tasksViewsPositions: Array<TaskInColumn>;
  title: Scalars['String'];
};

/** TaskFile */
export type TaskFile = {
  __typename?: 'TaskFile';
  /** Когда был прикреплен файл к задаче */
  attachDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  /** Проект */
  project: Project;
  /** Привязка к задаче */
  task: Task;
  /** Привязка к токену, под которым привязан файл к задаче */
  yandexToken: YandexToken;
};

/** Положение задачи внутри колонки */
export type TaskInColumn = {
  __typename?: 'TaskInColumn';
  id: Scalars['ID'];
  position: Scalars['Float'];
  taskId: Scalars['String'];
  tasksViewColumnId: Scalars['String'];
  tasksViewId: Scalars['String'];
};

/** История задачи */
export type TaskLog = {
  __typename?: 'TaskLog';
  id: Scalars['ID'];
  /** Тип записи в истории */
  logType: TaskLogType;
  /** Задача */
  task: Task;
  /** Кто делал то, что указано в логе */
  user: User;
};

/** Тип записи в истории задачи */
export enum TaskLogType {
  Created = 'created',
  StatusChanged = 'statusChanged',
  TaskChanged = 'taskChanged'
}

/** Участник задачи */
export type TaskMember = {
  __typename?: 'TaskMember';
  id: Scalars['ID'];
  /** Роль в задаче */
  memberType: TaskMemberType;
  /** Проект */
  task: Project;
  /** Участник */
  user: User;
};

export type TaskMemberInput = {
  memberType: TaskMemberType;
  userId: Scalars['ID'];
};

/** Роль в задаче */
export enum TaskMemberType {
  Member = 'member',
  Responsible = 'responsible'
}

/** TODO: пока добавляем статус задачи. Но потом это должно перейти в колонки в тасквью */
export enum TaskStatus {
  Done = 'done',
  Todo = 'todo',
  Wip = 'wip'
}

/** Фильтр для списка задач для конкретного пользователя */
export type TaskViewFilterPersonal = {
  __typename?: 'TaskViewFilterPersonal';
  memberId: Scalars['ID'];
};

/** Фильтр для списка задач в проекте */
export type TaskViewFilterProject = {
  __typename?: 'TaskViewFilterProject';
  projectId: Scalars['ID'];
};

/** Для попадания задач на доску */
export type TaskViewFilterValueUnion = TaskViewFilterPersonal | TaskViewFilterProject;

/** Доска */
export type TasksView = {
  __typename?: 'TasksView';
  /** Список колонок для отображения */
  columns: Array<TasksViewColumn>;
  /** Вид фильтрации списка задач */
  filterType: TasksViewFilterType;
  filterValue: TaskViewFilterValueUnion;
  id: Scalars['ID'];
  /** Проект */
  project?: Maybe<Project>;
  title: Scalars['String'];
  /** Вид отображения */
  viewType: Scalars['String'];
};

/** Статус задачи на доске (колонка) */
export type TasksViewColumn = {
  __typename?: 'TasksViewColumn';
  color: Scalars['String'];
  id: Scalars['ID'];
  isResolveColumn: Scalars['Boolean'];
  /** Список задач в колонке */
  tasks: Array<TaskInColumn>;
  /** Доска */
  tasksView: Project;
  title: Scalars['String'];
};

export type TasksViewCreateInput = {
  title: Scalars['String'];
};

/** Тип автоматического отбора задач для этой доски */
export enum TasksViewFilterType {
  Personal = 'personal',
  Project = 'project',
  UserUnion = 'userUnion'
}

export type TasksViewUpdateInput = {
  title?: InputMaybe<Scalars['String']>;
};

/** Персона, человек, пользователь */
export type User = {
  __typename?: 'User';
  /** Полное описание */
  aboutMe?: Maybe<Scalars['String']>;
  /** Ссылка на аватар */
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** Имя, фамилия (отчество) */
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Приглашения в проекты */
  invitesToProjects: Array<ProjectMember>;
  /** Приглашения в объединения */
  invitesToUsersUnions: Array<UsersUnionMember>;
  /** Проекты, добавленные в избранное */
  markedProjects?: Maybe<Array<Maybe<Project>>>;
  /** Доступ к проектам */
  memberOfProjects?: Maybe<Array<Maybe<ProjectMember>>>;
  /** Доступ к доскам */
  memberOfTasksView?: Maybe<Array<Maybe<TasksView>>>;
  /** Участник в объединениях */
  memberOfUnions?: Maybe<Array<Maybe<UsersUnionMember>>>;
  /** Ожидающие оповещения */
  notifications?: Maybe<Array<Maybe<Notification>>>;
  /** Проекты, которыми владеет пользователь */
  ownedProjects?: Maybe<Array<Maybe<Project>>>;
  /** Краткая подпись (отображается во всяких списках) */
  shortInfo?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UserProfileInput = {
  /** Полное описание */
  aboutMe: Scalars['String'];
  /** email */
  email: Scalars['String'];
  /** Имя, фамилия (отчество) */
  fullName: Scalars['String'];
  /** Краткая подпись (отображается во всяких списках) */
  shortInfo: Scalars['String'];
  /** Имя пользователя для авторизации и краткой ссылки на профиль */
  username: Scalars['String'];
};

export type UserRestorePasswordInput = {
  link: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserRestorePasswordResult = {
  __typename?: 'UserRestorePasswordResult';
  token: Scalars['String'];
  user: UserSignInResult;
};

export type UserSignInInput = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type UserSignInResult = {
  __typename?: 'UserSignInResult';
  avatarUrl: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  username: Scalars['String'];
};

export type UserSignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserSignUpResult = {
  __typename?: 'UserSignUpResult';
  token: Scalars['String'];
  user: UserSignInResult;
};

/** Объединение пользователей по какому-то признаку. Это может быть организацией или командой или просто группой людей, который занимаются чем-то общим */
export type UsersUnion = {
  __typename?: 'UsersUnion';
  /** Список прикрепленных папок */
  attachedFolders: Array<AttachedFolder>;
  /** У текущего пользователя есть доступ к объединению */
  currentUserIsMember: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Приглашения в проект */
  invites: Array<UsersUnionMemberInvite>;
  /** Участники объединения */
  members: Array<UsersUnionMember>;
  name?: Maybe<Scalars['String']>;
  /** Проекты, которыми владеет объединение */
  ownedProjects: Array<Project>;
  /** Платёжный аккаунт */
  paymentAccount: PaymentAccount;
  /** Слот оплаты */
  paymentSlot?: Maybe<PaymentSlot>;
  /** Приглашения в проекты */
  projectInvites: Array<ProjectMemberInvite>;
  /** Список тегов в объединении */
  tags: Array<Tag>;
  title: Scalars['String'];
};


/** Объединение пользователей по какому-то признаку. Это может быть организацией или командой или просто группой людей, который занимаются чем-то общим */
export type UsersUnionOwnedProjectsArgs = {
  showArchived?: Scalars['Boolean'];
};


/** Объединение пользователей по какому-то признаку. Это может быть организацией или командой или просто группой людей, который занимаются чем-то общим */
export type UsersUnionProjectInvitesArgs = {
  showArchived?: Scalars['Boolean'];
};

export type UsersUnionAddMemberInput = {
  accessLevel?: InputMaybe<AccessLevel>;
  emails: Array<Scalars['String']>;
  users: Array<Scalars['ID']>;
  usersUnionId: Scalars['ID'];
};

export type UsersUnionCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
};

/** Доступ пользователя к объединению */
export type UsersUnionMember = {
  __typename?: 'UsersUnionMember';
  /** Уровень доступа в объединении */
  accessLevel: AccessLevel;
  id: Scalars['ID'];
  /** Слот оплаты */
  paymentSlot?: Maybe<PaymentSlot>;
  /** Участник */
  user: User;
  /** Объединение */
  usersUnion: UsersUnion;
};

export type UsersUnionMemberChangeLevelInput = {
  level: AccessLevel;
  userId: Scalars['ID'];
  usersUnionId: Scalars['ID'];
};

/** Приглашение в объединение */
export type UsersUnionMemberInvite = {
  __typename?: 'UsersUnionMemberInvite';
  /** Уровень доступа к проекту */
  accessLevel: AccessLevel;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Когда было создано приглашение */
  invitationDate: Scalars['DateTime'];
  /** Приглашённый пользователь */
  invitedUser?: Maybe<User>;
  /** Приглашение объединения в объединение (NOT IMPLEMENTED) */
  invitedUsersUnion?: Maybe<UsersUnion>;
  /** Пригласивший пользователь */
  inviter?: Maybe<User>;
  /** Результат приглашения */
  resolution: InviteResolution;
  /** Объединение */
  usersUnion: UsersUnion;
};

export type UsersUnionUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** YandexToken */
export type YandexToken = {
  __typename?: 'YandexToken';
  /** !!!!!!!!!! */
  files: Array<TaskFile>;
  id: Scalars['ID'];
};

export type AddTaskMutationVariables = Exact<{
  input: AddTaskInput;
}>;


export type AddTaskMutation = { __typename?: 'Mutation', addTask: { __typename?: 'Task', id: string, title: string, description?: string | null, resolved: boolean, status: TaskStatus, tags: Array<string>, startDate?: any | null, endDate?: any | null, startTime?: number | null, endTime?: number | null, number: number, importance: number, members: Array<{ __typename?: 'TaskMember', id: string, memberType: TaskMemberType, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, project: { __typename?: 'Project', id: string, title: string } } };

export type AttachFolderMutationVariables = Exact<{
  path: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  usersUnionId?: InputMaybe<Scalars['String']>;
}>;


export type AttachFolderMutation = { __typename?: 'Mutation', attachFolder: boolean };

export type CalendarItemsQueryVariables = Exact<{
  filter: CalendarFilterInput;
}>;


export type CalendarItemsQuery = {
  __typename?: 'Query',
  calendarItems: Array<{
    __typename?: 'CalendarItem',
    id: string,
    title?: string | null,
    startDate: any,
    endDate: any,
    startTime: number,
    endTime: number
  }>
};

export type FilesInProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type FilesInProjectQuery = { __typename?: 'Query', filesInProject: Array<{ __typename?: 'ExternalFileWithTasks', id: string, path: string, tasks: Array<{ __typename?: 'Task', id: string, title: string, status: TaskStatus }> }> };

export type FilesInUsersUnionQueryVariables = Exact<{
  usersUnionId: Scalars['String'];
}>;


export type FilesInUsersUnionQuery = { __typename?: 'Query', filesInUsersUnion: Array<{ __typename?: 'ExternalFileWithTasks', path: string, tasks: Array<{ __typename?: 'Task', id: string, title: string, status: TaskStatus, project: { __typename?: 'Project', id: string, title: string } }> }> };

export type FindUsersQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type FindUsersQuery = { __typename?: 'Query', findUsers: Array<{ __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }>, findUsersUnions: Array<{ __typename?: 'UsersUnion', id: string, title: string, name?: string | null }> };

export type AttachedFolderFragment = { __typename?: 'AttachedFolder', id: string, name: string, path: string };

export type BaseProjectFragment = { __typename?: 'Project', id: string, isPublic: boolean, title: string, status: ProjectStatus, description?: string | null, attachedTags: Array<string>, archived: boolean, members: Array<{ __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null }>, userAsOwner?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null, accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null, statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number }, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> };

export type BaseUsersUnionFragment = { __typename?: 'UsersUnion', id: string, title: string, members: Array<{ __typename?: 'UsersUnionMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, ownedProjects: Array<{ __typename?: 'Project', id: string, title: string, status: ProjectStatus, members: Array<{ __typename?: 'ProjectMember', user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean } }>, projectInvites: Array<{ __typename?: 'ProjectMemberInvite', project: { __typename?: 'Project', id: string, title: string, description?: string | null, status: ProjectStatus, attachedTags: Array<string>, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, usersUnionAsOwner?: { __typename?: 'UsersUnion', title: string, id: string } | null, userAsOwner?: { __typename?: 'User', username: string, id: string } | null } }>, attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> };

export type ChildTaskFragment = {
  __typename?: 'Task',
  id: string,
  title: string,
  resolved: boolean,
  importance: number
};

export type FullProjectFragment = {
  __typename?: 'Project',
  id: string,
  isPublic: boolean,
  title: string,
  status: ProjectStatus,
  description?: string | null,
  attachedTags: Array<string>,
  archived: boolean,
  invites: Array<{
    __typename?: 'ProjectMemberInvite',
    id: string,
    accessLevel: AccessLevel,
    maxMembers: number,
    email?: string | null,
    user?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
    inviter?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null
  }>,
  tasksView: {
    __typename?: 'TasksView',
    id: string,
    title: string,
    filterType: TasksViewFilterType,
    viewType: string,
    columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
    filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
      __typename: 'TaskViewFilterProject',
      projectId: string
    }
  },
  attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
  transfer?: {
    __typename?: 'ProjectTransferRequest',
    usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
    user?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null
  } | null,
  members: Array<{
    __typename?: 'ProjectMember',
    id: string,
    accessLevel: AccessLevel,
    user: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    },
    asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
  }>,
  userAsOwner?: {
    __typename?: 'User',
    id: string,
    email: string,
    shortInfo?: string | null,
    username: string,
    avatarUrl?: string | null,
    fullName?: string | null
  } | null,
  usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
  accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
  statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
  UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
  tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
};

export type PaymentAccountFragment = { __typename?: 'PaymentAccount', id: string, title: string, isBlocked: boolean };

export type PaymentAccountFullFragment = {
  __typename?: 'PaymentAccount',
  id: string,
  paymentDate: any,
  title: string,
  totalMonthly: number,
  isBlocked: boolean,
  slots: Array<{
    __typename?: 'PaymentSlot',
    id: string,
    isCostFree: boolean,
    isEmpty: boolean,
    itemType: PaymentSlotType,
    status: PaymentSlotStatus
  }>,
  tariff: {
    __typename?: 'Tariff',
    currency: Currency,
    id: string,
    title: string,
    monthlyCost: number,
    personalProjectAdditionalMemberAdditionalCost: number,
    usersUnionAdditionalCost: number,
    usersUnionMembersAdditionalCost: number,
    usersUnionProjectMemberAdditionalCost: number
  },
  managers: Array<{ __typename?: 'User', avatarUrl?: string | null, username: string, id: string }>,
  receipts: Array<{
    __typename?: 'PaymentReceipt',
    amount: number,
    creationDate?: any | null,
    currency: Currency,
    paymentId?: string | null,
    paidDate?: any | null,
    id: string,
    paymentStatus?: PaymentStatusEnum | null
  }>
};

export type ProjectMemberFragment = { __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null };

export type ProjectMemberInviteFragment = {
  __typename?: 'ProjectMemberInvite',
  id: string,
  maxMembers: number,
  usersUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
  user?: {
    __typename?: 'User',
    id: string,
    email: string,
    shortInfo?: string | null,
    username: string,
    avatarUrl?: string | null,
    fullName?: string | null
  } | null,
  project: { __typename?: 'Project', id: string, title: string, description?: string | null },
  inviter?: {
    __typename?: 'User',
    id: string,
    email: string,
    shortInfo?: string | null,
    username: string,
    avatarUrl?: string | null,
    fullName?: string | null
  } | null
};

export type ProjectStatisticsFragment = { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number };

export type TagFragment = { __typename?: 'Tag', id: string, title: string, color: string };

export type TaskFragment = { __typename?: 'Task', id: string, title: string, description?: string | null, resolved: boolean, status: TaskStatus, tags: Array<string>, startDate?: any | null, endDate?: any | null, startTime?: number | null, endTime?: number | null, number: number, importance: number, members: Array<{ __typename?: 'TaskMember', id: string, memberType: TaskMemberType, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, project: { __typename?: 'Project', id: string, title: string } };

export type TaskViewFilterPersonalFragment = { __typename?: 'TaskViewFilterPersonal', memberId: string };

export type TaskViewFilterProjectFragment = { __typename?: 'TaskViewFilterProject', projectId: string };

export type TasksViewFragment = {
  __typename?: 'TasksView',
  id: string,
  title: string,
  filterType: TasksViewFilterType,
  viewType: string,
  columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
  filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
    __typename: 'TaskViewFilterProject',
    projectId: string
  }
};

export type UserFragment = { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null };

export type UsersUnionFragment = { __typename?: 'UsersUnion', id: string, title: string };

export type UsersUnionMemberFragment = { __typename?: 'UsersUnionMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, createDate: string, action?: string | null, receiver?: string | null, transport: NotificationTransport }> };

export type NotificationsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationsSubscriptionSubscription = { __typename?: 'Subscription', notificationSubscription: { __typename?: 'Notification', id: string, createDate: string, action?: string | null, receiver?: string | null, transport: NotificationTransport } };

export type OtherUserProfileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type OtherUserProfileQuery = { __typename?: 'Query', userProfile?: { __typename?: 'User', avatarUrl?: string | null, aboutMe?: string | null, fullName?: string | null, shortInfo?: string | null, username: string } | null };

export type PlugToUnionProjectMutationVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type PlugToUnionProjectMutation = {
  __typename?: 'Mutation', changedProject: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProcessInviteMutationVariables = Exact<{
  inviteId: Scalars['String'];
  resolution: InviteResolution;
  users: Array<Scalars['String']> | Scalars['String'];
}>;


export type ProcessInviteMutation = { __typename?: 'Mutation', processInvite: { __typename?: 'Project', id: string, isPublic: boolean, title: string, status: ProjectStatus, description?: string | null, attachedTags: Array<string>, archived: boolean, members: Array<{ __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null }>, userAsOwner?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null, accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null, statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number }, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> } };

export type ProjectAddMembersMutationVariables = Exact<{
  input: ProjectAddMemberInput;
}>;


export type ProjectAddMembersMutation = {
  __typename?: 'Mutation', changedProject: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProjectAddToFavoriteMutationVariables = Exact<{
  input: ProjectAddToFavoritesInput;
}>;


export type ProjectAddToFavoriteMutation = { __typename?: 'Mutation', projectAddToFavorite: { __typename?: 'Project', id: string, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean } } };

export type ProjectAttachTagsMutationVariables = Exact<{
  projectId: Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type ProjectAttachTagsMutation = { __typename?: 'Mutation', changedProject: { __typename?: 'Project', id: string, isPublic: boolean, title: string, status: ProjectStatus, description?: string | null, attachedTags: Array<string>, archived: boolean, members: Array<{ __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null }>, userAsOwner?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null, accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null, statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number }, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> } };

export type ProjectCardQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProjectCardQuery = {
  __typename?: 'Query', project: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    paymentAccount: { __typename?: 'PaymentAccount', id: string, title: string, isBlocked: boolean },
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProjectChangePriorityAndStatusMutationVariables = Exact<{
  projectId: Scalars['String'];
  usersUnionId: Scalars['String'];
  priority: Scalars['Float'];
  status: Scalars['String'];
}>;


export type ProjectChangePriorityAndStatusMutation = {
  __typename?: 'Mutation',
  projectChangePriorityAndStatus: { __typename?: 'Project', id: string, status: ProjectStatus, priority: number }
};

export type ProjectCreateMutationVariables = Exact<{
  input: ProjectCreateInput;
}>;


export type ProjectCreateMutation = { __typename?: 'Mutation', projectCreate: { __typename?: 'Project', id: string, isPublic: boolean, title: string, status: ProjectStatus, description?: string | null, attachedTags: Array<string>, archived: boolean, members: Array<{ __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null }>, userAsOwner?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null, accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null, statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number }, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> } };

export type ProjectLeaveMutationVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type ProjectLeaveMutation = { __typename?: 'Mutation', projectLeave: boolean };

export type ProjectMemberChangeLevelMutationVariables = Exact<{
  input: ProjectMemberChangeLevelInput;
}>;


export type ProjectMemberChangeLevelMutation = { __typename?: 'Mutation', changedProject: { __typename?: 'Project', id: string, members: Array<{ __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null }> } };

export type ProjectMemberInviteRemoveMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProjectMemberInviteRemoveMutation = {
  __typename?: 'Mutation', changedProject: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProjectRemoveMemberMutationVariables = Exact<{
  input: ProjectRemoveMemberInput;
}>;


export type ProjectRemoveMemberMutation = {
  __typename?: 'Mutation', changedProject: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProjectSwitchArchivedMutationVariables = Exact<{
  projectSwitchArchivedId: Scalars['String'];
}>;


export type ProjectSwitchArchivedMutation = {
  __typename?: 'Mutation', projectSwitchArchived: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProjectTransferMutationVariables = Exact<{
  projectId: Scalars['String'];
  userAsOwnerId?: InputMaybe<Scalars['String']>;
  usersUnionAsOwnerId?: InputMaybe<Scalars['String']>;
}>;


export type ProjectTransferMutation = { __typename?: 'Mutation', projectTransfer: boolean };

export type ProjectTransferAcceptMutationVariables = Exact<{
  transferId: Scalars['String'];
}>;


export type ProjectTransferAcceptMutation = {
  __typename?: 'Mutation', projectTransferAccept: {
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    invites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      accessLevel: AccessLevel,
      maxMembers: number,
      email?: string | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      usersUnion?: { __typename?: 'UsersUnion', description?: string | null, id: string, title: string } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    tasksView: {
      __typename?: 'TasksView',
      id: string,
      title: string,
      filterType: TasksViewFilterType,
      viewType: string,
      columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
      filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
        __typename: 'TaskViewFilterProject',
        projectId: string
      }
    },
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    transfer?: {
      __typename?: 'ProjectTransferRequest',
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string, name?: string | null } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    } | null,
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }
};

export type ProjectTransferRejectMutationVariables = Exact<{
  transferId: Scalars['String'];
}>;


export type ProjectTransferRejectMutation = { __typename?: 'Mutation', projectTransferReject: boolean };

export type ProjectTransferRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectTransferRequestsQuery = { __typename?: 'Query', projectTransferRequests: Array<{ __typename?: 'ProjectTransferRequest', id: string, requester: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, project: { __typename?: 'Project', id: string, title: string }, usersUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null, user?: { __typename?: 'User', id: string, fullName?: string | null, username: string } | null }> };

export type ProjectUpdateMutationVariables = Exact<{
  projectId: Scalars['String'];
  input: ProjectUpdateInput;
}>;


export type ProjectUpdateMutation = { __typename?: 'Mutation', changedProject: { __typename?: 'Project', id: string, isPublic: boolean, title: string, status: ProjectStatus, description?: string | null, attachedTags: Array<string>, archived: boolean, members: Array<{ __typename?: 'ProjectMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null }, asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null }>, userAsOwner?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null, accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null, statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number }, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> } };

export type ProjectsListQueryVariables = Exact<{
  filter?: ProjectsFilterInput;
}>;


export type ProjectsListQuery = {
  __typename?: 'Query', projects: Array<{
    __typename?: 'Project',
    id: string,
    isPublic: boolean,
    title: string,
    status: ProjectStatus,
    description?: string | null,
    attachedTags: Array<string>,
    archived: boolean,
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    tasksView: { __typename?: 'TasksView', id: string },
    members: Array<{
      __typename?: 'ProjectMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      },
      asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
    }>,
    userAsOwner?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, name?: string | null, title: string } | null,
    accessAsUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
    UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
  }>
};

export type ProjectsInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsInvitesQuery = {
  __typename?: 'Query',
  projectsInvites: Array<{
    __typename?: 'ProjectMemberInvite',
    id: string,
    maxMembers: number,
    usersUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
    user?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null,
    project: { __typename?: 'Project', id: string, title: string, description?: string | null },
    inviter?: {
      __typename?: 'User',
      id: string,
      email: string,
      shortInfo?: string | null,
      username: string,
      avatarUrl?: string | null,
      fullName?: string | null
    } | null
  }>
};

export type SignInMutationVariables = Exact<{
  data: UserSignInInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', userSignIn: string };

export type SignUpMutationVariables = Exact<{
  data: UserSignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', userSignUp: { __typename?: 'UserSignUpResult', token: string, user: { __typename?: 'UserSignInResult', id: string, email: string, username: string } } };

export type AllTariffsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTariffsQuery = { __typename?: 'Query', allTariffs: Array<{ __typename?: 'Tariff', id: string, title: string, archived: boolean, isDefault: boolean, currency: Currency, monthlyCost: number, personalProjectMembersFreeCount: number, personalProjectAdditionalMemberAdditionalCost: number, usersUnionFreeCount: number, usersUnionAdditionalCost: number, usersUnionMembersFreeCount: number, usersUnionMembersAdditionalCost: number, usersUnionProjectMemberFreeCount: number, usersUnionProjectMemberAdditionalCost: number }> };

export type UserPaymentAccountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type UserPaymentAccountQuery = {
  __typename?: 'Query',
  paymentAccount: {
    __typename?: 'PaymentAccount',
    id: string,
    paymentDate: any,
    title: string,
    totalMonthly: number,
    isBlocked: boolean,
    slots: Array<{
      __typename?: 'PaymentSlot',
      id: string,
      isCostFree: boolean,
      isEmpty: boolean,
      itemType: PaymentSlotType,
      status: PaymentSlotStatus
    }>,
    tariff: {
      __typename?: 'Tariff',
      currency: Currency,
      id: string,
      title: string,
      monthlyCost: number,
      personalProjectAdditionalMemberAdditionalCost: number,
      usersUnionAdditionalCost: number,
      usersUnionMembersAdditionalCost: number,
      usersUnionProjectMemberAdditionalCost: number
    },
    managers: Array<{ __typename?: 'User', avatarUrl?: string | null, username: string, id: string }>,
    receipts: Array<{
      __typename?: 'PaymentReceipt',
      amount: number,
      creationDate?: any | null,
      currency: Currency,
      paymentId?: string | null,
      paidDate?: any | null,
      id: string,
      paymentStatus?: PaymentStatusEnum | null
    }>
  }
};

export type Payments_AddSlotsMutationVariables = Exact<{
  input: AddSlotsInput;
  paymentAccountId: Scalars['String'];
}>;


export type Payments_AddSlotsMutation = { __typename?: 'Mutation', payments_addSlots: { __typename?: 'PaymentReceipt', id: string, paymentId?: string | null, currency: Currency, amount: number, amountByPrice: number, items: Array<{ __typename?: 'PaymentReceiptItem', amount: number, currency: Currency, discount: number, itemType: ReceiptItemType }> } };

export type Payments_ChangeTariffMutationVariables = Exact<{
  tariffId: Scalars['String'];
  paymentAccountId: Scalars['String'];
}>;


export type Payments_ChangeTariffMutation = { __typename?: 'Mutation', payments_changeTariff: { __typename?: 'ChangeTariffResult', paymentAccount: { __typename?: 'PaymentAccount', id: string, tariff: { __typename?: 'Tariff', title: string, currency: Currency, monthlyCost: number } }, paymentReceipt?: { __typename?: 'PaymentReceipt', amount: number, currency: Currency, id: string, items: Array<{ __typename?: 'PaymentReceiptItem', amount: number, currency: Currency, discount: number, itemType: ReceiptItemType, price: number, paymentSlot?: { __typename?: 'PaymentSlot', itemType: PaymentSlotType, status: PaymentSlotStatus } | null }> } | null } };

export type Payments_GetPaymentLinkQueryVariables = Exact<{
  input: GetPaymentLinkInput;
  paymentAccountId: Scalars['String'];
}>;


export type Payments_GetPaymentLinkQuery = { __typename?: 'Query', payments_getPaymentLink: { __typename?: 'GetPaymentLinkResult', confirmationToken: string } };

export type Payments_RemoveSlotMutationVariables = Exact<{
  slotId: Scalars['String'];
  paymentAccountId: Scalars['String'];
}>;


export type Payments_RemoveSlotMutation = {
  __typename?: 'Mutation',
  payments_removeSlot: { __typename?: 'PaymentAccount', id: string, title: string, isBlocked: boolean }
};

export type ProcessReceiptMutationVariables = Exact<{
  receiptId: Scalars['String'];
  paymentAccountId: Scalars['String'];
}>;


export type ProcessReceiptMutation = {
  __typename?: 'Mutation',
  ProcessReceipt: { __typename?: 'PaymentAccount', id: string, title: string, isBlocked: boolean }
};

export type AllPersonalTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllPersonalTagsQuery = { __typename?: 'Query', allPersonalTags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> };

export type EditTagMutationVariables = Exact<{
  id: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  usersUnionId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  color: Scalars['String'];
}>;


export type EditTagMutation = { __typename?: 'Mutation', editTag: { __typename?: 'Tag', id: string, title: string, color: string } };

export type GetDownloadLinkQueryVariables = Exact<{
  taskFileId: Scalars['String'];
}>;


export type GetDownloadLinkQuery = { __typename?: 'Query', link: string };

export type RemoveTagMutationVariables = Exact<{
  removeTagId: Scalars['String'];
}>;


export type RemoveTagMutation = { __typename?: 'Mutation', removeTag: boolean };

export type TaskQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TaskQuery = {
  __typename?: 'Query', task: {
    __typename?: 'Task',
    id: string,
    title: string,
    description?: string | null,
    resolved: boolean,
    status: TaskStatus,
    tags: Array<string>,
    startDate?: any | null,
    endDate?: any | null,
    startTime?: number | null,
    endTime?: number | null,
    number: number,
    importance: number,
    project: {
      __typename?: 'Project',
      id: string,
      title: string,
      archived: boolean,
      paymentAccount: { __typename?: 'PaymentAccount', id: string, title: string, isBlocked: boolean },
      usersUnionAsOwner?: { __typename?: 'UsersUnion', id: string, title: string } | null,
      members: Array<{
        __typename?: 'ProjectMember',
        id: string,
        accessLevel: AccessLevel,
        user: {
          __typename?: 'User',
          id: string,
          email: string,
          shortInfo?: string | null,
          username: string,
          avatarUrl?: string | null,
          fullName?: string | null
        },
        asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
      }>,
      attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>
    },
    childTasks?: Array<{
      __typename?: 'Task',
      id: string,
      title: string,
      resolved: boolean,
      importance: number
    } | null> | null,
    files: Array<{ __typename?: 'TaskFile', id: string, name: string, path: string, attachDate: any }>,
    members: Array<{
      __typename?: 'TaskMember',
      id: string,
      memberType: TaskMemberType,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      }
    }>
  }
};

export type TaskChangeColumnInViewMutationVariables = Exact<{
  taskId: Scalars['String'];
  taskViewId: Scalars['String'];
  columnId: Scalars['String'];
  overTaskId?: InputMaybe<Scalars['String']>;
}>;


export type TaskChangeColumnInViewMutation = {
  __typename?: 'Mutation',
  taskChangeColumnInView: {
    __typename?: 'Task',
    id: string,
    status: TaskStatus,
    tasksViewsPositions: Array<{ __typename?: 'TaskInColumn', tasksViewColumnId: string, position: number }>
  }
};

export type TaskChangeImportanceMutationVariables = Exact<{
  id: Scalars['String'];
  importance: Scalars['Float'];
}>;


export type TaskChangeImportanceMutation = { __typename?: 'Mutation', taskChangeImportance: { __typename?: 'Task', id: string, importance: number } };

export type TaskChangeTitleMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
}>;


export type TaskChangeTitleMutation = {
  __typename?: 'Mutation',
  taskChangeTitle: {
    __typename?: 'Task',
    id: string,
    title: string,
    description?: string | null,
    resolved: boolean,
    status: TaskStatus,
    tags: Array<string>,
    startDate?: any | null,
    endDate?: any | null,
    startTime?: number | null,
    endTime?: number | null,
    number: number,
    importance: number,
    members: Array<{
      __typename?: 'TaskMember',
      id: string,
      memberType: TaskMemberType,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      }
    }>,
    project: { __typename?: 'Project', id: string, title: string }
  }
};

export type TaskRemoveMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type TaskRemoveMutation = { __typename?: 'Mutation', taskRemove: boolean };

export type TaskResolvedMutationVariables = Exact<{
  id: Scalars['String'];
  resolved: Scalars['Boolean'];
}>;


export type TaskResolvedMutation = { __typename?: 'Mutation', taskResolved: { __typename?: 'Task', id: string, resolved: boolean } };

export type TaskSaveMutationVariables = Exact<{
  id: Scalars['String'];
  task: EditTaskInput;
}>;


export type TaskSaveMutation = { __typename?: 'Mutation', taskSave: { __typename?: 'Task', id: string, title: string, description?: string | null, resolved: boolean, status: TaskStatus, tags: Array<string>, startDate?: any | null, endDate?: any | null, startTime?: number | null, endTime?: number | null, number: number, importance: number, members: Array<{ __typename?: 'TaskMember', id: string, memberType: TaskMemberType, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, project: { __typename?: 'Project', id: string, title: string } } };

export type TasksByViewIdQueryVariables = Exact<{
  tasksViewId: Scalars['String'];
  includeResolved: Scalars['Boolean'];
  iAmResponsible: Scalars['Boolean'];
}>;


export type TasksByViewIdQuery = {
  __typename?: 'Query',
  tasks: Array<{
    __typename?: 'Task',
    id: string,
    title: string,
    status: TaskStatus,
    description?: string | null,
    resolved: boolean,
    tags: Array<string>,
    number: number,
    startTime?: number | null,
    endTime?: number | null,
    startDate?: any | null,
    endDate?: any | null,
    importance: number,
    files: Array<{ __typename?: 'TaskFile', name: string, id: string }>,
    members: Array<{
      __typename?: 'TaskMember',
      memberType: TaskMemberType,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      }
    }>,
    project: {
      __typename?: 'Project',
      id: string,
      title: string,
      usersUnionAsOwner?: { __typename?: 'UsersUnion', title: string } | null
    },
    tasksViewsPositions: Array<{
      __typename?: 'TaskInColumn',
      tasksViewId: string,
      tasksViewColumnId: string,
      position: number
    }>,
    childTasks?: Array<{
      __typename?: 'Task',
      id: string,
      title: string,
      resolved: boolean,
      importance: number
    } | null> | null
  }>
};

export type TasksViewsQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksViewsQuery = {
  __typename?: 'Query',
  tasksViews: Array<{
    __typename?: 'TasksView',
    id: string,
    title: string,
    filterType: TasksViewFilterType,
    viewType: string,
    project?: { __typename?: 'Project', paymentAccount: { __typename?: 'PaymentAccount', isBlocked: boolean } } | null,
    columns: Array<{
      __typename?: 'TasksViewColumn',
      title: string,
      id: string,
      color: string,
      tasks: Array<{ __typename?: 'TaskInColumn', position: number, taskId: string }>
    }>,
    filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
      __typename: 'TaskViewFilterProject',
      projectId: string
    }
  }>
};

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', userProfile?: { __typename?: 'User', id: string, avatarUrl?: string | null, username: string, email: string, aboutMe?: string | null, fullName?: string | null, shortInfo?: string | null } | null };

export type UserProfileUpdateMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type UserProfileUpdateMutation = { __typename?: 'Mutation', userProfileUpdate?: { __typename?: 'User', id: string, aboutMe?: string | null, avatarUrl?: string | null, email: string, fullName?: string | null, shortInfo?: string | null, username: string } | null };

export type UserRestorePasswordMutationVariables = Exact<{
  data: UserRestorePasswordInput;
}>;


export type UserRestorePasswordMutation = { __typename?: 'Mutation', userRestorePassword: { __typename?: 'UserRestorePasswordResult', token: string, user: { __typename?: 'UserSignInResult', id: string } } };

export type UserRestorePasswordSendLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserRestorePasswordSendLinkMutation = { __typename?: 'Mutation', userRestorePasswordSendLink: boolean };

export type UsersUnionsListQueryVariables = Exact<{
  showArchived?: Scalars['Boolean'];
}>;


export type UsersUnionsListQuery = { __typename?: 'Query', usersUnions: Array<{ __typename?: 'UsersUnion', id: string, title: string, members: Array<{ __typename?: 'UsersUnionMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, ownedProjects: Array<{ __typename?: 'Project', id: string, title: string, status: ProjectStatus, members: Array<{ __typename?: 'ProjectMember', user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean } }>, projectInvites: Array<{ __typename?: 'ProjectMemberInvite', project: { __typename?: 'Project', id: string, title: string, description?: string | null, status: ProjectStatus, attachedTags: Array<string>, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, usersUnionAsOwner?: { __typename?: 'UsersUnion', title: string, id: string } | null, userAsOwner?: { __typename?: 'User', username: string, id: string } | null } }>, attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> }>, usersUnionsInvites: Array<{ __typename?: 'UsersUnionMemberInvite', id: string, invitedUser?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, inviter?: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } | null, usersUnion: { __typename?: 'UsersUnion', id: string, title: string } }> };

export type CheckUsersUnionNameQueryVariables = Exact<{
  name: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
}>;


export type CheckUsersUnionNameQuery = { __typename?: 'Query', result: boolean };

export type UsersUnionUpdateMutationVariables = Exact<{
  id: Scalars['String'];
  input: UsersUnionUpdateInput;
}>;


export type UsersUnionUpdateMutation = { __typename?: 'Mutation', usersUnionUpdate: { __typename?: 'UsersUnion', id: string, title: string, description?: string | null, name?: string | null } };

export type UsersUnionCreateMutationVariables = Exact<{
  input: UsersUnionCreateInput;
  showArchived?: Scalars['Boolean'];
}>;


export type UsersUnionCreateMutation = { __typename?: 'Mutation', usersUnionCreate: { __typename?: 'UsersUnion', id: string, title: string, members: Array<{ __typename?: 'UsersUnionMember', id: string, accessLevel: AccessLevel, user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, ownedProjects: Array<{ __typename?: 'Project', id: string, title: string, status: ProjectStatus, members: Array<{ __typename?: 'ProjectMember', user: { __typename?: 'User', id: string, email: string, shortInfo?: string | null, username: string, avatarUrl?: string | null, fullName?: string | null } }>, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean } }>, projectInvites: Array<{ __typename?: 'ProjectMemberInvite', project: { __typename?: 'Project', id: string, title: string, description?: string | null, status: ProjectStatus, attachedTags: Array<string>, UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean }, usersUnionAsOwner?: { __typename?: 'UsersUnion', title: string, id: string } | null, userAsOwner?: { __typename?: 'User', username: string, id: string } | null } }>, attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>, tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }> } };

export type UsersUnionQueryVariables = Exact<{
  id: Scalars['String'];
  showArchived: Scalars['Boolean'];
  slotType: PaymentSlotType;
}>;


export type UsersUnionQuery = {
  __typename?: 'Query', usersUnion?: {
    __typename?: 'UsersUnion',
    id: string,
    title: string,
    description?: string | null,
    name?: string | null,
    currentUserIsMember: boolean,
    members: Array<{
      __typename?: 'UsersUnionMember',
      id: string,
      accessLevel: AccessLevel,
      user: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      }
    }>,
    tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>,
    ownedProjects: Array<{
      __typename?: 'Project',
      id: string,
      title: string,
      status: ProjectStatus,
      priority: number,
      attachedTags: Array<string>,
      members: Array<{
        __typename?: 'ProjectMember',
        id: string,
        accessLevel: AccessLevel,
        user: {
          __typename?: 'User',
          id: string,
          email: string,
          shortInfo?: string | null,
          username: string,
          avatarUrl?: string | null,
          fullName?: string | null
        },
        asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
      }>,
      tasksView: {
        __typename?: 'TasksView',
        id: string,
        title: string,
        filterType: TasksViewFilterType,
        viewType: string,
        columns: Array<{ __typename?: 'TasksViewColumn', id: string, title: string, color: string }>,
        filterValue: { __typename: 'TaskViewFilterPersonal', memberId: string } | {
          __typename: 'TaskViewFilterProject',
          projectId: string
        }
      },
      statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
      UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
      tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
    }>,
    invites: Array<{
      __typename?: 'UsersUnionMemberInvite',
      id: string,
      email?: string | null,
      invitedUser?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    projectInvites: Array<{
      __typename?: 'ProjectMemberInvite',
      id: string,
      maxMembers: number,
      project: {
        __typename?: 'Project',
        priority: number,
        status: ProjectStatus,
        attachedTags: Array<string>,
        id: string,
        title: string,
        description?: string | null,
        usersUnionAsOwner?: { __typename?: 'UsersUnion', title: string, id: string } | null,
        userAsOwner?: { __typename?: 'User', username: string, id: string } | null,
        members: Array<{
          __typename?: 'ProjectMember',
          id: string,
          accessLevel: AccessLevel,
          user: {
            __typename?: 'User',
            id: string,
            email: string,
            shortInfo?: string | null,
            username: string,
            avatarUrl?: string | null,
            fullName?: string | null
          },
          asUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null
        }>,
        tasksView: { __typename?: 'TasksView', id: string },
        statistics: { __typename?: 'ProjectStats', totalTasks: number, totalFiles: number, doneTasks: number },
        UserMarkers: { __typename?: 'ProjectUserMarker', isFavorite: boolean },
        tags: Array<{ __typename?: 'Tag', id: string, title: string, color: string }>
      },
      usersUnion?: { __typename?: 'UsersUnion', id: string, title: string } | null,
      user?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null,
      inviter?: {
        __typename?: 'User',
        id: string,
        email: string,
        shortInfo?: string | null,
        username: string,
        avatarUrl?: string | null,
        fullName?: string | null
      } | null
    }>,
    attachedFolders: Array<{ __typename?: 'AttachedFolder', id: string, name: string, path: string }>,
    paymentAccount: {
      __typename?: 'PaymentAccount',
      emptySlotsCount: number,
      id: string,
      title: string,
      isBlocked: boolean
    }
  } | null
};

export type UsersUnionAddMembersMutationVariables = Exact<{
  input: UsersUnionAddMemberInput;
}>;


export type UsersUnionAddMembersMutation = { __typename?: 'Mutation', usersUnionAddMembers: { __typename?: 'UsersUnion', id: string, title: string } };

export type UsersUnionDeleteMutationVariables = Exact<{
  usersUnionId: Scalars['String'];
}>;


export type UsersUnionDeleteMutation = { __typename?: 'Mutation', usersUnionDelete: boolean };

export type UsersUnionLeaveMutationVariables = Exact<{
  usersUnionId: Scalars['String'];
}>;


export type UsersUnionLeaveMutation = { __typename?: 'Mutation', usersUnionLeave: boolean };

export type UsersUnionMemberChangeLevelMutationVariables = Exact<{
  input: UsersUnionMemberChangeLevelInput;
}>;


export type UsersUnionMemberChangeLevelMutation = { __typename?: 'Mutation', usersUnion: { __typename?: 'UsersUnion', id: string } };

export type UsersUnionMemberInviteRemoveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UsersUnionMemberInviteRemoveMutation = { __typename?: 'Mutation', usersUnionMemberInviteRemove: { __typename?: 'UsersUnion', id: string, title: string } };

export type UsersUnionProcessInviteMutationVariables = Exact<{
  inviteId: Scalars['String'];
  resolution: InviteResolution;
}>;


export type UsersUnionProcessInviteMutation = { __typename?: 'Mutation', usersUnionProcessInvite: { __typename?: 'UsersUnion', id: string } };

export type UsersUnionMemberRemoveMutationVariables = Exact<{
  usersUnionId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type UsersUnionMemberRemoveMutation = { __typename?: 'Mutation', usersUnion: { __typename?: 'UsersUnion', id: string, title: string } };

export type YandexDiskGetFolderItemsQueryVariables = Exact<{
  path: Scalars['String'];
  attachedFolderId?: InputMaybe<Scalars['String']>;
  onlyFolders: Scalars['Boolean'];
}>;


export type YandexDiskGetFolderItemsQuery = { __typename?: 'Query', yandexDiskGetFolderItems: Array<{ __typename?: 'ExternalFile', name: string, created: any, modified: any, path: string, publicUrl?: string | null, type: ExternalFileType }> };

export type YandexDiskGetOAuthLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type YandexDiskGetOAuthLinkQuery = { __typename?: 'Query', yandexDiskGetOAuthLink?: string | null };

export type YandexDiskSaveOAuthTokenMutationVariables = Exact<{
  token: Scalars['String'];
  expiresIn: Scalars['Float'];
}>;


export type YandexDiskSaveOAuthTokenMutation = { __typename?: 'Mutation', yandexDiskSaveOAuthToken: string };

export const UserFragmentDoc = gql`
    fragment user on User {
  id
  email
  shortInfo
  username
  avatarUrl
  fullName
}
    `;
export const UsersUnionMemberFragmentDoc = gql`
    fragment usersUnionMember on UsersUnionMember {
  id
  accessLevel
  user {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const AttachedFolderFragmentDoc = gql`
    fragment attachedFolder on AttachedFolder {
  id
  name
  path
}
    `;
export const TagFragmentDoc = gql`
    fragment tag on Tag {
  id
  title
  color
}
    `;
export const BaseUsersUnionFragmentDoc = gql`
    fragment baseUsersUnion on UsersUnion {
  id
  title
  members {
    ...usersUnionMember
  }
  ownedProjects(showArchived: $showArchived) {
    id
    title
    status
    members {
      user {
        ...user
      }
    }
    UserMarkers {
      isFavorite
    }
  }
  projectInvites(showArchived: $showArchived) {
    project {
      id
      title
      description
      status
      attachedTags
      UserMarkers {
        isFavorite
      }
      usersUnionAsOwner {
        title
        id
      }
      userAsOwner {
        username
        id
      }
    }
  }
  attachedFolders {
    ...attachedFolder
  }
  tags {
    ...tag
  }
}
    ${UsersUnionMemberFragmentDoc}
${UserFragmentDoc}
${AttachedFolderFragmentDoc}
${TagFragmentDoc}`;
export const ChildTaskFragmentDoc = gql`
    fragment childTask on Task {
  id
  title
  resolved
      importance
}
    `;
export const ProjectMemberFragmentDoc = gql`
    fragment projectMember on ProjectMember {
  id
  accessLevel
  user {
    ...user
  }
  asUnion {
    id
    title
  }
}
    ${UserFragmentDoc}`;
export const ProjectStatisticsFragmentDoc = gql`
    fragment projectStatistics on ProjectStats {
  totalTasks
  totalFiles
  doneTasks
}
    `;
export const BaseProjectFragmentDoc = gql`
    fragment baseProject on Project {
  id
  isPublic
  title
  status
  description
  attachedTags
  archived
  members {
    ...projectMember
  }
  userAsOwner {
    ...user
  }
  usersUnionAsOwner {
    id
    name
    title
  }
  accessAsUnion {
    id
    title
  }
  statistics {
    ...projectStatistics
  }
  UserMarkers {
    isFavorite
  }
  tags {
    ...tag
  }
}
    ${ProjectMemberFragmentDoc}
${UserFragmentDoc}
${ProjectStatisticsFragmentDoc}
${TagFragmentDoc}`;
export const UsersUnionFragmentDoc = gql`
    fragment usersUnion on UsersUnion {
  id
  title
}
    `;
export const TaskViewFilterPersonalFragmentDoc = gql`
  fragment taskViewFilterPersonal on TaskViewFilterPersonal {
    memberId
  }
`;
export const TaskViewFilterProjectFragmentDoc = gql`
  fragment taskViewFilterProject on TaskViewFilterProject {
    projectId
  }
`;
export const TasksViewFragmentDoc = gql`
  fragment tasksView on TasksView {
    id
    title
    columns {
      id
      title
      color
    }
    filterType
    filterValue {
      __typename
      ...taskViewFilterPersonal
      ...taskViewFilterProject
    }
    viewType
  }
  ${TaskViewFilterPersonalFragmentDoc}
${TaskViewFilterProjectFragmentDoc}`;
export const FullProjectFragmentDoc = gql`
    fragment fullProject on Project {
  ...baseProject
  invites {
    id
    accessLevel
    maxMembers
    user {
      ...user
    }
    usersUnion {
      ...usersUnion
      description
    }
    email
    inviter {
      ...user
    }
  }
  tasksView {
    ...tasksView
  }
  attachedFolders {
    ...attachedFolder
  }
  transfer {
    usersUnion {
      id
      title
      name
    }
    user {
      ...user
    }
  }
}
    ${BaseProjectFragmentDoc}
${UserFragmentDoc}
${UsersUnionFragmentDoc}
${TasksViewFragmentDoc}
${AttachedFolderFragmentDoc}`;
export const PaymentAccountFragmentDoc = gql`
  fragment paymentAccount on PaymentAccount {
    id
    title
    isBlocked
  }
`;
export const PaymentAccountFullFragmentDoc = gql`
  fragment paymentAccountFull on PaymentAccount {
    id
    paymentDate
    title
    totalMonthly
    slots {
      id
      isCostFree
      isEmpty
      itemType
      itemType
      status
    }
    tariff {
      currency
      id
      title
      monthlyCost
      personalProjectAdditionalMemberAdditionalCost
      usersUnionAdditionalCost
      usersUnionMembersAdditionalCost
      usersUnionProjectMemberAdditionalCost
    }
    managers {
      avatarUrl
      username
      id
    }
    isBlocked
    receipts {
      amount
      creationDate
      currency
      paymentId
      paidDate
      id
      paymentStatus
    }
  }
`;
export const ProjectMemberInviteFragmentDoc = gql`
    fragment projectMemberInvite on ProjectMemberInvite {
  id
  usersUnion {
    ...usersUnion
  }
  user {
    ...user
  }
  project {
    id
    title
    description
  }
  inviter {
    ...user
  }
  maxMembers
}
    ${UsersUnionFragmentDoc}
${UserFragmentDoc}`;
export const TaskFragmentDoc = gql`
    fragment task on Task {
  id
  title
  description
  resolved
  members {
    id
    memberType
    user {
      ...user
    }
  }
  project {
    id
    title
  }
  status
  tags
  startDate
  endDate
  startTime
  endTime
  number
  importance
  resolved
}
    ${UserFragmentDoc}`;
export const AddTaskDocument = gql`
    mutation addTask($input: AddTaskInput!) {
  addTask(input: $input) {
    ...task
  }
}
    ${TaskFragmentDoc}`;
export type AddTaskMutationFn = Apollo.MutationFunction<AddTaskMutation, AddTaskMutationVariables>;

/**
 * __useAddTaskMutation__
 *
 * To run a mutation, you first call `useAddTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMutation, { data, loading, error }] = useAddTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaskMutation(baseOptions?: Apollo.MutationHookOptions<AddTaskMutation, AddTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTaskMutation, AddTaskMutationVariables>(AddTaskDocument, options);
      }
export type AddTaskMutationHookResult = ReturnType<typeof useAddTaskMutation>;
export type AddTaskMutationResult = Apollo.MutationResult<AddTaskMutation>;
export type AddTaskMutationOptions = Apollo.BaseMutationOptions<AddTaskMutation, AddTaskMutationVariables>;
export const AttachFolderDocument = gql`
    mutation attachFolder($path: String!, $projectId: String, $usersUnionId: String) {
  attachFolder(path: $path, projectId: $projectId, usersUnionId: $usersUnionId)
}
    `;
export type AttachFolderMutationFn = Apollo.MutationFunction<AttachFolderMutation, AttachFolderMutationVariables>;

/**
 * __useAttachFolderMutation__
 *
 * To run a mutation, you first call `useAttachFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFolderMutation, { data, loading, error }] = useAttachFolderMutation({
 *   variables: {
 *      path: // value for 'path'
 *      projectId: // value for 'projectId'
 *      usersUnionId: // value for 'usersUnionId'
 *   },
 * });
 */
export function useAttachFolderMutation(baseOptions?: Apollo.MutationHookOptions<AttachFolderMutation, AttachFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachFolderMutation, AttachFolderMutationVariables>(AttachFolderDocument, options);
      }
export type AttachFolderMutationHookResult = ReturnType<typeof useAttachFolderMutation>;
export type AttachFolderMutationResult = Apollo.MutationResult<AttachFolderMutation>;
export type AttachFolderMutationOptions = Apollo.BaseMutationOptions<AttachFolderMutation, AttachFolderMutationVariables>;
export const CalendarItemsDocument = gql`
  query calendarItems($filter: CalendarFilterInput!) {
    calendarItems(filter: $filter) {
      id
      title
      startDate
      endDate
      startTime
      endTime
    }
  }
`;

/**
 * __useCalendarItemsQuery__
 *
 * To run a query within a React component, call `useCalendarItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarItemsQuery(baseOptions: Apollo.QueryHookOptions<CalendarItemsQuery, CalendarItemsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<CalendarItemsQuery, CalendarItemsQueryVariables>(CalendarItemsDocument, options);
}
export function useCalendarItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarItemsQuery, CalendarItemsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<CalendarItemsQuery, CalendarItemsQueryVariables>(CalendarItemsDocument, options);
}
export type CalendarItemsQueryHookResult = ReturnType<typeof useCalendarItemsQuery>;
export type CalendarItemsLazyQueryHookResult = ReturnType<typeof useCalendarItemsLazyQuery>;
export type CalendarItemsQueryResult = Apollo.QueryResult<CalendarItemsQuery, CalendarItemsQueryVariables>;
export function refetchCalendarItemsQuery(variables: CalendarItemsQueryVariables) {
  return {query: CalendarItemsDocument, variables: variables}
}
export const FilesInProjectDocument = gql`
    query filesInProject($projectId: String!) {
  filesInProject(projectId: $projectId) {
    id
    path
    tasks {
      id
      title
      status
    }
  }
}
    `;

/**
 * __useFilesInProjectQuery__
 *
 * To run a query within a React component, call `useFilesInProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesInProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesInProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFilesInProjectQuery(baseOptions: Apollo.QueryHookOptions<FilesInProjectQuery, FilesInProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesInProjectQuery, FilesInProjectQueryVariables>(FilesInProjectDocument, options);
      }
export function useFilesInProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesInProjectQuery, FilesInProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesInProjectQuery, FilesInProjectQueryVariables>(FilesInProjectDocument, options);
        }
export type FilesInProjectQueryHookResult = ReturnType<typeof useFilesInProjectQuery>;
export type FilesInProjectLazyQueryHookResult = ReturnType<typeof useFilesInProjectLazyQuery>;
export type FilesInProjectQueryResult = Apollo.QueryResult<FilesInProjectQuery, FilesInProjectQueryVariables>;
export function refetchFilesInProjectQuery(variables: FilesInProjectQueryVariables) {
      return { query: FilesInProjectDocument, variables: variables }
    }
export const FilesInUsersUnionDocument = gql`
    query filesInUsersUnion($usersUnionId: String!) {
  filesInUsersUnion(usersUnionId: $usersUnionId) {
    path
    tasks {
      id
      title
      status
      project {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useFilesInUsersUnionQuery__
 *
 * To run a query within a React component, call `useFilesInUsersUnionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesInUsersUnionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesInUsersUnionQuery({
 *   variables: {
 *      usersUnionId: // value for 'usersUnionId'
 *   },
 * });
 */
export function useFilesInUsersUnionQuery(baseOptions: Apollo.QueryHookOptions<FilesInUsersUnionQuery, FilesInUsersUnionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesInUsersUnionQuery, FilesInUsersUnionQueryVariables>(FilesInUsersUnionDocument, options);
      }
export function useFilesInUsersUnionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesInUsersUnionQuery, FilesInUsersUnionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesInUsersUnionQuery, FilesInUsersUnionQueryVariables>(FilesInUsersUnionDocument, options);
        }
export type FilesInUsersUnionQueryHookResult = ReturnType<typeof useFilesInUsersUnionQuery>;
export type FilesInUsersUnionLazyQueryHookResult = ReturnType<typeof useFilesInUsersUnionLazyQuery>;
export type FilesInUsersUnionQueryResult = Apollo.QueryResult<FilesInUsersUnionQuery, FilesInUsersUnionQueryVariables>;
export function refetchFilesInUsersUnionQuery(variables: FilesInUsersUnionQueryVariables) {
      return { query: FilesInUsersUnionDocument, variables: variables }
    }
export const FindUsersDocument = gql`
    query findUsers($search: String!) {
  findUsers(search: $search) {
    ...user
  }
  findUsersUnions(search: $search) {
    id
    title
    name
  }
}
    ${UserFragmentDoc}`;

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFindUsersQuery(baseOptions: Apollo.QueryHookOptions<FindUsersQuery, FindUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUsersQuery, FindUsersQueryVariables>(FindUsersDocument, options);
      }
export function useFindUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUsersQuery, FindUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(FindUsersDocument, options);
        }
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<typeof useFindUsersLazyQuery>;
export type FindUsersQueryResult = Apollo.QueryResult<FindUsersQuery, FindUsersQueryVariables>;
export function refetchFindUsersQuery(variables: FindUsersQueryVariables) {
      return { query: FindUsersDocument, variables: variables }
    }
export const NotificationsDocument = gql`
    query notifications {
  notifications {
    id
    createDate
    action
    receiver
    transport
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export function refetchNotificationsQuery(variables?: NotificationsQueryVariables) {
      return { query: NotificationsDocument, variables: variables }
    }
export const NotificationsSubscriptionDocument = gql`
    subscription notificationsSubscription {
  notificationSubscription {
    id
    createDate
    action
    receiver
    transport
  }
}
    `;

/**
 * __useNotificationsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsSubscriptionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationsSubscriptionSubscription, NotificationsSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationsSubscriptionSubscription, NotificationsSubscriptionSubscriptionVariables>(NotificationsSubscriptionDocument, options);
      }
export type NotificationsSubscriptionSubscriptionHookResult = ReturnType<typeof useNotificationsSubscriptionSubscription>;
export type NotificationsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<NotificationsSubscriptionSubscription>;
export const OtherUserProfileDocument = gql`
    query otherUserProfile($id: String) {
  userProfile(id: $id) {
    avatarUrl
    aboutMe
    fullName
    shortInfo
    username
  }
}
    `;

/**
 * __useOtherUserProfileQuery__
 *
 * To run a query within a React component, call `useOtherUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOtherUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<OtherUserProfileQuery, OtherUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherUserProfileQuery, OtherUserProfileQueryVariables>(OtherUserProfileDocument, options);
      }
export function useOtherUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherUserProfileQuery, OtherUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherUserProfileQuery, OtherUserProfileQueryVariables>(OtherUserProfileDocument, options);
        }
export type OtherUserProfileQueryHookResult = ReturnType<typeof useOtherUserProfileQuery>;
export type OtherUserProfileLazyQueryHookResult = ReturnType<typeof useOtherUserProfileLazyQuery>;
export type OtherUserProfileQueryResult = Apollo.QueryResult<OtherUserProfileQuery, OtherUserProfileQueryVariables>;
export function refetchOtherUserProfileQuery(variables?: OtherUserProfileQueryVariables) {
      return { query: OtherUserProfileDocument, variables: variables }
    }
export const PlugToUnionProjectDocument = gql`
    mutation plugToUnionProject($projectId: String!) {
  changedProject: plugToUnionProject(projectId: $projectId) {
    ...fullProject
  }
}
    ${FullProjectFragmentDoc}`;
export type PlugToUnionProjectMutationFn = Apollo.MutationFunction<PlugToUnionProjectMutation, PlugToUnionProjectMutationVariables>;

/**
 * __usePlugToUnionProjectMutation__
 *
 * To run a mutation, you first call `usePlugToUnionProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlugToUnionProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plugToUnionProjectMutation, { data, loading, error }] = usePlugToUnionProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePlugToUnionProjectMutation(baseOptions?: Apollo.MutationHookOptions<PlugToUnionProjectMutation, PlugToUnionProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlugToUnionProjectMutation, PlugToUnionProjectMutationVariables>(PlugToUnionProjectDocument, options);
      }
export type PlugToUnionProjectMutationHookResult = ReturnType<typeof usePlugToUnionProjectMutation>;
export type PlugToUnionProjectMutationResult = Apollo.MutationResult<PlugToUnionProjectMutation>;
export type PlugToUnionProjectMutationOptions = Apollo.BaseMutationOptions<PlugToUnionProjectMutation, PlugToUnionProjectMutationVariables>;
export const ProcessInviteDocument = gql`
    mutation processInvite($inviteId: String!, $resolution: InviteResolution!, $users: [String!]!) {
  processInvite(inviteId: $inviteId, resolution: $resolution, users: $users) {
    ...baseProject
  }
}
    ${BaseProjectFragmentDoc}`;
export type ProcessInviteMutationFn = Apollo.MutationFunction<ProcessInviteMutation, ProcessInviteMutationVariables>;

/**
 * __useProcessInviteMutation__
 *
 * To run a mutation, you first call `useProcessInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processInviteMutation, { data, loading, error }] = useProcessInviteMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *      resolution: // value for 'resolution'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useProcessInviteMutation(baseOptions?: Apollo.MutationHookOptions<ProcessInviteMutation, ProcessInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessInviteMutation, ProcessInviteMutationVariables>(ProcessInviteDocument, options);
      }
export type ProcessInviteMutationHookResult = ReturnType<typeof useProcessInviteMutation>;
export type ProcessInviteMutationResult = Apollo.MutationResult<ProcessInviteMutation>;
export type ProcessInviteMutationOptions = Apollo.BaseMutationOptions<ProcessInviteMutation, ProcessInviteMutationVariables>;
export const ProjectAddMembersDocument = gql`
    mutation projectAddMembers($input: ProjectAddMemberInput!) {
  changedProject: projectAddMembers(input: $input) {
    ...fullProject
  }
}
    ${FullProjectFragmentDoc}`;
export type ProjectAddMembersMutationFn = Apollo.MutationFunction<ProjectAddMembersMutation, ProjectAddMembersMutationVariables>;

/**
 * __useProjectAddMembersMutation__
 *
 * To run a mutation, you first call `useProjectAddMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAddMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAddMembersMutation, { data, loading, error }] = useProjectAddMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectAddMembersMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAddMembersMutation, ProjectAddMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAddMembersMutation, ProjectAddMembersMutationVariables>(ProjectAddMembersDocument, options);
      }
export type ProjectAddMembersMutationHookResult = ReturnType<typeof useProjectAddMembersMutation>;
export type ProjectAddMembersMutationResult = Apollo.MutationResult<ProjectAddMembersMutation>;
export type ProjectAddMembersMutationOptions = Apollo.BaseMutationOptions<ProjectAddMembersMutation, ProjectAddMembersMutationVariables>;
export const ProjectAddToFavoriteDocument = gql`
    mutation projectAddToFavorite($input: ProjectAddToFavoritesInput!) {
  projectAddToFavorite(input: $input) {
    id
    UserMarkers {
      isFavorite
    }
  }
}
    `;
export type ProjectAddToFavoriteMutationFn = Apollo.MutationFunction<ProjectAddToFavoriteMutation, ProjectAddToFavoriteMutationVariables>;

/**
 * __useProjectAddToFavoriteMutation__
 *
 * To run a mutation, you first call `useProjectAddToFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAddToFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAddToFavoriteMutation, { data, loading, error }] = useProjectAddToFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectAddToFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAddToFavoriteMutation, ProjectAddToFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAddToFavoriteMutation, ProjectAddToFavoriteMutationVariables>(ProjectAddToFavoriteDocument, options);
      }
export type ProjectAddToFavoriteMutationHookResult = ReturnType<typeof useProjectAddToFavoriteMutation>;
export type ProjectAddToFavoriteMutationResult = Apollo.MutationResult<ProjectAddToFavoriteMutation>;
export type ProjectAddToFavoriteMutationOptions = Apollo.BaseMutationOptions<ProjectAddToFavoriteMutation, ProjectAddToFavoriteMutationVariables>;
export const ProjectAttachTagsDocument = gql`
    mutation projectAttachTags($projectId: String!, $tags: [String!]!) {
  changedProject: projectAttachTags(projectId: $projectId, tags: $tags) {
    ...baseProject
  }
}
    ${BaseProjectFragmentDoc}`;
export type ProjectAttachTagsMutationFn = Apollo.MutationFunction<ProjectAttachTagsMutation, ProjectAttachTagsMutationVariables>;

/**
 * __useProjectAttachTagsMutation__
 *
 * To run a mutation, you first call `useProjectAttachTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAttachTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAttachTagsMutation, { data, loading, error }] = useProjectAttachTagsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useProjectAttachTagsMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAttachTagsMutation, ProjectAttachTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAttachTagsMutation, ProjectAttachTagsMutationVariables>(ProjectAttachTagsDocument, options);
      }
export type ProjectAttachTagsMutationHookResult = ReturnType<typeof useProjectAttachTagsMutation>;
export type ProjectAttachTagsMutationResult = Apollo.MutationResult<ProjectAttachTagsMutation>;
export type ProjectAttachTagsMutationOptions = Apollo.BaseMutationOptions<ProjectAttachTagsMutation, ProjectAttachTagsMutationVariables>;
export const ProjectCardDocument = gql`
    query projectCard($id: String!) {
  project(id: $id) {
    ...fullProject
    paymentAccount {
      ...paymentAccount
    }
  }
    }
    ${FullProjectFragmentDoc}
${PaymentAccountFragmentDoc}`;

/**
 * __useProjectCardQuery__
 *
 * To run a query within a React component, call `useProjectCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectCardQuery(baseOptions: Apollo.QueryHookOptions<ProjectCardQuery, ProjectCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCardQuery, ProjectCardQueryVariables>(ProjectCardDocument, options);
      }
export function useProjectCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCardQuery, ProjectCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCardQuery, ProjectCardQueryVariables>(ProjectCardDocument, options);
        }
export type ProjectCardQueryHookResult = ReturnType<typeof useProjectCardQuery>;
export type ProjectCardLazyQueryHookResult = ReturnType<typeof useProjectCardLazyQuery>;
export type ProjectCardQueryResult = Apollo.QueryResult<ProjectCardQuery, ProjectCardQueryVariables>;
export function refetchProjectCardQuery(variables: ProjectCardQueryVariables) {
      return { query: ProjectCardDocument, variables: variables }
    }
export const ProjectChangePriorityAndStatusDocument = gql`
  mutation projectChangePriorityAndStatus($projectId: String!, $usersUnionId: String!, $priority: Float!, $status: String!) {
    projectChangePriorityAndStatus(
    projectId: $projectId
    usersUnionId: $usersUnionId
      priority: $priority
      status: $status
  ) {
    id
    status
      priority(contextId: $usersUnionId)
  }
}
    `;
export type ProjectChangePriorityAndStatusMutationFn = Apollo.MutationFunction<ProjectChangePriorityAndStatusMutation, ProjectChangePriorityAndStatusMutationVariables>;

/**
 * __useProjectChangePriorityAndStatusMutation__
 *
 * To run a mutation, you first call `useProjectChangePriorityAndStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectChangePriorityAndStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectChangePriorityAndStatusMutation, { data, loading, error }] = useProjectChangePriorityAndStatusMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      usersUnionId: // value for 'usersUnionId'
 *      priority: // value for 'priority'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useProjectChangePriorityAndStatusMutation(baseOptions?: Apollo.MutationHookOptions<ProjectChangePriorityAndStatusMutation, ProjectChangePriorityAndStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ProjectChangePriorityAndStatusMutation, ProjectChangePriorityAndStatusMutationVariables>(ProjectChangePriorityAndStatusDocument, options);
      }
export type ProjectChangePriorityAndStatusMutationHookResult = ReturnType<typeof useProjectChangePriorityAndStatusMutation>;
export type ProjectChangePriorityAndStatusMutationResult = Apollo.MutationResult<ProjectChangePriorityAndStatusMutation>;
export type ProjectChangePriorityAndStatusMutationOptions = Apollo.BaseMutationOptions<ProjectChangePriorityAndStatusMutation, ProjectChangePriorityAndStatusMutationVariables>;
export const ProjectCreateDocument = gql`
    mutation projectCreate($input: ProjectCreateInput!) {
  projectCreate(input: $input) {
    ...baseProject
  }
}
    ${BaseProjectFragmentDoc}`;
export type ProjectCreateMutationFn = Apollo.MutationFunction<ProjectCreateMutation, ProjectCreateMutationVariables>;

/**
 * __useProjectCreateMutation__
 *
 * To run a mutation, you first call `useProjectCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCreateMutation, { data, loading, error }] = useProjectCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProjectCreateMutation, ProjectCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectCreateMutation, ProjectCreateMutationVariables>(ProjectCreateDocument, options);
      }
export type ProjectCreateMutationHookResult = ReturnType<typeof useProjectCreateMutation>;
export type ProjectCreateMutationResult = Apollo.MutationResult<ProjectCreateMutation>;
export type ProjectCreateMutationOptions = Apollo.BaseMutationOptions<ProjectCreateMutation, ProjectCreateMutationVariables>;
export const ProjectLeaveDocument = gql`
    mutation projectLeave($projectId: String!) {
  projectLeave(projectId: $projectId)
}
    `;
export type ProjectLeaveMutationFn = Apollo.MutationFunction<ProjectLeaveMutation, ProjectLeaveMutationVariables>;

/**
 * __useProjectLeaveMutation__
 *
 * To run a mutation, you first call `useProjectLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectLeaveMutation, { data, loading, error }] = useProjectLeaveMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectLeaveMutation(baseOptions?: Apollo.MutationHookOptions<ProjectLeaveMutation, ProjectLeaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectLeaveMutation, ProjectLeaveMutationVariables>(ProjectLeaveDocument, options);
      }
export type ProjectLeaveMutationHookResult = ReturnType<typeof useProjectLeaveMutation>;
export type ProjectLeaveMutationResult = Apollo.MutationResult<ProjectLeaveMutation>;
export type ProjectLeaveMutationOptions = Apollo.BaseMutationOptions<ProjectLeaveMutation, ProjectLeaveMutationVariables>;
export const ProjectMemberChangeLevelDocument = gql`
    mutation projectMemberChangeLevel($input: ProjectMemberChangeLevelInput!) {
  changedProject: projectMemberChangeLevel(input: $input) {
    id
    members {
      ...projectMember
    }
  }
}
    ${ProjectMemberFragmentDoc}`;
export type ProjectMemberChangeLevelMutationFn = Apollo.MutationFunction<ProjectMemberChangeLevelMutation, ProjectMemberChangeLevelMutationVariables>;

/**
 * __useProjectMemberChangeLevelMutation__
 *
 * To run a mutation, you first call `useProjectMemberChangeLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectMemberChangeLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectMemberChangeLevelMutation, { data, loading, error }] = useProjectMemberChangeLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectMemberChangeLevelMutation(baseOptions?: Apollo.MutationHookOptions<ProjectMemberChangeLevelMutation, ProjectMemberChangeLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectMemberChangeLevelMutation, ProjectMemberChangeLevelMutationVariables>(ProjectMemberChangeLevelDocument, options);
      }
export type ProjectMemberChangeLevelMutationHookResult = ReturnType<typeof useProjectMemberChangeLevelMutation>;
export type ProjectMemberChangeLevelMutationResult = Apollo.MutationResult<ProjectMemberChangeLevelMutation>;
export type ProjectMemberChangeLevelMutationOptions = Apollo.BaseMutationOptions<ProjectMemberChangeLevelMutation, ProjectMemberChangeLevelMutationVariables>;
export const ProjectMemberInviteRemoveDocument = gql`
    mutation projectMemberInviteRemove($id: String!) {
  changedProject: projectMemberInviteRemove(id: $id) {
    ...fullProject
  }
}
    ${FullProjectFragmentDoc}`;
export type ProjectMemberInviteRemoveMutationFn = Apollo.MutationFunction<ProjectMemberInviteRemoveMutation, ProjectMemberInviteRemoveMutationVariables>;

/**
 * __useProjectMemberInviteRemoveMutation__
 *
 * To run a mutation, you first call `useProjectMemberInviteRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectMemberInviteRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectMemberInviteRemoveMutation, { data, loading, error }] = useProjectMemberInviteRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectMemberInviteRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ProjectMemberInviteRemoveMutation, ProjectMemberInviteRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectMemberInviteRemoveMutation, ProjectMemberInviteRemoveMutationVariables>(ProjectMemberInviteRemoveDocument, options);
      }
export type ProjectMemberInviteRemoveMutationHookResult = ReturnType<typeof useProjectMemberInviteRemoveMutation>;
export type ProjectMemberInviteRemoveMutationResult = Apollo.MutationResult<ProjectMemberInviteRemoveMutation>;
export type ProjectMemberInviteRemoveMutationOptions = Apollo.BaseMutationOptions<ProjectMemberInviteRemoveMutation, ProjectMemberInviteRemoveMutationVariables>;
export const ProjectRemoveMemberDocument = gql`
    mutation projectRemoveMember($input: ProjectRemoveMemberInput!) {
  changedProject: projectMemberRemove(input: $input) {
    ...fullProject
  }
}
    ${FullProjectFragmentDoc}`;
export type ProjectRemoveMemberMutationFn = Apollo.MutationFunction<ProjectRemoveMemberMutation, ProjectRemoveMemberMutationVariables>;

/**
 * __useProjectRemoveMemberMutation__
 *
 * To run a mutation, you first call `useProjectRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRemoveMemberMutation, { data, loading, error }] = useProjectRemoveMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRemoveMemberMutation, ProjectRemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRemoveMemberMutation, ProjectRemoveMemberMutationVariables>(ProjectRemoveMemberDocument, options);
      }
export type ProjectRemoveMemberMutationHookResult = ReturnType<typeof useProjectRemoveMemberMutation>;
export type ProjectRemoveMemberMutationResult = Apollo.MutationResult<ProjectRemoveMemberMutation>;
export type ProjectRemoveMemberMutationOptions = Apollo.BaseMutationOptions<ProjectRemoveMemberMutation, ProjectRemoveMemberMutationVariables>;
export const ProjectSwitchArchivedDocument = gql`
    mutation ProjectSwitchArchived($projectSwitchArchivedId: String!) {
  projectSwitchArchived(id: $projectSwitchArchivedId) {
    ...fullProject
  }
}
    ${FullProjectFragmentDoc}`;
export type ProjectSwitchArchivedMutationFn = Apollo.MutationFunction<ProjectSwitchArchivedMutation, ProjectSwitchArchivedMutationVariables>;

/**
 * __useProjectSwitchArchivedMutation__
 *
 * To run a mutation, you first call `useProjectSwitchArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSwitchArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSwitchArchivedMutation, { data, loading, error }] = useProjectSwitchArchivedMutation({
 *   variables: {
 *      projectSwitchArchivedId: // value for 'projectSwitchArchivedId'
 *   },
 * });
 */
export function useProjectSwitchArchivedMutation(baseOptions?: Apollo.MutationHookOptions<ProjectSwitchArchivedMutation, ProjectSwitchArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectSwitchArchivedMutation, ProjectSwitchArchivedMutationVariables>(ProjectSwitchArchivedDocument, options);
      }
export type ProjectSwitchArchivedMutationHookResult = ReturnType<typeof useProjectSwitchArchivedMutation>;
export type ProjectSwitchArchivedMutationResult = Apollo.MutationResult<ProjectSwitchArchivedMutation>;
export type ProjectSwitchArchivedMutationOptions = Apollo.BaseMutationOptions<ProjectSwitchArchivedMutation, ProjectSwitchArchivedMutationVariables>;
export const ProjectTransferDocument = gql`
    mutation projectTransfer($projectId: String!, $userAsOwnerId: String, $usersUnionAsOwnerId: String) {
  projectTransfer(
    projectId: $projectId
    userAsOwnerId: $userAsOwnerId
    usersUnionAsOwnerId: $usersUnionAsOwnerId
  )
}
    `;
export type ProjectTransferMutationFn = Apollo.MutationFunction<ProjectTransferMutation, ProjectTransferMutationVariables>;

/**
 * __useProjectTransferMutation__
 *
 * To run a mutation, you first call `useProjectTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTransferMutation, { data, loading, error }] = useProjectTransferMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userAsOwnerId: // value for 'userAsOwnerId'
 *      usersUnionAsOwnerId: // value for 'usersUnionAsOwnerId'
 *   },
 * });
 */
export function useProjectTransferMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTransferMutation, ProjectTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTransferMutation, ProjectTransferMutationVariables>(ProjectTransferDocument, options);
      }
export type ProjectTransferMutationHookResult = ReturnType<typeof useProjectTransferMutation>;
export type ProjectTransferMutationResult = Apollo.MutationResult<ProjectTransferMutation>;
export type ProjectTransferMutationOptions = Apollo.BaseMutationOptions<ProjectTransferMutation, ProjectTransferMutationVariables>;
export const ProjectTransferAcceptDocument = gql`
    mutation projectTransferAccept($transferId: String!) {
  projectTransferAccept(transferId: $transferId) {
    ...fullProject
  }
}
    ${FullProjectFragmentDoc}`;
export type ProjectTransferAcceptMutationFn = Apollo.MutationFunction<ProjectTransferAcceptMutation, ProjectTransferAcceptMutationVariables>;

/**
 * __useProjectTransferAcceptMutation__
 *
 * To run a mutation, you first call `useProjectTransferAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTransferAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTransferAcceptMutation, { data, loading, error }] = useProjectTransferAcceptMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *   },
 * });
 */
export function useProjectTransferAcceptMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTransferAcceptMutation, ProjectTransferAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTransferAcceptMutation, ProjectTransferAcceptMutationVariables>(ProjectTransferAcceptDocument, options);
      }
export type ProjectTransferAcceptMutationHookResult = ReturnType<typeof useProjectTransferAcceptMutation>;
export type ProjectTransferAcceptMutationResult = Apollo.MutationResult<ProjectTransferAcceptMutation>;
export type ProjectTransferAcceptMutationOptions = Apollo.BaseMutationOptions<ProjectTransferAcceptMutation, ProjectTransferAcceptMutationVariables>;
export const ProjectTransferRejectDocument = gql`
    mutation projectTransferReject($transferId: String!) {
  projectTransferReject(transferId: $transferId)
}
    `;
export type ProjectTransferRejectMutationFn = Apollo.MutationFunction<ProjectTransferRejectMutation, ProjectTransferRejectMutationVariables>;

/**
 * __useProjectTransferRejectMutation__
 *
 * To run a mutation, you first call `useProjectTransferRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTransferRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTransferRejectMutation, { data, loading, error }] = useProjectTransferRejectMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *   },
 * });
 */
export function useProjectTransferRejectMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTransferRejectMutation, ProjectTransferRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTransferRejectMutation, ProjectTransferRejectMutationVariables>(ProjectTransferRejectDocument, options);
      }
export type ProjectTransferRejectMutationHookResult = ReturnType<typeof useProjectTransferRejectMutation>;
export type ProjectTransferRejectMutationResult = Apollo.MutationResult<ProjectTransferRejectMutation>;
export type ProjectTransferRejectMutationOptions = Apollo.BaseMutationOptions<ProjectTransferRejectMutation, ProjectTransferRejectMutationVariables>;
export const ProjectTransferRequestsDocument = gql`
    query projectTransferRequests {
  projectTransferRequests {
    id
    requester {
      ...user
    }
    project {
      id
      title
    }
    usersUnion {
      id
      title
    }
    user {
      id
      fullName
      username
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useProjectTransferRequestsQuery__
 *
 * To run a query within a React component, call `useProjectTransferRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTransferRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTransferRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectTransferRequestsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectTransferRequestsQuery, ProjectTransferRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTransferRequestsQuery, ProjectTransferRequestsQueryVariables>(ProjectTransferRequestsDocument, options);
      }
export function useProjectTransferRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTransferRequestsQuery, ProjectTransferRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTransferRequestsQuery, ProjectTransferRequestsQueryVariables>(ProjectTransferRequestsDocument, options);
        }
export type ProjectTransferRequestsQueryHookResult = ReturnType<typeof useProjectTransferRequestsQuery>;
export type ProjectTransferRequestsLazyQueryHookResult = ReturnType<typeof useProjectTransferRequestsLazyQuery>;
export type ProjectTransferRequestsQueryResult = Apollo.QueryResult<ProjectTransferRequestsQuery, ProjectTransferRequestsQueryVariables>;
export function refetchProjectTransferRequestsQuery(variables?: ProjectTransferRequestsQueryVariables) {
      return { query: ProjectTransferRequestsDocument, variables: variables }
    }
export const ProjectUpdateDocument = gql`
    mutation projectUpdate($projectId: String!, $input: ProjectUpdateInput!) {
  changedProject: projectUpdate(id: $projectId, input: $input) {
    ...baseProject
  }
}
    ${BaseProjectFragmentDoc}`;
export type ProjectUpdateMutationFn = Apollo.MutationFunction<ProjectUpdateMutation, ProjectUpdateMutationVariables>;

/**
 * __useProjectUpdateMutation__
 *
 * To run a mutation, you first call `useProjectUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectUpdateMutation, { data, loading, error }] = useProjectUpdateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProjectUpdateMutation, ProjectUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectUpdateMutation, ProjectUpdateMutationVariables>(ProjectUpdateDocument, options);
      }
export type ProjectUpdateMutationHookResult = ReturnType<typeof useProjectUpdateMutation>;
export type ProjectUpdateMutationResult = Apollo.MutationResult<ProjectUpdateMutation>;
export type ProjectUpdateMutationOptions = Apollo.BaseMutationOptions<ProjectUpdateMutation, ProjectUpdateMutationVariables>;
export const ProjectsListDocument = gql`
    query projectsList($filter: ProjectsFilterInput! = {}) {
  projects(filter: $filter) {
    ...baseProject
    attachedFolders {
      ...attachedFolder
    }
    tasksView {
      id
    }
  }
}
    ${BaseProjectFragmentDoc}
${AttachedFolderFragmentDoc}`;

/**
 * __useProjectsListQuery__
 *
 * To run a query within a React component, call `useProjectsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectsListQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsListQuery, ProjectsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsListQuery, ProjectsListQueryVariables>(ProjectsListDocument, options);
      }
export function useProjectsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsListQuery, ProjectsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsListQuery, ProjectsListQueryVariables>(ProjectsListDocument, options);
        }
export type ProjectsListQueryHookResult = ReturnType<typeof useProjectsListQuery>;
export type ProjectsListLazyQueryHookResult = ReturnType<typeof useProjectsListLazyQuery>;
export type ProjectsListQueryResult = Apollo.QueryResult<ProjectsListQuery, ProjectsListQueryVariables>;
export function refetchProjectsListQuery(variables?: ProjectsListQueryVariables) {
      return { query: ProjectsListDocument, variables: variables }
    }
export const ProjectsInvitesDocument = gql`
    query projectsInvites {
  projectsInvites {
    ...projectMemberInvite
  }
}
    ${ProjectMemberInviteFragmentDoc}`;

/**
 * __useProjectsInvitesQuery__
 *
 * To run a query within a React component, call `useProjectsInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsInvitesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsInvitesQuery, ProjectsInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsInvitesQuery, ProjectsInvitesQueryVariables>(ProjectsInvitesDocument, options);
      }
export function useProjectsInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsInvitesQuery, ProjectsInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsInvitesQuery, ProjectsInvitesQueryVariables>(ProjectsInvitesDocument, options);
        }
export type ProjectsInvitesQueryHookResult = ReturnType<typeof useProjectsInvitesQuery>;
export type ProjectsInvitesLazyQueryHookResult = ReturnType<typeof useProjectsInvitesLazyQuery>;
export type ProjectsInvitesQueryResult = Apollo.QueryResult<ProjectsInvitesQuery, ProjectsInvitesQueryVariables>;
export function refetchProjectsInvitesQuery(variables?: ProjectsInvitesQueryVariables) {
      return { query: ProjectsInvitesDocument, variables: variables }
    }
export const SignInDocument = gql`
    mutation SignIn($data: UserSignInInput!) {
  userSignIn(data: $data)
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($data: UserSignUpInput!) {
  userSignUp(data: $data) {
    token
    user {
      id
      email
      username
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const AllTariffsDocument = gql`
    query allTariffs {
  allTariffs {
    id
    title
    archived
    isDefault
    currency
    monthlyCost
    personalProjectMembersFreeCount
    personalProjectAdditionalMemberAdditionalCost
    usersUnionFreeCount
    usersUnionAdditionalCost
    usersUnionMembersFreeCount
    usersUnionMembersAdditionalCost
    usersUnionProjectMemberFreeCount
    usersUnionProjectMemberAdditionalCost
  }
}
    `;

/**
 * __useAllTariffsQuery__
 *
 * To run a query within a React component, call `useAllTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTariffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTariffsQuery(baseOptions?: Apollo.QueryHookOptions<AllTariffsQuery, AllTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTariffsQuery, AllTariffsQueryVariables>(AllTariffsDocument, options);
      }
export function useAllTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTariffsQuery, AllTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTariffsQuery, AllTariffsQueryVariables>(AllTariffsDocument, options);
        }
export type AllTariffsQueryHookResult = ReturnType<typeof useAllTariffsQuery>;
export type AllTariffsLazyQueryHookResult = ReturnType<typeof useAllTariffsLazyQuery>;
export type AllTariffsQueryResult = Apollo.QueryResult<AllTariffsQuery, AllTariffsQueryVariables>;
export function refetchAllTariffsQuery(variables?: AllTariffsQueryVariables) {
      return { query: AllTariffsDocument, variables: variables }
    }
export const UserPaymentAccountDocument = gql`
  query UserPaymentAccount($id: String) {
    paymentAccount: userPaymentAccount(paymentAccountId: $id) {
    ...paymentAccountFull
  }
}
${PaymentAccountFullFragmentDoc}`;

/**
 * __useUserPaymentAccountQuery__
 *
 * To run a query within a React component, call `useUserPaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPaymentAccountQuery(baseOptions?: Apollo.QueryHookOptions<UserPaymentAccountQuery, UserPaymentAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPaymentAccountQuery, UserPaymentAccountQueryVariables>(UserPaymentAccountDocument, options);
      }
export function useUserPaymentAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPaymentAccountQuery, UserPaymentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPaymentAccountQuery, UserPaymentAccountQueryVariables>(UserPaymentAccountDocument, options);
        }
export type UserPaymentAccountQueryHookResult = ReturnType<typeof useUserPaymentAccountQuery>;
export type UserPaymentAccountLazyQueryHookResult = ReturnType<typeof useUserPaymentAccountLazyQuery>;
export type UserPaymentAccountQueryResult = Apollo.QueryResult<UserPaymentAccountQuery, UserPaymentAccountQueryVariables>;
export function refetchUserPaymentAccountQuery(variables?: UserPaymentAccountQueryVariables) {
      return { query: UserPaymentAccountDocument, variables: variables }
    }
export const Payments_AddSlotsDocument = gql`
    mutation Payments_addSlots($input: AddSlotsInput!, $paymentAccountId: String!) {
  payments_addSlots(input: $input, paymentAccountId: $paymentAccountId) {
    id
    paymentId
    currency
    amount
    amountByPrice
    items {
      amount
      currency
      discount
      itemType
    }
  }
}
    `;
export type Payments_AddSlotsMutationFn = Apollo.MutationFunction<Payments_AddSlotsMutation, Payments_AddSlotsMutationVariables>;

/**
 * __usePayments_AddSlotsMutation__
 *
 * To run a mutation, you first call `usePayments_AddSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayments_AddSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsAddSlotsMutation, { data, loading, error }] = usePayments_AddSlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentAccountId: // value for 'paymentAccountId'
 *   },
 * });
 */
export function usePayments_AddSlotsMutation(baseOptions?: Apollo.MutationHookOptions<Payments_AddSlotsMutation, Payments_AddSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Payments_AddSlotsMutation, Payments_AddSlotsMutationVariables>(Payments_AddSlotsDocument, options);
      }
export type Payments_AddSlotsMutationHookResult = ReturnType<typeof usePayments_AddSlotsMutation>;
export type Payments_AddSlotsMutationResult = Apollo.MutationResult<Payments_AddSlotsMutation>;
export type Payments_AddSlotsMutationOptions = Apollo.BaseMutationOptions<Payments_AddSlotsMutation, Payments_AddSlotsMutationVariables>;
export const Payments_ChangeTariffDocument = gql`
    mutation Payments_changeTariff($tariffId: String!, $paymentAccountId: String!) {
  payments_changeTariff(tariffId: $tariffId, paymentAccountId: $paymentAccountId) {
    paymentAccount {
      id
      tariff {
        title
        currency
        monthlyCost
      }
    }
    paymentReceipt {
      amount
      currency
      id
      items {
        amount
        currency
        discount
        itemType
        price
        paymentSlot {
          itemType
          status
        }
      }
    }
  }
}
    `;
export type Payments_ChangeTariffMutationFn = Apollo.MutationFunction<Payments_ChangeTariffMutation, Payments_ChangeTariffMutationVariables>;

/**
 * __usePayments_ChangeTariffMutation__
 *
 * To run a mutation, you first call `usePayments_ChangeTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayments_ChangeTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsChangeTariffMutation, { data, loading, error }] = usePayments_ChangeTariffMutation({
 *   variables: {
 *      tariffId: // value for 'tariffId'
 *      paymentAccountId: // value for 'paymentAccountId'
 *   },
 * });
 */
export function usePayments_ChangeTariffMutation(baseOptions?: Apollo.MutationHookOptions<Payments_ChangeTariffMutation, Payments_ChangeTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Payments_ChangeTariffMutation, Payments_ChangeTariffMutationVariables>(Payments_ChangeTariffDocument, options);
      }
export type Payments_ChangeTariffMutationHookResult = ReturnType<typeof usePayments_ChangeTariffMutation>;
export type Payments_ChangeTariffMutationResult = Apollo.MutationResult<Payments_ChangeTariffMutation>;
export type Payments_ChangeTariffMutationOptions = Apollo.BaseMutationOptions<Payments_ChangeTariffMutation, Payments_ChangeTariffMutationVariables>;
export const Payments_GetPaymentLinkDocument = gql`
    query Payments_getPaymentLink($input: GetPaymentLinkInput!, $paymentAccountId: String!) {
  payments_getPaymentLink(input: $input, paymentAccountId: $paymentAccountId) {
    confirmationToken
  }
}
    `;

/**
 * __usePayments_GetPaymentLinkQuery__
 *
 * To run a query within a React component, call `usePayments_GetPaymentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayments_GetPaymentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayments_GetPaymentLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *      paymentAccountId: // value for 'paymentAccountId'
 *   },
 * });
 */
export function usePayments_GetPaymentLinkQuery(baseOptions: Apollo.QueryHookOptions<Payments_GetPaymentLinkQuery, Payments_GetPaymentLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Payments_GetPaymentLinkQuery, Payments_GetPaymentLinkQueryVariables>(Payments_GetPaymentLinkDocument, options);
      }
export function usePayments_GetPaymentLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Payments_GetPaymentLinkQuery, Payments_GetPaymentLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Payments_GetPaymentLinkQuery, Payments_GetPaymentLinkQueryVariables>(Payments_GetPaymentLinkDocument, options);
        }
export type Payments_GetPaymentLinkQueryHookResult = ReturnType<typeof usePayments_GetPaymentLinkQuery>;
export type Payments_GetPaymentLinkLazyQueryHookResult = ReturnType<typeof usePayments_GetPaymentLinkLazyQuery>;
export type Payments_GetPaymentLinkQueryResult = Apollo.QueryResult<Payments_GetPaymentLinkQuery, Payments_GetPaymentLinkQueryVariables>;
export function refetchPayments_GetPaymentLinkQuery(variables: Payments_GetPaymentLinkQueryVariables) {
      return { query: Payments_GetPaymentLinkDocument, variables: variables }
    }
export const Payments_RemoveSlotDocument = gql`
    mutation Payments_removeSlot($slotId: String!, $paymentAccountId: String!) {
  payments_removeSlot(slotId: $slotId, paymentAccountId: $paymentAccountId) {
    ...paymentAccount
  }
}
    ${PaymentAccountFragmentDoc}`;
export type Payments_RemoveSlotMutationFn = Apollo.MutationFunction<Payments_RemoveSlotMutation, Payments_RemoveSlotMutationVariables>;

/**
 * __usePayments_RemoveSlotMutation__
 *
 * To run a mutation, you first call `usePayments_RemoveSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayments_RemoveSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsRemoveSlotMutation, { data, loading, error }] = usePayments_RemoveSlotMutation({
 *   variables: {
 *      slotId: // value for 'slotId'
 *      paymentAccountId: // value for 'paymentAccountId'
 *   },
 * });
 */
export function usePayments_RemoveSlotMutation(baseOptions?: Apollo.MutationHookOptions<Payments_RemoveSlotMutation, Payments_RemoveSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Payments_RemoveSlotMutation, Payments_RemoveSlotMutationVariables>(Payments_RemoveSlotDocument, options);
      }
export type Payments_RemoveSlotMutationHookResult = ReturnType<typeof usePayments_RemoveSlotMutation>;
export type Payments_RemoveSlotMutationResult = Apollo.MutationResult<Payments_RemoveSlotMutation>;
export type Payments_RemoveSlotMutationOptions = Apollo.BaseMutationOptions<Payments_RemoveSlotMutation, Payments_RemoveSlotMutationVariables>;
export const ProcessReceiptDocument = gql`
    mutation ProcessReceipt($receiptId: String!, $paymentAccountId: String!) {
  ProcessReceipt(receiptId: $receiptId, paymentAccountId: $paymentAccountId) {
    ...paymentAccount
  }
}
    ${PaymentAccountFragmentDoc}`;
export type ProcessReceiptMutationFn = Apollo.MutationFunction<ProcessReceiptMutation, ProcessReceiptMutationVariables>;

/**
 * __useProcessReceiptMutation__
 *
 * To run a mutation, you first call `useProcessReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processReceiptMutation, { data, loading, error }] = useProcessReceiptMutation({
 *   variables: {
 *      receiptId: // value for 'receiptId'
 *      paymentAccountId: // value for 'paymentAccountId'
 *   },
 * });
 */
export function useProcessReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ProcessReceiptMutation, ProcessReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessReceiptMutation, ProcessReceiptMutationVariables>(ProcessReceiptDocument, options);
      }
export type ProcessReceiptMutationHookResult = ReturnType<typeof useProcessReceiptMutation>;
export type ProcessReceiptMutationResult = Apollo.MutationResult<ProcessReceiptMutation>;
export type ProcessReceiptMutationOptions = Apollo.BaseMutationOptions<ProcessReceiptMutation, ProcessReceiptMutationVariables>;
export const AllPersonalTagsDocument = gql`
    query allPersonalTags {
  allPersonalTags {
    ...tag
  }
}
    ${TagFragmentDoc}`;

/**
 * __useAllPersonalTagsQuery__
 *
 * To run a query within a React component, call `useAllPersonalTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPersonalTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPersonalTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPersonalTagsQuery(baseOptions?: Apollo.QueryHookOptions<AllPersonalTagsQuery, AllPersonalTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPersonalTagsQuery, AllPersonalTagsQueryVariables>(AllPersonalTagsDocument, options);
      }
export function useAllPersonalTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPersonalTagsQuery, AllPersonalTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPersonalTagsQuery, AllPersonalTagsQueryVariables>(AllPersonalTagsDocument, options);
        }
export type AllPersonalTagsQueryHookResult = ReturnType<typeof useAllPersonalTagsQuery>;
export type AllPersonalTagsLazyQueryHookResult = ReturnType<typeof useAllPersonalTagsLazyQuery>;
export type AllPersonalTagsQueryResult = Apollo.QueryResult<AllPersonalTagsQuery, AllPersonalTagsQueryVariables>;
export function refetchAllPersonalTagsQuery(variables?: AllPersonalTagsQueryVariables) {
      return { query: AllPersonalTagsDocument, variables: variables }
    }
export const EditTagDocument = gql`
    mutation editTag($id: String!, $projectId: String, $usersUnionId: String, $userId: String, $title: String!, $color: String!) {
  editTag(
    id: $id
    projectId: $projectId
    usersUnionId: $usersUnionId
    userId: $userId
    title: $title
    color: $color
  ) {
    ...tag
  }
}
    ${TagFragmentDoc}`;
export type EditTagMutationFn = Apollo.MutationFunction<EditTagMutation, EditTagMutationVariables>;

/**
 * __useEditTagMutation__
 *
 * To run a mutation, you first call `useEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTagMutation, { data, loading, error }] = useEditTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      usersUnionId: // value for 'usersUnionId'
 *      userId: // value for 'userId'
 *      title: // value for 'title'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useEditTagMutation(baseOptions?: Apollo.MutationHookOptions<EditTagMutation, EditTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTagMutation, EditTagMutationVariables>(EditTagDocument, options);
      }
export type EditTagMutationHookResult = ReturnType<typeof useEditTagMutation>;
export type EditTagMutationResult = Apollo.MutationResult<EditTagMutation>;
export type EditTagMutationOptions = Apollo.BaseMutationOptions<EditTagMutation, EditTagMutationVariables>;
export const GetDownloadLinkDocument = gql`
    query getDownloadLink($taskFileId: String!) {
  link: getDownloadLink(taskFileId: $taskFileId)
}
    `;

/**
 * __useGetDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadLinkQuery({
 *   variables: {
 *      taskFileId: // value for 'taskFileId'
 *   },
 * });
 */
export function useGetDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, options);
      }
export function useGetDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, options);
        }
export type GetDownloadLinkQueryHookResult = ReturnType<typeof useGetDownloadLinkQuery>;
export type GetDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetDownloadLinkLazyQuery>;
export type GetDownloadLinkQueryResult = Apollo.QueryResult<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>;
export function refetchGetDownloadLinkQuery(variables: GetDownloadLinkQueryVariables) {
      return { query: GetDownloadLinkDocument, variables: variables }
    }
export const RemoveTagDocument = gql`
    mutation removeTag($removeTagId: String!) {
  removeTag(id: $removeTagId)
}
    `;
export type RemoveTagMutationFn = Apollo.MutationFunction<RemoveTagMutation, RemoveTagMutationVariables>;

/**
 * __useRemoveTagMutation__
 *
 * To run a mutation, you first call `useRemoveTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagMutation, { data, loading, error }] = useRemoveTagMutation({
 *   variables: {
 *      removeTagId: // value for 'removeTagId'
 *   },
 * });
 */
export function useRemoveTagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagMutation, RemoveTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTagMutation, RemoveTagMutationVariables>(RemoveTagDocument, options);
      }
export type RemoveTagMutationHookResult = ReturnType<typeof useRemoveTagMutation>;
export type RemoveTagMutationResult = Apollo.MutationResult<RemoveTagMutation>;
export type RemoveTagMutationOptions = Apollo.BaseMutationOptions<RemoveTagMutation, RemoveTagMutationVariables>;
export const TaskDocument = gql`
    query task($id: String!) {
  task(id: $id) {
    ...task
    project {
      id
      title
      archived
      paymentAccount {
        ...paymentAccount
      }
      usersUnionAsOwner {
        id
        title
      }
      members {
        ...projectMember
      }
      attachedFolders {
        ...attachedFolder
      }
    }
    childTasks {
      id
      title
    }
    files {
      id
      name
      path
      attachDate
    }
    childTasks {
      ...childTask
    }
  }
}
    ${TaskFragmentDoc}
${PaymentAccountFragmentDoc}
${ProjectMemberFragmentDoc}
${AttachedFolderFragmentDoc}
${ChildTaskFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export function refetchTaskQuery(variables: TaskQueryVariables) {
      return { query: TaskDocument, variables: variables }
    }
export const TaskChangeColumnInViewDocument = gql`
    mutation taskChangeColumnInView($taskId: String!, $taskViewId: String!, $columnId: String!, $overTaskId: String) {
  taskChangeColumnInView(
    taskId: $taskId
    taskViewId: $taskViewId
    columnId: $columnId
    overTaskId: $overTaskId
  ) {
    id
    status
    tasksViewsPositions {
      tasksViewColumnId
      position
    }
  }
}
    `;
export type TaskChangeColumnInViewMutationFn = Apollo.MutationFunction<TaskChangeColumnInViewMutation, TaskChangeColumnInViewMutationVariables>;

/**
 * __useTaskChangeColumnInViewMutation__
 *
 * To run a mutation, you first call `useTaskChangeColumnInViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeColumnInViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeColumnInViewMutation, { data, loading, error }] = useTaskChangeColumnInViewMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      taskViewId: // value for 'taskViewId'
 *      columnId: // value for 'columnId'
 *      overTaskId: // value for 'overTaskId'
 *   },
 * });
 */
export function useTaskChangeColumnInViewMutation(baseOptions?: Apollo.MutationHookOptions<TaskChangeColumnInViewMutation, TaskChangeColumnInViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskChangeColumnInViewMutation, TaskChangeColumnInViewMutationVariables>(TaskChangeColumnInViewDocument, options);
      }
export type TaskChangeColumnInViewMutationHookResult = ReturnType<typeof useTaskChangeColumnInViewMutation>;
export type TaskChangeColumnInViewMutationResult = Apollo.MutationResult<TaskChangeColumnInViewMutation>;
export type TaskChangeColumnInViewMutationOptions = Apollo.BaseMutationOptions<TaskChangeColumnInViewMutation, TaskChangeColumnInViewMutationVariables>;
export const TaskChangeImportanceDocument = gql`
    mutation taskChangeImportance($id: String!, $importance: Float!) {
  taskChangeImportance(id: $id, importance: $importance) {
    id
    importance
  }
}
    `;
export type TaskChangeImportanceMutationFn = Apollo.MutationFunction<TaskChangeImportanceMutation, TaskChangeImportanceMutationVariables>;

/**
 * __useTaskChangeImportanceMutation__
 *
 * To run a mutation, you first call `useTaskChangeImportanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeImportanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeImportanceMutation, { data, loading, error }] = useTaskChangeImportanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      importance: // value for 'importance'
 *   },
 * });
 */
export function useTaskChangeImportanceMutation(baseOptions?: Apollo.MutationHookOptions<TaskChangeImportanceMutation, TaskChangeImportanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskChangeImportanceMutation, TaskChangeImportanceMutationVariables>(TaskChangeImportanceDocument, options);
      }
export type TaskChangeImportanceMutationHookResult = ReturnType<typeof useTaskChangeImportanceMutation>;
export type TaskChangeImportanceMutationResult = Apollo.MutationResult<TaskChangeImportanceMutation>;
export type TaskChangeImportanceMutationOptions = Apollo.BaseMutationOptions<TaskChangeImportanceMutation, TaskChangeImportanceMutationVariables>;
export const TaskChangeTitleDocument = gql`
  mutation taskChangeTitle($id: String!, $title: String!) {
    taskChangeTitle(id: $id, title: $title) {
      ...task
    }
  }
${TaskFragmentDoc}`;
export type TaskChangeTitleMutationFn = Apollo.MutationFunction<TaskChangeTitleMutation, TaskChangeTitleMutationVariables>;

/**
 * __useTaskChangeTitleMutation__
 *
 * To run a mutation, you first call `useTaskChangeTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeTitleMutation, { data, loading, error }] = useTaskChangeTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useTaskChangeTitleMutation(baseOptions?: Apollo.MutationHookOptions<TaskChangeTitleMutation, TaskChangeTitleMutationVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<TaskChangeTitleMutation, TaskChangeTitleMutationVariables>(TaskChangeTitleDocument, options);
}
export type TaskChangeTitleMutationHookResult = ReturnType<typeof useTaskChangeTitleMutation>;
export type TaskChangeTitleMutationResult = Apollo.MutationResult<TaskChangeTitleMutation>;
export type TaskChangeTitleMutationOptions = Apollo.BaseMutationOptions<TaskChangeTitleMutation, TaskChangeTitleMutationVariables>;
export const TaskRemoveDocument = gql`
    mutation taskRemove($id: String!) {
  taskRemove(id: $id)
}
    `;
export type TaskRemoveMutationFn = Apollo.MutationFunction<TaskRemoveMutation, TaskRemoveMutationVariables>;

/**
 * __useTaskRemoveMutation__
 *
 * To run a mutation, you first call `useTaskRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskRemoveMutation, { data, loading, error }] = useTaskRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TaskRemoveMutation, TaskRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskRemoveMutation, TaskRemoveMutationVariables>(TaskRemoveDocument, options);
      }
export type TaskRemoveMutationHookResult = ReturnType<typeof useTaskRemoveMutation>;
export type TaskRemoveMutationResult = Apollo.MutationResult<TaskRemoveMutation>;
export type TaskRemoveMutationOptions = Apollo.BaseMutationOptions<TaskRemoveMutation, TaskRemoveMutationVariables>;
export const TaskResolvedDocument = gql`
    mutation taskResolved($id: String!, $resolved: Boolean!) {
  taskResolved(id: $id, resolved: $resolved) {
    id
    resolved
  }
}
    `;
export type TaskResolvedMutationFn = Apollo.MutationFunction<TaskResolvedMutation, TaskResolvedMutationVariables>;

/**
 * __useTaskResolvedMutation__
 *
 * To run a mutation, you first call `useTaskResolvedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskResolvedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskResolvedMutation, { data, loading, error }] = useTaskResolvedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      resolved: // value for 'resolved'
 *   },
 * });
 */
export function useTaskResolvedMutation(baseOptions?: Apollo.MutationHookOptions<TaskResolvedMutation, TaskResolvedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskResolvedMutation, TaskResolvedMutationVariables>(TaskResolvedDocument, options);
      }
export type TaskResolvedMutationHookResult = ReturnType<typeof useTaskResolvedMutation>;
export type TaskResolvedMutationResult = Apollo.MutationResult<TaskResolvedMutation>;
export type TaskResolvedMutationOptions = Apollo.BaseMutationOptions<TaskResolvedMutation, TaskResolvedMutationVariables>;
export const TaskSaveDocument = gql`
    mutation taskSave($id: String!, $task: EditTaskInput!) {
  taskSave(id: $id, task: $task) {
    ...task
  }
}
    ${TaskFragmentDoc}`;
export type TaskSaveMutationFn = Apollo.MutationFunction<TaskSaveMutation, TaskSaveMutationVariables>;

/**
 * __useTaskSaveMutation__
 *
 * To run a mutation, you first call `useTaskSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSaveMutation, { data, loading, error }] = useTaskSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useTaskSaveMutation(baseOptions?: Apollo.MutationHookOptions<TaskSaveMutation, TaskSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSaveMutation, TaskSaveMutationVariables>(TaskSaveDocument, options);
      }
export type TaskSaveMutationHookResult = ReturnType<typeof useTaskSaveMutation>;
export type TaskSaveMutationResult = Apollo.MutationResult<TaskSaveMutation>;
export type TaskSaveMutationOptions = Apollo.BaseMutationOptions<TaskSaveMutation, TaskSaveMutationVariables>;
export const TasksByViewIdDocument = gql`
    query tasksByViewId($tasksViewId: String!, $includeResolved: Boolean!, $iAmResponsible: Boolean!) {
  tasks(
    taskViewId: $tasksViewId
    includeResolved: $includeResolved
    iAmResponsible: $iAmResponsible
  ) {
    id
    title
    status
    description
    files {
      name
      id
    }
    members {
      user {
        ...user
      }
      memberType
    }
    resolved
    project {
      id
      title
      usersUnionAsOwner {
        title
      }
    }
    tags
    number
    startTime
    endTime
    startDate
    endDate
    importance
    resolved
    tasksViewsPositions {
      tasksViewId
      tasksViewColumnId
      position
    }
    childTasks {
      ...childTask
    }
  }
}
    ${UserFragmentDoc}
${ChildTaskFragmentDoc}`;

/**
 * __useTasksByViewIdQuery__
 *
 * To run a query within a React component, call `useTasksByViewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByViewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByViewIdQuery({
 *   variables: {
 *      tasksViewId: // value for 'tasksViewId'
 *      includeResolved: // value for 'includeResolved'
 *      iAmResponsible: // value for 'iAmResponsible'
 *   },
 * });
 */
export function useTasksByViewIdQuery(baseOptions: Apollo.QueryHookOptions<TasksByViewIdQuery, TasksByViewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksByViewIdQuery, TasksByViewIdQueryVariables>(TasksByViewIdDocument, options);
      }
export function useTasksByViewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksByViewIdQuery, TasksByViewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksByViewIdQuery, TasksByViewIdQueryVariables>(TasksByViewIdDocument, options);
        }
export type TasksByViewIdQueryHookResult = ReturnType<typeof useTasksByViewIdQuery>;
export type TasksByViewIdLazyQueryHookResult = ReturnType<typeof useTasksByViewIdLazyQuery>;
export type TasksByViewIdQueryResult = Apollo.QueryResult<TasksByViewIdQuery, TasksByViewIdQueryVariables>;
export function refetchTasksByViewIdQuery(variables: TasksByViewIdQueryVariables) {
      return { query: TasksByViewIdDocument, variables: variables }
    }
export const TasksViewsDocument = gql`
    query tasksViews {
  tasksViews {
    id
    title
    project {
      paymentAccount {
        isBlocked
      }
    }
    columns {
      title
      id
      color
      tasks {
        position
        taskId
      }
    }
    filterType
    filterValue {
      __typename
      ...taskViewFilterPersonal
      ...taskViewFilterProject
    }
    viewType
  }
}
    ${TaskViewFilterPersonalFragmentDoc}
${TaskViewFilterProjectFragmentDoc}`;

/**
 * __useTasksViewsQuery__
 *
 * To run a query within a React component, call `useTasksViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksViewsQuery(baseOptions?: Apollo.QueryHookOptions<TasksViewsQuery, TasksViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksViewsQuery, TasksViewsQueryVariables>(TasksViewsDocument, options);
      }
export function useTasksViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksViewsQuery, TasksViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksViewsQuery, TasksViewsQueryVariables>(TasksViewsDocument, options);
        }
export type TasksViewsQueryHookResult = ReturnType<typeof useTasksViewsQuery>;
export type TasksViewsLazyQueryHookResult = ReturnType<typeof useTasksViewsLazyQuery>;
export type TasksViewsQueryResult = Apollo.QueryResult<TasksViewsQuery, TasksViewsQueryVariables>;
export function refetchTasksViewsQuery(variables?: TasksViewsQueryVariables) {
      return { query: TasksViewsDocument, variables: variables }
    }
export const UserProfileDocument = gql`
    query userProfile {
  userProfile {
    id
    avatarUrl
    username
    email
    aboutMe
    fullName
    shortInfo
    username
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export function refetchUserProfileQuery(variables?: UserProfileQueryVariables) {
      return { query: UserProfileDocument, variables: variables }
    }
export const UserProfileUpdateDocument = gql`
    mutation userProfileUpdate($input: UserProfileInput!) {
  userProfileUpdate(input: $input) {
    id
    aboutMe
    avatarUrl
    email
    fullName
    shortInfo
    username
  }
}
    `;
export type UserProfileUpdateMutationFn = Apollo.MutationFunction<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;

/**
 * __useUserProfileUpdateMutation__
 *
 * To run a mutation, you first call `useUserProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileUpdateMutation, { data, loading, error }] = useUserProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>(UserProfileUpdateDocument, options);
      }
export type UserProfileUpdateMutationHookResult = ReturnType<typeof useUserProfileUpdateMutation>;
export type UserProfileUpdateMutationResult = Apollo.MutationResult<UserProfileUpdateMutation>;
export type UserProfileUpdateMutationOptions = Apollo.BaseMutationOptions<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;
export const UserRestorePasswordDocument = gql`
    mutation userRestorePassword($data: UserRestorePasswordInput!) {
  userRestorePassword(data: $data) {
    token
    user {
      id
    }
  }
}
    `;
export type UserRestorePasswordMutationFn = Apollo.MutationFunction<UserRestorePasswordMutation, UserRestorePasswordMutationVariables>;

/**
 * __useUserRestorePasswordMutation__
 *
 * To run a mutation, you first call `useUserRestorePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRestorePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRestorePasswordMutation, { data, loading, error }] = useUserRestorePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserRestorePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserRestorePasswordMutation, UserRestorePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRestorePasswordMutation, UserRestorePasswordMutationVariables>(UserRestorePasswordDocument, options);
      }
export type UserRestorePasswordMutationHookResult = ReturnType<typeof useUserRestorePasswordMutation>;
export type UserRestorePasswordMutationResult = Apollo.MutationResult<UserRestorePasswordMutation>;
export type UserRestorePasswordMutationOptions = Apollo.BaseMutationOptions<UserRestorePasswordMutation, UserRestorePasswordMutationVariables>;
export const UserRestorePasswordSendLinkDocument = gql`
    mutation userRestorePasswordSendLink($email: String!) {
  userRestorePasswordSendLink(email: $email)
}
    `;
export type UserRestorePasswordSendLinkMutationFn = Apollo.MutationFunction<UserRestorePasswordSendLinkMutation, UserRestorePasswordSendLinkMutationVariables>;

/**
 * __useUserRestorePasswordSendLinkMutation__
 *
 * To run a mutation, you first call `useUserRestorePasswordSendLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRestorePasswordSendLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRestorePasswordSendLinkMutation, { data, loading, error }] = useUserRestorePasswordSendLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserRestorePasswordSendLinkMutation(baseOptions?: Apollo.MutationHookOptions<UserRestorePasswordSendLinkMutation, UserRestorePasswordSendLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRestorePasswordSendLinkMutation, UserRestorePasswordSendLinkMutationVariables>(UserRestorePasswordSendLinkDocument, options);
      }
export type UserRestorePasswordSendLinkMutationHookResult = ReturnType<typeof useUserRestorePasswordSendLinkMutation>;
export type UserRestorePasswordSendLinkMutationResult = Apollo.MutationResult<UserRestorePasswordSendLinkMutation>;
export type UserRestorePasswordSendLinkMutationOptions = Apollo.BaseMutationOptions<UserRestorePasswordSendLinkMutation, UserRestorePasswordSendLinkMutationVariables>;
export const UsersUnionsListDocument = gql`
    query UsersUnionsList($showArchived: Boolean! = false) {
  usersUnions {
    ...baseUsersUnion
  }
  usersUnionsInvites {
    id
    invitedUser {
      ...user
    }
    inviter {
      ...user
    }
    usersUnion {
      ...usersUnion
    }
  }
}
    ${BaseUsersUnionFragmentDoc}
${UserFragmentDoc}
${UsersUnionFragmentDoc}`;

/**
 * __useUsersUnionsListQuery__
 *
 * To run a query within a React component, call `useUsersUnionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersUnionsListQuery({
 *   variables: {
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useUsersUnionsListQuery(baseOptions?: Apollo.QueryHookOptions<UsersUnionsListQuery, UsersUnionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersUnionsListQuery, UsersUnionsListQueryVariables>(UsersUnionsListDocument, options);
      }
export function useUsersUnionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersUnionsListQuery, UsersUnionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersUnionsListQuery, UsersUnionsListQueryVariables>(UsersUnionsListDocument, options);
        }
export type UsersUnionsListQueryHookResult = ReturnType<typeof useUsersUnionsListQuery>;
export type UsersUnionsListLazyQueryHookResult = ReturnType<typeof useUsersUnionsListLazyQuery>;
export type UsersUnionsListQueryResult = Apollo.QueryResult<UsersUnionsListQuery, UsersUnionsListQueryVariables>;
export function refetchUsersUnionsListQuery(variables?: UsersUnionsListQueryVariables) {
      return { query: UsersUnionsListDocument, variables: variables }
    }
export const CheckUsersUnionNameDocument = gql`
    query checkUsersUnionName($name: String!, $id: String) {
  result: checkUsersUnionName(name: $name, id: $id)
}
    `;

/**
 * __useCheckUsersUnionNameQuery__
 *
 * To run a query within a React component, call `useCheckUsersUnionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUsersUnionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUsersUnionNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckUsersUnionNameQuery(baseOptions: Apollo.QueryHookOptions<CheckUsersUnionNameQuery, CheckUsersUnionNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUsersUnionNameQuery, CheckUsersUnionNameQueryVariables>(CheckUsersUnionNameDocument, options);
      }
export function useCheckUsersUnionNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUsersUnionNameQuery, CheckUsersUnionNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUsersUnionNameQuery, CheckUsersUnionNameQueryVariables>(CheckUsersUnionNameDocument, options);
        }
export type CheckUsersUnionNameQueryHookResult = ReturnType<typeof useCheckUsersUnionNameQuery>;
export type CheckUsersUnionNameLazyQueryHookResult = ReturnType<typeof useCheckUsersUnionNameLazyQuery>;
export type CheckUsersUnionNameQueryResult = Apollo.QueryResult<CheckUsersUnionNameQuery, CheckUsersUnionNameQueryVariables>;
export function refetchCheckUsersUnionNameQuery(variables: CheckUsersUnionNameQueryVariables) {
      return { query: CheckUsersUnionNameDocument, variables: variables }
    }
export const UsersUnionUpdateDocument = gql`
    mutation usersUnionUpdate($id: String!, $input: UsersUnionUpdateInput!) {
  usersUnionUpdate(id: $id, input: $input) {
    id
    title
    description
    name
  }
}
    `;
export type UsersUnionUpdateMutationFn = Apollo.MutationFunction<UsersUnionUpdateMutation, UsersUnionUpdateMutationVariables>;

/**
 * __useUsersUnionUpdateMutation__
 *
 * To run a mutation, you first call `useUsersUnionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionUpdateMutation, { data, loading, error }] = useUsersUnionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersUnionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionUpdateMutation, UsersUnionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionUpdateMutation, UsersUnionUpdateMutationVariables>(UsersUnionUpdateDocument, options);
      }
export type UsersUnionUpdateMutationHookResult = ReturnType<typeof useUsersUnionUpdateMutation>;
export type UsersUnionUpdateMutationResult = Apollo.MutationResult<UsersUnionUpdateMutation>;
export type UsersUnionUpdateMutationOptions = Apollo.BaseMutationOptions<UsersUnionUpdateMutation, UsersUnionUpdateMutationVariables>;
export const UsersUnionCreateDocument = gql`
    mutation usersUnionCreate($input: UsersUnionCreateInput!, $showArchived: Boolean! = false) {
  usersUnionCreate(input: $input) {
    ...baseUsersUnion
  }
}
    ${BaseUsersUnionFragmentDoc}`;
export type UsersUnionCreateMutationFn = Apollo.MutationFunction<UsersUnionCreateMutation, UsersUnionCreateMutationVariables>;

/**
 * __useUsersUnionCreateMutation__
 *
 * To run a mutation, you first call `useUsersUnionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionCreateMutation, { data, loading, error }] = useUsersUnionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useUsersUnionCreateMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionCreateMutation, UsersUnionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionCreateMutation, UsersUnionCreateMutationVariables>(UsersUnionCreateDocument, options);
      }
export type UsersUnionCreateMutationHookResult = ReturnType<typeof useUsersUnionCreateMutation>;
export type UsersUnionCreateMutationResult = Apollo.MutationResult<UsersUnionCreateMutation>;
export type UsersUnionCreateMutationOptions = Apollo.BaseMutationOptions<UsersUnionCreateMutation, UsersUnionCreateMutationVariables>;
export const UsersUnionDocument = gql`
  query usersUnion($id: String!, $showArchived: Boolean!, $slotType: PaymentSlotType!) {
  usersUnion(id: $id) {
    id
    title
    description
    name
    currentUserIsMember
    members {
      ...usersUnionMember
    }
    tags {
      ...tag
    }
    ownedProjects(showArchived: $showArchived) {
      id
      title
      status
      priority(contextId: $id)
      members {
        ...projectMember
      }
      tasksView {
        ...tasksView
      }
      statistics {
        ...projectStatistics
      }
      UserMarkers {
        isFavorite
      }
      attachedTags
      tags {
        ...tag
      }
    }
    invites {
      id
      email
      invitedUser {
        ...user
      }
    }
    projectInvites(showArchived: $showArchived) {
      ...projectMemberInvite
      project {
        priority(contextId: $id)
        status
        usersUnionAsOwner {
          title
          id
        }
        userAsOwner {
          username
          id
        }
        members {
          ...projectMember
        }
        tasksView {
          id
        }
        statistics {
          ...projectStatistics
        }
        UserMarkers {
          isFavorite
        }
        attachedTags
        tags {
          ...tag
        }
      }
    }
    attachedFolders {
      ...attachedFolder
    }
    paymentAccount {
      ...paymentAccount
      emptySlotsCount(slotType: $slotType)
    }
  }
}
    ${UsersUnionMemberFragmentDoc}
${TagFragmentDoc}
${ProjectMemberFragmentDoc}
${TasksViewFragmentDoc}
${ProjectStatisticsFragmentDoc}
${UserFragmentDoc}
${ProjectMemberInviteFragmentDoc}
${AttachedFolderFragmentDoc}
${PaymentAccountFragmentDoc}`;

/**
 * __useUsersUnionQuery__
 *
 * To run a query within a React component, call `useUsersUnionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersUnionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      showArchived: // value for 'showArchived'
 *      slotType: // value for 'slotType'
 *   },
 * });
 */
export function useUsersUnionQuery(baseOptions: Apollo.QueryHookOptions<UsersUnionQuery, UsersUnionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersUnionQuery, UsersUnionQueryVariables>(UsersUnionDocument, options);
      }
export function useUsersUnionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersUnionQuery, UsersUnionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersUnionQuery, UsersUnionQueryVariables>(UsersUnionDocument, options);
        }
export type UsersUnionQueryHookResult = ReturnType<typeof useUsersUnionQuery>;
export type UsersUnionLazyQueryHookResult = ReturnType<typeof useUsersUnionLazyQuery>;
export type UsersUnionQueryResult = Apollo.QueryResult<UsersUnionQuery, UsersUnionQueryVariables>;
export function refetchUsersUnionQuery(variables: UsersUnionQueryVariables) {
      return { query: UsersUnionDocument, variables: variables }
    }
export const UsersUnionAddMembersDocument = gql`
    mutation usersUnionAddMembers($input: UsersUnionAddMemberInput!) {
  usersUnionAddMembers: usersUnionInviteMembers(input: $input) {
    ...usersUnion
  }
}
    ${UsersUnionFragmentDoc}`;
export type UsersUnionAddMembersMutationFn = Apollo.MutationFunction<UsersUnionAddMembersMutation, UsersUnionAddMembersMutationVariables>;

/**
 * __useUsersUnionAddMembersMutation__
 *
 * To run a mutation, you first call `useUsersUnionAddMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionAddMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionAddMembersMutation, { data, loading, error }] = useUsersUnionAddMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersUnionAddMembersMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionAddMembersMutation, UsersUnionAddMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionAddMembersMutation, UsersUnionAddMembersMutationVariables>(UsersUnionAddMembersDocument, options);
      }
export type UsersUnionAddMembersMutationHookResult = ReturnType<typeof useUsersUnionAddMembersMutation>;
export type UsersUnionAddMembersMutationResult = Apollo.MutationResult<UsersUnionAddMembersMutation>;
export type UsersUnionAddMembersMutationOptions = Apollo.BaseMutationOptions<UsersUnionAddMembersMutation, UsersUnionAddMembersMutationVariables>;
export const UsersUnionDeleteDocument = gql`
    mutation usersUnionDelete($usersUnionId: String!) {
  usersUnionDelete(id: $usersUnionId)
}
    `;
export type UsersUnionDeleteMutationFn = Apollo.MutationFunction<UsersUnionDeleteMutation, UsersUnionDeleteMutationVariables>;

/**
 * __useUsersUnionDeleteMutation__
 *
 * To run a mutation, you first call `useUsersUnionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionDeleteMutation, { data, loading, error }] = useUsersUnionDeleteMutation({
 *   variables: {
 *      usersUnionId: // value for 'usersUnionId'
 *   },
 * });
 */
export function useUsersUnionDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionDeleteMutation, UsersUnionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionDeleteMutation, UsersUnionDeleteMutationVariables>(UsersUnionDeleteDocument, options);
      }
export type UsersUnionDeleteMutationHookResult = ReturnType<typeof useUsersUnionDeleteMutation>;
export type UsersUnionDeleteMutationResult = Apollo.MutationResult<UsersUnionDeleteMutation>;
export type UsersUnionDeleteMutationOptions = Apollo.BaseMutationOptions<UsersUnionDeleteMutation, UsersUnionDeleteMutationVariables>;
export const UsersUnionLeaveDocument = gql`
    mutation usersUnionLeave($usersUnionId: String!) {
  usersUnionLeave(usersUnionId: $usersUnionId)
}
    `;
export type UsersUnionLeaveMutationFn = Apollo.MutationFunction<UsersUnionLeaveMutation, UsersUnionLeaveMutationVariables>;

/**
 * __useUsersUnionLeaveMutation__
 *
 * To run a mutation, you first call `useUsersUnionLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionLeaveMutation, { data, loading, error }] = useUsersUnionLeaveMutation({
 *   variables: {
 *      usersUnionId: // value for 'usersUnionId'
 *   },
 * });
 */
export function useUsersUnionLeaveMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionLeaveMutation, UsersUnionLeaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionLeaveMutation, UsersUnionLeaveMutationVariables>(UsersUnionLeaveDocument, options);
      }
export type UsersUnionLeaveMutationHookResult = ReturnType<typeof useUsersUnionLeaveMutation>;
export type UsersUnionLeaveMutationResult = Apollo.MutationResult<UsersUnionLeaveMutation>;
export type UsersUnionLeaveMutationOptions = Apollo.BaseMutationOptions<UsersUnionLeaveMutation, UsersUnionLeaveMutationVariables>;
export const UsersUnionMemberChangeLevelDocument = gql`
    mutation usersUnionMemberChangeLevel($input: UsersUnionMemberChangeLevelInput!) {
  usersUnion: usersUnionMemberChangeLevel(input: $input) {
    id
  }
}
    `;
export type UsersUnionMemberChangeLevelMutationFn = Apollo.MutationFunction<UsersUnionMemberChangeLevelMutation, UsersUnionMemberChangeLevelMutationVariables>;

/**
 * __useUsersUnionMemberChangeLevelMutation__
 *
 * To run a mutation, you first call `useUsersUnionMemberChangeLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionMemberChangeLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionMemberChangeLevelMutation, { data, loading, error }] = useUsersUnionMemberChangeLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersUnionMemberChangeLevelMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionMemberChangeLevelMutation, UsersUnionMemberChangeLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionMemberChangeLevelMutation, UsersUnionMemberChangeLevelMutationVariables>(UsersUnionMemberChangeLevelDocument, options);
      }
export type UsersUnionMemberChangeLevelMutationHookResult = ReturnType<typeof useUsersUnionMemberChangeLevelMutation>;
export type UsersUnionMemberChangeLevelMutationResult = Apollo.MutationResult<UsersUnionMemberChangeLevelMutation>;
export type UsersUnionMemberChangeLevelMutationOptions = Apollo.BaseMutationOptions<UsersUnionMemberChangeLevelMutation, UsersUnionMemberChangeLevelMutationVariables>;
export const UsersUnionMemberInviteRemoveDocument = gql`
    mutation usersUnionMemberInviteRemove($id: ID!) {
  usersUnionMemberInviteRemove(id: $id) {
    ...usersUnion
  }
}
    ${UsersUnionFragmentDoc}`;
export type UsersUnionMemberInviteRemoveMutationFn = Apollo.MutationFunction<UsersUnionMemberInviteRemoveMutation, UsersUnionMemberInviteRemoveMutationVariables>;

/**
 * __useUsersUnionMemberInviteRemoveMutation__
 *
 * To run a mutation, you first call `useUsersUnionMemberInviteRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionMemberInviteRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionMemberInviteRemoveMutation, { data, loading, error }] = useUsersUnionMemberInviteRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUsersUnionMemberInviteRemoveMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionMemberInviteRemoveMutation, UsersUnionMemberInviteRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionMemberInviteRemoveMutation, UsersUnionMemberInviteRemoveMutationVariables>(UsersUnionMemberInviteRemoveDocument, options);
      }
export type UsersUnionMemberInviteRemoveMutationHookResult = ReturnType<typeof useUsersUnionMemberInviteRemoveMutation>;
export type UsersUnionMemberInviteRemoveMutationResult = Apollo.MutationResult<UsersUnionMemberInviteRemoveMutation>;
export type UsersUnionMemberInviteRemoveMutationOptions = Apollo.BaseMutationOptions<UsersUnionMemberInviteRemoveMutation, UsersUnionMemberInviteRemoveMutationVariables>;
export const UsersUnionProcessInviteDocument = gql`
    mutation usersUnionProcessInvite($inviteId: String!, $resolution: InviteResolution!) {
  usersUnionProcessInvite(inviteId: $inviteId, resolution: $resolution) {
    id
  }
}
    `;
export type UsersUnionProcessInviteMutationFn = Apollo.MutationFunction<UsersUnionProcessInviteMutation, UsersUnionProcessInviteMutationVariables>;

/**
 * __useUsersUnionProcessInviteMutation__
 *
 * To run a mutation, you first call `useUsersUnionProcessInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionProcessInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionProcessInviteMutation, { data, loading, error }] = useUsersUnionProcessInviteMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useUsersUnionProcessInviteMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionProcessInviteMutation, UsersUnionProcessInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionProcessInviteMutation, UsersUnionProcessInviteMutationVariables>(UsersUnionProcessInviteDocument, options);
      }
export type UsersUnionProcessInviteMutationHookResult = ReturnType<typeof useUsersUnionProcessInviteMutation>;
export type UsersUnionProcessInviteMutationResult = Apollo.MutationResult<UsersUnionProcessInviteMutation>;
export type UsersUnionProcessInviteMutationOptions = Apollo.BaseMutationOptions<UsersUnionProcessInviteMutation, UsersUnionProcessInviteMutationVariables>;
export const UsersUnionMemberRemoveDocument = gql`
    mutation usersUnionMemberRemove($usersUnionId: ID!, $userId: ID!) {
  usersUnion: usersUnionMemberRemove(usersUnionId: $usersUnionId, userId: $userId) {
    ...usersUnion
  }
}
    ${UsersUnionFragmentDoc}`;
export type UsersUnionMemberRemoveMutationFn = Apollo.MutationFunction<UsersUnionMemberRemoveMutation, UsersUnionMemberRemoveMutationVariables>;

/**
 * __useUsersUnionMemberRemoveMutation__
 *
 * To run a mutation, you first call `useUsersUnionMemberRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersUnionMemberRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersUnionMemberRemoveMutation, { data, loading, error }] = useUsersUnionMemberRemoveMutation({
 *   variables: {
 *      usersUnionId: // value for 'usersUnionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUsersUnionMemberRemoveMutation(baseOptions?: Apollo.MutationHookOptions<UsersUnionMemberRemoveMutation, UsersUnionMemberRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersUnionMemberRemoveMutation, UsersUnionMemberRemoveMutationVariables>(UsersUnionMemberRemoveDocument, options);
      }
export type UsersUnionMemberRemoveMutationHookResult = ReturnType<typeof useUsersUnionMemberRemoveMutation>;
export type UsersUnionMemberRemoveMutationResult = Apollo.MutationResult<UsersUnionMemberRemoveMutation>;
export type UsersUnionMemberRemoveMutationOptions = Apollo.BaseMutationOptions<UsersUnionMemberRemoveMutation, UsersUnionMemberRemoveMutationVariables>;
export const YandexDiskGetFolderItemsDocument = gql`
    query yandexDiskGetFolderItems($path: String!, $attachedFolderId: String, $onlyFolders: Boolean!) {
  yandexDiskGetFolderItems(
    path: $path
    attachedFolderId: $attachedFolderId
    onlyFolders: $onlyFolders
  ) {
    name
    created
    modified
    path
    publicUrl
    type
  }
}
    `;

/**
 * __useYandexDiskGetFolderItemsQuery__
 *
 * To run a query within a React component, call `useYandexDiskGetFolderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useYandexDiskGetFolderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYandexDiskGetFolderItemsQuery({
 *   variables: {
 *      path: // value for 'path'
 *      attachedFolderId: // value for 'attachedFolderId'
 *      onlyFolders: // value for 'onlyFolders'
 *   },
 * });
 */
export function useYandexDiskGetFolderItemsQuery(baseOptions: Apollo.QueryHookOptions<YandexDiskGetFolderItemsQuery, YandexDiskGetFolderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YandexDiskGetFolderItemsQuery, YandexDiskGetFolderItemsQueryVariables>(YandexDiskGetFolderItemsDocument, options);
      }
export function useYandexDiskGetFolderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YandexDiskGetFolderItemsQuery, YandexDiskGetFolderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YandexDiskGetFolderItemsQuery, YandexDiskGetFolderItemsQueryVariables>(YandexDiskGetFolderItemsDocument, options);
        }
export type YandexDiskGetFolderItemsQueryHookResult = ReturnType<typeof useYandexDiskGetFolderItemsQuery>;
export type YandexDiskGetFolderItemsLazyQueryHookResult = ReturnType<typeof useYandexDiskGetFolderItemsLazyQuery>;
export type YandexDiskGetFolderItemsQueryResult = Apollo.QueryResult<YandexDiskGetFolderItemsQuery, YandexDiskGetFolderItemsQueryVariables>;
export function refetchYandexDiskGetFolderItemsQuery(variables: YandexDiskGetFolderItemsQueryVariables) {
      return { query: YandexDiskGetFolderItemsDocument, variables: variables }
    }
export const YandexDiskGetOAuthLinkDocument = gql`
    query yandexDiskGetOAuthLink {
  yandexDiskGetOAuthLink
}
    `;

/**
 * __useYandexDiskGetOAuthLinkQuery__
 *
 * To run a query within a React component, call `useYandexDiskGetOAuthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useYandexDiskGetOAuthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYandexDiskGetOAuthLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useYandexDiskGetOAuthLinkQuery(baseOptions?: Apollo.QueryHookOptions<YandexDiskGetOAuthLinkQuery, YandexDiskGetOAuthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YandexDiskGetOAuthLinkQuery, YandexDiskGetOAuthLinkQueryVariables>(YandexDiskGetOAuthLinkDocument, options);
      }
export function useYandexDiskGetOAuthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YandexDiskGetOAuthLinkQuery, YandexDiskGetOAuthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YandexDiskGetOAuthLinkQuery, YandexDiskGetOAuthLinkQueryVariables>(YandexDiskGetOAuthLinkDocument, options);
        }
export type YandexDiskGetOAuthLinkQueryHookResult = ReturnType<typeof useYandexDiskGetOAuthLinkQuery>;
export type YandexDiskGetOAuthLinkLazyQueryHookResult = ReturnType<typeof useYandexDiskGetOAuthLinkLazyQuery>;
export type YandexDiskGetOAuthLinkQueryResult = Apollo.QueryResult<YandexDiskGetOAuthLinkQuery, YandexDiskGetOAuthLinkQueryVariables>;
export function refetchYandexDiskGetOAuthLinkQuery(variables?: YandexDiskGetOAuthLinkQueryVariables) {
      return { query: YandexDiskGetOAuthLinkDocument, variables: variables }
    }
export const YandexDiskSaveOAuthTokenDocument = gql`
    mutation yandexDiskSaveOAuthToken($token: String!, $expiresIn: Float!) {
  yandexDiskSaveOAuthToken(token: $token, expiresIn: $expiresIn)
}
    `;
export type YandexDiskSaveOAuthTokenMutationFn = Apollo.MutationFunction<YandexDiskSaveOAuthTokenMutation, YandexDiskSaveOAuthTokenMutationVariables>;

/**
 * __useYandexDiskSaveOAuthTokenMutation__
 *
 * To run a mutation, you first call `useYandexDiskSaveOAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYandexDiskSaveOAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yandexDiskSaveOAuthTokenMutation, { data, loading, error }] = useYandexDiskSaveOAuthTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      expiresIn: // value for 'expiresIn'
 *   },
 * });
 */
export function useYandexDiskSaveOAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<YandexDiskSaveOAuthTokenMutation, YandexDiskSaveOAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<YandexDiskSaveOAuthTokenMutation, YandexDiskSaveOAuthTokenMutationVariables>(YandexDiskSaveOAuthTokenDocument, options);
      }
export type YandexDiskSaveOAuthTokenMutationHookResult = ReturnType<typeof useYandexDiskSaveOAuthTokenMutation>;
export type YandexDiskSaveOAuthTokenMutationResult = Apollo.MutationResult<YandexDiskSaveOAuthTokenMutation>;
export type YandexDiskSaveOAuthTokenMutationOptions = Apollo.BaseMutationOptions<YandexDiskSaveOAuthTokenMutation, YandexDiskSaveOAuthTokenMutationVariables>;