import {Task} from "../../generated-types";
import React, {useState} from "react";
import {Button, Col, DatePicker, Divider, Form, FormInstance, Typography} from "antd";
import {CalendarOutlined, ClockCircleOutlined} from "@ant-design/icons";
import dayjs, {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";
import TimeIntervalSelector from "../TimeIntervalSelector";

interface TaskDatesProps {
    task: Task,
    form: FormInstance
}

const dateFormat = 'DD.MM.YYYY';

enum DatesMode {
    singleDay = "singleDay",
    fewDays = "fewDays",
    schedule = "schedule",
}

const TaskDates: React.FC<TaskDatesProps> = ({task, form}) => {
    const {t} = useTranslation();
    const [mode, setMode] = useState<DatesMode | null>(DatesMode.fewDays)
    const [opened, setOpened] = useState(false)

    const formInitialDate = (value: Dayjs) => {
        return {value: dayjs(value)}
    }

    return <div style={{display: "flex", alignItems: "center"}}>
        <Col sm={mode === null ? 24 : 1}>
            <Button type={"default"} block={mode === null} style={{border: 0}}

                    icon={
                        <CalendarOutlined style={{margin: 2, color: "#8d8d8d"}}/>
                    }
                    onClick={() => setOpened(!opened)}
            >
                {mode === null && <Typography.Text type="secondary" style={{
                    display: "flex",
                    alignItems: "center"
                }}>{t('task.datesSelector.placeholder')}</Typography.Text>
                }
            </Button>
        </Col>

	        <Col>
		        <Form.Item name="startDate" labelAlign={"left"} noStyle={true}
		                   getValueProps={(value) => value && formInitialDate(value)}>
			        <DatePicker placeholder={t('task.startDate') as string} format={dateFormat} bordered={false}
			                    suffixIcon={null} style={{maxWidth: 110}}
			        />
		        </Form.Item>
		        <Form.Item name="endDate" labelAlign={"left"} noStyle={true}
		                   getValueProps={(value) => value && formInitialDate(value)}>
			        <DatePicker placeholder={t('task.endDate') as string} format={dateFormat} bordered={false}
			                    suffixIcon={null} style={{maxWidth: 110}}
			        />
		        </Form.Item>
	        </Col>
        {/*}*/}

        <Divider type={"vertical"}/>

        {mode != null &&
					<Col style={{display: "flex", alignItems: "center"}}>

											<Form.Item name="startTime" labelAlign={"left"} noStyle={true}><></>
											</Form.Item>
											<Form.Item name="endTime" labelAlign={"left"} noStyle={true}><></>
											</Form.Item>

											<Button type={"default"} style={{border: 0}}
											        icon={<ClockCircleOutlined style={{margin: 2, color: "#8d8d8d"}}/>}
											></Button>
						<TimeIntervalSelector
													h1={!task.startTime ? null : Math.floor(task.startTime / 60)}
													m1={!task.startTime ? null : task.startTime % 60}
													h2={!task.endTime ? null : Math.floor(task.endTime / 60)}
													m2={!task.endTime ? null : task.endTime % 60}
							onChange={(h1, m1, h2, m2) => {
                                if (h1 != null) {
                                    let startTime = (h1 ?? 0) * 60 + (m1 ?? 0);
                                    form.setFieldValue("startTime", startTime);
                                }
                                if (h2 != null) {
                                    let endTime = (h2 ?? 0) * 60 + (m2 ?? 0);
                                    form.setFieldValue("endTime", endTime);
                                }
              }}/>
										</Col>
        }
    </div>
}

export default TaskDates