import React from "react";
import {Currency, PaymentStatusEnum, useUserPaymentAccountQuery} from "../../generated-types";
import {Divider, Table, Tag} from "antd";
import {CheckCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";
import {PayButton} from "./PayButton";
import {currencyType} from "../../utils/currencyType";

interface DataType {
    key: string;
    creationDate: any;
    paidDate: any;
    paymentStatus?: PaymentStatusEnum | null;
    currency: Currency;
    amount: number;
    paymentId?: string | null;
    id:string;
}

export const PaymentAccountHistory: React.FC = () => {
    const {t} = useTranslation();
    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery();
    const paymentAccountHistory = paymentAccount?.receipts;

    const columns: ColumnsType<DataType> = [
        {
            title: t('paymentAccount.wasPaymentDate'),
            dataIndex: 'id',
            sortOrder: "descend",
            defaultSortOrder: "descend",
            key: 'id',
            render: (v, record) => {
                return t("paymentAccount.receiptOf")+" "+record.id
            }
        },
        {
            title: t('paymentAccount.creationDate'),
            dataIndex: 'creationDate',
            key: 'creationDate',
            render: (v) => dayjs(v).format('DD.MM.YY')
        },
        {
            title: t('paymentAccount.amount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (v, record) => {
                return <>
                    <span style={{margin: 2}}>{v}{currencyType(record.currency!)}</span>
                    {record.paymentStatus == PaymentStatusEnum.Succeeded &&
                      <Tag icon={<CheckCircleOutlined />} color="success">{t('paymentAccount.receiptSuccess')}</Tag>
                    }
                    {record.paymentStatus == PaymentStatusEnum.Pending &&
	                    <Tag icon={<SyncOutlined />} color="processing">{t('paymentAccount.receiptPending')}</Tag>
                    }
                    {record.paymentStatus == PaymentStatusEnum.Canceled &&
	                    <Tag icon={<MinusCircleOutlined />} color="default">{t('paymentAccount.receiptCanceled')}</Tag>
                    }
                    {record.paidDate === null ?
                    <PayButton
                        type={"primary"}
                        paymentData={record}
                        id={paymentAccount?.id!}
                        key={record.key}
                    />: null}
                </>
            }
        }]

    const dataSource: DataType[] | undefined = paymentAccountHistory?.map((item) => {
        return {
            key: item.id,
            creationDate: item.creationDate,//!= null ? dayjs(item.creationDate).format('DD.MM') : null,
            amount: item.amount,
            currency: item.currency,
            paidDate: item.paidDate,
            paymentStatus: item.paymentStatus,
            paymentId: item.paymentId,
            id:item.id
        }
    })

    if (!dataSource?.length)
        return null;

    return <>
        <Divider style={{margin: "5px 0"}}>{t('paymentAccount.history')}</Divider>
        <Table dataSource={dataSource} columns={columns} size={"small"} bordered={false} showHeader={false}
               pagination={{
                   hideOnSinglePage: true,
                   showSizeChanger: false,
                   pageSize: 20
               }}
               scroll={{y: 240}}/>
    </>;
}