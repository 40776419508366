import {Badge, Button, Tooltip } from "antd";
import {useTaskChangeImportanceMutation} from "../../generated-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";


export interface TaskImportanceProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string
    value: number,
    onChange?: (value: number) => void;
}




function TaskImportance(props: TaskImportanceProps) {
    const {t} = useTranslation();
    const [value, setValue] = useState<number>(props.value)
    const [delay, setDelay] = useState<any>(undefined)
    let color = "lightgrey"
    if (value ==1 ) color = "lime"
    if (value ==2 ) color = "yellow"
    if (value ==3 ) color = "gold"
    if (value ==4 ) color = "pink"
    if (value ==5 ) color = "purple"


    const [taskChangeImportance] = useTaskChangeImportanceMutation({
        onCompleted: () => {
            clearTimeout(delay)
            setDelay(undefined)
            // TODO: сделать что-то красивое
        }
    })

    return (
        <Tooltip title={t("task.importance")}>
        <Button type="text" shape={"circle"} style={{padding: 3}} onClick={(e)=> {
            let importance = 0;
            if (value < 1) importance = 1;
            else if (value < 3) importance = 3;
            else if (value < 5) importance = 5;
            else importance = 0;

            setValue(importance)

            if (!props.onChange && props.id) {
                if (delay) clearTimeout(delay)

                setDelay(setTimeout(() => {
                    taskChangeImportance({
                        variables: {
                            importance,
                            id: props.id
                        }
                    })
                }, 1000))
            }

            if (props.onChange)
                props.onChange(importance)
            e.preventDefault();
            e.stopPropagation();
        }}>
            <Badge count={value} color={color} showZero></Badge>
        </Button></Tooltip>)
}

export default TaskImportance