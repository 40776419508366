import React from 'react';
import {Button, List, Divider, Popconfirm, message} from 'antd';
import {
    AccessLevel,
    UsersUnion,
    useUsersUnionMemberChangeLevelMutation, useUsersUnionMemberRemoveMutation
} from "../../generated-types";
import UserSearch, {IInvite, UserSearchResults} from "../User/UserSearch";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import UsersUnionMembersInvites from "../Project/UsersUnionMembersInvites";
import AccessLevelSelector from "../AccessLevelSelector";
import {showErrors, showLoading} from "../helpers";
import UserListItem from "../User/UserListItem";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import {CountInvitesMembers} from "../CountInvitesMembers";

interface UsersUnionMembersEditorProps {
    isAdmin: boolean,
    usersUnion: UsersUnion,
    membersAdded: (result: UserSearchResults) => boolean
}

const UsersUnionMembersEditor: React.FC<UsersUnionMembersEditorProps> = ({usersUnion, membersAdded, isAdmin}) => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [messageApi, contextHolder] = message.useMessage();

    const [ChangeLevel, opt2] = useUsersUnionMemberChangeLevelMutation({});
    const [Remove, opt3] = useUsersUnionMemberRemoveMutation({});

    const members = usersUnion != null && usersUnion.members != null ? usersUnion.members.map(v => v == null ? null : v.user) : [];

    const slots = usersUnion.paymentAccount?.slots;

    return (
        <div>
            {contextHolder}
            {showErrors([opt2, opt3])}
            {showLoading([opt2, opt3])}
            {isAdmin && <UserSearch disabled={usersUnion.paymentAccount?.isBlocked}
                                    membersAdded={membersAdded} members={members}
                                    addUsersUnions={false}
                                    invites={usersUnion.invites.map(i => (
                                        {email: i.email, user: i.invitedUser} as IInvite)
                                    )}
            />
            }
            {isAdmin && slots && <CountInvitesMembers slots={slots} type={'UsersUnionMembers'}/>}
            {usersUnion.members != null ? <List
                    loading={false}
                    itemLayout="horizontal"
                    loadMore={false}
                    dataSource={usersUnion.members}
                    renderItem={(item) => (item &&
                        <List.Item
                            actions={[
                                isAdmin && <Popconfirm
                                    icon={null}
                                    title={t('really')}
                                    description={t('usersUnion.SureRemoveMember')}
                                    onConfirm={() => {
                                        if (item.user.id === authInfo.user.id) {
                                            messageApi.warning(t('youCantEditYourselfInUsersUnionMembers'));
                                            return false;
                                        } else
                                            Remove({
                                                variables: {
                                                    userId: item.user.id,
                                                    usersUnionId: usersUnion.id
                                                }
                                            })
                                    }
                                    }
                                    onCancel={() => {
                                    }}
                                    okText={t('remove')}
                                    cancelText={t('no')}
                                >
                                    <Button type="text" onDragOver={() => {

                                    }} danger><DeleteOutlined rev={undefined}/></Button>
                                </Popconfirm>
                            ]}
                        >
                            <UserListItem user={item.user}/>
                            <AccessLevelSelector selected={item.accessLevel}
                                                 disabled={!isAdmin}
                                                 onChange={(level: AccessLevel) => {
                                                     if (item.user.id === authInfo.user.id) {
                                                         messageApi.warning(t('youCantEditYourselfInUsersUnionMembers'));
                                                         return false;
                                                     } else
                                                         ChangeLevel({
                                                             variables: {
                                                                 input: {
                                                                     userId: item.user.id,
                                                                     usersUnionId: usersUnion.id,
                                                                     level
                                                                 }
                                                             }
                                                         })
                                                     return true;
                                                 }
                                                 }/>
                        </List.Item>
                    )}
                />
                : <div>{t('usersUnion.noMembers')}</div>
            }

            {isAdmin && usersUnion.invites.length > 0 && [
                <Divider key={1}><UsergroupAddOutlined rev={undefined}/> {t('project.invitesList')}</Divider>,
                <UsersUnionMembersInvites key={2} usersUnion={usersUnion} invites={usersUnion.invites}
                                          allowRemove={isAdmin}/>
            ]}
        </div>
    );
};

export default UsersUnionMembersEditor;