import React, { useState} from "react";
import {Modal} from "antd";
import {useProcessReceiptMutation} from "../../generated-types";
import Title from "antd/es/typography/Title";
import {useTranslation} from "react-i18next";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";
interface PaymentWidgetModalProps {
}

export const PaymentWidgetModal: React.FC<PaymentWidgetModalProps> = () => {

    const [status, setStatus] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [processReceipt] = useProcessReceiptMutation();
    const ctx = useReactiveVar(paymentWidgetContext);
    const {t} = useTranslation();
    const paymentAccountId = ctx.paymentAccountId;
    const receiptId = ctx.receiptId;
    const confirmationToken = ctx.confirmationToken;

    const getPaymentWidget = () => {
        const script = document.createElement('script');
        script.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
        document.head.appendChild(script);
        script.addEventListener('load', () => {
            // @ts-ignore
            const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: confirmationToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa

                error_callback: function (error: any) {
                    console.log(error)
                }
            });
            checkout.on('success', () => {
                //Код, который нужно выполнить после успешной оплаты.
                processReceipt({
                    variables: {
                        paymentAccountId: paymentAccountId!,
                        receiptId: receiptId!
                    }
                    // update: (cache, {data}) => {
                    //     const id = cache.identify({id: data?.ProcessReceipt.id});
                    //     cache.evict({id})
                    // } // TODO включить если кеш не обновиться
                })
                setStatus('success')
                //Удаление инициализированного виджета
                checkout.destroy();
            });

            checkout.on('fail', () => {
                //Код, который нужно выполнить после неудачной оплаты.
                setStatus('fail')
                //Удаление инициализированного виджета
                checkout.destroy();
            });
            checkout.render('payment-form')
        })

    }

    if (!paymentAccountId && !receiptId && !confirmationToken)
        return null

    if (!open) {
        setOpen(true)
        getPaymentWidget();
    }


    const onClose = () => {
        setOpen(false);
        paymentWidgetContext({
            confirmationToken: undefined,
            paymentAccountId: undefined,
            receiptId: undefined
        })
        setStatus('');
    };


    return (
        <Modal
            open={open}
            onCancel={onClose}
            cancelButtonProps={{style: {display: 'none'}}}
            onOk={onClose}
            centered
        >
            <div id="payment-form"></div>
            {status === 'success' && <Title>{t('paymentAccount.paymentSuccessful')}</Title>}
            {status === 'fail' &&
                <>
                    <Title>{t('paymentAccount.paymentFail')}</Title>
                    <link href={"https://t.me/+ZHao_pYdqQRmYTdi"}>{t('paymentAccount.paymentFailHelp')}</link>
                </>
            }
        </Modal>
    )
}

const PaymentWidgetEditor: React.FC<PaymentWidgetModalProps> = (props) => {
    const ctx = useReactiveVar(paymentWidgetContext);
    const paymentAccountId = ctx.paymentAccountId;
    const receiptId = ctx.receiptId;
    const confirmationToken = ctx.confirmationToken;

    if (!paymentAccountId && !receiptId && !confirmationToken)
        return null;


    return <PaymentWidgetModal {...props} />;
};

interface PaymentWidgetContext {
    confirmationToken: string | undefined;
    paymentAccountId: string | undefined;
    receiptId: string | undefined;
}

function emptyState(): PaymentWidgetContext {
    return {
        confirmationToken: undefined,
        paymentAccountId: undefined,
        receiptId: undefined
    }
}

export const paymentWidgetContext: ReactiveVar<PaymentWidgetContext> = makeVar<PaymentWidgetContext>(emptyState());
export default PaymentWidgetEditor;