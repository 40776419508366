import React, {useEffect, useState} from "react";
import {
    PaymentSlot,
    PaymentSlotType,
    usePayments_RemoveSlotMutation,
    useUserPaymentAccountQuery
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {Button, Col, Divider, Row, Tooltip, Typography} from "antd";
import Text from "antd/es/typography/Text";
import {addSlotsContext} from "./AddSlotsModal";
import {MinusOutlined} from "@ant-design/icons";
import {tariffTableModalContext} from "./ModalTariffsTable";
import {currencyType} from "../../utils/currencyType";
import {slotsTypesWithAmounts, TypesWithAmounts} from "./SlotsTypesWithAmounts";
import dayjs from "dayjs";

export interface PaymentWidgetModalProps {
    paymentAccountId?: string;
    receiptId?: string;
    confirmationToken?: string;
}

export const PaymentAccount: React.FC = () => {
    const [slotsType, setSlotsType] = useState<TypesWithAmounts[] | undefined>();
    const {t} = useTranslation();
    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery();
    const [paymentsRemoveSlot] = usePayments_RemoveSlotMutation();
    const currency = currencyType(paymentAccount?.tariff.currency!);
    const allSlots = paymentAccount?.slots!

    const {Title} = Typography;
    const openAddSlotsModal = () => {
        addSlotsContext({
            slotsType: slotsType
        });
    }
    const openTariffTableModal = () => {
        tariffTableModalContext({
            paymentAccountId: paymentAccount?.id!,
            currentTariffId: paymentAccount?.tariff.id
        })
    }
    const removeSlot = (payEmptySlots: PaymentSlot[]) => {
        const id = payEmptySlots[payEmptySlots.length - 1].id;
        paymentsRemoveSlot({
            variables: {
                paymentAccountId: paymentAccount?.id!,
                slotId: id
            }
        })
    }

    useEffect(() => {
        if (allSlots) {
            const items = Object.keys(PaymentSlotType)
                .map(k => slotsTypesWithAmounts(allSlots, k))
            setSlotsType(items)
        }
    }, [allSlots]);

    return (
        <div style={{border: '1px solid rgba(5, 5, 5, 0.06)', padding: 10}}>
            <Row style={{alignItems: 'baseline', gap: 15}}>
                <Col style={{display: 'flex', gap: 10, flexWrap: "wrap"}} span={8}>
                    <Tooltip color={'#1677ff'} title={t('paymentAccount.currentTariff')}>
                        <Title style={{cursor: 'pointer'}} level={5}>
                            {paymentAccount?.tariff.title}
                        </Title>
                    </Tooltip>
                    <Tooltip title={t('paymentAccount.changeTariff')} color={'#1677ff'}>
                        <Button
                            onClick={openTariffTableModal}
                            type="primary"
                            ghost
                            size={'small'}>{t('paymentAccount.change')}
                        </Button>
                    </Tooltip>
                </Col>
                <Col>
                    <Text>{t('paymentAccount.period')}
                        <span style={{
                            fontWeight: 600,
                            fontSize: 18,
                            marginLeft: 8
                        }}>{paymentAccount?.totalMonthly}</span>
                        <span style={{marginLeft: 4}}>{currency}/{t('tariffsTable.period')}</span>
                    </Text>
                </Col>
                <Col>
                    <Text>{t('paymentAccount.paymentDate')}</Text>
                    <Text strong style={{marginLeft: 8}}>
                        {`${dayjs(paymentAccount?.paymentDate).format('DD.MM')}`}
                    </Text>
                </Col>
            </Row>
            <Divider style={{margin: 5, width: `${80}%`}}/>
            <Row style={{gap: `${13}%`, marginLeft: `${40}%`, justifyContent: "flex-start", flexWrap: 'nowrap'}}>
                <Col>
                    <Title level={5}>{t('paymentAccount.freeCost')}</Title>
                </Col>
                <Col>
                    <Title level={5}>{t('paymentAccount.paid')}</Title>
                </Col>
            </Row>
            {slotsType && slotsType.map((s, i) => (
                <Row style={{alignItems: 'center'}} key={i}>
                    <Col className={'paymentAccountField'}>
                        <Text style={{
                            display: 'block',
                            cursor: 'pointer'
                        }}>{t(`paymentAccount.${s.type}`)}</Text>
                    </Col>
                    <Col className={'paymentAccountCell'}>
                        <Tooltip title={`${t('paymentAccount.used')}/${t('paymentAccount.total')}`}>
                            <Text strong style={{display: 'block', cursor: 'pointer'}}>
                                {`${s.freeUsedCount}/${s.freeTotalCount}`}
                            </Text>
                        </Tooltip>
                    </Col>
                    <Col className={'paymentAccountCell'}>
                        <Tooltip title={`${t('paymentAccount.used')}/${t('paymentAccount.total')}`}>
                            <Text strong style={{display: 'block', cursor: 'pointer'}}>
                                {s.payTotalCount == 0 && s.payUsedCount == 0 ? 0 : `${s.payUsedCount}/${s.payTotalCount}`}
                            </Text>
                        </Tooltip>
                    </Col>
                    <Col className={'paymentAccountCell'}>
                        {s.payEmptySlots.length > 0 &&
                        <Tooltip
                            color={'#ff4d4f'}
                            title={t('paymentAccount.remove')}>
                            <Button
                                disabled={s.payEmptySlots.length === 0}
                                onClick={() => removeSlot(s.payEmptySlots)}
                                icon={<MinusOutlined/>}/>
                        </Tooltip>
                        }
                    </Col>
                </Row>
            ))}
            <Row style={{marginTop: 12}}>
                <Tooltip title={t('paymentAccount.addSlots')} color={'#1677ff'}>
                    <Button type="primary" ghost size={'small'}
                            onClick={openAddSlotsModal}>{t('paymentAccount.add')}</Button>
                </Tooltip>
            </Row>
        </div>
    )
}