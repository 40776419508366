import {Space} from "antd";
import {HomeOutlined, NotificationOutlined, OrderedListOutlined} from "@ant-design/icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ButtonsContainer, ButtonWrapper} from "./LeftMenuButtons.styled";

interface LeftMenuButtonsProps {
    collapsed:boolean;
    onClick:()=>void
}

export const LeftMenuButtons: React.FC<LeftMenuButtonsProps> = ({collapsed, onClick}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const onClickButton = (rout:string)=>{
        onClick();
        navigate(rout)
    }

    return <ButtonsContainer collapsed={collapsed ? "true" : undefined}>
        <Space direction={"vertical"} style={{width:'100%'}}>
            <ButtonWrapper collapsed={collapsed ? "true" : undefined} onClick={() => onClickButton('/')}>
                <HomeOutlined rev={undefined}/>
                {!collapsed && <p>{t('allProjects')}</p>}
            </ButtonWrapper>
            <ButtonWrapper collapsed={collapsed ? "true" : undefined} onClick={() => onClickButton("/mytasks")}>
                <OrderedListOutlined rev={undefined}/>
                {!collapsed && <p>{t('myTasks')}</p>}
            </ButtonWrapper>
            <ButtonWrapper collapsed={collapsed ? "true" : undefined} onClick={() => onClickButton("/notifications")}
                           style={{color: "lightgray"}}
            >
                {/*<Badge dot={true} offset={[-3, 3]}>*/}
                   <NotificationOutlined rev={undefined}/>
                {/*</Badge>*/}
                {!collapsed && <p>{t('notifications')}</p>}

            </ButtonWrapper>
        </Space>
    </ButtonsContainer>
}