import type { CSSProperties, FC } from 'react'
import type { XYCoord } from 'react-dnd'
import {useDragDropManager, useDragLayer} from 'react-dnd'
import React, {useEffect, useRef, useState} from "react";
import ProjectsListCard from "./Project/ProjectsListCard";
import TasksBoardItem from "./Tasks/TasksBoardItem";

const layerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
}

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (initialOffset == null || currentOffset == null) {
        return {
            display: 'none',
        }
    }

    let { x, y } = currentOffset
    x = x-30;

    const transform = `translate(${x}px, ${y}px)`
    return {
        border: "1px solid gray",
        width: "300px",
        opacity: 0.6,
        boxShadow: "#1677ff 0px 0px 4px",
        background: "#a0c7fd4f",
        transform,
        WebkitTransform: transform,
    }
}

export interface CustomDragLayerProps {
    
}

/*
* TODO: в будущем нужно будет убрать тут плохой код, который не соответствует O в SOLID
* */
export const CommonDragLayer: FC<CustomDragLayerProps> = () => {
    const [dragValue, setDragValue] = useState<boolean>(false);
    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();
    const timerRef = useRef<NodeJS.Timer>();
    const unsubscribeRef = useRef<any>();
    const rrr =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))

    const setScrollIntervall = (speed: number, container: HTMLElement) => {
        timerRef.current = setInterval(() => {
            container.scrollBy(0, speed);
        }, 1);
    };
    useEffect(() => {
        if (dragValue) {
            unsubscribeRef.current = monitor.subscribeToOffsetChange(() => {
                const offset = monitor.getClientOffset();
                const container = document.getElementById('main-container'); // container with scrollbar

                if (!offset || !container) return;

                if (offset.y < container.clientHeight / 2 - 100) {
                    if (timerRef.current) clearInterval(timerRef.current);
                    setScrollIntervall(-5, container);
                } else if (offset.y > container.clientHeight / 2 + 100) {
                    if (timerRef.current) clearInterval(timerRef.current);
                    setScrollIntervall(5, container);
                } else if (
                    offset.y > container.clientHeight / 2 - 100 &&
                    offset.y < container.clientHeight / 2 + 100
                ) {
                    if (timerRef.current) clearInterval(timerRef.current);
                }
            });
        } else if (unsubscribeRef.current) {
            if (timerRef.current) clearInterval(timerRef.current);
            unsubscribeRef.current();
        }
    }, [dragValue, monitor]);

    useEffect(() => {
        const unsubscribe = monitor.subscribeToStateChange(() => {
            if (monitor.isDragging()) setDragValue(() => true);
            else if (!monitor.isDragging()) setDragValue(() => false);
        });

        return () => {
            unsubscribe();
        };
    }, [monitor]);

    const {isDragging, item, initialOffset, currentOffset } = rrr;

    if (!isDragging) {
        return null
    }



    return (
        <div style={layerStyles} id='main-container'>
            <div
                style={getItemStyles(initialOffset, currentOffset)}
            >
                {rrr.itemType == "PROJECT" && <ProjectsListCard project={item} />}
                {rrr.itemType == "TASK" && <TasksBoardItem task={item} showProjectTitle={false} />}
            </div>
        </div>
    )
}