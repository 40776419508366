import React from 'react';
import { Avatar, Button, List, Popconfirm } from 'antd';
import {
    Project, ProjectMemberInvite, useProjectMemberInviteRemoveMutation
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import { TeamOutlined } from '../../c';
import {DeleteOutlined} from "@ant-design/icons";
import UserListItem from "../User/UserListItem";
import {updateProjectInCache} from "../../subscriptions/allProjects";

interface ProjectMembersInvitesProps {
    allowRemove: boolean,
    project: Project,
    invites: ProjectMemberInvite[]
}

const ProjectMembersInvites: React.FC<ProjectMembersInvitesProps> = ({invites, allowRemove}) => {
    const {t} = useTranslation();

    const [Remove] = useProjectMemberInviteRemoveMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    return (
        <div>
            {/*{showErrors([opt1, opt2, opt3])}*/}
            {/*{showLoading([opt1, opt2, opt3])}*/}
            <List
                split={false}
                loading={false}
                itemLayout="horizontal"
                loadMore={false}
                dataSource={invites}
                renderItem={(item) => ( item && <List.Item
						        actions={[
                                    allowRemove && <Popconfirm
                                    icon={null}
                                    title={t('really')}
                                    description={t('inviteSureRemove')}
                                    onConfirm={()=> {
                                        Remove({variables: { id: item.id }})
                                    }
                                    }
                                    onCancel={()=>{}}
                                    okText={t('remove')}
                                    cancelText={t('no')}
                                >
                            <Button type="text" danger><DeleteOutlined rev={undefined} /></Button>
                        </Popconfirm>
                    ]}
					        >
                        {
                            item.user && <UserListItem user={item.user} />
                        }
                        {
                            item.usersUnion && <><List.Item.Meta
                                avatar={<Avatar style={{marginLeft: 5}} ><TeamOutlined rev={undefined} /></Avatar>}
                                title={item.usersUnion.title}
                                description={item.usersUnion.description}
                            />
                                <div>{t('countInvitesUnionMembers', {count: item.maxMembers})}</div>
                            </>
                        }
                        {
                            item.email && <List.Item.Meta
                                avatar={<Avatar style={{marginLeft: 5}} ><TeamOutlined rev={undefined} /></Avatar>}
                                title={item.email}
                                description={t('invitedByEmail')}
                            />
                        }
					{/*	        <AccessLevelSelector selected={item.accessLevel} onChange={(level: AccessLevel) => {*/}

                    {/*    ChangeLevel({variables: {*/}
                    {/*            input: {*/}
                    {/*                userId: item.user.id,*/}
                    {/*                projectId: project.id,*/}
                    {/*                level*/}
                    {/*            }*/}
                    {/*        }})*/}
                    {/*    return true;*/}
                    {/*}*/}
                    {/*} />*/}
					        </List.Item>
                )}
            />
        </div>
    );
};

export default ProjectMembersInvites;