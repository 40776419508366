import React from 'react';
import {Button, Carousel, Divider, Popconfirm } from 'antd';
import {
    InviteResolution,
    UsersUnionMemberInvite,
    useUsersUnionProcessInviteMutation
} from "../../generated-types";
import {CheckOutlined, StopOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Text from 'antd/es/typography/Text';
import UserAvatar from "../User/UserAvatar";

const contentStyle: React.CSSProperties = {
    // margin: 0,
    height: '130px',
    color: '#fff',
    // lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

export interface UsersUnionsInvitesList {
    invites: UsersUnionMemberInvite[]
}

const UsersUnionsInvitesList: React.FC<UsersUnionsInvitesList> = ({invites}) => {
    const {t} = useTranslation();

    const [processInvite] = useUsersUnionProcessInviteMutation({ });

    const acceptInvite = (invite: UsersUnionMemberInvite) => {
        if (!invite) return;
        processInvite({
            variables: {
                inviteId: invite.id,
                resolution: InviteResolution.Accepted
            }
        })
    };

    const declineInvite = (invite: UsersUnionMemberInvite) => {
        if (!invite) return;
        processInvite({
            variables: {
                inviteId: invite.id,
                resolution: InviteResolution.Declined
            }
        })
    };

    return (
        <Carousel>
            {invites.map(i => (
                <div key={i.id}>
                    <div style={contentStyle}>
                        <Text style={{color: contentStyle.color, fontSize: "110%", lineHeight: "2"}}>
                            <UserAvatar avatarUrl={i.inviter?.avatarUrl} />
                            <span style={{padding: 3}}>{i.inviter?.fullName ?? i.inviter?.username}</span>
                            {t('usersUnion.newInvite')}
                            <br/>
                            <b>{i.usersUnion.title}</b>
                            <br/>
                        </Text>
                    <Button ghost onClick={()=>acceptInvite(i)}><CheckOutlined  rev={undefined} />{t('project.acceptInvite')}</Button>
                        <Divider type="vertical"></Divider>
                    <Popconfirm
                        icon={null}
                        title={t('usersUnionInvites.declineInviteTitle')}
                        description={t('usersUnionInvites.declineInviteBody')}
                        onConfirm={()=>declineInvite(i)}
                        onCancel={() => {
                        }}
                        okText={t('decline')}
                        cancelText={t('no')}
                    >
                        <Button type="dashed" ghost><StopOutlined rev={undefined} />{t('project.declineInvite')}</Button>
                    </Popconfirm>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default UsersUnionsInvitesList;