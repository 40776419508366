import styled, {css} from "styled-components";

export const ButtonsContainer = styled.div<{ collapsed?: string }>`

  .ant-space-gap-col-small {
    column-gap: 8px;
  }

  .ant-space-gap-row-small {
    row-gap: 8px;
  }

  .ant-space-item {
    padding: 8px 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 8px;
    }
  }

  ${({collapsed}) =>
          collapsed &&
          css`
            display: flex;
            justify-content: center;
          `};
`

export const ButtonWrapper = styled.div<{ collapsed?: string }>`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 600;

 .anticon{
   width: 24px;
   height: 24px;
   
   svg{
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
 }

  ${({collapsed}) =>
          collapsed &&
          css`
            justify-content: center;
          `};
`