import React, {Key, useState} from 'react';
import {Transfer} from 'antd';
import {Project, ProjectMemberInvite, User, UsersUnion} from "../../generated-types";
import {useTranslation} from "react-i18next";
import UserListItem from "../User/UserListItem";

interface RecordType {
    key: string;
    user: User;
}

interface ProjectInviteMembersSelectorProps {
    invite: ProjectMemberInvite,
    union: UsersUnion,
    project: Project
    items: RecordType[]
    onChange: (selected: User[]) => void
    maxMembers: number
}

const ProjectInviteMembersSelector: React.FC<ProjectInviteMembersSelectorProps> = ({
                                                                                       maxMembers,
                                                                                       invite,
                                                                                       union,
                                                                                       project,
                                                                                       items,
                                                                                       onChange
                                                                                   }) => {

    const {t} = useTranslation()
    const [targetKeys, setTargetKeys] = useState<Key[]>(project.members.map(m => m.user.id));
    const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const onChangeTransfer = (nextTargetKeys: Key[]) => {
        if (nextTargetKeys.length <= maxMembers){
            setTargetKeys(nextTargetKeys);
            onChange(union.members.filter(m => nextTargetKeys.indexOf(m.user.id) > -1).map(m => m.user))
        } else return
    };

    const onSelectChange = (sourceSelectedKeys: Key[], targetSelectedKeys: Key[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        if ([...sourceSelectedKeys, ...targetSelectedKeys].length > maxMembers) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    };

    return (<Transfer
        status={disabled ? 'error' : undefined}
        locale={{
            // titles?: React.ReactNode[];
            // notFoundContent?: React.ReactNode | React.ReactNode[];
            searchPlaceholder: t("usersUnion.processProjectInvite.searchPlaceholder") as string,
            itemUnit: t("usersUnion.processProjectInvite.itemUnit") as string,
            itemsUnit: t("usersUnion.processProjectInvite.itemsUnit") as string,
            remove: t("usersUnion.processProjectInvite.remove") as string,
            selectAll: t("usersUnion.processProjectInvite.selectAll") as string,
            selectCurrent: t("usersUnion.processProjectInvite.selectCurrent") as string,
            selectInvert: t("usersUnion.processProjectInvite.selectInvert") as string,
            removeAll: t("usersUnion.processProjectInvite.removeAll") as string,
            removeCurrent: t("usersUnion.processProjectInvite.removeCurrent") as string
        }}
        className={"ProcessProjectInvite"}
        style={{width: "100%"}}
        dataSource={items}
        titles={[union.title, invite.project.title]}
        targetKeys={targetKeys}
        // Эта штука не работает. Не удается выбрать отдельных пользователей. Из-за этого пришлось ввести RecordType с полем key
        // rowKey={(r) => r.user.id}
        selectedKeys={selectedKeys}
        onChange={onChangeTransfer}
        onSelectChange={onSelectChange}
        render={(item) => <UserListItem key={item.user.id} user={item.user}/>}
    />)
};

export default ProjectInviteMembersSelector;