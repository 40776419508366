import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const Container = styled.div`
 position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap:8px;
`

export const TextData = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`