import React, {useMemo, useState} from "react";
import {Button} from "antd";
import {LeftOutlined, MenuOutlined, RightOutlined} from "@ant-design/icons";
import TweenOne from "rc-tween-one";
import styled from "styled-components";
import {isMobile} from "react-device-detect";


export const CollapsedButton = styled(TweenOne)`
  z-index: 100;
  top: 2px;
  position: fixed;
`

export const Btn = styled(Button)`
  font-size: 16px;
  width: 36px !important;
  height: 36px !important;
  right: initial;
  background-color: #ffffff94;
  border: 1px solid rgb(187, 187, 187);
  box-shadow: rgb(185, 185, 185) 1px 1px 3px 0;
  border-radius: 7px;
`

interface LeftMenuCollapseButtonProps {
    collapsed: boolean
    isMd: boolean
    setCollapsed: (v: boolean) => void
}


let timer: any;

export const LeftMenuCollapseButton: React.FC<LeftMenuCollapseButtonProps> = ({collapsed, isMd, setCollapsed}) => {

    const [moment, setMoment] = React.useState<number | undefined>(0);
    const [hovered, setHovered] = useState(false)

    const left = useMemo(() => {
        if (!isMd) {
            return !collapsed ? '220px' : '54px'
        } else
            return collapsed ? '-16px' : 'initial'
    }, [isMd, collapsed])

    const stop = (timeout: number) => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            setMoment(undefined);
            setTimeout(() => {
                setMoment(0);
            });
            setHovered(false)
        }, timeout)
    }

    const start = () => {
        if (!hovered)
            setHovered(true)
    }

    const icon = hovered ? (collapsed ? <RightOutlined/> : <LeftOutlined/>) : <MenuOutlined/>;

    const btn = <Btn
        type="text"
        icon={icon}
        onClick={() => {
            setCollapsed(!collapsed)
        }}
    />;

    if (isMobile)
        return <div style={{
            left: left,
            // right: isMd ? '-16px' : "initial",
            zIndex: 100,
            top: 2,
            position: "fixed"
        }}>{btn}</div>;

    return <CollapsedButton
        style={{
            left: left,
            right: isMd ? '-16px' : "initial",
            // transform: hovered ? "translateX("+(collapsed ? -10 : 10)+"px)" : undefined
        }}
        animation={{
            x: collapsed ? 7 : -7,
            yoyo: true,
            repeat: 2,
            duration: 1000
        }}
        paused={!hovered}
        moment={moment}
    >
        {btn}
    </CollapsedButton>

}