import React, { useState } from 'react'
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, message, Popconfirm, Row, Typography } from 'antd'
import { Link, useNavigate } from "react-router-dom";
import { InviteResolution, Project, useProcessInviteMutation, useProjectAttachTagsMutation } from "../../generated-types";
import { useTranslation } from "react-i18next";
import { authState } from "../../routes/Auth/authContext";
import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';
import UserAvatar from "../User/UserAvatar";
import { DragPreviewImage, useDrag, useDrop } from "react-dnd";
import { ProjectTasksFastListProps } from "./ProjectTasksFastList";
import Tagger from "../Tagger";
import { updateProjectInCache } from "../../subscriptions/allProjects";
import ProjectFavoriteButton from "./ProjectFavoriteButton";
import { isMobile } from "react-device-detect";
const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'

interface ProjectsListCardProps {
    project: Project,
    showFavorite?: boolean,
    showStatus?: boolean,
    showButtons?: boolean,
    ownerInfo?: any,
    onProjectClick?: (project: Project) => void
}

const LinkWithEvent = ({ children, to, onProjectClick, project }: { children: any, to: string, onProjectClick: any, project: Project }) => {
    return <Link to={to} key="tasks" onClick={(e) => {
        if (onProjectClick) {
            onProjectClick(project)
            e.preventDefault();
            e.stopPropagation()
            return false;
        }
    }}>{children}
    </Link>
}

export const droppedOnProject: ReactiveVar<Project | null> = makeVar<Project | null>(null);

function ProjectsListCard({ ownerInfo, project, onProjectClick, showButtons = true, showStatus = true, showFavorite = true }: ProjectsListCardProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authInfo = useReactiveVar(authState);
    const [inviteResolution, setInviteResolution] = useState<InviteResolution>();
    useNavigate();
    const avatars = project.members != null ?
        project.members.map(m => m != null ? <UserAvatar key={m.user.id} user={m.user} /> : null) : [];



    const [processInvite] = useProcessInviteMutation({
        onCompleted: () => {
            const id = invite?.project?.id;
            if (id)
                navigate(`/project/${id}`)
            setInviteResolution(undefined)
        },
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.processInvite as Project)
        }
    });

    const { totalFiles = 0, totalTasks = 0, doneTasks = 0 } = project.statistics ?? {};
    const donePercent = ((totalTasks ?? 0) == 0) ? 0 : 100.0 * (doneTasks ?? 0) / (totalTasks ?? 0);
    const invite = project.invites?.find(i => i.user?.id == authInfo.user.id)
    const stl = invite
        ? { boxShadow: "#1677ff 0px 0px 4px", background: "#a0c7fd4f" }
        : { marginBottom: 3 };

    const acceptInvite = () => {
        if (!invite) return;
        setInviteResolution(InviteResolution.Accepted)
        processInvite({
            variables: {
                inviteId: invite.id,
                users: [],
                resolution: InviteResolution.Accepted
            }
        })
    };

    const declineInvite = () => {
        if (!invite) return;
        setInviteResolution(InviteResolution.Declined)
        processInvite({
            variables: {
                inviteId: invite.id,
                users: [],
                resolution: InviteResolution.Declined
            }
        })
    };
    const actions: JSX.Element[] = [];

    if (invite) {
        actions.push(<Button type="primary" loading={inviteResolution === InviteResolution.Accepted} onClick={acceptInvite}>
            <CheckOutlined rev={undefined} />{t('project.acceptInvite')}
        </Button>);
        actions.push(<Popconfirm
            icon={null}
            title={t('projectInvites.declineInviteTitle')}
            description={t('projectInvites.declineInviteBody')}
            onConfirm={declineInvite}
            onCancel={() => {
            }}
            okText={t('decline')}
            cancelText={t('no')}
        >
            <Button loading={inviteResolution === InviteResolution.Declined} onClick={declineInvite}>
                <StopOutlined rev={undefined} />{t('project.declineInvite')}
            </Button>
        </Popconfirm>);
    }

    const [projectAttachTags] = useProjectAttachTagsMutation();
    //Удаление тега из проекта, на вход поступает новый массив тегов
    const onTagDelete = (tags: string[]) => {
        projectAttachTags({
            variables: {
                projectId: project.id,
                tags
            },
            update: (cache, r) => {debugger
                updateProjectInCache(cache, r.data?.changedProject as Project)
            },
            onError: (error, clientOptions)=>{
                
                message.error(t('tags.tagDelitionErrorMessage'))
            },
        })
    }
    return <>
    <div >
        <div >
            <Card hoverable
                style={stl}
                bodyStyle={{ padding: 15 }}
                actions={actions}
                onClick={() => {
                    navigate(`/project/${project.id}`)
                }}
            >
                <Row wrap={false} style={{ margin: "-15px -20px 0px -10px" }}>
                    <Col flex="auto">
                        {ownerInfo}
                        <LinkWithEvent to={'/project/' + project.id} project={project} onProjectClick={onProjectClick}>
                            <Typography.Text strong={true} ellipsis={true} style={{
                                whiteSpace: "pre-wrap",
                                width: "100%",
                                margin: "10px"
                            }}>
                                <span style={{ fontSize: "110%" }}>{project.title}</span>
                                {showStatus && <br />}
                                {showStatus && <span style={{
                                    fontWeight: "400",
                                    fontSize: "85%"
                                }}>{t(`project.status.${project.status}`)}</span>}
                            </Typography.Text>
                        </LinkWithEvent>
                    </Col>
                    {showFavorite && !invite && <Col flex="none"><ProjectFavoriteButton project={project} /></Col>}
                </Row>
                <Tagger projectId={project.id} editable={false} block={true} defaultValue={project.attachedTags} onCloseTag={onTagDelete} />
                <span style={{ opacity: false ? 1 : 0.5 }}>
                    {invite
                        ?
                        <Avatar.Group
                            style={{ width: "100%" }}
                            maxCount={4}
                            size="default"
                            maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                        >
                            {<UserAvatar user={invite.inviter} />}
                        </Avatar.Group>
                        :
                        <LinkWithEvent to={`/project/${project.id}`} project={project} onProjectClick={onProjectClick}>
                            <Avatar.Group
                                style={{ width: "100%" }}
                                maxCount={4}
                                size="default"
                                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                            >
                                {avatars}
                            </Avatar.Group>
                        </LinkWithEvent>
                    }
                </span>
                <LinkWithEvent to={`/project/${project.id}/tasks`} key="tasks" project={project} onProjectClick={() => {
                    ProjectTasksFastListProps({ project })
                }}>
                    <Button type={"dashed"}
                        style={{ border: "none" }}><span>{doneTasks}/{totalTasks} активных задач</span></Button>
                </LinkWithEvent>
                {/*<Progress type="circle" percent={donePercent} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}*/}
                {/*          format={() => `${doneTasks}/${totalTasks}`} size={30}*/}
                {/*/>*/}
            </Card></div>
    </div>
    </>
}

export default ProjectsListCard;