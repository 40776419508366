import React from 'react';
import {Button, Carousel, Divider, Popconfirm} from 'antd';
import {
    ProjectTransferRequest,
    useProjectTransferAcceptMutation,
    useProjectTransferRejectMutation,
    useProjectTransferRequestsQuery,
} from "../../generated-types";
import {CheckOutlined, StopOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Text from 'antd/es/typography/Text';
import UserAvatar from "../User/UserAvatar";
import {pollInterval} from "../../ApolloClient";
import {useNavigate} from "react-router-dom";

const contentStyle: React.CSSProperties = {
    height: '130px',
    color: '#fff',
    textAlign: 'center',
    background: '#364d79',
};

export interface ProjectTransfersListProps {
}

const ProjectTransfersList: React.FC<ProjectTransfersListProps> = ({}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const transfers = useProjectTransferRequestsQuery({
        pollInterval: pollInterval * 2
    })

    const [accept] = useProjectTransferAcceptMutation({onCompleted: (data)=>{
            navigate(`/project/${data.projectTransferAccept.id}`)
            transfers.refetch({})
        } });
    const [reject] = useProjectTransferRejectMutation({onCompleted: ()=>{
        transfers.refetch({})
    }});

    const acceptInvite = (transfer: ProjectTransferRequest) => {
        if (!transfer) return;
        accept({
            variables: {
                transferId: transfer.id
            }
        })
    };

    const declineInvite = (transfer: ProjectTransferRequest) => {
        if (!transfer) return;
        reject({
            variables: {
                transferId: transfer.id
            }
        })
    };

    if (!transfers.data?.projectTransferRequests)
        return null;

    const requests = transfers.data?.projectTransferRequests as ProjectTransferRequest[];

    return (
        <Carousel>
            {requests.map((i) => (
                <div key={i.id}>
                    <div style={contentStyle}>
                        <Text style={{color: contentStyle.color, fontSize: "110%", lineHeight: "2"}}>
                            <UserAvatar avatarUrl={i.requester?.avatarUrl} />
                            <span style={{padding: 3}}>{i.requester?.fullName ?? i.requester?.username}</span>
                            {t('project.transferAcceptReject')}
                            <br/>
                            {i.project.title}
                            <br/>
                            {/*{JSON.stringify(i)}*/}
                            {i.usersUnion
                                ? <b>{t('project.transferAcceptRejectInUsersUnion')} {i.usersUnion?.title}</b>
                                : <b>{t('project.transferAcceptRejectInPersonal')}</b>
                            }
                            <br/>
                        </Text>
                    <Button ghost onClick={()=>acceptInvite(i)}><CheckOutlined  rev={undefined} />{t('project.acceptInvite')}</Button>
                        <Divider type="vertical"></Divider>
                    <Popconfirm
                        icon={null}
                        title={t('project.transferRejectTitle')}
                        description={t('project.transferRejectBody')}
                        onConfirm={()=>declineInvite(i)}
                        onCancel={() => {
                        }}
                        okText={t('decline')}
                        cancelText={t('no')}
                    >
                        <Button type="dashed" ghost><StopOutlined rev={undefined} />{t('project.declineInvite')}</Button>
                    </Popconfirm>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default ProjectTransfersList;