import {Button, Col, Divider, InputNumber, Modal, Row, Spin, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import Text from "antd/es/typography/Text";
import ButtonGroup from "antd/es/button/button-group";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {
    AddSlotsInput,
    Tariff,
    usePayments_AddSlotsMutation,
    usePayments_GetPaymentLinkLazyQuery,
    useUserPaymentAccountQuery,
} from "../../generated-types";
import {currencyType} from "../../utils/currencyType";
import {TypesWithAmounts} from "./SlotsTypesWithAmounts";
import {paymentWidgetContext} from "./PaymentWidgetModal";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";


export interface PaymentAccountModalProps {
}


const initial = {
    personalProjectAdditionalMember: 0,
    usersUnion: 0,
    usersUnionMembers: 0,
    usersUnionProjectMember: 0
}

export const AddSlotsModal: React.FC<PaymentAccountModalProps> = () => {
    const [count, setCount] = useState<AddSlotsInput>(initial);
    const [receiptId, setReceiptId] = useState<string | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const {t} = useTranslation();
    const [paymentsAddSlotsMutation, {
        data: addSlotsData,
        loading
    }] = usePayments_AddSlotsMutation();
    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery();
    const [getPaymentLink] = usePayments_GetPaymentLinkLazyQuery();
    const ctx = useReactiveVar(addSlotsContext);
    const tariff = paymentAccount?.tariff as Tariff;
    const paymentAccountId = paymentAccount?.id;
    const currency = currencyType(paymentAccount?.tariff.currency!);

    const onChangeInput = (value: number | null, field: string) => {
        if (value) setCount((prev) => ({...prev, [field]: value}));
    }
    const addCount = (field: string) => {
        setCount((prev) => ({...prev, [field]: count[field as keyof object]! + 1}));
    }
    const deleteCount = (field: string) => {
        setCount((prev) => ({...prev, [field]: count[field as keyof object]! - 1}));
    }

    const addSlots = () => {
        if (addSlotsData?.payments_addSlots.amount) {
            getPaymentLink({
                variables: {
                    input: {
                        receiptId: addSlotsData?.payments_addSlots.id,
                        amount: addSlotsData?.payments_addSlots.amount!
                    },
                    paymentAccountId: paymentAccountId!
                },
                onCompleted: (data) => {
                    paymentWidgetContext({
                        paymentAccountId: paymentAccountId!,
                        confirmationToken: data.payments_getPaymentLink.confirmationToken,
                        receiptId: addSlotsData?.payments_addSlots.id
                    });
                }
            })
        } else {
            return
        }

    }

    useEffect(() => {
        paymentsAddSlotsMutation({
            variables: {
                input: {...count, receiptId: receiptId},
                paymentAccountId: paymentAccountId!
            }
        }).then((res) => setReceiptId(res.data?.payments_addSlots.id!))
    }, [count]);

    if (!open) {
        setOpen(true)
    }

    const onClose = () => {
        addSlotsContext({
            slotsType: undefined
        })
        setOpen(false);
    };

    return (
        <Modal
            title={t('paymentAccount.addSlots')}
            open={open}
            onOk={addSlots}
            onCancel={onClose}
            cancelText={t('paymentAccount.return')}
            okText={t('paymentAccount.pay')}
        >
            <Divider style={{margin: 5, width: `${80}%`}}/>
            <div style={{paddingTop: 20}}>
                {ctx.slotsType && ctx.slotsType.map((s, i) => (
                    <Row style={{alignItems: 'center', justifyContent: "space-between"}} key={i}>
                        <Col style={{flexBasis: `${50}%`}}>
                            <Text style={{display: 'block'}}>{s.description}</Text>
                        </Col>
                        <Col>
                            <Text strong style={{display: 'block'}}>
                                {tariff?.[`${s.type}AdditionalCost` as unknown as keyof Tariff] !== -1 ?
                                    <>
                                        <span>{tariff?.[`${s.type}AdditionalCost` as unknown as keyof Tariff]}</span>
                                        <span>{currency}/{t('tariffsTable.period')}</span>
                                    </>
                                    : ''
                                }
                            </Text>
                        </Col>
                        <Col>
                            <InputNumber
                                disabled={tariff?.[`${s.type}AdditionalCost` as unknown as keyof Tariff] === -1}
                                min={0} max={10} controls={false} defaultValue={1}
                                value={count[s.type as keyof AddSlotsInput]}
                                onChange={(value) => onChangeInput(value as number, s.type)}
                            />
                            <ButtonGroup>
                                <Tooltip color={'#ff4d4f'} title={t('paymentAccount.remove')}>
                                    <Button
                                        disabled={tariff?.[`${s.type}AdditionalCost` as unknown as keyof Tariff] === -1
                                            || count[s.type as keyof AddSlotsInput] === 0}
                                        onClick={() => {
                                            deleteCount(s.type)
                                        }}
                                        icon={<MinusOutlined/>}/>
                                </Tooltip>

                                <Button
                                    disabled={tariff?.[`${s.type}AdditionalCost` as unknown as keyof Tariff] === -1}
                                    onClick={() => addCount(s.type)}
                                    icon={<PlusOutlined/>}/>
                            </ButtonGroup>
                        </Col>
                    </Row>
                ))}
                <Divider style={{width: `${80}%`, marginBottom: 12, marginTop: 12}}/>

                <Row style={{alignItems: 'baseline', justifyContent: "flex-end"}}>
                    <Text style={{marginRight: 8}}>{t('paymentAccount.payNaw')}</Text>
                    {loading ? <Spin/> :
                        <Text strong>{`${addSlotsData?.payments_addSlots.amount} ${currency}`}</Text>
                    }
                </Row>
                <Row style={{alignItems: 'baseline', justifyContent: "flex-end"}}>
                    <Text style={{marginRight: 8}}>{t('paymentAccount.period')}</Text>
                    {loading ? <Spin/> :
                        <Text strong>{`${addSlotsData?.payments_addSlots.amountByPrice} ${currency}`}</Text>
                    }
                </Row>
            </div>
        </Modal>
    )
}

const AddSlotsModalEditor: React.FC<PaymentAccountModalProps> = (props) => {
    const ctx = useReactiveVar(addSlotsContext);
    const slotsType = ctx.slotsType;

    if (!slotsType)
        return null;

    return <AddSlotsModal {...props} />;
};

interface AddSlotsContext {
    slotsType: TypesWithAmounts[] | undefined
}

function emptyState(): AddSlotsContext {
    return {
        slotsType: undefined
    }
}

export const addSlotsContext: ReactiveVar<AddSlotsContext> = makeVar<AddSlotsContext>(emptyState());

export default AddSlotsModalEditor;