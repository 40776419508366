import React, {useEffect, useState} from "react";
import {
    ChildTaskFragmentDoc,
    Project,
    Task,
    useTaskChangeImportanceMutation,
    useTaskChangeTitleMutation
} from "../../generated-types";
import {List} from "antd";
import {BlockOutlined, VerticalAlignMiddleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import AddTaskInput from "./AddTaskInput";
import TaskRemoveBtn from "./TaskRemoveBtn";
import TaskResolved from "./TaskResolved";
import Paragraph from "antd/es/typography/Paragraph";
import {useDrag, useDrop} from "react-dnd";
import {isMobile} from "react-device-detect";
import {makeVar, ReactiveVar} from "@apollo/client";

interface TaskCheckListProps {
    parentTask: Task,
    project: Project,
    childTasks: Task[]
}

export const droppedOnTask: ReactiveVar<Task | null> = makeVar<Task | null>(null);

const CHECKLISTITEM = "CHECKLISTITEM"

const TaskCheckListItem = ({taskItem, onMoveItem, index}: {
    index: number,
    taskItem: Task,
    onMoveItem: (t1: Task, t2: Task) => void
}) => {

    const [, dragRef] = useDrag(
        () => ({
            type: CHECKLISTITEM,
            item: taskItem,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 2 : 1
            })
        }),
        []
    )

    const [{isOver}, drop] = useDrop(() => ({
        accept: CHECKLISTITEM,
        drop: (taskDropped: Task, monitor: any) => {
            if (!monitor.didDrop()) {
                onMoveItem(taskDropped, taskItem)
                droppedOnTask(taskItem)
            }
        },

        collect: (monitor) => ({
            isOver: !isMobile && monitor.isOver(),
            canDrop: !isMobile && monitor.canDrop()
        })
    }))

    const [changedTitle, setChangedTitle] = useState(taskItem.title);

    const [taskChangeTitle] = useTaskChangeTitleMutation({
        // optimisticResponse: ({id, task: ttt}) => {
        //     const members = ttt.members.map(m => ({
        //         id: m.userId,
        //         memberType: m.memberType,
        //         user: project.members.find(pm => pm.user.id == m.userId)?.user
        //     } as any));
        //
        //     // Почему-то не пересчитывается счетчик файлов на карточке на доске.
        //     const files = ttt.files
        //     const {title, tags, status, endTime, endDate, startTime, startDate, description, importance} = ttt;
        //     const res = {
        //         taskSave: {
        //             ...task, title, tags, status, endTime, endDate,
        //             startTime, startDate, description, importance, members, files
        //         }
        //     } as TaskSaveMutation;
        //     return res;
        // },
        // TODO: эта штука не работает для моих задач. Из-за этого пришлось впендюрить pollInterval в TaskViewList
        // refetchQueries: [RefetchesList.TasksByViewId],
    });


    useEffect(() => {
        if (taskItem.title != changedTitle) {
            taskChangeTitle({
                variables: {
                    id: taskItem.id,
                    title: changedTitle
                }
            })
        }
    }, [changedTitle]);

    return (taskItem &&
			<List.Item
							ref={drop}
							style={{
                  border: isOver ? "5px solid #dbdbdb" : undefined
              }}
				actions={[<TaskRemoveBtn
            disabled={
                taskItem.parentTask?.project?.paymentAccount?.isBlocked
                ||
                taskItem.parentTask?.project?.archived
            }
            id={taskItem.id}
            onChange={() => {
            }}/>]}
			>
							<div ref={dragRef}><VerticalAlignMiddleOutlined/></div>
				<TaskResolved
					disabled={
                        taskItem.parentTask?.project?.paymentAccount?.isBlocked
              ||
                        taskItem.parentTask?.project?.archived
          }

										id={taskItem.id}
										value={taskItem.resolved}
										key={"" + taskItem.resolved}/>
                {taskItem.importance}
				<Paragraph editable={{onChange: setChangedTitle, triggerType: ["text", "icon"]}}
				           style={{
                               textDecoration: taskItem.resolved ? "line-through" : undefined,
                       flex: 1,
                       marginBottom: -1,
                       marginLeft: 5
                   }}>
            {changedTitle}
				</Paragraph>
			</List.Item>
    )
}

const byImportance = (p1: Task, p2: Task) => {
    if (p1.importance != p2.importance) return p1.importance < p2.importance ? 1 : -1
    return p1.id > p2.id ? 1 : -1
};

const TaskCheckList = ({childTasks: tasks, project, parentTask}: TaskCheckListProps) => {
    const {t} = useTranslation();

    const childTasks = tasks ? [...tasks].sort(byImportance) : []

    console.log(childTasks)


    const [taskChangeImportance] = useTaskChangeImportanceMutation({
        onCompleted: () => {
        }
    })

    const onMoveItem = (t1: Task, t2: Task) => {
        // TODO: тут надо правильно вычислять важность причем для всех остальных задач - тоже
        const importance = t2.importance + 1;

        taskChangeImportance({
            variables: {
                id: t1.id, importance
            },
            update: (cache, r) => {
                const iid = cache.identify(t1);

                const baseQ = {
                    id: iid,
                    fragment: ChildTaskFragmentDoc,
                    fragmentName: "childTask"
                };

                const tsk = cache.readFragment(baseQ) as Task

                if (tsk)
                    cache.updateFragment(baseQ, () => ({...tsk, importance}))
            },
            optimisticResponse: ({id, importance}) => {
                return {
                    taskChangeImportance: {
                        id: t1.id, importance: importance
                    }
                };
            }

        })
    }

    return <>
        {childTasks && childTasks.length > 0 &&
            <div className={"header"}>
                <BlockOutlined style={{paddingRight: 5}}/>
                {`${t('task.childTasks')} (${childTasks.filter(ct => ct.resolved).length}/${childTasks.length})`}
            </div>}
        {<AddTaskInput placeholder={t('task.addChildTask') as string} ctx={{
            projectId: project.id,
            parentId: parentTask.id,
            //TODO: хз.... что ставить?
            // tasksViewColumnId: task.,
            // taskViewId: taskView.id,
        }} onCompleted={(newChild: Task) => {
            console.log(newChild)
        }}/>}
        {childTasks && childTasks.length > 0 &&
	        <List size={"small"} dataSource={childTasks}
		              renderItem={(child, i) => <TaskCheckListItem index={i} key={child.id + "_" + child.importance}
                                                               taskItem={{...child, importance: i}}
                                                               onMoveItem={onMoveItem}></TaskCheckListItem>}>
            </List>}</>

}

export default TaskCheckList