import {Button, Card, Col, Row} from 'antd';
import React, {CSSProperties, ReactNode, useEffect, useState} from 'react';
import {
    Task,
    TasksView,
    TasksViewColumn,
    TaskViewFilterProject,
    useTaskChangeColumnInViewMutation
} from "../../generated-types";
import {useDrop} from 'react-dnd'
import TasksBoardItem, {droppedOnTask} from "./TasksBoardItem";
import {DIRECTION, SORTFIELD, tasksSorter} from "./TaskViewList";
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import AddTaskInput from "./AddTaskInput";
import {isMobile} from 'react-device-detect';
import {useSwipeable} from "react-swipeable";

interface TasksBoardColumnProps {
    tasks: Task[],
    column: TasksViewColumn,
    taskView: TasksView,
    span: number,
    order: number,
    showProjectTitle: boolean,
    columnHeader: ReactNode
}

const TasksBoardColumn: React.FC<TasksBoardColumnProps> = (props) => {
    const {tasks, column, taskView, span, order, showProjectTitle} = props;

    const [changeColumn] = useTaskChangeColumnInViewMutation({
        // optimisticResponse: ({taskId, taskViewId, overTaskId, columnId}) => {
        //     // TODO: почему-то не работает optimisticResponse. надо доразбираться в причинах
        //     const res = {
        //         taskChangeColumnInView: {
        //             id: taskId,
        //             status: "wip", // TODO: change
        //             tasksViewsPositions: [{
        //                 tasksViewColumnId: columnId,
        //                 position: 1 // TODO: change
        //             }]
        //         }
        //     } as TaskChangeColumnInViewMutation;
        //     return res;
        // }
    })

    const [newTaskPosition, setNewTaskPosition] = useState<[Task | null, Task | null]>([null, null])

    /*
    * Не всегда tasks содержит элементы, если вызывать прямо из drop. Нужно будет разобраться, почему.
    * А пока для этого сделал вызов в useEffect
    * */
    useEffect(() => {
        const [task, underTask] = newTaskPosition;
        if (!task) return;
        const variables = {
            taskId: task.id,
            taskViewId: taskView.id,
            columnId: column.id,
            overTaskId: underTask?.id
        };

        changeColumn({ variables })

        // TODO: из-за кода ниже запускаются запросы. но интерфейс не обновляется.
        // const taskPosition = task.tasksViewsPositions.find(v=>v.tasksViewColumnId == column.id) as TaskInColumn;
        // const underPosition = underTask?.tasksViewsPositions.find(v=>v.tasksViewColumnId == column.id)?.position ?? 0;
        // const upperPositions = tasks.map(t=>t.tasksViewsPositions.find(v=>v.tasksViewColumnId == column.id)?.position ?? 0).filter(v=>v && v > (underPosition ?? 0))
        // const upperPosition = upperPositions && upperPositions.length > 0 ? Math.min(...upperPositions) : underPosition*10
        //
        // client.cache.modify({
        //     fields: {
        //         tasksViewsPositions() {
        //             return [{
        //                 ...taskPosition,
        //                 position: underPosition + (upperPosition - underPosition) / 2,
        //                 tasksViewColumnId: column.id
        //             } as TaskInColumn]
        //         }
        //     },
        //     id: client.cache.identify(task)
        // })

        droppedOnTask(null);
    }, [newTaskPosition]);

    const [{ isOver }, drop] = useDrop(() => ({
            accept: 'TASK',
            drop: (task: Task) => {
                setNewTaskPosition([task, droppedOnTask()])
            },
            collect: (monitor) => ({
                isOver: !isMobile && monitor.isOver(),
                canDrop: !isMobile && monitor.canDrop()
            })
        })
    )


    const styleColumn = {
        borderRadius: 8,
        margin: "0px 5px",
        flexGrow: 1, display: "flex", flexDirection: "column", height: "100%"
    } as CSSProperties;

    const style = {
        borderRadius: 8,
        border: isOver ? `1px solid ${column.color}` : undefined,
        flexGrow: 1, display: "flex", flexDirection: "column", height: "100%"
    } as CSSProperties;

    const cardBodyStyle={
        minHeight: 300,
        // height:'100%',
        height: 'calc(var(--vh, 1vh) * 100 - 205px)',
      //  overflowY:'auto' as "auto",
    };

    return (
            <Col span={span} order={order}
                 ref={drop}
                 role={'COLUMN'}
                 style={style}
            >
                <div style={styleColumn}>
                    <Card className={'card'} bodyStyle={cardBodyStyle} title={props.columnHeader} >
                        {!showProjectTitle && <AddTaskInput
                            isBlocked={taskView.project?.paymentAccount?.isBlocked}
                            ctx={{
                            projectId: (taskView.filterValue as TaskViewFilterProject)?.projectId,
                            tasksViewColumnId: column.id,
                            taskViewId: taskView.id,
                            status: column.title // TODO: нужно убирать зависимость статуса и колонки. слишком сильно уже разрослось
                        }} />}
                        {tasks.map(task=>{
                            let position = task.id + "-" + (task.tasksViewsPositions.find(v=>v.tasksViewColumnId === column.id)?.position ?? 0);
                            return <TasksBoardItem key={position} task={task} showProjectTitle={showProjectTitle} />
                        })}
                    </Card>
                </div>
            </Col>
    )
}

const TasksBoard: React.FC<{tasks: Task[], taskView: TasksView, showProjectTitle: boolean}> = ({tasks, taskView, showProjectTitle}) => {
    const visibleCount = isMobile ? 1 : (taskView.columns?.length ?? 1)
    const {t} = useTranslation();
    const [visibleIndexes, setVisibleIndexes] = useState<number[]>(
        Array.from(Array(visibleCount).keys())
    );

    const span = 24/visibleCount

    const min = Math.min.apply(null, visibleIndexes)
    const max = Math.max.apply(null, visibleIndexes)
    const total = taskView.columns.length
    const showButtons = total > visibleCount;

    const turnRight = () => {
        if (max >= total-1) return;

        let newArr = visibleIndexes.slice(1, visibleCount);
        newArr.push(max+1)
        setVisibleIndexes(newArr)
    }

    const turnLeft = () => {
        if (min <= 0) return;

        let newArr = visibleIndexes.slice(1, visibleCount);
        newArr.push(max-1)
        setVisibleIndexes(newArr)
    }

    const handlers = useSwipeable({
        onSwipedRight: () => turnLeft(),
        onSwipedLeft: () => turnRight(),
    });

    if (!taskView.columns)
        return null;

    return (
        <Row style={{margin: isMobile ? 0 : 5, flexGrow: 1, display: "100%"}} {...handlers}>
                {taskView.columns.map( (column, i) => {
                    if (visibleIndexes.indexOf(i) < 0)
                        return null;

                    let columnTasks: Task[] = tasks.filter(task => task.status === column.title);
                    columnTasks = columnTasks.sort((p1, p2) => tasksSorter(SORTFIELD.position, DIRECTION.DESC, p1, p2, taskView))

                    const header = <div>
                        {showButtons && <Button
                          icon={<LeftOutlined />} type="link"
                          disabled={!(i > 0 && i == min)} onClick={turnLeft}/>}

                        {t(`task.status.${column.title}`)} ({columnTasks.length})

                        {showButtons && <Button
                          icon={<RightOutlined />} type="link"
                          disabled={i == total-1}
                          onClick={turnRight} />}
                    </div>

                    return <TasksBoardColumn key={column.id} tasks={columnTasks} column={column} taskView={taskView} span={span} order={i}
                                             columnHeader={header}
                                             showProjectTitle={showProjectTitle}/>;
                })}
            </Row>
    )
};

export default TasksBoard;
