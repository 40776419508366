import { Button, message, Row } from 'antd'
import React, { MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Slider, { Settings } from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import GeneralBoardColumn, { GeneralBoardColumnType } from './GeneralBoardColumn';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { GeneralBoardItemType } from './GeneralBoardCard';
import { useDrop } from 'react-dnd';


const SliderContainer = styled.div`
width: 100%;
position: relative;
height: 100%;
`

const ArrowsContainer = styled.div`
width: 90%;
min-height: 40px;
position: absolute;
left: 50%;
transform: translate(-50%, 10%);
display: flex;
justify-content: space-between;
align-items: center;
pointer-events: none;
z-index: 1;
`
interface GeneralBoardProps<I, C> {
    signature: string, //сигнатура карточек на доске
    items: GeneralBoardItemType<I>[],
    columns: GeneralBoardColumnType<C>[],
    columnsSplitter: (column: GeneralBoardColumnType<C>, items: GeneralBoardItemType<I>[]) => GeneralBoardItemType<I>[],//обработчик для разделения элементов по столбцам
    onItemRender: (item: I) => React.JSX.Element,//обработчик для отрисовки карточки элемента
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C) => void
    stickyTopElement?: (column: C) => React.JSX.Element
    columnsToShow?: number,
    touchEventsDelay?: number
}

function GeneralBoard<I, C>({ signature, items, columns, columnsSplitter, onItemRender, onItemDropped, stickyTopElement, columnsToShow = 4, touchEventsDelay = 500 }: GeneralBoardProps<I, C>) {

    const sliderRef = useRef<Slider>(null)
    const sliderContainerRef = useRef<HTMLDivElement>(null)
    const visibleCount = isMobile ? 1 : columnsToShow;
    const [currentColumn, setCurrentColumn] = useState(0);
    const [isDraggable, setIsDraggable] = useState(false)
    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false)
    const [isCursorOver, setIsCursorOver] = useState(false)
    const [cursorType, setCursorType] = useState<string | undefined>(undefined)
    const [enableUserSwipe, setEnableUserSwipe] = useState(true)
    const [startX, setStartX] = useState(0)

    //листание слайдера перетаскиванием с нажатой клавишей ctrl
    useEffect(() => {

        const handleKeyDown = (e: any) => {
            if (e.code === 'ControlLeft' || e.code === 'ControlRight') {
                setCtrlKeyPressed(true)
            }
        }

        const handleKeyUp = (e: any) => {
            if (e.code === 'ControlLeft' || e.code === 'ControlRight') {
                setCtrlKeyPressed(false)
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('keyup', handleKeyUp)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('keyup', handleKeyUp)
        }

    }, [])

    useEffect(() => {

        if (isCursorOver && ctrlKeyPressed) {
            setCursorType('grab')
            setIsDraggable(true)
        } else {
            setIsDraggable(false)
            setCursorType(undefined)
        }
    }, [isCursorOver, ctrlKeyPressed])

    const handleMouseDown = (e: MouseEvent) => {
        if (isDraggable && e.button === 0) {
            setCursorType('grabbing')
        }
    }

    const handleMouseUp = (e: MouseEvent) => {
        if (isDraggable && e.button === 0) {
            setCursorType('grab')
        }
    }

    //перелистывание доски если пользователь пытается уйти за край при перетаскиваниии
    const [, drop] = useDrop({
        accept: signature,
        hover: (item, monitor) => {
            if (sliderContainerRef.current) {
                setEnableUserSwipe(false)
                const gap = 30;//расстояние в пикселях от края доски, при котором будет срабатывать перелистывание
                const { left, width } = sliderContainerRef.current.getBoundingClientRect()
                const clientOffsetX = monitor.getClientOffset()?.x ?? 0
                const xPosition = clientOffsetX - left
                if (xPosition < gap) {
                    prevColumn()
                }
                if (xPosition > width - gap) {
                    nextColumn()
                }
            }
        },
        drop: () => {
            setEnableUserSwipe(true)
        }

    }
    )

    const sliderSettings: Settings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: visibleCount,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchMove: isDraggable,
        afterChange(currentSlide) {
            setCurrentColumn(currentSlide)
        },
    };

    const nextColumn = () => {
        sliderRef.current?.slickNext()
    }

    const prevColumn = () => {
        sliderRef.current?.slickPrev()
    }

    //перелистывание доски по свайпу
    const touchStartHandler = (e: TouchEvent) => {
        const touch = e.touches[0]
        setStartX(touch.clientX)
    }

    const touchEndHandler = (e: TouchEvent) => {
        if (enableUserSwipe) {
            const threshold = 100
            const touch = e.touches[0]
            const deltaX = touch.clientX - startX
            if (deltaX > threshold)
                prevColumn()
            else if (deltaX < -threshold)
                nextColumn()

        }
    }

    const buttonStyle: React.CSSProperties = {
        pointerEvents: 'auto'
    }
    return (
        <Row style={{ maxHeight: '100%', height: '100%' }} ref={drop} onTouchStart={touchStartHandler} onTouchMove={touchEndHandler}
        >
            <SliderContainer ref={sliderContainerRef}
                style={{ cursor: cursorType }}
                onMouseEnter={() => setIsCursorOver(true)}
                onMouseLeave={() => setIsCursorOver(false)}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                {visibleCount === 1 && <ArrowsContainer>
                    <Button disabled={currentColumn === 0}
                        style={buttonStyle}
                        icon={<LeftOutlined />}
                        onClick={prevColumn}
                        type="link" />
                    <Button
                        disabled={currentColumn === columns.length - 1}
                        style={buttonStyle}
                        icon={<RightOutlined />}
                        onClick={nextColumn}
                        type="link" />
                </ArrowsContainer>}
                <Slider className='generalBoardSlider' draggable={isDraggable} {...sliderSettings} ref={sliderRef}>
                    {
                        columns.map((column, index) => {
                            const columnItems = columnsSplitter(column, items)
                            const columnTitle = column.title + ` (${columnItems.length})`
                            return <GeneralBoardColumn
                                key={`GB-Col-${index}`}
                                column={column}
                                onItemRender={onItemRender}
                                items={columnItems}
                                signature={signature}
                                columnTitle={columnTitle}
                                onItemDropped={onItemDropped}
                                stickyTopElement={stickyTopElement}
                                isBoardDragging={isDraggable}
                                cursorType={cursorType}
                                touchEventsDelay={touchEventsDelay}
                            />
                        })
                    }
                </Slider>
            </SliderContainer>
        </Row>
    )
}

export default GeneralBoard