import {List} from "antd";
import React, {useState} from "react";
import UserAvatar from "./UserAvatar";
import {User} from "../../generated-types";
import {Link} from "react-router-dom";

const UserListItem = ({user, active = true, asLink = true}: {
    user: User,
    active?: boolean | undefined,
    asLink?: boolean | undefined
}) => {
    const [hovered, setHovered] = useState(false);

    const title = asLink ?
        <Link to={"/User/" + user.id}>{user.fullName ?? user.username}</Link> : user.fullName ?? user.username;

    return (<List.Item.Meta
        style={{
            opacity: active || hovered ? 1 : 0.7
        }}
        avatar={<UserAvatar style={{marginLeft: 5}} avatarUrl={user.avatarUrl} />}
        title={title}
        description={<span>{user.shortInfo ?? user.email}</span>}
    />)
}
// style={{color: active ? undefined: "lightgray"}}

export default UserListItem