import React, {useEffect, useState} from "react";
import TimeSelector, {lbl} from "./TimeSelector";
import {useTranslation} from "react-i18next";

interface TimeIntervalSelectorProps {
    h1?: number | null,
    m1?: number | null,
    h2?: number | null,
    m2?: number | null,
    onChange: (h1: number | null, m1: number | null, h2: number | null, m2: number | null) => void
}

const TimeIntervalSelector: React.FC<TimeIntervalSelectorProps> = ({onChange, h1: ih1, m1: im1, h2: ih2, m2: im2}) => {
    const {t} = useTranslation();
    const [h1, setH1] = useState<number | null>(ih1 ?? null)
    const [m1, setM1] = useState<number | null>(im1 ?? null)
    const [h2, setH2] = useState<number | null>(ih2 ?? null)
    const [m2, setM2] = useState<number | null>(im2 ?? null)

    useEffect(() => {
        onChange(h1, m1, h2, m2)
    });

    return <div style={{display: "flex", alignItems: "center"}}>
        <span style={{color: "lightgray"}}>{t('task.startTime')}</span>
        <TimeSelector h={h1} m={m1} onChange={(h, m) => {
            setH1(h);
            setM1(m);

            // Если время второе стало меньше, чем первое время, тогда нужно второе время прибавить так,
            // чтобы остался такой же интервал, как был до изменения
            let h22 = (h ?? 0) + (h2 ?? 0) - (h1 ?? 0)
            if (h22 > 23) h22 = 23
            setH2(h22)

            let m22 = (m ?? 0) + (m2 ?? 0) - (m1 ?? 0);
            if (m22 > 59) m22 = 45
            setM2(m22)
            return true;
        }}/>

        <span style={{color: "lightgray"}}>{t('task.endTime')}</span>
        {/*TODO: тут надо посчитать минимальное значение минут, которое может выставляться иначе интервал может быть некорректным*/}
        <TimeSelector key={"" + h2 + m2 + h1 + m1} h={h2} m={m2} minH={h1} minM={h1 === h2 ? m1 : 0}
                      onChange={(h, m) => {
            setH2(h);
            setM2(m);
            return true;
        }}/>
    </div>
}


export const FormatTime = (v: number) => {
    return lbl(Math.floor(v / 60)) + ":" + lbl(v % 60)
}


export const FormatInterval = (start: number, end: number) => {
    return FormatTime(start) + "-" + FormatTime(end)
}

export default TimeIntervalSelector