import React from 'react';
import {Affix, Layout, theme, Typography} from "antd";

const { Content} = Layout;

const {Title} = Typography;

type Props = {
    children?: React.ReactNode
};

const Text: React.FC<Props> = ({children}) => {
    return <p>{children}</p>
}

export const TermsOfUse = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    
    const level=4

    return (
        <Content style={{ margin: '24px' }}>
            <div style={{ fontSize: '120%', padding: 24, minHeight: 360, background: colorBgContainer }}>
                <Affix><Title level={2}>Условия использования</Title></Affix>
            <Title level={level}>1. ОПРЕДЕЛЕНИЯ</Title><Text>1.1. ПО –
            результат интеллектуальной деятельности, программа для ЭВМ «Backline», исключительные права на использование
            которой принадлежат Лицензиару. ПО предназначено для управления проектами путем постановки задач. Подробное
            описание функций ПО указано на Сайте Лицензиара. ПО поддерживает только следующие браузеры: Windows, Mac,
            Linux: Windows IE11 и старше, Chrome, Firefox, Opera (Blink), Yandex.Browser последней версии, Mac OS Safari
            последней версии. iOS 10 и старше: Safari и Chrome. Android 9 и старше: Android браузер по-умолчанию и
            Chrome.</Text>
            <Text>1.2. Лицензия – неисключительное право Лицензиата
            использовать ПО, передаваемое Лицензиату Лицензиаром, с ограничением доступного набора Опций, числа
            Пользователей в команде и срока использования. Ограничения Лицензии выбираются Лицензиатом в Разделе Тарифы
            в момент оформления заказа на Лицензию.</Text><Text>1.3. Опция, или
            Дополнительная Опция – функция ПО, право Лицензиата на использование которой передается Лицензиату в
            соответствии с заказом на Лицензию, оформляемым в Панели управления.</Text><Text
        >1.4. Сайт Лицензиара – Веб-сайт https://backline.moscow/</Text><Text
        >1.5. Пользователь – конечный пользователь ПО, имеющий учетную
            запись для входа в ПО.</Text><Text>1.6. Раздел Управление –
            пользовательский интерфейс для управления функциями и настройками ПО, доступный по адресу&nbsp;<a
                href="https://backline.moscow/profile" rel="nofollow">https://backline.moscow/profile</a>
        </Text><Text>1.7. Раздел Тарифы - пользовательский интерфейс для оплаты
            лицензий, управления подписками и просмотра статуса текущей лицензии, доступный по адресу&nbsp;<a
                href="https://backline.moscow/profile" rel="nofollow">https://backline.moscow/profile</a>
        </Text><Text>1.8. Тариф – стоимость использования ПО, определяемая
            Лицензиаром и размещаемая на Сайте Лицензиара. Лицензиат приобретает Лицензию в Разделе Тарифы согласно
            Тарифу, действующему на момент приобретения Лицензии.</Text><Text>1.9.
            Подписка – автопродление Лицензии на ПО на платной основе по тарифам, опубликованным на сайте
            https://backline.moscow/tariffs.</Text><Text>1.10. Первоначальная
            Лицензия – Лицензия, предшествующая Подписке.</Text><Text>1.11. Команда
            - группа пользователей использующая ПО.</Text><Title level={level}>2. ПРЕДМЕТ ДОГОВОРА</Title><Text
        >2.1. Предметом настоящего Лицензионного договора является
            передача Лицензиаром неисключительных прав использования ПО Лицензиату на условиях простой
            (неисключительной) лицензии путем предоставления доступа к серверу Лицензиара.</Text><Text
        >2.2. Лицензиат вправе использовать ПО следующими способами:</Text><Text
        >2.2.1. Круглосуточно получать доступ к ПО, за исключением
            периодов проведения технических работ;</Text><Text>2.2.2. Использовать
            все функциональные возможности ПО, указанные в Лицензии, с учетом ограничения набора Опций, срока
            использования и количества Пользователей в команде, определяемых Лицензией.</Text><Text
        >2.3. Лицензиат не вправе передавать право использования ПО
            третьим лицам.</Text><Title level={level}>3. ИСПОЛНЕНИЕ ДОГОВОРА</Title><Text
        >3.1. Лицензиар обязан предоставить Лицензиату неисключительную
            Лицензию в течение 5 рабочих дней с момента получения оплаты за Лицензию.</Text><Text
        >3.2. Лицензиат обязан использовать ПО только теми способами,
            которые предусмотрены настоящим Договором, и согласно ограничениям, определяемым Лицензией.</Text><Text
        >3.3. Лицензиату предоставляется техническая поддержка в отношении
            использования ПО в течение всего срока действия Лицензии. Поддержка осуществляется по
            e-mail&nbsp;hi@backline.moscow&nbsp;и в чате по ссылке&nbsp;<a href="https://t.me/+ZHao_pYdqQRmYTdi"
                                                                           rel="nofollow">https://t.me/Backline_support</a>.</Text>
                <Text>3.4.
            Лицензиар может проводить технологические перерывы в предоставлении доступа к ПО, которые рассматриваются
            как предоставление доступа к ПО в штатном режиме и не являются основанием для перерасчета оплаченных
            Лицензиатом сумм. Технологические перерывы могут проводиться в том числе для обновления функциональности ПО.
            Технологические перерывы не превышают 4 часов за один календарный месяц и проводятся в ночное время (с 23:00
            до 06:00 по московскому времени).</Text><Title level={level}>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</Title><Text
        >4.1. В течение срока действия настоящего Договора Лицензиат
            обязуется:</Text><Text>4.1.1. не модифицировать исходный код ПО;</Text><Text
        >4.1.2. не модифицировать внешний вид пользовательских интерфейсов
            ПО, за исключением средств модификации внешнего вида, предусмотренных в Разделе Управление .</Text><Text
        >4.2. Лицензиат обязуется не собирать и не обрабатывать
            персональные данные резидентов ЕС, ЕЭЗ, СНГ с использованием ПО.</Text><Text
        >4.3. Лицензиар вправе изменять Тариф на покупку новых Лицензий
            без предварительного уведомления Лицензиата. Изменение Тарифа никак не влияет на Лицензии, приобретенные до
            изменения Тарифа.</Text><Text>4.4. Лицензиар вправе без
            предварительного уведомления производить обновления ПО, изменять набор функций, внешний вид и логику работы
            ПО.</Text><Text>4.5. Лицензиар оставляет за собой право на
            использование любой информации, материалов Лицензиата, размещенных в открытом доступе на страницах
            Лицензиата в сети «Интернет», а равно упоминание, ссылки на такие материалы, сайты Лицензиата , в
            коммерческих целях, маркетинговых целях и иных незапрещенных законом целях, за исключением случаев, когда от
            Лицензиата было получено письменное возражение на такое использование, упоминание, ссылку и/или иные способы
            использования материалов, сайтов Лицензиаром.</Text><Title level={level}>5. ПОРЯДОК РАСЧЕТОВ, ПОДПИСКА
            И ПЕРЕДАЧА ПРАВ</Title><Text>5.1 Для приобретения Лицензии, Лицензиат
            оформляет заказ в Разделе Тарифы, и указывает при этом параметры лицензии: набор Опций, максимальное число
            Пользователей в команде и срок использования ПО. Лицензиат оплачивает Лицензиару лицензионное вознаграждение
            за право использования ПО в соответствии с Тарифом и выбранными параметрами Лицензии
            (https://backline.moscow/tariffs). НДС не облагается на основании ст. 149 п. 2 пп.26 НК РФ. Лицензиар не
            выставляет Лицензиату счета-фактуры на основании п.3 ст 169 НК РФ.</Text><Text
        >5.2. В случае оплаты Первоначальной Лицензии через платежные
            системы, Подписка включается автоматически.</Text><Text>5.3. Количество
            Пользователей в команде и срок действия Лицензии по Подписке соответствует Первоначальной Лицензии.</Text><Text
        >5.4. При списании денежных средств по Подписке, возврат средств
            не производится.</Text><Text>5.5. Лицензиат оплачивает выставленный
            счет путем перечисления Лицензиару 100% суммы, указанной в счете.</Text><Text
        >5.6. Лицензиар обязуется передать право использования ПО
            (активировать Лицензию), согласно указанным в Лицензии ограничениям, в течение 5 (пяти) рабочих дней от даты
            поступления оплаты за Лицензию на счет Лицензиара.</Text><Text>5.7.В
            случае, если в течение 10 (десяти) дней от даты оплаты Лицензии Лицензиар не получил от Лицензиата
            письменной претензии, связанной с объемом предоставленных прав, то считается, что неисключительное право
            использования ПО предоставлено Лицензиату в полном объеме надлежащим образом.</Text><Text
        >5.8. Лицензиат вправе отказаться от использования ПО и
            потребовать возврата 100% суммы, уплаченной по настоящему Договору, в течение 10 (десяти) дней от даты
            оплаты Лицензии. По истечении указанного срока возврат средств не производится.</Text><Title level={level}
        >6. РАСШИРЕНИЕ ЛИЦЕНЗИИ</Title><Text>6.1. Для
            того чтобы добавить новые Опции к Лицензии, либо увеличить ограничение на количество Пользователей в
            команде, Лицензиат должен приобрести новую Лицензию в Разделе Тарифы.</Text><Text
        >6.2. Расширение лицензии производится по Тарифу, действующему на
            момент расширения.</Text><Title level={level}>7. ОТВЕТСТВЕННОСТЬ СТОРОН</Title><Text
        >7.1. За неисполнение или ненадлежащее исполнение настоящего
            Договора Стороны несут ответственность в соответствии с законодательством Российской Федерации.</Text><Text
        >7.2. Лицензиар не возмещает Лицензиату и/или третьим лицам
            убытки, понесенные в связи с использованием и/или невозможностью использования ПО, разглашения информации
            третьим лицам в связи с использованием ПО, другие убытки, прямо или косвенно связанные с использованием и/или невозможностью
                использования ПО, включая (но не ограничиваясь) упущенную выгоду.</Text>
            <Text>7.3. Лицензиат несет ответственность за соответствие
                действующему законодательству информации, передаваемой Лицензиатом с использованием ПО, а также за
                действия Пользователей при использовании ПО. В случае предъявления к Лицензиару претензий третьих лиц,
                связанных с содержанием передаваемой информации, Лицензиат самостоятельно и за свой счет обязуется
                урегулировать такие претензии, а также возместить Лицензиару причиненные убытки.</Text><Text
        >7.4. В случае нарушения п.4.2 Договора Лицензиат несет
            материальную ответственность перед Лицензиаром и обязуется возместить в полном объеме судебные расходы,
            штрафы контролирующих органов и иные убытки связанные с нарушением данного пункта.</Text><Text
        >7.5. В случае обнаружения нарушений действующего законодательства
            и при наличии соответствующих распоряжений со стороны правоохранительных и иных уполномоченных
            государственных органов и должностных лиц, Лицензиар оставляет за собой право ограничить доступ Лицензиата к
            ПО, направив Лицензиату соответствующее письменное уведомление.</Text><Text
        >7.6. Лицензиат использует ПО на свой собственный риск. ПО
            предоставляется «как есть». Лицензиар не принимает на себя никакой ответственности, в том числе за
            соответствие ПО целям Лицензиата.</Text><Text>7.7. Любые информацию
            и/или материалы (в том числе загружаемое ПО, файлы, письма, какие-либо инструкции и руководства к действию и
            т.д.), доступ к которым Лицензиат получает с использованием ПО, Лицензиат может использовать на свой
            собственный страх и риск и самостоятельно несет ответственность за возможные последствия использования
            указанных информации и/или материалов, в том числе за ущерб, который это может причинить компьютеру
            Лицензиата и/или третьим лицам, за потерю данных или любой другой вред.</Text><Text
        >7.8. В случае, если Лицензиат совершает спам-рассылку или
            осуществляет иные действия с целью спама посредством ПО Лицензиара и/или его инструментов, Лицензиар
            оставляет за собой право в одностороннем порядке и без предварительного уведомления расторгнуть Договор с
            Лицензиатом. Лицензионное вознаграждение по Договору за неиспользованный период не возвращается.</Text><Text
        >7.9. При любых обстоятельствах ответственность Лицензиара в
            соответствии со статьей 15 Гражданского кодекса Российской Федерации ограничена 10 000 (десятью тысячами)
            рублей РФ и возлагается на него при наличии в его действиях вины.</Text><Title level={level}>8. СРОК
            ДЕЙСТВИЯ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА</Title><Text>8.1. Настоящий
            Договор вступает в силу с момента принятия условий настоящего Договора Лицензиатом и действует в течение
            срока, установленного приобретенной Лицензией. При приобретении новой лицензии, Договор автоматически
            пролонгируется на новый срок на условиях новой приобретенной Лицензии.</Text><Text
        >8.2. Стороны могут расторгнуть настоящий Договор по взаимному
            согласию или по основаниям, предусмотренным законодательством Российской Федерации.</Text><Text
        >8.3. Любая из Сторон вправе в одностороннем порядке отказаться от
            исполнения настоящего Лицензионного договора, уведомив другую Сторону путем направления за 30 (тридцать)
            дней сообщения средствами электронной связи.</Text><Text>8.4. Лицензиар
            вправе вносить изменения в условия настоящего Договора путем публикации на Сайте Лицензиара нового текста
            Договора.</Text><Text>8.5. В случае нарушения Лицензиатом условий
            настоящего Договора, Лицензиар вправе досрочно расторгнуть договор и незамедлительно блокировать доступ к
            серверу без предварительного уведомления Лицензиата.</Text><Title level={level}>9. ОБРАБОТКА
            ПЕРСОНАЛЬНЫХ ДАННЫХ</Title><Text>9.1. Заключение настоящего Договора
            рассматривается Сторонами как поручение, данное Лицензиатом Лицензиару, на обработку персональных данных
            посетителей сайта (сайтов) Лицензиата, предусмотренное частью 3 статьи 6 Федерального закона от 27 июля 2006
            г. № 152-ФЗ «О персональных данных». Целью обработки персональных данных по настоящему поручению является
            обеспечение возможности взаимодействия Лицензиата<br/><br/>- посетителями сайта (сайтов) Лицензиата с
            помощью
            ПО Backline, а также обеспечение доступа Пользователей к ПО. При этом Лицензиат поручает Лицензиару
            осуществление следующих действий (операций) с персональными данными, совершаемых с использованием
            средств автоматизации: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение) после внесения изменений Лицензиатом, извлечение, использование, передачу (распространение,
            доступ), блокирование, удаление, уничтожение. Содержание и перечень обрабатываемых персональных данных
            посетителя сайта(сайтов) Лицензиата:</Text>
            <Text>- ФИО;</Text><Text
        >- адрес электронной почты;</Text><Text
        >- номер телефона;</Text><Text
        >Содержание и перечень обрабатываемых персональных данных
            Пользователей:</Text><Text>- ФИО;</Text><Text
        >- адрес электронной почты;</Text><Text
        >- номер телефона;</Text><Text
        >- должность.</Text><Text
        >9.2. Лицензиат гарантирует:</Text><Text
        >9.2.1. что при обработке персональных данных им соблюдены все
            права субъектов персональных данных, предусмотренные действующим законодательством Российской Федерации в
            области защиты персональных данных;</Text><Text>9.3. Лицензиар
            гарантирует:</Text><Text>9.3.1. что в соответствии со статьей 19
            Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных» им приняты необходимые
            организационные и технические меры для защиты используемых в рамках данного поручения персональных данных от
            неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
            распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных,
            в частности:</Text><Text>- определение угроз безопасности персональных
            данных при их обработке;</Text><Text>- установление правил доступа к
            обрабатываемым персональным данным;</Text><Text>- обнаружение фактов
            несанкционированного доступа к персональным данным и принятие мер по их пресечению;</Text><Text
        >- проведение оценки эффективности принимаемых мер по обеспечению
            безопасности персональных данных и контроля за принимаемыми мерами.</Text><Text
        >9.3.2. что при обработке персональных данных в рамках настоящего
            Договора, Лицензиаром обеспечено выполнение требований к уровням защищенности персональных данных,
            установленных Постановлением Правительства Российской Федерации от 1 ноября 2012 г. № 1119 «Об утверждении
            требований к защите персональных данных при их обработке в информационных системах персональных данных».</Text>
            <Text>9.4. Лицензиар обязуется обрабатывать полученные от Лицензиата
                персональные данные в рамках текущего Договора.</Text><Text>9.5. В
            целях соблюдения прав субъекта персональных данных, предусмотренных Федеральным законом от 27 июля 2006 г. №
            152-ФЗ «О персональных данных» Стороны договорились, что в случае получения Лицензиатом (Оператором
            персональных данных) запроса, содержащего отзыв согласия субъекта персональных данных на обработку
            персональных данных, Лицензиат обязуется в течение семи рабочих дней с момента его получения уведомить
            Лицензиара о необходимости удаления отозванных данных, либо представить субъекту персональных данных
            мотивированный отказ от выполнения запроса.</Text><Title level={level}>10. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ
            СИЛЫ (ФОРС-МАЖОР)</Title><Text>10.1. Стороны освобождаются от
            ответственности за полное или частичное неисполнение обязательств по настоящему Договору, если таковое
            явилось следствием обстоятельств непреодолимой силы, а именно: DDoS атак, пожара, наводнения, землетрясения,
            диверсии, военных действий или изменения законодательства, если эти обстоятельства непосредственно повлияли
            на исполнение обязательств по настоящему Договору. При этом срок исполнения обязательств по настоящему
            Договору отодвигается соразмерно времени, в течение которого действовали такие обстоятельства.</Text><Text
        >10.2. Лицензиар должен в десятидневный срок по электронной почте,
            указанной при регистрации, уведомить Лицензиата о начале и окончании обстоятельств форс-мажора,
            препятствующих исполнению обязательств по настоящему Договору.</Text><Text
        >10.3. Лицензиат должен в десятидневный срок по электронной
            почте&nbsp;hi@backline.moscow&nbsp;Лицензиара о начале и окончании обстоятельств форс-мажора, препятствующих
            исполнению обязательств по настоящему Договору.</Text><Text>10.4. Если
            обстоятельства непреодолимой силы будут вызывать невозможность полного или частичного исполнения
            обязательств по настоящему Договору более 3 (трех) месяцев подряд, то каждая из Сторон вправе расторгнуть
            настоящий Договор в одностороннем внесудебном порядке путем направления уведомления другой Стороне за 15
            (пятнадцать) календарных дней до предполагаемой даты расторжения Договора.</Text>
            <Title level={level}>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</Title>
            <Text
            >11.1. Если какое-либо из условий или положений Договора будет
                признано любым судом или органом административной власти компетентной юрисдикции недействительным или не
                исполнимым, это не повлияет на действительность или возможность принудительного исполнения любых других
                положений или условий Договора, которые остаются после этого полностью действительными, если иное не
                предусмотрено законодательством Российской Федерации.</Text><Text>11.2.
            Все, что не урегулировано настоящим Договором, регулируется в соответствии с законодательством Российской
            Федерации.</Text><Text>11.3. Принимая условия настоящего Договора,
            Лицензиат дает согласие на получение информационных рассылок по указанным при регистрации на сайте и в ПО
            адресам электронной почты и телефонам.</Text><Text>11.4. Условия
            настоящего Договора применяются, если иного не установлено письменным соглашением сторон.</Text>
        </div>
            </Content>
    )
}