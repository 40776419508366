import React, {useState} from 'react';
import {Alert, Col, Drawer, Form, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {
    ExternalFile,
    Task,
    TaskMember,
    TaskSaveMutation,
    useTaskQuery,
    useTaskSaveMutation
} from "../../generated-types";
import TaskCard, {prepareTask, valuesToTaskInput} from "./TaskCard";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";
import Spinner from "../Spinner";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import TaskResolved, {ResolvedColor} from "./TaskResolved";
import TaskRemoveBtn from "./TaskRemoveBtn";


interface TaskEditProps {
    // taskId: Task
}

const TaskEditorModal: React.FC<TaskEditProps> = () => {
    const {t} = useTranslation();
    // const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    // const [task, setTask] = useState<Task>({title: "", description: ""} as Task);
    const ctx = useReactiveVar(editTaskContext);
    // const allP = useReactiveVar(allProjects);

    const taskId = ctx.taskId;


    // TODO: тут не надо пустую строку передавать. как лучше поступить, чтобы избавиться от этого?
    const {data, error, loading} = useTaskQuery({
        variables: {id: taskId ?? ""},
        onCompleted: (data) => {
            const preparedTask = prepareTask(data.task as Task);
            form.setFieldsValue(preparedTask);
        }
    });
    const isBlocked = data?.task.project.paymentAccount?.isBlocked;
    const isArchived =data?.task.project.archived;

    const [taskSave] = useTaskSaveMutation({
        optimisticResponse: ({id, task: ttt}) => {
            const members = ttt.members.map(m => ({
                id: m.userId,
                memberType: m.memberType,
                user: project.members.find(pm => pm.user.id == m.userId)?.user
            } as any));

            // Почему-то не пересчитывается счетчик файлов на карточке на доске.
            const files = ttt.files
            const {title, tags, status, endTime, endDate, startTime, startDate, description, importance} = ttt;
            const res = {
                taskSave: {
                    ...task, title, tags, status, endTime, endDate,
                    startTime, startDate, description, importance, members, files
                }
            } as TaskSaveMutation;
            return res;
        },
        // TODO: эта штука не работает для моих задач. Из-за этого пришлось впендюрить pollInterval в TaskViewList
        // refetchQueries: [RefetchesList.TasksByViewId],
    });

    if (!taskId)
        return null;

    if (!open)
        setOpen(true)

    if (!data)
        return <Spinner />

    const task = data.task as Task;
    const {project} = task;

    const onClose = () => {
        form.submit();//Валидируем поля формы
        setOpen(false);
        editTaskContext({taskId: undefined})
    };
    const save = (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => {
        const variables = {
            id: taskId,
            task: valuesToTaskInput(values, members, files, tags)
        };
        variables.task.status = task.status
        variables.task.projectId = task.project.id

        taskSave({ variables })
    };

    return (
        <div style={{height: "40px", marginTop: "auto"}}>
            <Drawer key="2"
                    title={<>
                        <Row>
                            <Col flex={"auto"}>
                                <span>{t('task.editWindowTitle')}</span>
                                <span className={"taskNumber"} style={{}}>#{task.number}</span>
                            </Col>
                            <Col flex={"40px"}>{task?.id && <TaskResolved
                                disabled={isBlocked ||isArchived}
                                id={task.id}
                                value={task.resolved}/>}</Col>
                            <Col flex={"60px"}><TaskRemoveBtn disabled={isBlocked ||isArchived} id={task?.id} onChange={(value)=> {
                                if (value)
                                    onClose()
                            }} /></Col>
                        </Row>
                        <ProjectBreadcrumb projectId={project.id} />
                        {/*<TaskChangeColumn taskView={ta} task={task} />*/}
                    </>}
                    width={720}
                    headerStyle={{backgroundColor: task.resolved ? ResolvedColor : undefined}}
                    onClose={onClose}
                    open={open}
                    bodyStyle={{ paddingBottom: 80, backgroundColor: "#d3d3d321" }}
                // footer={
                //     <Space direction="vertical" style={{ width: '100%' }}>
                //         <Button disabled={isBlocked ||isArchived} type="primary" block onClick={form.submit} htmlType="submit">
                //             {t('task.save')}
                //         </Button>
                //     </Space>
                // }
            >
                <Spinner loading={loading} />
                {error && <Alert type="error" message={error.message} banner />}
                {!loading && !error && <TaskCard projectId={project.id}
								                                 task={task} save={save}
								                                 project={project}
								                                 form={form}
								/>}
            </Drawer>
        </div>
    );
};


// обертка, чтобы не делать лишний запрос на сервер с пустым идентификатором задачи
const TaskEditor: React.FC<TaskEditProps> = (props) => {
    const ctx = useReactiveVar(editTaskContext);
    const taskId = ctx.taskId;

    if (!taskId)
        return null;

    return <TaskEditorModal {...props} />;
};

interface EditTaskContext {
    taskId: string | undefined
}

function emptyState(): EditTaskContext {
    return {
        taskId: undefined
    }
}

export const editTaskContext: ReactiveVar<EditTaskContext> = makeVar<EditTaskContext>(emptyState());

export default TaskEditor;