import { Modal} from "antd";
import {
    InviteResolution, ProcessInviteDocument,
    Project,
    ProjectMemberInvite,
    User,
    UsersUnion
} from "../../generated-types";
import React, {useState} from "react";
import apolloClient from "../../ApolloClient";
import {getI18n} from "react-i18next";
import ProjectInviteMembersSelector from "./ProjectInviteMembersSelector";
import Text from "antd/es/typography/Text";

export let SelectedUsers: User[] = [];

const ProcessProjectInvite = (props: {
    invite: ProjectMemberInvite | null,
    union: UsersUnion,
    onClose: () => void
}) => {
    SelectedUsers = [];
    const {t} = getI18n();
    const [opened, setOpened] = useState(true);

    return <Modal open={opened} width={"100%"} closable={true} centered={true} //footer={null}
                  title={t("usersUnion.processProjectInvite.title")}
                  destroyOnClose={true}
                  onOk={() => {
                      if (!props.invite) return;
                      const variables = {
                          inviteId: props.invite.id,
                          users: SelectedUsers.map(u => u.id),
                          resolution: InviteResolution.Accepted
                      };

                      return apolloClient.mutate({mutation: ProcessInviteDocument, variables})
                          .then(() => {
                              props.onClose()
                          })
                          .catch(() => console.log('Oops errors!'));
                  }} onCancel={() => {
        setOpened(false)
        props.onClose()
    }}
    >
        <ProjectInviteMembersSelector
            maxMembers={props.invite?.maxMembers!}
            invite={props.invite as ProjectMemberInvite} union={props.union}
            project={props.invite?.project as Project}
            onChange={(selected) => {
                SelectedUsers = selected
            }}
            items={props.union.members.map(m => ({
                key: m.user.id,
                user: m.user
            }))}
        />
        {props.invite && <><Text style={{marginTop: 12, display: 'block'}} strong>
            {t('usersUnion.processProjectInvite.maxMembers', {count: props.invite.maxMembers})}
        </Text></>}
    </Modal>
};

export default ProcessProjectInvite