import React from "react";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {paymentWidgetContext} from "./PaymentWidgetModal";
import {Currency, usePayments_GetPaymentLinkLazyQuery} from "../../generated-types";
import { ButtonProps } from "antd/lib/button";

export interface paymentData {
    key?: string;
    paymentDate?: any;
    currency?: Currency;
    amount: number;
    paymentId?: string | null;
    id: string;
}

interface PayButtonProps extends ButtonProps {
    paymentData: paymentData;
    id: string;
}

export const PayButton: React.FC<PayButtonProps> = (props) => {
    const {paymentData, id, ...buttonProps} = props
    const {t} = useTranslation();
    const [getPaymentLink] = usePayments_GetPaymentLinkLazyQuery();
    const getPaymentWidget = (paymentData: paymentData, id: string) => {
        getPaymentLink({
            variables: {
                input: {
                    receiptId: paymentData.id,
                    amount: paymentData.amount
                },
                paymentAccountId: id
            },
            onCompleted: (data) => {
                paymentWidgetContext({
                    paymentAccountId: id,
                    confirmationToken: data.payments_getPaymentLink.confirmationToken,
                    receiptId: paymentData.id
                })
            }
        })

    }
    return <Button size="small" onClick={() => getPaymentWidget(paymentData, id)} {...buttonProps}>
        {t('paymentAccount.pay')}
    </Button>
}