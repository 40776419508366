import { Button } from 'antd';
import React from 'react';
import {
    PlusCircleOutlined
} from '@ant-design/icons';

interface AddButtonProps {
    title: string,
    onClick?: any,
    disabled?:boolean|undefined
}
const AddButton = (props: AddButtonProps) => (
    <Button disabled={props.disabled} size="small" type="dashed" color="primaryBorder" block onClick={props.onClick}
            style={{height: "100%"}} icon={<PlusCircleOutlined rev={undefined} />}>
        {props.title}
    </Button>
);

export default AddButton;