import {ApolloCache, makeVar, ReactiveVar} from "@apollo/client";
import {
    BaseProjectFragmentDoc, FullProjectFragmentDoc,
    Project, ProjectCardDocument,
    User,
    UsersUnion
} from "../generated-types";
import {authState} from "../routes/Auth/authContext";
import {allUsersUnions} from "./allUsersUnions";
import {onlyUnique} from "../utils";

export class AllProjects {
    projects: Project[] = [];

    constructor(projects: Project[] = []) {
        this.projects = projects;
    }

    myProjects(searchProp?: string): Project[] {
        const p = this.projects.filter(p => p.userAsOwner && authState().user.id == p.userAsOwner.id);
        if (searchProp) {
            const search = searchProp.toUpperCase();
            return p.filter((p) => p.title.toUpperCase().includes(search))
        } else {
            return p
        }

    }

    otherUsersProjects(searchProp?: string): Project[] {
        const p = this.projects.filter(p => p.userAsOwner && authState().user.id != p.userAsOwner.id);
        if (searchProp) {
            const search = searchProp.toUpperCase();
            return p.filter((p) => p.title.toUpperCase().includes(search))
        } else {
            return p
        }
    }

    otherUnionsProjects(searchProp?: string): Project[] {
        const {unions} = allUsersUnions();
        const p = this.projects.filter(p => p.usersUnionAsOwner && unions.filter(u => u.id == p.usersUnionAsOwner?.id).length == 0);
        if (searchProp) {
            const search = searchProp.toUpperCase();
            return p.filter((p) => p.title.toUpperCase().includes(search)|| p.usersUnionAsOwner?.title.toUpperCase().includes(search))
        } else {
            return p
        }
    }

    myUnionsProjects(searchProp?: string): Project[] {
        const {unions} = allUsersUnions();
        const p = this.projects.filter(p => p.usersUnionAsOwner && unions.filter(u => u.id == p.usersUnionAsOwner?.id).length > 0);
        if (searchProp) {
            const search = searchProp.toUpperCase();
            return p.filter((p) => p.title.toUpperCase().includes(search) || p.usersUnionAsOwner?.title.toUpperCase().includes(search))
        } else {
            return p
        }
    }

    otherUnions(searchProp?: string): UsersUnion[] {
        return this.otherUnionsProjects(searchProp).map(v => v.usersUnionAsOwner).filter(onlyUnique) as UsersUnion[] ?? [];
    }

    otherUsers(): User[] {
        const users = this.otherUsersProjects().map(p => p.userAsOwner)
        return users
            .filter((thing, i, arr) => {
                return arr.indexOf(arr.find(t => t != undefined && thing != undefined && t.id === thing.id)) === i;
            }) as User[];
    }
}

export const updateProjectInCache = (cache: ApolloCache<any>, inputProject: Project) => {
    let projects = allProjects().projects
    // readQuery иногда возвращает null, почему - непонятно. в инете пишут, что надо чтобы variables
    // были такие же... но в нашем запросе нет variables совсем. Сделал пока конструкцию с allProjects().projects
    // const q = cache.readQuery({ query: ProjectsListDocument }) as any;
    // let projects = q?.projects as Project[];
        if (!projects) {
        console.log('updateProjectInCache.projects', projects)
        return;
    }

    const iid = cache.identify(inputProject);

    const baseQ = {
        id: iid,
        fragment: BaseProjectFragmentDoc,
        fragmentName: "baseProject"
    };

    const fullQ = {
        id: iid,
        fragment: FullProjectFragmentDoc,
        fragmentName: "fullProject"
    };
    const baseProject = cache.readFragment(baseQ) as Project
    const fullProject = cache.readFragment(fullQ) as Project

    if (baseProject)
        cache.updateFragment(baseQ, () => ({...baseProject, ...inputProject}))

    if (fullProject)
        cache.updateFragment(fullQ, () => ({...fullProject, ...inputProject}))

    // Если проекта не было в списке проектов, тогда его надо туда добавить (актуально только при добавлении нового проекта). Если проект есть, изменяем поля в соответствии с инпутом
    const allP = projects.some(p => p.id == inputProject.id) ? projects.map(p=>p.id===inputProject.id? {...p,...inputProject} : p) : [...projects, {...baseProject, ...inputProject}]
    allProjects(new AllProjects(allP));

    /*
    По пока невыясненной причине закомментированный код выглядит логично. Но он не работает.
    Т.е. не вызывается метод рендера левого меню и общего списка заказов, хотя они зависят от allProjects (которая зависит от ProjectsListDocument)

    cache.writeQuery({
        query: ProjectsListDocument,
         variables: {archived: false},
         data: {projects: allP}
    })

    Баг пока остаётся - не работает сразу появление в левом меню, если добавить проект в избранное
    */

    if (fullProject)
        cache.writeQuery({
            query: ProjectCardDocument,
            variables: {id: inputProject.id},
            data: {project: {...fullProject, ...inputProject}}
        })
}

export const allProjects: ReactiveVar<AllProjects> = makeVar<AllProjects>(new AllProjects());
