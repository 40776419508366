import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {
    BankOutlined,
    GlobalOutlined,
    HomeOutlined,
    NotificationOutlined,
    OrderedListOutlined,
    PayCircleOutlined,
    TeamOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import {Affix, Badge, Button, Divider, Layout, Menu, Space, Tooltip} from 'antd';
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../routes/Auth/authContext";
import {allUsersUnions} from "../subscriptions/allUsersUnions";
import {allProjects} from "../subscriptions/allProjects";
import {Project, User, UsersUnion} from "../generated-types";

const { Sider } = Layout;

interface LeftMenuProps {
    contentMargin: number,
    setContentMargin: (number:number) => void
}

 const divider = {
    type: 'divider',
    style: {
        // margin: "10px",
        borderColor: "rgb(124, 124, 124, 0.3)",
    }
};

export const getActiveLeftMenuKey = (items: any[], k: string): string => {
    const res = items.map(v=>{
        if (k.includes(v.key) && k===v.key) return v.key;
        if (v.children) {
            return getActiveLeftMenuKey(v.children, k);
        }
        return null;
    });
    return res.find(v=>v !== null) ?? null
}

// TODO: нужно ли настройки отображения левого меню сохранять на сервере?
export const getLeftMenuOpenKeys = (): string[] | undefined => {
    const txt = localStorage.getItem(`leftMenuOpenKeys`);
    if (txt) {
        return JSON.parse(txt);
    }
    return undefined;
}
export const saveLeftMenuOpenKeys = (keys: string[]): void => {
    localStorage.setItem(`leftMenuOpenKeys`, JSON.stringify(keys));
}

const LeftMenu: React.FC<LeftMenuProps> = () => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState)
    const navigate = useNavigate();
    //Логика своачивания боковой панели
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [openedKeys, setOpenedKeys] = useState<string[] | undefined>(getLeftMenuOpenKeys());
    const [selectedKey, setSelectedKey] = useState<string>(""); // TODO: get from location

    const [isMd, setMd] = useState<boolean>(false) //изменяется на true - false при изменении ширины экрана. 

    const allUnions = useReactiveVar(allUsersUnions);
    const allP = useReactiveVar(allProjects);

    // Показываем вообще все проекты
    // const leftMenuProjectsFilter = (): boolean =>true;

    // Показываем только избранные проекты
    const leftMenuProjectsFilter = (p: Project): boolean =>p.UserMarkers.isFavorite;

    const projectSorter = (p1: Project, p2: Project): number => p1.usersUnionAsOwner?.title+"-"+p1.title > p1.usersUnionAsOwner?.title+"-"+p2.title ? 1 : -1;

    let leftItems: any[] = [ ];

    const noProjectsItem = () => ({
        style: {cursor: "default", paddingLeft: 10, marginTop: -3, marginBottom: -3},
        label: t('noProjects'),
        disabled: true,
        key: Math.random()
    });

    const addTitle = (title: string, children: any[]): any => {
        if (children === undefined || !children)
            children = []

        if (children.length === 0)
            children.push(noProjectsItem());

        leftItems.push(divider)
        const res = {
            style: {cursor: "default", marginLeft: -7, marginBottom: -5, marginTop: -5, padding: 0},
            disabled: false,
            key: title,
            children: children,
            label: <Divider orientation="left" style={{color: "white", marginLeft: -14}}>{title}</Divider>,
        };
        leftItems.push(res)
        return res;
    }

    const addProject = (p: Project): any => {
        const to = `/project/${p.id}`;
        return {
            key: to,
            style: {paddingLeft: 10, marginTop: -3, marginBottom: -3},
            onClick: ()=> {
                setSelectedKey(to)
                navigate(to)
            },
            label: <Tooltip title={p.title} placement="right" mouseEnterDelay={0.5}>
                <Link to={to}>{p.title}</Link>
            </Tooltip>
        };
    }


    const addGroups = (unions: UsersUnion[] | User[] , projects: Project[], icon: any): any[] => {
        const res: any[] = []
        unions.sort((uu1: UsersUnion | User, uu2: UsersUnion | User) => {
            if (uu1.__typename == 'UsersUnion')
                return (uu1 as UsersUnion).title > (uu2 as UsersUnion).title ? -1 : 1
            if (uu1.__typename == 'User')
                return ((uu1 as User).fullName ?? "") > ((uu2 as User).fullName ?? "") ? -1 : 1
            return -1;
        }).map(ou => {
            const filtered = projects
                .filter(p=>p.usersUnionAsOwner?.id === ou.id || p.userAsOwner?.id === ou.id);
                let to = `/${ou.__typename}/${ou.id}`;
                const tt = (ou as UsersUnion)?.title ?? (ou as User)?.username;
                const children = filtered.length > 0 ? filtered.map(addProject) : [noProjectsItem()];
                res.push({
                    style: {},
                    key: to,
                    icon: icon,
                    className: "teamMenuItem",
                    children,
                    label: <Tooltip title={tt} placement="right" mouseEnterDelay={0.5}>
                        <div style={{
                        }} onClick={(e)=>{
                            setSelectedKey(to)
                            navigate(to)
                            e.preventDefault()
                            e.stopPropagation()
                        }}>{tt}</div>
                    </Tooltip>,
                })
            return true;
        })
        return res;
    }

    if (authInfo.user) {
        const myProjects = allP.myProjects().filter(leftMenuProjectsFilter).sort(projectSorter);
        if (myProjects.length > 0) {
            const u = myProjects.find(_=>true)?.userAsOwner;
            const projects = addGroups(u ? [u] : [], myProjects, null)
            const children = projects.find(_=>true).children;
            addTitle(t("personalProjects"), children)
        }

        addTitle(t("usersUnion.teams"),
            allP.myUnionsProjects().length > 0
                ? addGroups(allUnions.unions, allP.myUnionsProjects().concat(allP.otherUnionsProjects())
                    .filter(leftMenuProjectsFilter).sort(projectSorter), <TeamOutlined rev={undefined}/>)
                : []
        )

        addTitle(t("otherUsersProjects"),
            allP.otherUsers().length > 0 ?
            addGroups(allP.otherUsers(), allP.otherUsersProjects().filter(leftMenuProjectsFilter).sort(projectSorter), <UserOutlined rev={undefined} />)
                : []
        )

        leftItems.push(divider)
    }
    else {
        leftItems.push({
            key: '/signin',
            icon: <VideoCameraOutlined rev={undefined} />,
            label: <Link to="/signin">{t('auth.signIn')}</Link>,
        })
        leftItems.push({
            key: '/signup',
            icon: <UploadOutlined rev={undefined} />,
            label: <Link to="/signup">{t('auth.signUp')}</Link>,
        })
        leftItems.push({
            key: '/tariffs',
            icon: <PayCircleOutlined rev={undefined} />,
            label: <Link to="/tariffs">{t('tariffsTable.title')}</Link>,
        })
        leftItems.push({
            key: '/termsofuse',
            icon: <BankOutlined rev={undefined} />,
            label: <Link to="/termsofuse">{t('termsOfUse')}</Link>,
        })
        leftItems.push({
            key: '/contacts',
            icon: <GlobalOutlined rev={undefined} />,
            label: <Link to="/contacts">{t('contacts')}</Link>,
        })
    }

    // Указание выбранного пункта меню в соответствии с адресом страницы
    useEffect(() => {
        const selectedKeyFromPath = getActiveLeftMenuKey(leftItems, window.location.pathname)
        if (selectedKeyFromPath !== selectedKey)
            setSelectedKey(selectedKeyFromPath)
    },[selectedKey]);

    const allKeys = leftItems.map(v=>v.key);
    if (!openedKeys)
        setOpenedKeys(allKeys);


    const logo = <Link to="/">
        <div className={!collapsed ? "logo": "logo logo_mini"}></div>
    </Link>;

    const buttons = <Affix offsetTop={0} style={{overflow:"hidden"}}>
        <div style={{position: "absolute", top: 0, left: 0, width: "100%", backgroundColor: "#001529", overflow:"hidden"}}>
            {logo}
            <Space.Compact block direction={collapsed ? "vertical":"horizontal"} style={{padding: 5, paddingLeft: 40, justifyContent: "space-evenly", alignItems: "center"}}>
                <Tooltip title={t('mainPage')}>
                    <Button icon={<HomeOutlined rev={undefined} />} style={{color: "white"}} ghost={true}
                            onClick={_=>navigate("/")}
                    />
                </Tooltip>
                <Tooltip title={t('notifications')}>
                    <Badge dot={true} offset={[-3, 3]}>
                        <Button icon={<NotificationOutlined rev={undefined} />} style={{color: "white"}} ghost={true}
                                onClick={_=>navigate("/notifications")}
                        />
                    </Badge>
                </Tooltip>
                <Tooltip title={t('myTasks')}>
                    <Button icon={<OrderedListOutlined rev={undefined} />} style={{color: "white"}} ghost={true}
                            onClick={_=>navigate("/mytasks")}
                    />
                </Tooltip>
                <Tooltip title={t('auth.profile')}>
                    <Button data-testid="profile-btn" icon={<UserOutlined rev={undefined} />} style={{color: "white"}} ghost={true}
                            onClick={_=>navigate("/profile")}
                    />
                </Tooltip>
            </Space.Compact>
            {/*<Divider style={{borderColor: divider.style.borderColor, margin: "10px 0px"}} />*/}
        </div>
    </Affix>;

    return <Sider className="left-menu" breakpoint="md" collapsedWidth={isMd ? "0" : "80"} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} onBreakpoint={(broken)=>setMd(broken)}>
        {authInfo.user ? buttons : <Affix>{logo}</Affix>}
        <Affix offsetTop={130} style={{display: !collapsed ? undefined : "none"}}>
            {allKeys.length > 0 && <Menu
                style={{
                    height: "calc(100vh - 180px)",
                    overflowY: "auto"
            }}
                theme="dark"
                mode="inline"
                selectedKeys={[selectedKey]}
                items={leftItems}
                onOpenChange={(v) => {
                    setOpenedKeys(v)
                    saveLeftMenuOpenKeys(v)
                }}
                openKeys={openedKeys}
                defaultOpenKeys={allKeys}
            />}
        </Affix>
    </Sider>

};

export default LeftMenu;


//
// const selectedLang = languages.find(l=>i18next.resolvedLanguage == l.key) || languages[0];
//
// const itemsLanguages: MenuItemType[] = languages.map( l => {
//     let i: MenuItemType = {
//         key: l.key,
//         label: (
//             <a rel="noopener noreferrer" onClick={_ => changeLanguage(l.key)}>{l.title}</a>
//         )
//     };
//     if (i18next.resolvedLanguage == l.key) {
//         i.icon = <CheckOutlined  rev={undefined} />;
//         i.disabled = true;
//     }
//
//     return i;
// })


// TODO: временно убрал переключатель языков. Их надо поправить (разметку и сами переводы дополнить
// leftItems3.push({
//     key: 'languages',
//     label: selectedLang.title,
//     icon: <CommentOutlined rev={undefined} />,
//     children: itemsLanguages
// });
