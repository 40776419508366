import React, { useState } from 'react';
import {
    ApiOutlined,
} from '@ant-design/icons';
import {Alert, Button,
    Col, Empty, Row} from 'antd';
import {
    useYandexDiskGetOAuthLinkQuery,
    useYandexDiskSaveOAuthTokenMutation
} from "../../generated-types";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RefetchList from "../../queries/RefetchesList";

function parseParams(str: string) {
    let pieces = str.split("&"), data: any = {}, i, parts;
    for (i = 0; i < pieces.length; i++) {
        parts = pieces[i].split("=");
        if (parts.length < 2) {
            parts.push("");
        }
        data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
}


interface YandexAuthReturnParams {
    access_token: string
    expires_in: string,
    state: string,
    token_type: string
}

interface ConnectYandexDiskProps {
    children: any,
    allowAttachToken: boolean
}

const ConnectYandexDisk: React.FC<ConnectYandexDiskProps> = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);

    const {data, refetch} = useYandexDiskGetOAuthLinkQuery() // почему-то не работает refetch
    const [saveToken, {error}] = useYandexDiskSaveOAuthTokenMutation({
        onCompleted: () => {
            setInProgress(false)
            refetch()
        },
        refetchQueries: [RefetchList.YandexDiskGetOAuthLink]
    })

    const getToken = () => {
        if (inProgress) return;
        setInProgress(true)
        if (!oauthLink) {
            console.log("oauthLink is null")
            return;
        }

        const w = window.open(oauthLink, '_blank');
        if (w) {
            w.focus()
            let i = 0;
            const timer = setInterval(() => {
                try {
                    i++
                    if (w.location.origin == window.location.origin) {
                        window.focus()
                        clearInterval(timer)
                        let hash = "";
                        try {
                            hash = w.location.hash
                            w.close()
                        }
                        catch (e) {
                            console.log(e)
                        }
                        if (hash == "") {
                            console.log('ERRRRRRORRRRR hash == ""')
                            clearInterval(timer)
                            return;
                        }
                        hash = hash.substring(1, hash.length-1)
                        const params = parseParams(hash) as YandexAuthReturnParams;
                        const {access_token: token, expires_in: expiresIn} = params
                        if (params.state != window.location.href)
                            navigate(params.state)
                        saveToken({
                            variables: {
                                token, expiresIn: +expiresIn
                            }
                        })
                    }
                    if (i > 50)
                        clearInterval(timer)
                } catch (e) {
                    if (i > 50)
                        clearInterval(timer)
                    console.log(e)
                }
            }, 600)
        }
    };

    let oauthLink = data?.yandexDiskGetOAuthLink;
    if (oauthLink)
        oauthLink +="&redirect_uri="+document.location.origin+"&=state"+document.location.pathname;

    return <>
        {oauthLink && <Row style={{minHeight: 30}}>
            <Col>
                <Empty
			            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
			            imageStyle={{ height: 60 }}
			            description={<>{t("file.noConnectedStorage")}</>
                  }
		            >
                    {props.allowAttachToken && <Button type="primary" icon={<ApiOutlined  rev={undefined} />} loading={inProgress}
			                    onClick={getToken}
			            >{t("file.connectYandexDisk")}</Button>}
		            </Empty>
            </Col>
        </Row>}
        {!oauthLink && props.children}
        {error != null && <Alert type="error" message={error.message} banner />}
    </>
}

export default ConnectYandexDisk;
