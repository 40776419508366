import React, {useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, Col, Dropdown, Input, MenuProps, Row, Space} from 'antd'
import {useTranslation} from "react-i18next";
import {Task, TaskMemberType, TasksView, TasksViewColumn, useTasksByViewIdLazyQuery,} from "../../generated-types";
import {addTaskContext} from "./TaskNew";
import TasksList from "./TasksList";
import {DownOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';
import TasksBoard from "./TasksBoard";
import TasksFastList from "./TasksFastList";
import Tagger from "../Tagger";
import {useReactiveVar} from "@apollo/client";
import {allProjects} from "../../subscriptions/allProjects";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {allPersonalTags} from "../../subscriptions/allPersonalTags";
import {onlyUnique} from "../../utils";
import TasksCalendar from "./TasksCalendar";
import AddButton from "../AddButton";
import {pollInterval} from "../../ApolloClient";

interface TaskViewListProps {
    tasksView: TasksView
    allowCreate?: boolean
    showProjectTitle?: boolean
    showHeader?: boolean
    defaultDisplayView?: DisplayView
    defaultDisplayParams?: TaskViewDisplayParams
}

export enum DisplayView {
    list,
    board,
    fastList,
    calendar
}

export enum DisplayType {
    list = 'list',
    board = 'board',
    calendar = 'calendar'
}

export enum SORTFIELD {
    title = "title",
    responsible = "responsible",
    column = "column",
    deadline = "deadline",
    importance = "importance",
    position = "position"
}

const DisplaySortFields = [SORTFIELD.column, SORTFIELD.title, SORTFIELD.responsible, SORTFIELD.importance, SORTFIELD.deadline]

const DisplayViewSortFields = [DisplayType.board, DisplayType.list, DisplayType.calendar]

export enum DIRECTION {
    ASC = 1,
    DESC = -1
}

export const tasksSorter = (sort: SORTFIELD, direction: DIRECTION, p1: Task, p2: Task, tasksView: TasksView): number => {
    if (sort === SORTFIELD.responsible) {
        let v1 = (p1.members.find(m => m.memberType === TaskMemberType.Responsible))?.user.username;
        let v2 = (p2.members.find(m => m.memberType === TaskMemberType.Responsible))?.user.username;
        return (v1 ?? "") > (v2 ?? "") ? direction : -direction
    }

    if (sort === SORTFIELD.title) {
        let v1 = p1.title;
        let v2 = p2.title;
        return (v1 ?? "") > (v2 ?? "") ? direction : -direction
    }

    if (sort === SORTFIELD.position) {
        let colpos1 = p1.tasksViewsPositions.find(v => v.tasksViewId === tasksView.id);
        let colpos2 = p2.tasksViewsPositions.find(v => v.tasksViewId === tasksView.id);

        let res = 0;

        if (colpos1?.tasksViewColumnId === colpos2?.tasksViewColumnId)
            res = (colpos1?.position ?? 0) > (colpos2?.position ?? 0) ? direction : -direction;

        return res;
    }

    if (sort === SORTFIELD.importance) {
        let v1 = p1.importance;
        let v2 = p2.importance;
        return (v1 ?? 0) > (v2 ?? 0) ? direction : -direction
    }

    if (sort === SORTFIELD.deadline) {
        let v1 = p1.endDate;
        let v2 = p2.endDate;
        return (v1 ?? 0) > (v2 ?? 0) ? direction : -direction
    }

    if (sort === SORTFIELD.column) {
        let clmns = tasksView.columns ?? []
        let v1 = clmns.find(clmn => (clmn?.title === p1.status)) as TasksViewColumn;
        let v2 = clmns.find(clmn => (clmn?.title === p2.status)) as TasksViewColumn;
        return clmns.indexOf(v1) > clmns.indexOf(v2) ? direction : -direction
    }

    return p1.id > p2.id ? 1 : -1
};

interface TaskViewDisplayParams {
    displayView: DisplayView,
    sort: SORTFIELD,
    sortDirection: DIRECTION,
    includeResolved: boolean,
    iAmResponsible: boolean,
    tags: string[],
}

// TODO: настройки конкретного отображения задач надо сохранять на сервере для конкретного пользователя и оттуда же считывать
const getDefaultTaskViewDisplayParams = (id: String, defaultDisplayView: DisplayView): TaskViewDisplayParams => {
    const txt = localStorage.getItem(`taskView${id}`);

    let res = {
        displayView: defaultDisplayView,
        sort: SORTFIELD.column,
        includeResolved: true,
        iAmResponsible: false,
        sortDirection: DIRECTION.ASC,
        tags: []
    } as TaskViewDisplayParams;

    if (txt) {
        const saved = JSON.parse(txt);
        saved.displayView = defaultDisplayView;
        if (saved) {
            res = saved as TaskViewDisplayParams
        }
    }

    return res
}

const saveTaskViewDisplayParams = (id: String, p: TaskViewDisplayParams) => {
    localStorage.setItem(`taskView${id}`, JSON.stringify(p));
}

const TaskNew: React.FC<{ taskViewId: string }> = ({taskViewId}) => {
    const {t} = useTranslation();
    const addTaskCtx = useReactiveVar(addTaskContext);
    const allP = useReactiveVar(allProjects);

    if (!addTaskCtx.projectId)
        return null;

    const project = allP.projects.find(p => p.id === addTaskCtx.projectId)
    if (!project)
        return null

    const isBlocked = project.paymentAccount?.isBlocked;
    const isArchived = project.archived;

    return <AddButton
        disabled={isBlocked || isArchived}
        title={t('task.create')}
        onClick={() => {
            addTaskContext({...addTaskCtx, taskViewId});
        }}
        key="1"/>
};


function TaskViewList({
                          tasksView, allowCreate = false, showProjectTitle = true, showHeader = true,
                          defaultDisplayView = DisplayView.board,
                          defaultDisplayParams = undefined
                      }: TaskViewListProps) {
    const {t} = useTranslation();
    const [displayParams, setDisplayParams] = useState(() => {
        return defaultDisplayParams ?? getDefaultTaskViewDisplayParams(tasksView.id, defaultDisplayView);
    });
    const [search, setSearch] = useState<string | undefined>();
    const allP = useReactiveVar(allProjects);
    const allUnions = useReactiveVar(allUsersUnions);
    const personalTags = useReactiveVar(allPersonalTags);

    const [tasksByViewId, {data}] = useTasksByViewIdLazyQuery({
        pollInterval // TODO: переделать на подписку
    })

    useEffect(() => {
        tasksByViewId({
            initialFetchPolicy: "network-only",
            nextFetchPolicy: "cache-first",
            variables: {
                tasksViewId: tasksView.id,
                includeResolved: displayParams.includeResolved,
                iAmResponsible: displayParams.iAmResponsible
            }
        })
    }, [tasksView?.id, displayParams.includeResolved, displayParams.iAmResponsible]);

    let tasks: Task[] = (data?.tasks ?? []) as Task[];

    // Надо собрать все теги с проектов и команд, которые есть в задачах и отобразить в списке тегов
    const tags = useMemo(()=> {
        return tasks.map((t)=>{
            const proj = allP.projects.filter((p)=>p.id === t.project.id).flatMap(p=>p.tags)
            const unions = allUnions.unions.filter((u)=>u.id === t.project.usersUnionAsOwner?.id).flatMap(u=>u.tags)
            const allTags = proj.concat(unions).concat(personalTags.tags);
            return allTags.filter((ct)=>t.tags.some(tt=>tt ===ct.id))
        }).flat().filter(onlyUnique)
    },[tasks])


    if (displayParams.tags && displayParams.tags.length > 0)
        tasks = tasks.filter(t => t.tags.some(tg => {
            return tags.some(ttg => displayParams.tags.some(ftg => ftg == ttg.id) && (tg == ttg.title || tg == ttg.id));
        }))

    //TODO: тут надо будет еще useMemo использовать для сохранения отфильтрованного списка и чтобы лишние расчеты не делались для ускорения

    if (search?.length && search) {
        tasks = tasks.filter((task) => (
            task.project.title.toUpperCase().includes(search) ||
            task.project.usersUnionAsOwner?.title.toUpperCase().includes(search) ||
            task.title.toUpperCase().includes(search) ||
            task.members.some((m) => m.user.username.toUpperCase().includes(search) || m.user.fullName?.toUpperCase().includes(search)) ||
            "#" + task.number == search
        ))
    }

    tasks = tasks.map(v => v).sort((p1, p2) => tasksSorter(displayParams.sort, displayParams.sortDirection, p1, p2, tasksView))
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const sort = e.key as SORTFIELD;
        setDisplayParams({...displayParams, sort})
    };

    const handleMenuDisplayTypeClick:MenuProps['onClick']=(e)=>{
        const display = e.key as DisplayType;
        setDisplayParams({...displayParams, displayView:DisplayView[display]})
    }

    const onSearch = (value: string) => {
        setSearch(value)
    }

    const displayType: MenuProps['items'] = DisplayViewSortFields.map((i) => {
        return ({
            label: t('task.displayType.' + i),
            key: i
        })
    })

    const displayTypeMenuProps = {
        displayType,
        onClick: handleMenuDisplayTypeClick,
    };

    const items: MenuProps['items'] = DisplaySortFields.map(k => {
        return ({
            label: t("task.sortFields." + k),
            key: k
        })
    });

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    useEffect(() => {
        // TODO: надо разобраться, как не вызывать сохранение сразу после загрузки из локалстореджа
        if (!defaultDisplayParams)
            saveTaskViewDisplayParams(tasksView.id, displayParams)
    }, [displayParams]);


    let view;
    switch (displayParams.displayView) {
        case DisplayView.list:
            view = <TasksList tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle}/>
            break;
        case DisplayView.fastList:
            view = <TasksFastList tasks={tasks} taskView={tasksView}/>
            break;
        case DisplayView.board:
            view = <TasksBoard tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle}/>
            break;
        case DisplayView.calendar:
            view = <TasksCalendar tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle}/>
            break;
        default:
            view = <TasksBoard tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle}/>
            break;
    }

    return <div style={{width: "100%", flexGrow: 1, display: "flex", flexDirection: "column", height: "100%"}}>
        {showHeader && <Row style={{padding: "5px 10px"}} justify="start" align="middle" gutter={[5, 5]}>
            <Col sm={8}  md={3}>
                <Dropdown menu={{items:displayTypeMenuProps.displayType, onClick:displayTypeMenuProps.onClick}}>
                    <Button>
                        <Space>
                            {t('task.displayType.'+DisplayView[displayParams.displayView])}
                            <DownOutlined rev={undefined}/>
                        </Space>
                    </Button>
                </Dropdown>
            </Col>

            <Col xs={12} sm={12} md={6} lg={8}>
            <Input allowClear bordered={false} width={"100%"}
                   prefix={<SearchOutlined />}
                    placeholder={t('task.search') as string} onChange={(e) => onSearch(e.target.value?.toUpperCase())}/>
            </Col>

	        <Col xs={12} sm={12} md={5} lg={5}>
                <Tagger projectId={tasksView.project?.id}
                        allowEditProjectTags={false} tags={tags} editable={true} block={true}
                        placeholder={t('task.tasksViewFilterByTags') as string}
                        defaultValue={displayParams.tags}
                        onChanged={(tags) => {
                            setDisplayParams({...displayParams, tags})
                        }}
                />
                {displayParams.displayView !== DisplayView.board && displayParams.displayView !== DisplayView.calendar &&
                    <Space.Compact>
                        <Button icon={displayParams.sortDirection === -1 ? <SortDescendingOutlined rev={undefined}/> :
                            <SortAscendingOutlined rev={undefined}/>}
                                onClick={() => {
                                    setDisplayParams({...displayParams, sortDirection: -displayParams.sortDirection})
                                }}>
                        </Button>
                        <Dropdown menu={menuProps}>
                            <Button>
                                <Space>
                                    {t("task.sortFields." + displayParams.sort)}
                                    <DownOutlined rev={undefined}/>
                                </Space>
                            </Button>
                        </Dropdown>
                    </Space.Compact>
                }
            </Col>

            <Col xs={12} sm={8} md={5} lg={4} style={{display: "flex", flexDirection: "column"}}>

	            <Checkbox
                        defaultChecked={displayParams.includeResolved}
                        checked={displayParams.includeResolved}
                        onChange={() => {
                            setDisplayParams({...displayParams, includeResolved: !displayParams.includeResolved})
                        }}
	            >{t('task.includeResolved')}</Checkbox>
	            <Checkbox
		            checked={displayParams.iAmResponsible}
		            defaultChecked={displayParams.iAmResponsible}
		            onChange={() => {
                    setDisplayParams({...displayParams, iAmResponsible: !displayParams.iAmResponsible})
                        }}
	            >{t('task.iAmResponsible')}</Checkbox>
            </Col>

            {/*{allowCreate && <Col xs={12} sm={8} md={6} lg={5}><TaskNew taskViewId={tasksView.id}/></Col>}*/}
        </Row>}
        {view}
    </div>
}

export default TaskViewList