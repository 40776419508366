import {ApolloClient, createHttpLink, InMemoryCache, split} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {clearToken, tokenKey} from "./routes/Auth/authContext";
import { onError } from '@apollo/client/link/error';
import {ServerParseError} from "@apollo/client/link/http";
import {notification} from "antd";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

// import {createPersistedQueryLink} from "@apollo/client/link/persisted-queries";
// import { sha256 } from 'crypto-hash';

const getBaseUrl = (protocol: string, path: string) => {
    const ssl = window.location.protocol.indexOf("https") > -1 ? 's' : ''
    switch(process.env.NODE_ENV) {
        case 'development':
            return `${protocol}s://dev.backline.moscow/graphql`;
            // return `${protocol}://localhost:4000/graphql`;
        default:
            return `${protocol}${ssl}://${window.location.host}${path}`;
    }
}

const httpLink = createHttpLink({
    uri: getBaseUrl("http", "/graphql")
});

const wsClient = createClient({
    keepAlive: 5000, //ping-pong interval
    disablePong: false,
    lazy: false,
    url: getBaseUrl("ws", '/ws'),
    connectionParams: () => {
        const token = localStorage.getItem(tokenKey);
        return {
            Authorization: "Bearer "+token
        }
    }
});

wsClient.on("error", ()=> {

})

wsClient.on("closed", ()=> {

})

const wsLink = new GraphQLWsLink(wsClient);

const logoutLink = onError(e => {
    if (e.networkError && (e.networkError as ServerParseError).statusCode === 401) {
        clearToken()
        window.location.reload()
    }
    else {
        e.graphQLErrors?.forEach(ee=> {
            notification.error({
                message: ee.message
            });
        })
        console.log(e)
    }
})
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(tokenKey);
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    // wsLink,
    authLink.concat(wsLink),
    authLink.concat(logoutLink.concat(httpLink)),
    // authLink.concat(logoutLink.concat(createPersistedQueryLink({ sha256 }).concat(httpLink))),
    // link: authLink.concat(logoutLink.concat(httpLink)),
    // link: authLink.concat(logoutLink.concat(createPersistedQueryLink({ sha256 }).concat(httpLink))),
    // link: splitLink,
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
    queryDeduplication: false,
    defaultOptions: {
        query: {
            errorPolicy: "all"
        }
    }
});

export const pollInterval = 60000;
export default client