import { Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useState } from 'react';
import axios from 'axios';
import { tokenKey } from '../../routes/Auth/authContext';
import {useTranslation} from "react-i18next";
import {Maybe} from "../../generated-types";

interface AvatarEditorProps {
  userId: string
  avatarUrl?: Maybe<string>
}
const token = localStorage.getItem(tokenKey);

const AvatarEditor: React.FC<AvatarEditorProps> = ({avatarUrl}) => {
  const {t} = useTranslation();
  const avatar:UploadFile[] = [];
  if(avatarUrl){ 
    avatar.push(
      {
        uid: '-1',
        name: 'avatar',
        status: 'done',
        url: avatarUrl,
      }
    )
  }
  const [fileList, setFileList] = useState<UploadFile[]>(avatar);
  
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  
  const uploadImage = ({onSuccess, file, action}:any) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const data = reader.result;
      try {
        await axios.post(action, data, {
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "Content-Type": "image/png" 
          }
        })
        onSuccess('OK')
        message.success(t('Изображение загружено'), 2)
      } catch (e:any) {
        // TODO: write to log
        message.error(e.message, 2)
      }
    }
  }
  return(
    <div className="avatar-editor-contianer">
      <ImgCrop rotationSlider>
        <Upload
          accept="image/png, image/jpeg"
          maxCount={1}
          action="avatar/upload"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onRemove={() => false}
          onPreview={onPreview}
          customRequest={uploadImage}
        >
          {fileList.length < 1 && '+ Загрузить'}
        </Upload>
      </ImgCrop>
      {/* показать кнопку смены аватара, если установлен */}
      {fileList.length > 0 
        && 
        <ImgCrop rotationSlider>
          <Upload className="change-avatar-btn" accept="image/png, image/jpeg" maxCount={1} action="avatar/upload" onChange={onChange} customRequest={uploadImage} showUploadList={false}>Сменить</Upload>
        </ImgCrop>}
  </div>
  )
  
};

export default AvatarEditor;