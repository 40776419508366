import styled, {css} from "styled-components";
import Sider from "antd/es/layout/Sider";
import {Menu} from "antd";

export const LeftSider = styled(Sider)`
  max-height: 100vh;
  overflow: hidden;

  &.ant-layout, &.ant-layout-sider {
    background: #FFFFFF;
  }

  & > .ant-layout-sider-zero-width-trigger {
    background: #fff;
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 2px 1px 8px 0 rgba(0, 0, 224, 0.2);
  }

  &.ant-menu-root,&.ant-menu-inline {
    border-inline-end:none;
  }

  .ant-menu-item,.ant-menu-inline {
    margin-block: 0;
    margin-inline: 0;
  }
`

export const CustomMenu = styled(Menu)`
  height: calc(100vh - 300px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 6px;
  }

  /* для FireFox */
  scrollbar-color: #fff rgba(0, 0, 0, 0.45); /* Цвет скролла, цвет ползунка */
  scrollbar-width: thin; /* Толщина скролла */


  .ant-menu-submenu-title {
    display: flex;
    justify-content: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
  }

  .ant-divider-inner-text {
    color: rgba(0, 0, 0, 0.88);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .ant-menu-submenu-title > .ant-menu-title-content {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

  }

  .ant-menu-submenu-title {
    padding: 12px !important;
    margin: 0 !important;
    width: 100%;
  }

  .anticon {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .ant-menu-item {
    border-radius: 0px;
  }

  .ant-menu-submenu-title {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-menu-submenu-title .ant-menu-title-content {
    //flex-grow: 1;
    //background-color: green;
  }
  
  .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: rgba(0, 0, 0, 0.88);
    transform: none;
    height: 20px;
    width: 20px;
  }

  .ant-menu-submenu-title .group-item-container {
    //background-color: red;
    padding: 0px;
    width: 100%;
    line-height: 40px;
  }

  .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.88);
    //transform: none;
    //height: 20px;
    //width: 20px;
  }

  //.hideCollapseButton > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  //  display: none;
  //}

  .hideCollapseButton li {
    background-color: white;
  }

  .hideCollapseButton > ul > li > .ant-menu-submenu-title > .ant-menu-title-content {
    margin-left: 35px;
  }

  .hideCollapseButton > ul > li > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    left: 25px
  }

  .hideCollapseButton > .ant-menu-submenu-title {
      //cursor: default;
  }

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-item .ant-menu-title-content {
    margin-inline-start: 0px;
  }

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-item-selected {
    background-color: #00000026;;
  }
`
export const ItemContainer = styled.div<{ collapsed?: string }>`
  padding: 12px;

  ${({collapsed}) =>
          collapsed &&
          css`
            padding: 0;
          `};
`

export const TitleContainer = styled.div`
    display: flex;
`

export const TitleText = styled.span<{ collapsed?: string }>`
    display: inline;

  ${({collapsed}) =>
          collapsed &&
          css`
            display: none !important;
          `};
`
