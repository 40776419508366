import {PaymentSlot, PaymentSlotType} from "../../generated-types";
import {t} from "i18next";

export interface TypesWithAmounts {
    type: string,
    description: string,
    freeTotalCount: number,
    freeUsedCount: number,
    payTotalCount: number,
    payUsedCount: number,
    freeEmptySlots:PaymentSlot[],
    payEmptySlots: PaymentSlot[],
    slots: PaymentSlot[]
}

export const slotsTypesWithAmounts = (allSlots: any[], type: string): TypesWithAmounts => {
    const slots = allSlots?.filter(s => s.itemType == PaymentSlotType[type as unknown as keyof typeof PaymentSlotType]);
    return {
        type: PaymentSlotType[type as unknown as keyof typeof PaymentSlotType],
        description: t(`paymentAccount.${PaymentSlotType[type as unknown as keyof typeof PaymentSlotType]}`),
        freeTotalCount: slots.filter(s => s.isCostFree).length,
        freeUsedCount: slots.filter(s => !s.isEmpty && s.isCostFree).length,
        freeEmptySlots: slots.filter(s => s.isEmpty && s.isCostFree),
        payTotalCount: slots.filter(s => !s.isCostFree).length,
        payUsedCount: slots.filter(s => !s.isEmpty && !s.isCostFree).length,
        payEmptySlots: slots.filter(s => s.isEmpty && !s.isCostFree),
        slots
    }
}