import {allUsersUnions} from "../subscriptions/allUsersUnions";
import {allProjects} from "../subscriptions/allProjects";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

const ProjectBreadcrumb: React.FC<{projectId: string}> = ({projectId}) => {

    useReactiveVar(allUsersUnions);
    const allP = useReactiveVar(allProjects);

    const project = allP.projects.find(p=>p.id == projectId)

    let description = [];
    if (project) {
        if (project.usersUnionAsOwner)
            description.push({
                title: <Link to={`/usersUnion/${project.usersUnionAsOwner.id}`}>{project.usersUnionAsOwner.title}</Link>
            });
        if (project.userAsOwner)
            // TODO: если как участник - нужно сделать тут отображение
            description.push({
                title: <Link to={`/user/${project.userAsOwner.id}`}>{project.userAsOwner.username}</Link>
            });

        description.push({
            title: <Link to={`/project/${project.id}`}>{project.title}</Link>
        });
    }
    return (<Breadcrumb items={description} rootClassName={"projectBreadcrumbLink"} />)
}

export default ProjectBreadcrumb