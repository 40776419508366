import React from 'react'
import {Col, Form, FormInstance, Input, Row} from 'antd';
import {Project, User, useUserPaymentAccountQuery} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Text from "antd/es/typography/Text";
import UserAvatar from "../User/UserAvatar";


interface ProjectSettingsProps {
    project: Project,
    save: any,
    form: FormInstance
}

function ProjectSettings(props: ProjectSettingsProps) {
    const {t} = useTranslation();

    const isNew = !props.project?.id;

    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery({
        variables: {id: isNew ? null : props.project.paymentAccount.id}
    });

    return (
        <Form layout="vertical" requiredMark={false} form={props.form}
              initialValues={props.project as any}
              onFinish={props.save}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="title"
                        label={t('project.title')}
                        rules={[{ required: true, message: ""+t('project.titlePlaceholder') }]}
                    >
                        <Input placeholder={""+t('project.titlePlaceholder')} />
                    </Form.Item>
                </Col>
                {!isNew && paymentAccount &&
                    <Col span={12}>
                        <Link to={`/User/${paymentAccount?.id}`}>
                            <Text style={{display: "block", marginBottom: 10}}>{t('usersUnion.paymentAccount')}</Text>
	                        <UserAvatar key={paymentAccount?.id} user={paymentAccount.managers[0] as User}/>
	                        <Text style={{marginLeft: 8}}>{paymentAccount?.title}</Text>
                        </Link>
                    </Col>
                }
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="description"
                        label={""+t('project.description')}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <Input.TextArea rows={4} placeholder={""+t('project.descriptionPlaceholder')} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default ProjectSettings